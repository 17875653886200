import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  toolbar: {
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  control: {
    width: '100%',
    minHeight: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: theme.spacing(2),
      maxWidth: `calc(50% - ${theme.spacing(2)}px)`,
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 'calc(100% / 3)',
    },
  },
  controlWrapper: {
    flex: 1,
  },
  button: {
    flexShrink: 0,
    width: 160,
    marginBottom: 5,
  },
  cell: {
    '&:first-child': {
      paddingLeft: 25,
    },
  },
  time: {
    minWidth: 35,
    marginLeft: 5,
  },
  link: {
    color: theme.palette.text.primary,
  },
  accountStatus: {
    position: 'relative',
    marginLeft: 10,
    textTransform: 'capitalize',

    '&:before': {
      content: '""',
      position: 'absolute',
      left: -5,
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: 2,
      height: 2,
      borderRadius: '50%',
      backgroundColor: theme.palette.text.secondary,
    },
  },
  tabPanel: {
    minHeight: 150,
  },
}));
