import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import SearchAutocomplete from '../../../components/search-autocomplete/SearchAutocomplete';
import { searchByName } from '../../../slices/users.slice';
import { matchSorter } from '../../../utils/array.utils';
import useStyles from './styles';

const SearchChat = (props) => {
  const { onSelectResult, className, value } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSearchByName = useCallback(
    async (inputValue, setOptions, setErrors) => {
      if (inputValue.length > 3) {
        try {
          const responseAction = await dispatch(searchByName(inputValue));
          const response = unwrapResult(responseAction);
          setOptions(response);

          if (
            response.length === 1 &&
            response[0].name.toLowerCase() === inputValue.toLowerCase()
          ) {
            onSelectResult(response[0]);
          }
        } catch (err) {
          setErrors(err.message);
        }
      }
    },
    [dispatch, onSelectResult]
  );

  const debouncedSearchByName = debounce(handleSearchByName, 500);

  return (
    <SearchAutocomplete
      className={cn(classes.input, className)}
      onSearch={debouncedSearchByName}
      onChange={(_, result) => onSelectResult(result)}
      size="medium"
      label={t('searchByUser')}
      variant="outlined"
      getOptionLabel={(option) =>
        `${option.name} ${option.nickname ? `(${option.nickname})` : ''}`
      }
      getOptionSelected={(option, currentValue) =>
        option.name === currentValue.name
      }
      filterOptions={(opts, state) =>
        matchSorter(opts, state.inputValue, ['name', 'nickname'])
      }
      value={value}
    />
  );
};

SearchChat.propTypes = {
  onSelectResult: PropTypes.func.isRequired,
  className: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }),
};

SearchChat.defaultProps = {
  className: '',
  value: null,
};

export default SearchChat;
