import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flex: 1,
    },
  }),
  {
    name: 'Protected',
  }
);
