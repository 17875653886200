import React from 'react';
import SortableTable from '../../../components/sortable-table/SortableTable';
import { formatDouble } from '../../../utils/number.utils';

const TABLE_COLUMNS = [
  {
    id: 'createdAt',
    isSortable: true,
    render: (value) => new Date(value).toLocaleDateString(),
  },
  { id: 'extra' },
  {
    id: 'billing',
    isSortable: true,
    render: (billing) => `${billing.name} ${billing.surname}`,
  },
  {
    id: 'totalPrice',
    isSortable: true,
    render: (value) => formatDouble(value, 2),
  },
];

function InvoicesTable(props) {
  const { orders } = props;

  return (
    <SortableTable
      columns={TABLE_COLUMNS}
      data={orders}
      translationNamespace='shop'
      isSelectAvailable={false}
    />
  );
}

export default InvoicesTable;
