import { equipmentApi } from "../api/api";

export const parseProductSet = (set) => ({
  createdAt: set.created_at,
  description: set.description,
  id: set.id,
  discount: set.discount,
  name: set.name,
  products: set.products,
  updatedAt: set.updated_at,
  price: set.discount
    ? (set.products.reduce((summ, currentValue) => summ + +currentValue.price, 0) +
        set.salary_rates.reduce((summ, currentValue) => summ + +currentValue.rate, 0) +
        +set.profit) *
      (1 - set.discount / 100)
    : set.products.reduce((summ, currentValue) => summ + +currentValue.price, 0) +
      set.salary_rates.reduce((summ, currentValue) => summ + +currentValue.rate, 0) +
      +set.profit,
  competences: set.competences,
  salaryRates: set.salary_rates,
  slotNumber: set.slot_number,
  createdByLocation: set.created_by_location,
  // disableEdit: !!set.created_by_location,
  disableDelete: !!set.created_by_location,
  profit: +set.profit,
  forCp: set.for_cp,
  forTn: set.for_tn,
  equipmentIsTn: set.equipment_is_tn,
  active: set.active,
  forShop: set.for_shop,
  locations: set.locations.map((location) => location.id),
});

export const parseProductSets = (sets) => sets.map((set) => parseProductSet(set));
