import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  phone: {
    position: 'relative',
    display: 'inline-block',

    '& .country-list': {
      backgroundColor: theme.palette.background.paper,
    },
  },

  desen: {
    marginTop: 8,
    marginBottom: 4,
  },

  fullWidth: {
    maxWidth: '100%',
    width: '100%',
  },

  phoneInput: {
    // border: 0,
    width: '100%',
    padding: '7.5px 12px',
    fontSize: '0.86rem',
    border: 'solid 1px rgba(0, 0, 0, 0.23)',
    background: 'transparent',
    color: theme.palette.text.primary,
    borderRadius: 4,

    '&::placeholder': {
      color: 'currentColor',
      opacity: 0.42,
    },

    '&:not([disabled]):hover': {
      outline: 0,
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },

    '&:not([disabled]):active': {
      outline: 0,
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },

    '&:not([disabled]):focus': {
      outline: 0,
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },

    '&:disabled': {
      color: theme.palette.text.disabled,
      borderColor: 'rgba(0, 0, 0, 0.26)',
    },
  },
  legend: {
    width: 'auto',
    height: 11,
    display: 'block',
    padding: 0,
    fontSize: '0.75em',
    maxWidth: 0.01,
    textAlign: 'left',
    transition: 'max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    position: 'absolute',
    top: -5,
    left: 9,
    backgroundColor: theme.palette.background.paper,
  },
  legendShrink: {
    maxWidth: 1000,
    transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
  },
  legendLabel: {
    visibility: 'hidden',
    fontSize: '0.75rem',
  },
  label: {
    top: 2,
    left: 40,
  },
  inputWithLabel: {
    '&::placeholder': {
      opacity: 0,
    },
  },
}));
