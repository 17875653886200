import PropTypes from 'prop-types';

// eslint-disable-next-line
export const DROP_ZONES_THEME = {
  id: PropTypes.number,
  img: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  address: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  planes: PropTypes.arrayOf(PropTypes.string),
  altitudes: PropTypes.arrayOf(PropTypes.number),
  runwaySize: PropTypes.number,
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  services: PropTypes.arrayOf(PropTypes.string),
  email: PropTypes.string,
  phones: PropTypes.arrayOf(PropTypes.string),
  long: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  active: PropTypes.bool,
  primed: PropTypes.bool,
  primedAmount: PropTypes.number,
};

export const DROP_ZONES_DEFAULT = {
  active: false,
  primed: false,
  primedAmount: 0,
  logo: '',
};
