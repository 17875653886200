import { parseOrders } from './orders.services';

export const parseRawUser = (user) => ({
  id: user.id,
  birthDate: user.birth_date,
  birthPlace: user.birth_place || '',
  clientApp: user.client_app,
  acceptedRegulations: user.accepted_regulations,
  acceptedSpeedflyRegulations: user.accepted_speedfly_regulations,
  createdAt: new Date(user.created_at).toString(),
  creationDate: user.creation_date,
  currentBalance: user.current_balance || 0,
  documentNumber: user.document_number || '',
  profileType: user.profile_type || '',
  avatar: user.avatar,
  blood: user.blood || '',
  deletedAt: user.deleted_at,
  email: user.email || '',
  exported: user.exported || '',
  externalId: user.external_id,
  gender: user.gender || '',
  height: user.height || '',
  name: user.name || '',
  pesel: user.pesel || '',
  phone: user.phone ? `+${user.phone}` : '',
  signature: user.signature,
  token: user.token,
  updatedAt: user.updated_at,
  weight: user.weight || '',
  type: user.profile_type || '',
  roles: user.roles || [],
  competence: user.competence || {},
  agent: user.agent,
  totalOrders: user.total_orders || 0,
  soldOrders: user.sold_orders || 0,
  disableDelete: user.profile_type === 'agent',
  orders: user.orders ? parseOrders(user.orders) : [],
  nickname: user.nickname,
});

// eslint-disable-next-line import/prefer-default-export
export const parseRawUsers = (users) => (
  users.map((user) => parseRawUser(user))
);
