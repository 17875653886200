/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { usersApi, ordersApi, productsSetsApi, discountApi } from '../api/api';
import { parseRawUser } from '../services/users.services';
import { parseDiscounts } from '../services/discounts.services';
import { parseProductSets, parseProductSet } from '../services/productSets.services';
import { parseOrders } from '../services/orders.services';
import { setNotification } from './notifications.slice';
import { MESSAGES_TYPES, MESSAGES } from '../constants/constants';
import { fromArray } from '../utils/array.utils';
import { dateToString } from '../utils/date.utils';

export const getAll = createAsyncThunk(
  'agents/getAll',
  async (params, { dispatch, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await usersApi.getAgents({ cancelToken: source.token });

      const responseDiscounts = response.data.map(async (agent) => {
        const agentDiscounts = await discountApi.getByUserId(agent.id);

        return {
          ...parseRawUser(agent),
          discounts: parseDiscounts(agentDiscounts.data),
        };
      });

      const result = await Promise.all(responseDiscounts);

      return result;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { agents } = getState();

      if (Object.values(agents.agentsById).length > 0) {
        return false;
      }

      return true;
    },
  }
);

export const create = createAsyncThunk('agents/create', async (rawData) => {
  try {
    const data = {
      phone: rawData.phone.replace('+', ''),
      name: rawData.name,
      email: rawData.email,
      profile_type: 'agent',
    };

    const response = await usersApi.create(data);

    const parsedAgent = parseRawUser(response.data);

    if (rawData.discount) {
      const responseDiscountsAssign = await discountApi.assignToUser(parsedAgent.id.toString(), [
        rawData.discount,
      ]);

      return {
        ...parsedAgent,
        discounts: responseDiscountsAssign.data,
      };
    }

    return parsedAgent;
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return Promise.reject(err.response.data);
  }
});

export const edit = createAsyncThunk('agents/edit', async (rawData, { getState }) => {
  try {
    const { agents } = getState();
    const agent = agents.agentsById[rawData.id];

    const data = {
      phone: rawData.phone.replace('+', ''),
      name: rawData.name,
      email: rawData.email,
    };

    await usersApi.edit(rawData.id, data);

    if (agent.discounts.length > 0 && !rawData.discount) {
      await discountApi.unasignFromUser(rawData.id.toString(), [agent.discounts[0].id]);

      return {
        ...rawData,
        discounts: [],
      };
    } else if (rawData.discount) {
      const responseDiscountsAssign = await discountApi.assignToUser(rawData.id.toString(), [
        rawData.discount,
      ]);

      return {
        ...rawData,
        discounts: parseDiscounts(responseDiscountsAssign.data),
      };
    }

    return rawData;
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return Promise.reject(err.response.data);
  }
});

export const generateOrders = createAsyncThunk('agents/generateOrders', async (rawData) => {
  try {
    const data = {
      agent_id: rawData.agentId.toString(),
      times: rawData.quantity,
      product_set_id: rawData.productSetId.toString(),
      location_id: rawData.locationId.toString(),
      flight_type: rawData.flightType,
    };

    if (rawData.csv) {
      data.csv = true;
    }

    if (rawData.sendToUser) {
      data.send_to_agent = true;
    }

    const response = await ordersApi.createOrderForAgent(data);

    const agent = await usersApi.getUser(rawData.agentId);

    return {
      agentId: rawData.agentId,
      ordersNumber: rawData.quantity,
      orders: parseOrders(agent.data.orders),
      data: response.data,
    };
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return Promise.reject(err.response.data);
  }
});

export const updateOrder = createAsyncThunk('agents/updateOrder', async (data, { dispatch }) => {
  try {
    const createdAt = new Date(
      typeof data.expiredAt === 'number' ? data.expiredAt : data.expiredAt.getTime()
    );

    const response = await ordersApi.updateForAgent({
      id: data.id.toString(),
      code: data.code,
      flyer: {
        name: data.name,
        phone: data.phone.includes('+') ? data.phone.replace('+', '') : data.phone,
        email: data.email,
      },
      created_at: dateToString(createdAt),
    });

    if (!response.data.success) {
      return Promise.reject();
    }

    dispatch(
      setNotification({
        message: MESSAGES.successEdited,
        isLocalMessage: true,
        options: {
          variant: MESSAGES_TYPES.success,
        },
      })
    );

    return {
      agentId: data.agentId,
      id: data.id,
      code: data.code,
      flyers: [
        {
          name: data.name,
          phone: data.phone,
          email: data.email,
        },
      ],
      createdAt: dateToString(createdAt),
    };
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    dispatch(
      setNotification({
        message: err.response.data.message,
        options: {
          variant: MESSAGES_TYPES.error,
        },
      })
    );

    return Promise.reject(err.response.data);
  }
});

export const downloadOrder = createAsyncThunk(
  'agents/downloadOrder',
  async (data, { dispatch }) => {
    try {
      const response = await ordersApi.findForAgent({
        order_id: data.externalId,
        flight_type: data.flightType,
        pdf: true,
      });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return Promise.reject(err.response.data);
    }
  }
);

export const cancelOrder = createAsyncThunk(
  'agent/cancelOrder',
  async ({ id, userId, totalPrice }, { dispatch, rejectWithValue }) => {
    try {
      await ordersApi.edit(id, {
        user_id: userId.toString(),
        order: {
          canceled: true,
          total_price: totalPrice.toString(),
        },
      });

      return {
        id,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const remove = createAsyncThunk('agents/remove', async (id) => {
  try {
    await usersApi.remove(id);

    return { id };
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return Promise.reject(err.response.data);
  }
});

export const getProductSets = createAsyncThunk(
  'agents/getProductSets',
  async (params, { dispatch, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await productsSetsApi.getByLocationId(null, {
        cancelToken: source.token,
      });

      return parseProductSets(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const createProductSet = createAsyncThunk(
  'agents/createProductSet',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        name: rawData.name,
        for_cp: false,
        for_tn: false,
        equipment_is_tn: false,
        active: false,
      };

      if (rawData.description) {
        data.description = rawData.description;
      }

      const response = await productsSetsApi.create(
        rawData.locations,
        rawData.productIds,
        rawData.competenceIds,
        [],
        data
      );

      return parseProductSet(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const editProductSet = createAsyncThunk(
  'agents/editProductSet',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        name: rawData.name,
        description: rawData.description,
      };

      await productsSetsApi.edit(
        rawData.id,
        rawData.locations.map((el) => el.id),
        rawData.productIds.map((el) => el.id),
        rawData.competences.map((el) => el.id),
        [],
        data
      );

      return rawData;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
export const removeProductSet = createAsyncThunk(
  'agents/removeProductSet',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await productsSetsApi.delete(id);

      dispatch(
        setNotification({
          message: MESSAGES.successDeleted,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const agents = createSlice({
  name: 'agents',
  initialState: {
    agentsById: {},
    agentsOrder: [],
    loading: false,
    isSuccess: false,
    error: '',
    productSets: [],
    ordersById: {},
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getAll.fulfilled]: (state, action) => {
      console.log(action.payload, ' action.payload');
      action.payload.forEach((agent) => {
        state.agentsById[agent.id] = {
          ...agent,
          orders: agent.orders.map((order) => order.id),
        };
        state.ordersById = {
          ...state.ordersById,
          ...fromArray(agent.orders, 'id'),
        };
      });
      state.agentsOrder = action.payload.map((agent) => agent.id);
      state.loading = false;
    },
    [getAll.rejected]: (state) => {
      state.loading = false;
    },
    [create.fulfilled]: (state, action) => {
      state.agentsById[action.payload.id] = action.payload;
      state.agentsOrder.push(action.payload.id);
      state.isSuccess = true;
    },
    [edit.pending]: (state) => {
      state.error = '';
    },
    [edit.fulfilled]: (state, action) => {
      state.agentsById[action.payload.id] = {
        ...state.agentsById[action.payload.id],
        ...action.payload,
      };
      state.isSuccess = true;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
    [remove.pending]: (state) => {
      state.error = '';
    },
    [remove.fulfilled]: (state, action) => {
      delete state.agentsById[action.payload.id];
    },
    [remove.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
    [generateOrders.fulfilled]: (state, action) => {
      state.agentsById[action.payload.agentId] = {
        ...state.agentsById[action.payload.agentId],
        totalOrders:
          state.agentsById[action.payload.agentId].totalOrders + action.payload.ordersNumber,
        orders: action.payload.orders.map((order) => order.id),
      };

      state.ordersById = {
        ...state.ordersById,
        ...fromArray(action.payload.orders, 'id'),
      };
    },
    [updateOrder.fulfilled]: (state, action) => {
      state.agentsById[action.payload.agentId].soldOrders =
        state.agentsById[action.payload.agentId].soldOrders + 1;

      state.ordersById[action.payload.id] = {
        ...state.ordersById[action.payload.id],
        externalId: action.payload.code,
        flyers: action.payload.flyers,
        createdAt: action.payload.createdAt,
      };
    },
    [cancelOrder.fulfilled]: (state, action) => {
      state.ordersById[action.payload.id] = {
        ...state.ordersById[action.payload.id],
        cancelled: true,
      };
    },
    [getProductSets.fulfilled]: (state, action) => {
      state.productSets = action.payload.filter((el) =>
        el.competences.some((competence) => competence.name === 'AGENT')
      );
    },
    [createProductSet.fulfilled]: (state, action) => {
      state.productSets.push(action.payload);
      state.isSuccess = true;
    },
    [createProductSet.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
    [editProductSet.fulfilled]: (state, action) => {
      state.productSets = state.productSets.map((el) => {
        if (el.id === action.payload.id) {
          return action.payload;
        }

        return el;
      });
      state.isSuccess = true;
    },
    [removeProductSet.pending]: (state) => {
      state.error = '';
    },
    [removeProductSet.fulfilled]: (state, action) => {
      state.productSets = state.productSets.filter((el) => el.id !== action.payload.id);
    },
  },
});

export const { resetSuccess, resetError } = agents.actions;

export default agents.reducer;
