import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/sidebar/Sidebar';
import NavList from '../../components/nav-list/NavList';
import Header from '../../components/header/Header';
import { getShopAdminMenu } from './constants';
import useStyles from './styles';

const Dashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const navMenu = useMemo(() => getShopAdminMenu(), []);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Header onDrawerToggle={handleDrawerToggle} title={t('shopPanel')} />
      <Sidebar toggle={handleDrawerToggle} isOpenMobile={mobileOpen}>
        <NavList navMenu={navMenu} />
      </Sidebar>
      <div className={classes.layout}>
        <div className={classes.toolbarOffset} />
      </div>
    </>
  );
};

export default Dashboard;
