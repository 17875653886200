import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Toolbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@material-ui/core';
import DropZone from './components/drop-zone/DropZone';
import DropZoneDialog from './components/drop-zone-dialog/DropZoneDialog';
import PageLoader from '../../components/page-loader/PageLoader';
import { getAll, remove, resetError } from '../../slices/locations.slice';
import LocationsSelectors from '../../selectors/locations.selectors';
import useStyles from './styles';

const ConfirmDialog = ({ isOpen, onClose, onAgree, onDisagree, error }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [codename, setCodename] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    try {
      setIsLoading(true);
      await onAgree(codename);
      setIsLoading(false);
      onClose();
    } catch (err) {
      setIsLoading(false);
    }
  }, [codename, onAgree, onClose]);

  const handleInputChange = useCallback((e) => {
    setCodename(e.target.value);
  }, []);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('confirmDelete')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('dropZones.enterCodename')}
        </DialogContentText>
        <TextField
          className={classes.codenameInput}
          placeholder={t('dropZones.codename')}
          margin="dense"
          onChange={handleInputChange}
          error={!!error}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} color="primary" variant="contained">
          {t('delete')}
        </Button>
        <Button
          onClick={onDisagree}
          color="primary"
          autoFocus
          variant="outlined"
        >
          {t('cancel')}
        </Button>
      </DialogActions>
      {isLoading && <PageLoader />}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired,
  onDisagree: PropTypes.func.isRequired,
  error: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  error: '',
};

const DropZones = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { locations } = useSelector((state) => state);
  const locationsForCP = useSelector(LocationsSelectors.locationsForCP);
  const locationsForCheckin = useSelector(
    LocationsSelectors.locationsForCheckin
  );
  const uncategorizedLocations = useSelector(
    LocationsSelectors.uncategorizedLocations
  );
  const commonLocalizations = useSelector(
    LocationsSelectors.commonLocalizations
  );

  useEffect(() => {
    const promise = dispatch(getAll());

    return () => {
      promise.abort();
    };
  }, [dispatch]);

  const [isDialog, setIsDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsDialog(false);
  }, []);

  const [isConfirmDialog, setIsConfirmDialog] = useState(false);
  const [deletedLocationId, setDeletedLocationId] = useState(null);

  const openConfirmDialog = useCallback((id) => {
    setIsConfirmDialog(true);
    setDeletedLocationId(id);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setIsConfirmDialog(false);
    dispatch(resetError());
  }, [dispatch]);

  const handleDelete = useCallback(
    async (codename) => {
      const responseAction = await dispatch(
        remove({ id: deletedLocationId, codename })
      );
      unwrapResult(responseAction);
    },
    [deletedLocationId, dispatch]
  );

  if (locations.loading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h5" component="h2">
          {t('dropZones.title')}
        </Typography>
        <Button variant="contained" color="primary" onClick={openCreateDialog}>
          {t('dropZones.create')}
        </Button>
      </Toolbar>
      {locationsForCP.length > 0 && (
        <>
          <Typography className={classes.subtitle} variant="h6" component="h3">
            {t('dropZones.forCP')}
          </Typography>
          <div className={classes.block}>
            {locationsForCP.map((item) => (
              <DropZone
                key={item.id}
                id={item.id}
                dz={item}
                onDelete={openConfirmDialog}
              />
            ))}
          </div>
        </>
      )}
      {locationsForCheckin.length > 0 && (
        <>
          <Typography className={classes.subtitle} variant="h6" component="h3">
            {t('dropZones.forCheckin')}
          </Typography>
          <div className={classes.block}>
            {locationsForCheckin.map((item) => (
              <DropZone
                key={item.id}
                id={item.id}
                dz={item}
                onDelete={openConfirmDialog}
              />
            ))}
          </div>
        </>
      )}
      {commonLocalizations.length > 0 && (
        <>
          <Typography className={classes.subtitle} variant="h6" component="h3">
            {t('dropZones.common')}
          </Typography>
          <div className={classes.block}>
            {commonLocalizations.map((item) => (
              <DropZone
                key={item.id}
                id={item.id}
                dz={item}
                onDelete={openConfirmDialog}
              />
            ))}
          </div>
        </>
      )}
      {uncategorizedLocations && uncategorizedLocations.length > 0 && (
        <>
          <Typography className={classes.subtitle} variant="h6" component="h3">
            {t('dropZones.uncategorized')}
          </Typography>
          <div className={classes.block}>
            {uncategorizedLocations.map((item) => (
              <DropZone
                key={item.id}
                id={item.id}
                dz={item}
                onDelete={openConfirmDialog}
              />
            ))}
          </div>
        </>
      )}
      {isDialog && (
        <DropZoneDialog isOpen={isDialog} handleClose={closeCreateDialog} />
      )}
      {isConfirmDialog && (
        <ConfirmDialog
          isOpen={isConfirmDialog}
          onClose={closeConfirmDialog}
          onAgree={handleDelete}
          onDisagree={closeConfirmDialog}
          error={locations.error}
        />
      )}
    </main>
  );
};

export default DropZones;
