export const parseSalaryRate = (salaryRate) => ({
  id: salaryRate.id,
  createdAt: salaryRate.created_at,
  name: salaryRate.name,
  locationId: salaryRate.location_id,
  vat: salaryRate.vat,
  rate: +salaryRate.rate,
  updatedAt: salaryRate.updated_at,
  disableEdit: !!salaryRate.created_by_location,
  disableDelete: !!salaryRate.created_by_location,
  competences: salaryRate.competences,
});

export const parseSalaryRates = (salaryRates) => (
  salaryRates.map((salaryRate) => parseSalaryRate(salaryRate))
);
