import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '6em',
    height: '6em',
    marginBottom: theme.spacing(3),
  },
  text: {
    textAlign: 'center',
  },
}));
