import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, ListItem } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './styles';

const Message = (props) => {
  const {
    name, title, time, isSent,
  } = props;
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');

  if (matches) {
    return (
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.name}>{isSent ? `To: ${name}` : name}</TableCell>
        <TableCell className={classes.title}>{title}</TableCell>
        <TableCell className={classes.time}>{time}</TableCell>
      </TableRow>
    );
  }

  return (
    <ListItem className={classes.item}>
      <div className={classes.row}>
        <div className={classes.name}>{isSent ? `To: ${name}` : name}</div>
        <div className={classes.time}>{time}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.title}>{title}</div>
      </div>
    </ListItem>
  );
};

Message.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  isSent: PropTypes.bool,
};

Message.defaultProps = {
  isSent: false,
};

export default Message;
