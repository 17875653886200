import { createSelector } from 'reselect';

const salaryRates = (state) => state.salaryRates;
const loading = createSelector(salaryRates, (state) => state.loading);
const byId = createSelector(salaryRates, (state) => state.byId);

const getByLocationId = createSelector(
  byId,
  (_, id) => id,
  (state, id) => {
    if (!id) {
      return [];
    }

    return state[id] || [];
  }
);

const SalaryRatesSelectors = {
  getByLocationId,
  loading,
};

export default SalaryRatesSelectors;
