/* eslint-disable import/prefer-default-export */
import { COMPETENCES } from '../../constants/constants';
import { RATE_TYPES } from './constants';

export const getRateTypeByCompetences = (competences) => {
  const competencesNames = competences.map((el) => el.name);
  if (competencesNames.indexOf(COMPETENCES.cameramen) > -1
  && competencesNames.indexOf(COMPETENCES.handycamTandem) > -1) {
    return RATE_TYPES.cameraHandy;
  }

  if (competencesNames.indexOf(COMPETENCES.cameramen) > -1) {
    return RATE_TYPES.camera;
  }

  if (competencesNames.indexOf(COMPETENCES.handycamTandem) > -1) {
    return RATE_TYPES.handy;
  }

  return RATE_TYPES.base;
};
