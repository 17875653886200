import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EquipmentDialog from './equipment-dialog/EquipmentDialog';
import SearchAutocomplete from '../../components/search-autocomplete/SearchAutocomplete';
import PageLoader from '../../components/page-loader/PageLoader';
import SortableTable from '../../components/sortable-table/SortableTable';
import {
  getAll,
  remove,
  searchByMain,
  searchByReserve,
  searchByBagType,
} from '../../slices/equipment.slice';
import { getAll as getLocations } from '../../slices/locations.slice';
import useStyles from './styles';

const TABLE_COLUMNS = [
  {
    id: 'main',
    name: 'mainParachute',
    disablePadding: true,
    isSortable: true,
  },
  { id: 'mainSize', isSortable: true },
  { id: 'reserve', isSortable: true },
  { id: 'reserveSize', isSortable: true },
  { id: 'reserveNextPack', isSortable: true },
  { id: 'harnessName', isSortable: true },
  { id: 'aad', isSortable: true },
  { id: 'aadCounter', isSortable: true },
  { id: 'bagType', isSortable: true },
  { id: 'size', isSortable: true },
  { id: 'canRent', isSortable: true },
  { id: 'rentPrice', isSortable: true },
  { id: 'packed', isSortable: true },
  { id: 'selfPack', isSortable: true },
  { id: 'isTN', isSortable: true },
  { id: 'weight', isSortable: true },
  { id: 'user', render: (data) => data && data.name },
];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { numSelected, onDelete, onSelectSearchResult } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isCreatDialog, setIsCreateDialog] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const openCreateDialog = useCallback(() => {
    setIsCreateDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsCreateDialog(false);
  }, []);

  const handleSelectSearchResult = useCallback(
    (e, data) => {
      onSelectSearchResult(data);
    },
    [onSelectSearchResult]
  );

  const handleSearchByMain = useCallback(
    async (value, setOptions, setError) => {
      if (value.length > 3) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const timout = setTimeout(async () => {
          try {
            const responsAction = await dispatch(searchByMain(value));
            const response = unwrapResult(responsAction);

            setOptions(response);
          } catch (err) {
            setError(err.message);
          }
        }, 500);
        setTimeoutId(timout);
      }
    },
    [dispatch, timeoutId]
  );

  const handleSearchByReserve = useCallback(
    async (value, setOptions, setError) => {
      if (value.length > 3) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const timout = setTimeout(async () => {
          try {
            const responsAction = await dispatch(searchByReserve(value));
            const response = unwrapResult(responsAction);

            setOptions(response);
          } catch (err) {
            setError(err.message);
          }
        }, 500);
        setTimeoutId(timout);
      }
    },
    [dispatch, timeoutId]
  );

  const handleSearchByBagType = useCallback(
    async (value, setOptions, setError) => {
      if (value.length > 3) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const timout = setTimeout(async () => {
          try {
            const responsAction = await dispatch(searchByBagType(value));
            const response = unwrapResult(responsAction);

            setOptions(response);
          } catch (err) {
            setError(err.message);
          }
        }, 500);
        setTimeoutId(timout);
      }
    },
    [dispatch, timeoutId]
  );

  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.row}>
        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          component="h2"
        >
          {t('dictionaries.canopies')}
        </Typography>
      </div>

      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography
              className={classes.selectedTitle}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {`${numSelected} ${t('selected')}`}
            </Typography>

            <Tooltip title={t('delete')}>
              <IconButton
                className={classes.selectedButton}
                aria-label={t('delete')}
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className={classes.controls}>
        <SearchAutocomplete
          className={classes.control}
          onSearch={handleSearchByMain}
          onChange={handleSelectSearchResult}
          size="medium"
          label={t('equipment.searchByMain')}
          getOptionLabel={(option) => option.main}
          getOptionSelected={(option, value) => option.id === value.id}
        />
        <SearchAutocomplete
          className={classes.control}
          onSearch={handleSearchByReserve}
          onChange={handleSelectSearchResult}
          size="medium"
          label={t('equipment.searchByReserve')}
          getOptionLabel={(option) => option.reserve}
          getOptionSelected={(option, value) => option.id === value.id}
        />
        <SearchAutocomplete
          className={classes.control}
          onSearch={handleSearchByBagType}
          onChange={handleSelectSearchResult}
          size="medium"
          label={t('equipment.searchByBagType')}
          getOptionLabel={(option) => option.bagType}
          getOptionSelected={(option, value) => option.id === value.id}
        />
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={openCreateDialog}
        >
          {t('dictionaries.create')}
        </Button>
      </div>
      {isCreatDialog && (
        <EquipmentDialog open={isCreatDialog} onClose={closeCreateDialog} />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectSearchResult: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { equipment, locations } = useSelector((state) => state);

  useEffect(() => {
    const equipmentPromise = dispatch(getAll());
    const locationEquipment = dispatch(getLocations());

    return () => {
      equipmentPromise.abort();
      locationEquipment.abort();
    };
  }, [dispatch]);

  const [searchResult, setSearchResult] = useState(null);

  const handleSetSearchResult = useCallback((data) => {
    setSearchResult(data);
  }, []);

  const [selected, setSelected] = useState([]);

  const handleDeleteSelected = useCallback(() => {
    equipment.all.forEach((el) => {
      if (selected.indexOf(el.id) > -1) {
        dispatch(remove({ id: el.id, userId: el.userId }));
      }
    });
    setSelected([]);
  }, [dispatch, selected, equipment]);

  const [isDialog, setIsDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dialogMode, setDialogMode] = useState('');

  const openDialog = useCallback((data, mode) => {
    setIsDialog(true);
    setDialogData(data);
    setDialogMode(mode);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialog(false);
    setDialogData(null);
    setDialogMode('');
  }, []);

  const handleDelete = useCallback(
    async (data) => {
      await dispatch(remove({ id: data.id, userId: data.userId }));
    },
    [dispatch]
  );

  if (equipment.loading || locations.loading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        onDelete={handleDeleteSelected}
        onSelectSearchResult={handleSetSearchResult}
      />
      <SortableTable
        columns={TABLE_COLUMNS}
        data={searchResult ? [searchResult] : equipment.all}
        translationNamespace="equipment"
        onSelect={setSelected}
        selected={selected}
        onShow={(data) => openDialog(data, 'view')}
        onEdit={(data) => openDialog(data, 'edit')}
        onDelete={handleDelete}
        bodyCellClassName={classes.cell}
        headCellClassName={classes.cell}
      />
      {isDialog && (
        <EquipmentDialog
          open={isDialog}
          onClose={closeDialog}
          data={dialogData}
          mode={dialogMode}
        />
      )}
    </main>
  );
}
