export const ORDER_STATUSES = {
  used: 'used',
  cancelled: 'cancelled',
  sold: 'sold',
  sentToCustomer: 'sentToCustomer',
  waitingDelivery: 'waitingDelivery',
  inProgress: 'inProgress',
  paymentDelayed: 'paymentDelayed',
  notUsed: 'notUsed',
};
