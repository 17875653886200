/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { competencesApi } from '../api/api';
import { parseCompetences, parseCompetence } from '../services/competences.services';

export const getAll = createAsyncThunk(
  'competences/getAll',
  async (data, { rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await competencesApi.getAll({ cancelToken: source.token });

      return parseCompetences(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (data, { getState }) => {
      const { competences } = getState();

      if (competences.all.length > 0) {
        return false;
      }

      return true;
    },
  },
);

export const getNotNeedDoc = createAsyncThunk(
  'competences/getNotNeedDoc',
  async (data, {
    rejectWithValue, signal,
  }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await competencesApi.getNotNeedDoc({ cancelToken: source.token });

      return parseCompetences(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getForUser = createAsyncThunk(
  'competences/getForUser',
  async (userId, { rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await competencesApi.getForUser(
        { user_id: userId },
        { cancelToken: source.token },
      );

      return parseCompetences(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const create = createAsyncThunk(
  'competences/create',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        name: rawData.name,
        competence_type: 'DZ',
        can_have_student: rawData.canHaveStudent,
      };

      if (rawData.description) {
        data.description = rawData.description;
      }

      const response = await competencesApi.create(data);

      return parseCompetence(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const manageUserCompetences = createAsyncThunk(
  'competences/manageUserCompetences',
  async (data, { rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const assignResponse = data.assignIds.map(async (id) => {
        const response = await competencesApi.assignToUser({
          user_id: data.userId.toString(),
          competence_id: id.toString(),
        });
        return response.data;
      });

      const unAssignResponse = data.unassignIds.map(async (id) => {
        try {
          const response = await competencesApi.unssignFromUser({
            user_id: data.userId.toString(),
            competence_id: id.toString(),
          });

          return response.data;
        } catch (err) {
          throw err.payload.message;
        }
      });

      await Promise.all(assignResponse);
      await Promise.all(unAssignResponse);

      return null;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

const competences = createSlice({
  name: 'competences',
  initialState: {
    all: [],
    error: '',
    loading: false,
    isSuccess: false,
    noNeedDocIds: [],
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.all = action.payload;
    },
    [getAll.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [create.pending]: (state) => {
      state.error = '';
      state.isSuccess = false;
    },
    [create.fulfilled]: (state, action) => {
      state.all = [...state.all, action.payload];
      state.isSuccess = true;
    },
    [create.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [getNotNeedDoc.fulfilled]: (state, action) => {
      state.noNeedDocIds = action.payload.map((el) => el.id);
    },
  },
});

export const { resetSuccess, resetError } = competences.actions;

export default competences.reducer;
