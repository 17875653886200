import { createSelector } from 'reselect';

const agents = (state) => state.agents;

const agentsById = createSelector(agents, (state) => state.agentsById);
const agentsOrder = createSelector(agents, (state) => state.agentsOrder);
const ordersById = createSelector(agents, (state) => state.ordersById);
const all = createSelector(agentsById, agentsOrder, ordersById, (state, order, ordersState) =>
  order.map((id) => ({
    ...state[id],
    orders: state[id].orders.map((id) => ordersState[id.toString()]),
  }))
);
const isSuccess = createSelector(agents, (state) => state.isSuccess);
const error = createSelector(agents, (state) => state.error);
const loading = createSelector(agents, (state) => state.loading);
const productSets = createSelector(agents, (state) => state.productSets);
const getAgentById = createSelector(
  agentsById,
  ordersById,
  (_, id) => id,
  (state, ordersState, id) => ({
    ...state[id],
    orders: state[id].orders.map((id) => ordersState[id.toString()]),
  })
);

const AgentsSelectors = {
  all,
  isSuccess,
  error,
  loading,
  productSets,
  getAgentById,
};

export default AgentsSelectors;
