import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import { hideConversation } from '../../../slices/chats.slice';
import useStyles from './styles';

const ChatPreview = (props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  const {
    recipientId,
    img,
    title,
    message,
    unreadNumber,
    isDeleteMode,
  } = props;

  const deleteChat = (e) => {
    e.preventDefault();
    dispatch(hideConversation(recipientId));
  };

  return (
    <Box className={classes.container}>
      {isDeleteMode && (
        <IconButton size="small" onClick={deleteChat}>
          <DeleteIcon />
        </IconButton>
      )}
      <Avatar alt="Remy Sharp" src={img} />
      <Box className={classes.main}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography className={classes.title} variant="h6" color="primary">
            {title}
          </Typography>
          {message && (
            <Typography
              className={classes.time}
              variant="caption"
              color="textSecondary"
            >
              {new Date(message.createdAt).toLocaleTimeString(i18n.language, {
                hour: 'numeric',
                minute: 'numeric',
              })}
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          {message && (
            <Typography
              className={classes.message}
              variant="caption"
              color="textSecondary"
            >
              {message.body}
            </Typography>
          )}
          {unreadNumber > 0 && (
            <Box className={classes.unread}>
              <Typography className={classes.unreadText} variant="caption">
                {unreadNumber}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ChatPreview.propTypes = {
  recipientId: PropTypes.number.isRequired,
  img: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  unreadNumber: PropTypes.number,
  isDeleteMode: PropTypes.bool,
};

ChatPreview.defaultProps = {
  img: null,
  title: '',
  message: '',
  unreadNumber: 0,
  isDeleteMode: false,
};

export default ChatPreview;
