import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';

import SortableTable from '../../components/sortable-table/SortableTable';
import ProductSetDialog from './components/product-set-dialog/ProductSetDialog';
import PageLoader from '../../components/page-loader/PageLoader';

import LocationsSelectors from '../../selectors/locations.selectors';
import ProductSetsSelectors from '../../selectors/productSets.selectors';
import { getAll as getProductSets, remove } from '../../slices/productSets.slice';
import { getAll as getAllLocations } from '../../slices/locations.slice';
import { getAll as getAllDiscounts } from '../../slices/discounts.slice';
import { getAll as getAllCompetences } from '../../slices/competences.slice';
import { formatDouble } from '../../utils/number.utils';
import useStyles from './styles';

const TABLE_COLUMNS = [
  { id: 'name', disablePadding: true, isSortable: true },
  { id: 'description' },
  { id: 'price', isSortable: true, render: (value) => formatDouble(value, 2) },
  { id: 'slotNumber', isSortable: true },
  { id: 'discount', isSortable: true },
  { id: 'active', isSortable: true },
  { id: 'forCp', isSortable: true },
];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const {
    numSelected,
    pricing,
    locations,
    currentLocation,
    onSelectSearchResult,
    onDelete,
    onChangeLocations,
  } = props;
  const { t } = useTranslation();
  const [isCreatDialog, setIsCreateDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsCreateDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsCreateDialog(false);
  }, []);

  const handleLocationChange = useCallback(
    (e, value) => {
      onChangeLocations(value);
    },
    [onChangeLocations]
  );

  const handleSearchChange = useCallback(
    (e, value) => {
      onSelectSearchResult(value);
    },
    [onSelectSearchResult]
  );

  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.row}>
        <Typography className={classes.title} variant="h5" id="tableTitle" component="h2">
          {t('productSets.title')}
        </Typography>
      </div>

      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography
              className={classes.selectedTitle}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {`${numSelected} ${t('users.selected')}`}
            </Typography>

            <Tooltip title={t('delete')}>
              <IconButton
                className={classes.selectedButton}
                aria-label={t('delete')}
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className={classes.controls}>
        <Autocomplete
          className={classes.control}
          size="medium"
          options={locations}
          value={currentLocation}
          onChange={handleLocationChange}
          renderOption={(option) => option.name}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t('productSets.dropZone')}
              placeholder={t('productSets.dropZone')}
            />
          )}
        />
        <Autocomplete
          className={classes.control}
          type="search"
          name="search"
          variant="outlined"
          size="medium"
          options={pricing}
          renderOption={(option) => option.name}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={handleSearchChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('search')}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={openCreateDialog}
        >
          {t('create')}
        </Button>
      </div>
      {isCreatDialog && <ProductSetDialog isOpen={isCreatDialog} onClose={closeCreateDialog} />}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  currentLocation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onChangeLocations: PropTypes.func.isRequired,
  onSelectSearchResult: PropTypes.func.isRequired,
  pricing: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
};

EnhancedTableToolbar.defaultProps = {
  currentLocation: null,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector(ProductSetsSelectors.loading);
  const locations = useSelector(LocationsSelectors.all);

  const [isLoading, setIsLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(locations[0]);

  const all = useSelector((state) => {
    if (currentLocation) {
      return ProductSetsSelectors.getByLocationId(state, currentLocation.id);
    }
    return [];
  });

  const handleChangeLocation = useCallback((value) => {
    if (value) {
      setCurrentLocation(value);
    }
  }, []);

  useEffect(() => {
    if (locations[0]) {
      setCurrentLocation(locations[0]);
    }
  }, [locations]);

  useEffect(() => {
    const get = async () => {
      const requests = [
        dispatch(getAllLocations()),
        dispatch(getAllDiscounts()),
        dispatch(getAllCompetences()),
      ];
      setIsLoading(true);
      await Promise.all(requests);
      setIsLoading(false);
    };
    get();
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProductSets());
  }, [dispatch]);

  const [selected, setSelected] = useState([]);

  const [isDialog, setIsDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dialogMode, setDialogMode] = useState('');

  const openDialog = useCallback((data, mode) => {
    setIsDialog(true);
    setDialogData(data);
    setDialogMode(mode);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialog(false);
    setDialogData(null);
    setDialogMode('');
  }, []);

  const [searchResult, setSearchResult] = useState(null);

  const handleSetSearchResult = useCallback((value) => {
    setSearchResult(value);
  }, []);

  const handleDeleteSelected = useCallback(() => {
    selected.forEach((id) => {
      const deletedEl = all.filter((el) => el.id === id)[0];
      if (deletedEl && !deletedEl.disableDelete) {
        dispatch(remove(id));
      }
    });
    setSelected([]);
  }, [dispatch, selected, all]);

  const handleDelete = useCallback(
    async (data) => {
      await dispatch(remove(data.id));
    },
    [dispatch]
  );

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        locations={locations}
        currentLocation={currentLocation}
        onChangeLocations={handleChangeLocation}
        pricing={all}
        onSelectSearchResult={handleSetSearchResult}
        onDelete={handleDeleteSelected}
      />
      <SortableTable
        columns={TABLE_COLUMNS}
        data={searchResult ? [searchResult] : all}
        translationNamespace="productSets"
        onSelect={setSelected}
        selected={selected}
        onShow={(data) => openDialog(data, 'view')}
        onEdit={(data) => openDialog(data, 'edit')}
        onDelete={handleDelete}
      />
      {isDialog && (
        <ProductSetDialog
          isOpen={isDialog}
          onClose={closeDialog}
          mode={dialogMode}
          data={dialogData}
        />
      )}
      {loading && <PageLoader />}
    </main>
  );
}
