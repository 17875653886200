import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { DialogContent, TextField } from '@material-ui/core';
import PageLoader from '../../../../components/page-loader/PageLoader';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import FormDialogActions from '../../../../components/form-dialog/FormDialogActions';
import {
  create,
  resetSuccess,
  resetError,
  edit,
} from '../../../../slices/roles.slice';
import useStyles from './styles';

const CreateDialog = (props) => {
  const { isOpen, handleClose, data, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state);

  const {
    control,
    register,
    handleSubmit,
    formState,
    setError,
    errors,
  } = useForm({ defaultValues: data });

  useEffect(() => {
    let timeoutId;

    if (roles.isSuccess) {
      timeoutId = setTimeout(() => {
        dispatch(resetSuccess());
        handleClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch, handleClose, roles.isSuccess]);

  useEffect(() => {
    if (roles.error && typeof roles.error === 'object') {
      Object.keys(roles.error).forEach((key) => {
        setError(key, '', roles.error[key][0]);
      });
    }
  }, [setError, roles.error]);

  const onClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    handleClose();
  }, [handleClose, dispatch]);

  const onSubmit = useCallback(
    (values) => {
      if (mode === 'edit') {
        dispatch(
          edit({
            id: data.id,
            ...values,
          })
        );
      } else {
        dispatch(create(values));
      }
    },
    [dispatch, mode, data.id]
  );

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      onClose={onClose}
      aria-labelledby="user-role-dialog"
      isOpen={isOpen}
      onSubmit={memoSubmit}
      title={t('users.createRole')}
    >
      <DialogContent className={classes.content}>
        <Controller
          as={TextField}
          control={control}
          className={classes.theme}
          defaultValue=""
          label={t('users.role')}
          placeholder={t('users.role')}
          name="name"
          required
          helperText={errors.name && errors.name.message}
          error={!!errors.name}
          // disabled={mode === 'edit'}
        />
        <textarea
          className={classes.textarea}
          placeholder={t('users.description')}
          ref={register}
          name="description"
        />
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <FormDialogActions
        mode={mode}
        isSuccess={roles.isSuccess}
        error={roles.error}
        onCancel={handleClose}
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

CreateDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  mode: PropTypes.oneOf(['create', 'edit']),
};

CreateDialog.defaultProps = {
  isOpen: false,
  data: {},
  mode: 'create',
};

export default CreateDialog;
