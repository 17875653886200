import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    tableContainer: {
      maxHeight: 300,
      minHeight: 300,
      position: 'relative',
    },
    body: {
      overflow: 'scroll',
    },
    controls: {
      flexDirection: 'column',
      marginBottom: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    noResultsText: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    control: {
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(1),

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    legend: {
      listStyle: 'none',
      display: 'flex',
      marginTop: theme.spacing(2),
      marginLeft: 20,
      fontSize: '1rem',
    },
    legendItem: {
      marginRight: theme.spacing(4),

      '&:last-child': {
        marginRight: 0,
      },
    },
    legendSold: {
      position: 'relative',

      '&:before': {
        content: '""',
        position: 'absolute',
        left: -16,
        top: 6,
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: theme.palette.success.main,
      },
    },
    legendUsed: {
      position: 'relative',

      '&:before': {
        content: '""',
        position: 'absolute',
        left: -16,
        top: 6,
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: '#c8d7e1',
      },
    },
    legendNoUsed: {
      position: 'relative',

      '&:before': {
        content: '""',
        position: 'absolute',
        left: -16,
        top: 6,
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
      },
    },
    legendCancelled: {
      position: 'relative',

      '&:before': {
        content: '""',
        position: 'absolute',
        left: -16,
        top: 6,
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: theme.palette.error.main,
      },
    },
    legendInput: {
      visibility: 'hidden',
      width: 0,
      height: 0,

      '&:checked + $legendLabel': {
        borderBottomColor: theme.palette.secondary.main,
      },
    },
    legendLabel: {},
    actionsCell: {
      width: 100,
    },
    expandCell: {
      width: 30,
    },
  }),
  {
    name: 'AgentOrders',
  }
);
