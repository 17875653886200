import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    flex: 1,
  },

  chatInput: {
    margin: theme.spacing(1),
    marginTop: 'auto',
  },
  inputRoot: {
    borderRadius: 5,
  },
  chat: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflowX: 'hidden',
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  date: {
    alignSelf: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  controls: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginRight: theme.spacing(1),

    '&:last-child': {
      marginRight: 0,
    },
  },
}),
{
  name: 'Chat',
});
