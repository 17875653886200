import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  DialogContent,
  InputLabel,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import FormDialogAction from '../../../../components/form-dialog/FormDialogActions';
import TelInput from '../../../../components/tel-input/TelInput';
import SearchAutocomplete from '../../../../components/search-autocomplete/SearchAutocomplete';
import Loader from '../../../../components/page-loader/PageLoader';
import { create, resetSuccess, resetError } from '../../../../slices/subcontractors.slice';
import SubcontractorsSelectors from '../../../../selectors/subcontractors.selectors';
import { searchByName } from '../../../../slices/users.slice';
import { getRateTypeByCompetences } from '../../utils';
import { matchSorter } from '../../../../utils/array.utils';
import useStyles from './styles';

const USER_STATUS = {
  new: 'new',
  existing: 'existing',
};

const SubcontractorDialog = (props) => {
  const { open, onClose, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const salaryRates = useSelector(SubcontractorsSelectors.salaryRates);

  const [tab, setTab] = useState(USER_STATUS.existing);

  const handleSetTab = useCallback((e) => {
    setTab(e.target.value);
  }, []);

  const isSuccess = useSelector(SubcontractorsSelectors.isSuccess);
  const error = useSelector(SubcontractorsSelectors.error);

  useEffect(() => {
    let timeout;
    if (isSuccess) {
      timeout = setTimeout(() => {
        dispatch(resetSuccess());
        onClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, onClose, isSuccess]);

  const { control, handleSubmit, formState, setValue } = useForm({
    defaultValues: {
      user: null,
    },
  });

  const handleSearchByName = useCallback(
    async (value, setOptions, setErrors) => {
      if (value.length > 3) {
        try {
          const responseAction = await dispatch(searchByName(value));
          const response = unwrapResult(responseAction);
          setOptions(response);

          if (response.length === 1 && response[0].name.toLowerCase() === value.toLowerCase()) {
            setValue('user', response[0]);
          }
        } catch (err) {
          setErrors(err.message);
        }
      }
    },
    [dispatch, setValue]
  );

  const onSubmit = useCallback(
    async (values) => {
      const rates = {};

      Object.keys(values.rates).forEach((key) => {
        rates[key] = {
          rate: values.rates[key].rate,
          rateType: key,
        };
      });

      if (values.user) {
        await dispatch(
          create({
            ...values.user,
            rates,
          })
        );
      } else {
        await dispatch(
          create({
            ...values,
            rates,
          })
        );
      }
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    onClose();
  }, [dispatch, onClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <FormDialog
      onClose={handleClose}
      isOpen={open}
      onSubmit={memoSubmit}
      title={t('users.user')}
      maxWidth="sm"
    >
      <DialogContent className={classes.content}>
        <RadioGroup
          className={classes.radioGroup}
          aria-label="gender"
          name="gender1"
          value={tab}
          onChange={handleSetTab}
        >
          <FormControlLabel value="new" control={<Radio />} label={t('createUser')} />
          <FormControlLabel value="existing" control={<Radio />} label={t('existingUser')} />
        </RadioGroup>
        <div className={classes.container}>
          {tab === USER_STATUS.new && (
            <div>
              <div className={classes.item}>
                <InputLabel className={classes.label} htmlFor="name">
                  <Typography
                    className={classes.labelText}
                    color="textPrimary"
                    variant="subtitle1"
                    component="div"
                  >
                    {`${t('users.name')}: `}
                  </Typography>
                </InputLabel>
                <Controller
                  as={TextField}
                  control={control}
                  className={classes.input}
                  defaultValue=""
                  id="name"
                  name="name"
                  variant="outlined"
                  placeholder={t('users.name')}
                  margin="dense"
                  disabled={mode === 'view'}
                  fullWidth
                  required
                />
              </div>
              <div className={classes.item}>
                <InputLabel className={classes.label} htmlFor="email">
                  <Typography
                    className={classes.labelText}
                    color="textPrimary"
                    variant="subtitle1"
                    component="div"
                  >
                    {`${t('users.email')}: `}
                  </Typography>
                </InputLabel>
                <Controller
                  as={TextField}
                  control={control}
                  className={classes.input}
                  defaultValue=""
                  id="email"
                  name="email"
                  variant="outlined"
                  placeholder={t('users.email')}
                  margin="dense"
                  disabled={mode === 'view'}
                  fullWidth
                  required
                />
              </div>
              <div className={classes.item}>
                <InputLabel className={classes.label} htmlFor="phone">
                  <Typography
                    className={classes.labelText}
                    color="textPrimary"
                    variant="subtitle1"
                    component="div"
                  >
                    {`${t('users.phone')}: `}
                  </Typography>
                </InputLabel>
                <Controller
                  as={TelInput}
                  control={control}
                  className={classes.input}
                  defaultValue=""
                  id="phone"
                  name="phone"
                  variant="outlined"
                  required
                  placeholder={t('users.phone')}
                  margin="dense"
                  disabled={mode === 'view'}
                  fullWidth
                />
              </div>
            </div>
          )}
          {tab === USER_STATUS.existing && (
            <div className={classes.item}>
              <InputLabel className={classes.label} htmlFor="user">
                <Typography
                  className={classes.labelText}
                  color="textPrimary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('findUser')}: `}
                </Typography>
              </InputLabel>
              <Controller
                control={control}
                as={SearchAutocomplete}
                className={classes.search}
                onSearch={handleSearchByName}
                size="medium"
                label={t('users.searchByName')}
                variant="outlined"
                margin="dense"
                id="user"
                name="user"
                getOptionLabel={(option) =>
                  `${option.name} ${option.nickname ? `(${option.nickname})` : ''}`
                }
                getOptionSelected={(option, value) => option.name === value.name}
                filterOptions={(opts, state) =>
                  matchSorter(opts, state.inputValue, ['name', 'nickname'])
                }
                inputProps={{
                  margin: 'dense',
                }}
                onChange={(value) => value[1]}
                required
              />
            </div>
          )}
        </div>
        <Typography
          className={classes.labelText}
          color="textPrimary"
          variant="h6"
          component="h3"
          gutterBottom
        >
          {t('salary.title')}
        </Typography>
        {salaryRates.map((el) => (
          <div key={el.id}>
            <InputLabel className={classes.label} htmlFor={el.id}>
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {el.name}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              defaultValue={el.rate}
              id={el.id.toString()}
              name={`rates.${getRateTypeByCompetences(el.competences)}.rate`}
              variant="outlined"
              type="number"
              inputProps={{
                step: 0.1,
                min: 0,
              }}
              placeholder={t('salaryRate')}
              margin="dense"
              disabled={mode === 'view'}
              fullWidth
              required
            />
          </div>
        ))}
        {formState.isSubmitting && <Loader />}
      </DialogContent>
      <FormDialogAction
        isSuccess={isSuccess}
        error={error}
        mode={mode}
        onCancel={handleClose}
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

SubcontractorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    createdAt: PropTypes.string,
    birthDate: PropTypes.string,
  }),
  mode: PropTypes.string,
};

SubcontractorDialog.defaultProps = {
  open: false,
  data: {},
  mode: 'create',
};

export default SubcontractorDialog;
