import React from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import DashboardShopAdmin from './DashboardShopAdmin';
import DashboardSuperAdmin from './DashboardSuperAdmin';
import AuthSelectors from '../../selectors/auth.selectors';

const Dashboard = () => {
  const isSuperAdmin = useSelector(AuthSelectors.isSuperAdmin);

  return (
    <>
      <CssBaseline />
      {isSuperAdmin ? <DashboardSuperAdmin /> : <DashboardShopAdmin />}
    </>
  );
};

export default Dashboard;
