import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { FILTER_TYPES } from './constants';
import ShopsSelectors from '../../../selectors/shops.selectors';
import useStyles from './styles';

const OrdersToolbar = (props) => {
  const classes = useStyles();
  const { orders, onFilterChange, shop } = props;
  const { t } = useTranslation();

  const locations = useSelector((state) => ShopsSelectors.locationsByDomain(state, shop));
  const places = locations.map((location) => location.name);

  const [searchedLocation, setSearchedLocation] = useState('');
  const [searchedId, setSearchedId] = useState('');
  const [searchedExternalId, setSearchedExternalId] = useState('');
  const [searchedName, setSearchedName] = useState('');
  const [searchedPhone, setSearchedPhone] = useState('');
  const [searchedEmail, setSearchedEmail] = useState('');

  const resetFilters = () => {
    setSearchedLocation('');
    setSearchedId('');
    setSearchedExternalId('');
    setSearchedName('');
    setSearchedPhone('');
    setSearchedEmail('');
  };

  const handleSearchLocation = (e, value) => {
    resetFilters();
    setSearchedLocation(value);
    onFilterChange(FILTER_TYPES.location, value);
  };

  const handleSearchOrderById = (e, value) => {
    resetFilters();
    setSearchedId(value);
    onFilterChange(FILTER_TYPES.orderNumber, value);
  };

  const handleSearchOrderByExternalId = (e, value) => {
    resetFilters();
    setSearchedExternalId(value);
    onFilterChange(FILTER_TYPES.orderCode, value);
  };

  const handleSearchOrderByUserName = (e, value) => {
    resetFilters();
    setSearchedName(value);
    onFilterChange(FILTER_TYPES.name, value);
  };

  const handleSearchOrderByPhone = (e, value) => {
    resetFilters();
    setSearchedPhone(value);
    onFilterChange(FILTER_TYPES.phone, value);
  };

  const handleSearchOrderByEmail = (e, value) => {
    resetFilters();
    setSearchedEmail(value);
    onFilterChange(FILTER_TYPES.email, value);
  };

  const ordersNumbers = useMemo(() => orders.map((order) => order.id), [orders]);
  const ordersCodes = useMemo(
    () => orders.filter((order) => order.externalId).map((order) => order.externalId),
    [orders]
  );

  const customers = useMemo(
    () => [
      ...new Set(
        orders
          .filter((order) => order.billing)
          .map((order) => `${order.billing.name} ${order.billing.surname}`)
      ),
    ],
    [orders]
  );

  const phones = useMemo(
    () => [
      ...new Set(
        orders
          .filter((order) => order.billing && order.billing.phone)
          .map((order) => order.billing.phone)
      ),
    ],
    [orders]
  );

  const emails = useMemo(
    () => [
      ...new Set(
        orders
          .filter((order) => order.billing && order.billing.email)
          .map((order) => order.billing.email)
      ),
    ],
    [orders]
  );

  return (
    <Toolbar className={cn(classes.toolbar)}>
      <div className={classes.row}>
        <Typography className={classes.title} variant="h5" id="tableTitle" component="h2">
          {t('Orders')}
        </Typography>
      </div>

      <div className={classes.controls}>
        <Autocomplete
          className={classes.control}
          type="select"
          name="location"
          variant="outlined"
          size="medium"
          options={places}
          onChange={handleSearchLocation}
          value={searchedLocation}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('location')}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
        <Autocomplete
          className={classes.control}
          type="select"
          name="order"
          variant="outlined"
          size="medium"
          options={ordersNumbers}
          renderOption={(option) => option.toString()}
          getOptionLabel={(option) => option.toString()}
          onChange={handleSearchOrderById}
          value={searchedId}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('shop.id')}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
        <Autocomplete
          className={classes.control}
          type="select"
          name="order"
          variant="outlined"
          size="medium"
          options={ordersCodes}
          onChange={handleSearchOrderByExternalId}
          value={searchedExternalId}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('shop.externalId')}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      </div>
      <div className={classes.controls}>
        <Autocomplete
          className={classes.control}
          type="select"
          name="name"
          variant="outlined"
          size="medium"
          options={customers}
          onChange={handleSearchOrderByUserName}
          value={searchedName}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('users.name')}
              InputProps={{
                ...params.InputProps,
              }}
              inputProps={{
                ...params.inputProps,
                autocomplete: 'nope',
              }}
            />
          )}
        />
        <Autocomplete
          className={classes.control}
          type="select"
          name="phone"
          variant="outlined"
          size="medium"
          options={phones}
          onChange={handleSearchOrderByPhone}
          value={searchedPhone}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('users.phone')}
              InputProps={{
                ...params.InputProps,
              }}
              inputProps={{
                ...params.inputProps,
                autocomplete: 'nope',
              }}
            />
          )}
        />

        <Autocomplete
          className={classes.control}
          type="select"
          name="email"
          variant="outlined"
          size="medium"
          options={emails}
          onChange={handleSearchOrderByEmail}
          value={searchedEmail}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('users.email')}
              InputProps={{
                ...params.InputProps,
              }}
              inputProps={{
                ...params.inputProps,
                autocomplete: 'nope',
              }}
            />
          )}
        />
      </div>
    </Toolbar>
  );
};

OrdersToolbar.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  onLocationChange: PropTypes.func,
  currentLocation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
};

OrdersToolbar.defaultProps = {
  locations: [],
  onLocationChange: null,
  currentLocation: null,
};

export default OrdersToolbar;
