import React from 'react';
// import { useTranslation } from 'react-i18next';
import useStyles from './styles';

// const OFFERS = [
//   {
//     key: 'parachuteCourses',
//     path: 'offer-creator/parachute-courses',
//   },
//   {
//     key: 'instructorCourses',
//     path: 'offer-creator/instructor-courses',
//   },
//   {
//     key: 'events',
//     path: 'offer-creator/events',
//   },
//   {
//     key: 'specialOffers',
//     path: 'offer-creator/special-offers',
//   },
//   {
//     key: 'tunnelTraining',
//     path: 'offer-creator/tunnel-training',
//   },
//   {
//     key: 'academy',
//     path: 'offer-creator/academy',
//   },
//   {
//     key: 'offersInstructors',
//     path: 'offer-creator/offers-instructors',
//   },
//   {
//     key: 'insurance',
//     path: 'offer-creator/insurance',
//   },
// ];

const OfferCreator = () => {
  const classes = useStyles();
  // const { t } = useTranslation();

  return (
    <main className={classes.container}>
      <div className={classes.header}>
        {/*
        <Tabs value={activeType} onChange={handleSetActiveType} aria-label="simple tabs example">
          {OFFERS.map((type) => (
            <Tab key={type.key} label={t(`offerCreator.${type.key}`)} />
          ))}
        </Tabs>
        */}
      </div>
    </main>
  );
};

export default OfferCreator;
