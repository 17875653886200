import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Products from '../products/Products';
import DictionaryCourses from '../dictionary-courses/DictionaryCourses';
import DocumentTypes from '../document-types/DocumentTypes';
import ProductSets from '../product-sets/ProductSets';
import UserRoles from '../users-roles/UsersRoles';
import Competences from '../competences/Competences';

const Dictionaries = () => (
  <Switch>
    <Route path="/dictionaries/products" component={Products} />
    <Route path="/dictionaries/courses" component={DictionaryCourses} />
    <Route path="/dictionaries/document-types" component={DocumentTypes} />
    <Route path="/dictionaries/tasks" component={ProductSets} />
    <Route path="/dictionaries/user-competences">
      <Competences />
    </Route>
    <Route path="/dictionaries/users-roles">
      <UserRoles />
    </Route>
  </Switch>
);

export default Dictionaries;
