/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ordersApi } from '../api/api';
import { parseOrders } from '../services/orders.services';
import { parseLocations } from '../services/locations.services';
import { setNotification } from './notifications.slice';
import { setOrders } from './orders.slice';
import { setLocations } from './locations.slice';
import { MESSAGES_TYPES } from '../constants/constants';

export const getOrdersByDomain = createAsyncThunk(
  'shops/getByDomain',
  async (domain, { signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await ordersApi.getByDomain({
        cancelToken: source.token,
        domain: domain,
      });
      const parsedOrders = parseOrders(response.data.orders);
      const parsedLocations = parseLocations(response.data.locations);

      dispatch(setOrders(parsedOrders));
      dispatch(setLocations(parsedLocations));

      return {
        domain,
        orders: parsedOrders,
        locations: parsedLocations,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

const shops = createSlice({
  name: 'shops',
  initialState: {
    byDomain: {},
    loading: false,
  },
  extraReducers: {
    [getOrdersByDomain.pending]: (state) => {
      state.loading = true;
    },
    [getOrdersByDomain.fulfilled]: (state, action) => {
      state.loading = false;
      state.byDomain[action.payload.domain] = {
        orders: action.payload.orders.map((el) => el.id),
        locations: action.payload.locations.map((el) => el.id),
      };
    },
    [getOrdersByDomain.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default shops.reducer;
