import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    minWidth: 400,
  },
  title: {
    fontSize: '1.5rem',
  },
  disagreeButton: {
    minWidth: 86,
  },
}),
{
  name: 'ConfirDialog',
});
