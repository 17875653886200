import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import Chats from './chats/Chats';
import ChatPage from './chat-page/ChatPage';

const CommunicationPanel = () => {
  let { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Chats />
      </Route>
      <Route path={`${url}/:id`}>
        <ChatPage />
      </Route>
    </Switch>
  );
};

export default CommunicationPanel;
