import { TIME_RANGE } from './constants';

export const isDataUnderRange = (range, date, fromData, toDate) => {
  const now = new Date();

  switch (range) {
    case TIME_RANGE.week: {
      return date > new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    }
    case TIME_RANGE.month: {
      return date > new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
    }
    case TIME_RANGE.currentMonth: {
      return date > new Date(now.getFullYear(), now.getMonth(), 0);
    }
    case TIME_RANGE.year: {
      return date > new Date(now.getFullYear(), 0, 0);
    }
    case TIME_RANGE.custom: {
      if (!fromData || !toDate) {
        return false;
      }

      return date >= fromData && date <= toDate;
    }
    default: {
      return false;
    }
  }
};

export const getTimeRange = (rangeType, fromDate, toDate) => {
  const now = new Date();

  switch (rangeType) {
    case TIME_RANGE.week: {
      const weekRange = [];
      for (let i = 6; i >= 0; i--) {
        weekRange.push(new Date(now.getFullYear(), now.getMonth(), now.getDate() - i));
      }
      return weekRange;
    }
    case TIME_RANGE.month: {
      const monthRange = [];
      for (let i = 30; i >= 0; i--) {
        monthRange.push(new Date(now.getFullYear(), now.getMonth(), now.getDate() - i));
      }
      return monthRange;
    }
    case TIME_RANGE.currentMonth: {
      const currentMonthRange = [];
      for (let i = 30; i >= 0; i--) {
        const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - i);
        if (date.getMonth() === now.getMonth()) {
          currentMonthRange.push(new Date(now.getFullYear(), now.getMonth(), now.getDate() - i));
        }
      }
      return currentMonthRange;
    }
    case TIME_RANGE.year: {
      const yearRange = [];
      for (let i = 361; i >= 0; i--) {
        const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - i);
        if (date.getFullYear() === now.getFullYear()) {
          yearRange.push(new Date(now.getFullYear(), now.getMonth(), now.getDate() - i));
        }
      }
      return yearRange;
    }
    case TIME_RANGE.custom: {
      if (!fromDate || !toDate) {
        return [];
      }
      const customRange = [];
      let date = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());

      while (date <= toDate) {
        customRange.push(date);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      }
      return customRange;
    }
    default: {
      return [];
    }
  }
};
