/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authApi } from '../api/api';
import { parseRawUser } from '../services/users.services';
import { saveState, clearState } from '../utils/local-storage.utils';

export const login = createAsyncThunk(
  'auth/login',
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await authApi.login({
        email: data.email,
        password: data.password,
      });
      return parseRawUser(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (data, { getState }) => {
      const { auth } = getState();
      const isLoading = auth.loading;

      if (isLoading) {
        return false;
      }

      return true;
    },
  }
);

export const findByToken = createAsyncThunk(
  'auth/findByToken',
  async (data) => {
    try {
      const response = await authApi.findByToken(
        { token: data.token },
        data.token
      );

      return parseRawUser(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return Promise.reject(err.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await authApi.logout(getState().auth.token);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (data, { getState }) => {
      const { auth } = getState();
      const isLoading = auth.loading;

      if (isLoading) {
        return false;
      }

      return true;
    },
  }
);

const auth = createSlice({
  name: 'auth',
  initialState: {
    token: '',
    isAuth: false,
    error: '',
    user: {},
    loading: false,
  },
  reducers: {
    resetAuth: (state) => {
      state.isAuth = false;
      state.user = {};
      state.token = '';
      state.loading = false;
      clearState('auth');
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [login.fulfilled]: (state, action) => {
      const { token, ...user } = action.payload;
      state.isAuth = true;
      state.user = user;
      state.loading = false;
      state.error = '';
      state.token = token;
      saveState({
        auth: {
          error: '',
          loading: false,
          isAuth: true,
          token,
          user,
        },
      });
    },
    [login.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.loading = false;
    },
    [logout.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [logout.fulfilled]: (state) => {
      state.isAuth = false;
      state.user = {};
      state.token = '';
      state.loading = false;
      clearState('auth');
    },
    [logout.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.loading = false;
    },
    [findByToken.fulfilled]: (state, action) => {
      const { token, ...user } = action.payload;
      state.isAuth = true;
      state.user = user;
      state.token = token;

      saveState({
        auth: {
          error: '',
          loading: false,
          isAuth: true,
          token,
          user,
        },
      });
    },
    [findByToken.rejected]: (state) => {
      state.isAuth = false;
      state.user = {};
      state.token = '';

      clearState('auth');
    },
  },
});

export const { resetAuth } = auth.actions;

export default auth.reducer;
