import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Toolbar, Typography, IconButton, Tooltip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import OrganizationDialog from './components/organization-dialog/OrganizationDialog';
import SortableTable from '../../components/sortable-table/SortableTable';
import PageLoader from '../../components/page-loader/PageLoader';
import { getAll, remove } from '../../slices/organizations.slice';
import { getAll as getAllLocations } from '../../slices/locations.slice';
import LocationsSelectors from '../../selectors/locations.selectors';
import useStyles from './styles';

const TABLE_COLUMNS = [
  { id: 'name', disablePadding: true, isSortable: true },
  { id: 'address', isSortable: true },
  { id: 'director', isSortable: true },
  { id: 'email', isSortable: true },
  { id: 'phone', isSortable: true },
  { id: "systemOwner", isSortable: true}
];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { numSelected, onSelectSearchResult, planes, locations, onDelete } = props;
  const { t } = useTranslation();

  const [isCreatDialog, setIsCreateDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsCreateDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsCreateDialog(false);
  }, []);

  const handleSearchChange = useCallback(
    (e, value) => {
      onSelectSearchResult(value);
    },
    [onSelectSearchResult]
  );

  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.row}>
        <Typography className={classes.title} variant="h5" id="tableTitle" component="h2">
          {t('dictionaries.planes')}
        </Typography>
      </div>

      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography
              className={classes.selectedTitle}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {`${numSelected} ${t('selected')}`}
            </Typography>

            <Tooltip title={t('delete')}>
              <IconButton
                className={classes.selectedButton}
                aria-label={t('delete')}
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className={classes.controls}>
        <div className={classes.controlWrapper}>
          <Autocomplete
            className={classes.control}
            id="type"
            name="type"
            size="medium"
            options={planes}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('search')}
                placeholder={t('search')}
              />
            )}
            onChange={handleSearchChange}
          />
        </div>
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={openCreateDialog}
        >
          {t('dictionaries.create')}
        </Button>
      </div>
      {isCreatDialog && (
        <OrganizationDialog
          isOpen={isCreatDialog}
          handleClose={closeCreateDialog}
          locations={locations}
          mode="create"
        />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectSearchResult: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  planes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { organizations } = useSelector((state) => state);
  const relatedLocations = useSelector(LocationsSelectors.relatedLocations);

  const locations = useSelector(LocationsSelectors.all);
  const locationsLoading = useSelector(LocationsSelectors.loading);

  useEffect(() => {
    const orgPromise = dispatch(getAll());
    const locationsPromise = dispatch(getAllLocations());

    return () => {
      orgPromise.abort();
      locationsPromise.abort();
    };
  }, [dispatch]);

  const [searchResult, setSearchResult] = useState(null);

  const handleSetSearchResult = useCallback((role) => {
    setSearchResult(role);
  }, []);

  const [selected, setSelected] = useState([]);

  const handleDeleteSelected = useCallback(() => {
    organizations.all.forEach((plane) => {
      if (selected.indexOf(plane.id) > -1) {
        dispatch(remove(plane.id));
      }
    });
    setSelected([]);
  }, [dispatch, selected, organizations]);

  const [isDialog, setIsDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dialogMode, setDialogMode] = useState('');

  const openDialog = useCallback((data, mode) => {
    setIsDialog(true);
    setDialogData(data);
    setDialogMode(mode);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialog(false);
    setDialogData(null);
    setDialogMode('');
  }, []);

  const handleDelete = useCallback(
    async (data) => {
      await dispatch(remove(data.id));
    },
    [dispatch]
  );

  if (organizations.loading || locationsLoading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        onSelectSearchResult={handleSetSearchResult}
        onDelete={handleDeleteSelected}
        planes={organizations.all}
        locations={relatedLocations}
      />
      <SortableTable
        data={searchResult ? [searchResult] : organizations.all}
        selected={selected}
        columns={TABLE_COLUMNS}
        translationNamespace="organizations"
        onSelect={setSelected}
        onShow={(data) => openDialog(data, 'view')}
        onEdit={(data) => openDialog(data, 'edit')}
        onDelete={handleDelete}
      />
      {isDialog && (
        <OrganizationDialog
          isOpen={isDialog}
          handleClose={closeDialog}
          locations={locations}
          data={dialogData}
          mode={dialogMode}
        />
      )}
    </main>
  );
}
