/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  usersApi,
  salaryRatesApi,
  competencesApi,
  subcontractorRatesApi,
  ordersApi,
} from '../api/api';
import { parseRawUser } from '../services/users.services';
import { parseSalaryRates } from '../services/salaryRates.services';
import { parseRates } from '../services/subcontractors.services';
import { parseOrder } from '../services/orders.services';
import { setNotification } from './notifications.slice';
import { MESSAGES_TYPES, COMPETENCES } from '../constants/constants';
import { fromArray } from '../utils/array.utils';

export const getAll = createAsyncThunk(
  'subcontractors/getAll',
  async (params, { dispatch, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await usersApi.getSubcontractors({
        cancelToken: source.token,
      });

      const promises = response.data.map(async (el) => {
        const promiseResponse = await subcontractorRatesApi.getBySubcontractor({
          subcontractor_id: el.id,
        });

        return { ...parseRawUser(el), rates: parseRates(promiseResponse.data) };
      });

      const responseAll = await Promise.all(promises);

      return responseAll;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const createRate = createAsyncThunk(
  'subcontractor/createRate',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        subcontractor_id: rawData.userId.toString(),
        rate: rawData.rate,
        rate_type: rawData.rateType,
      };

      const response = await subcontractorRatesApi.create(data);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const editRate = createAsyncThunk(
  'subcontractor/editRate',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        subcontractor_id: rawData.userId.toString(),
        rate: rawData.rate,
        rate_type: rawData.rateType,
      };

      subcontractorRatesApi.edit(rawData.id, data);

      return rawData;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const assignSubcontractorToUser = createAsyncThunk(
  'subcontractors/assignSubcontractorToUser',
  async (user, { rejectWithValue }) => {
    try {
      const competencesResponse = await competencesApi.getAll();

      const competences = competencesResponse.data;

      const subcontractorCompetence = competences.filter(
        (el) => el.name === COMPETENCES.subcontractor
      )[0];

      await competencesApi.assignToUser({
        user_id: user.id.toString(),
        competence_id: subcontractorCompetence?.id.toString(),
      });

      return user;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const getOrderInfo = createAsyncThunk(
  'subcontractors/getOrderInfo',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ordersApi.getByExternalId(data.externalId);

      return {
        subcontractorId: data.subcontractorId,
        order: parseOrder(response.data),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const create = createAsyncThunk(
  'subcontractors/create',
  async (rawData, { dispatch }) => {
    try {
      let user = rawData;

      if (rawData.id) {
        await dispatch(assignSubcontractorToUser(rawData));
      } else {
        const data = {
          phone: rawData.phone.replace('+', ''),
          name: rawData.name,
          email: rawData.email,
        };

        const response = await usersApi.create(data);

        user = parseRawUser(response.data);

        await dispatch(assignSubcontractorToUser(user));
      }

      const promises = Object.values(rawData.rates).map(async (el) => {
        const response = await dispatch(
          createRate({
            ...el,
            userId: user.id,
          })
        );

        return response.payload;
      });

      const response = await Promise.all(promises);

      return { ...user, rates: parseRates(response) };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return Promise.reject(err.response.data);
    }
  }
);

export const edit = createAsyncThunk(
  'subcontractors/edit',
  async (rawData, { dispatch, rejectWithValue }) => {
    try {
      const data = {
        phone: rawData.phone.replace('+', ''),
        name: rawData.name,
        email: rawData.email,
      };

      await usersApi.edit(rawData.id, data);

      const promises = Object.values(rawData.rates).map(async (el) => {
        const response = await dispatch(editRate(el));

        return response;
      });

      await Promise.all(promises);

      return rawData;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const remove = createAsyncThunk('subcontractors/remove', async (id) => {
  try {
    await usersApi.remove(id);

    return { id };
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return Promise.reject(err.response.data);
  }
});

export const getSalaryRates = createAsyncThunk(
  'subcontractors/getSalaryRates',
  async (data, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await salaryRatesApi.getForSubcontractors(source.token);

      return parseSalaryRates(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return Promise.reject(err.response.data);
    }
  }
);

const subcontractors = createSlice({
  name: 'subcontractors',
  initialState: {
    all: [],
    loading: false,
    isSuccess: false,
    error: '',
    salaryRates: [],
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getAll.fulfilled]: (state, action) => {
      state.all = action.payload.map((el) => ({
        ...el,
        rates: fromArray(el.rates, 'rateType'),
        orders: el.rates
          .map((rate) => rate.orderExternalId)
          .filter((order) => !!order),
        ordersInfo: {},
      }));
      state.loading = false;
    },
    [getAll.rejected]: (state) => {
      state.loading = false;
    },
    [create.fulfilled]: (state, action) => {
      state.all.push({
        ...action.payload,
        rates: fromArray(action.payload.rates, 'rateType'),
        ordersInfo: {},
      });
      state.isSuccess = true;
    },
    [edit.pending]: (state) => {
      state.error = '';
    },
    [edit.fulfilled]: (state, action) => {
      state.all = state.all.map((el) => {
        if (el.id === action.payload.id) {
          return {
            ...action.payload,
            rates: {
              ...el.rates,
              ...action.payload.rates,
            },
          };
        }

        return el;
      });
      state.isSuccess = true;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
    [remove.pending]: (state) => {
      state.error = '';
    },
    [remove.fulfilled]: (state, action) => {
      state.all = state.all.filter((el) => el.id !== action.payload.id);
      state.searchResult = state.searchResult.filter(
        (el) => el.id !== action.payload.id
      );
    },
    [remove.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
    [getSalaryRates.fulfilled]: (state, action) => {
      state.salaryRates = action.payload;
    },
    [getOrderInfo.fulfilled]: (state, action) => {
      state.all = state.all.map((el) => {
        if (el.id === action.payload.subcontractorId) {
          el.ordersInfo[action.payload.order.externalId] = action.payload.order;
        }

        return el;
      });
    },
  },
});

export const {
  resetSuccess,
  resetError,
  setSearchResult,
} = subcontractors.actions;

export default subcontractors.reducer;
