import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import SortableTable from '../../components/sortable-table/SortableTable';
import PageLoader from '../../components/page-loader/PageLoader';
import DiscountDialog from './components/discount-dialog/DiscountDialog';
import AssignDiscount from './components/assign-discount/AssignDiscount';
import { ORIGINS } from './constants';
import { getAll, remove } from '../../slices/discounts.slice';
import DiscountsSelectors from '../../selectors/discounts.selectors';
import useStyles from './styles';

const TABLE_COLUMNS = [
  { id: 'origin', disablePadding: true, isSortable: true },
  { id: 'inPercents', isSortable: true },
  { id: 'asGift', isSortable: true },
  { id: 'description', isSortable: false },
];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { numSelected, onSelectSearchResult, onDelete } = props;
  const { t } = useTranslation();
  const [isCreatDialog, setIsCreateDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsCreateDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsCreateDialog(false);
  }, []);

  const handleSearchChange = useCallback(
    (e, value) => {
      onSelectSearchResult(value);
    },
    [onSelectSearchResult]
  );

  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.row}>
        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          component="h2"
        >
          {t('discounts.title')}
        </Typography>
      </div>

      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography
              className={classes.selectedTitle}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {`${numSelected} ${t('users.selected')}`}
            </Typography>

            <Tooltip title={t('delete')}>
              <IconButton
                className={classes.selectedButton}
                aria-label={t('delete')}
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className={classes.controls}>
        <div className={classes.controlWrapper}>
          <Autocomplete
            className={classes.control}
            type="search"
            name="search"
            variant="outlined"
            size="medium"
            options={ORIGINS}
            onChange={handleSearchChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('search')}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={openCreateDialog}
        >
          {t('create')}
        </Button>
      </div>
      {isCreatDialog && (
        <DiscountDialog
          isOpen={isCreatDialog}
          handleClose={closeCreateDialog}
        />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectSearchResult: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { discounts } = useSelector((state) => state);
  const all = useSelector(DiscountsSelectors.all);

  useEffect(() => {
    const promise = dispatch(getAll());

    return () => {
      promise.abort();
    };
  }, [dispatch]);

  const [selected, setSelected] = useState([]);
  const [searchResult, setSearchResult] = useState(null);

  const handleDeleteSelected = useCallback(() => {
    selected.forEach((id) => {
      dispatch(remove(id));
    });
    setSelected([]);
  }, [dispatch, selected]);

  const hendleSetSearchResult = useCallback(
    (value) => {
      if (value) {
        setSearchResult(all.filter((el) => el.origin === value));
      } else {
        setSearchResult(null);
      }
    },
    [all]
  );

  const [isDialog, setIsDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dialogMode, setDialogMode] = useState('');

  const openDialog = useCallback((data, mode) => {
    setIsDialog(true);
    setDialogData(data);
    setDialogMode(mode);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialog(false);
    setDialogData(null);
    setDialogMode('');
  }, []);

  const handleDelete = useCallback(
    async (data) => {
      await dispatch(remove(data.id));
    },
    [dispatch]
  );

  if (discounts.loading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        onSelectSearchResult={hendleSetSearchResult}
        onDelete={handleDeleteSelected}
      />
      <SortableTable
        data={searchResult || all}
        selected={selected}
        columns={TABLE_COLUMNS}
        translationNamespace="discounts"
        onSelect={setSelected}
        onShow={(data) => openDialog(data, 'view')}
        onEdit={(data) => openDialog(data, 'edit')}
        onDelete={handleDelete}
      />
      <AssignDiscount className={classes.assignRole} />
      {isDialog && (
        <DiscountDialog
          isOpen={isDialog}
          handleClose={closeDialog}
          data={dialogData}
          mode={dialogMode}
        />
      )}
    </main>
  );
}
