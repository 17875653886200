import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import DatePicker from '../date-picker/DatePicker';
import useStyles from './styles';

function DateRange(props) {
  const { onChange, placeholderFrom, placeholderTo, labelFrom, labelTo } = props;
  const classes = useStyles();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleSetFromDate = (value) => {
    setFromDate(value);
    onChange(value, toDate);
  };

  const handleSetToDate = (value) => {
    setToDate(value);
    onChange(fromDate, value);
  };

  return (
    <Box display='flex'>
      <DatePicker
        className={classes.input}
        onChange={handleSetFromDate}
        disableToolbar
        inputVariant='outlined'
        variant='inline'
        format='dd/MM/yyyy'
        value={fromDate}
        placeholder={placeholderFrom}
      />
      <DatePicker
        onChange={handleSetToDate}
        disableToolbar
        inputVariant='outlined'
        variant='inline'
        format='dd/MM/yyyy'
        value={toDate}
        placeholder={placeholderTo}
      />
    </Box>
  );
}

export default DateRange;
