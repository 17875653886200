import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  DialogContent,
  InputLabel,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import FormDialogAction from '../../../../components/form-dialog/FormDialogActions';
import TelInput from '../../../../components/tel-input/TelInput';
import { create, resetSuccess, resetError } from '../../../../slices/agents.slice';
import AgentsSelectors from '../../../../selectors/agents.selectors';
import DiscountsSelectors from '../../../../selectors/discounts.selectors';
import useStyles from './styles';

const AgentDialog = (props) => {
  const { isOpen, onClose, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSuccess = useSelector(AgentsSelectors.isSuccess);
  const error = useSelector(AgentsSelectors.error);
  const dropZoneDiscounts = useSelector(DiscountsSelectors.dzDiscounts);

  useEffect(() => {
    let timeout;
    if (isSuccess) {
      timeout = setTimeout(() => {
        dispatch(resetSuccess());
        onClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, onClose, isSuccess]);

  const { control, handleSubmit, formState } = useForm();

  const onSubmit = useCallback(
    (values) => {
      dispatch(create(values));
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    onClose();
  }, [dispatch, onClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <FormDialog
      onClose={handleClose}
      isOpen={isOpen}
      onSubmit={memoSubmit}
      title={t('users.user')}
      maxWidth="sm"
    >
      <DialogContent className={classes.content}>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="name"
            name="name"
            variant="outlined"
            placeholder={t('users.name')}
            margin="dense"
            disabled={mode === 'view'}
            fullWidth
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="email">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.email')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="email"
            name="email"
            variant="outlined"
            placeholder={t('users.email')}
            margin="dense"
            disabled={mode === 'view'}
            fullWidth
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="phone">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.phone')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TelInput}
            control={control}
            className={classes.input}
            defaultValue=""
            id="phone"
            name="phone"
            variant="outlined"
            required
            placeholder={t('users.phone')}
            margin="dense"
            disabled={mode === 'view'}
            fullWidth
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('discounts.discount')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={(controllerProps) => (
              <FormControl className={classes.formControl} {...controllerProps}>
                <InputLabel htmlFor="discountId">{t('discounts.discount')}</InputLabel>
                <Select id="discountId" label={t('discounts.discount')} {...controllerProps}>
                  <MenuItem value="">{t('none')}</MenuItem>
                  {dropZoneDiscounts.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {`${item.description} ${item.inPercents}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            control={control}
            className={classes.input}
            onChange={([selected]) => selected.target.value}
            name="discount"
            id="discount"
            defaultValue=""
            fullWidth
            margin="dense"
            variant="outlined"
          />
        </div>
      </DialogContent>
      <FormDialogAction
        isSuccess={isSuccess}
        error={error}
        mode={mode}
        onCancel={handleClose}
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

AgentDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    createdAt: PropTypes.string,
    birthDate: PropTypes.string,
  }),
  mode: PropTypes.string,
};

AgentDialog.defaultProps = {
  isOpen: false,
  data: {},
  mode: 'create',
};

export default AgentDialog;
