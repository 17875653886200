import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: 4,
  },
  item: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
}), {
  name: 'DiscountDialog',
});
