import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    buttonIcon: {
      marginLeft: theme.spacing(1),
    },
    link: {
      color: theme.palette.text.primary,
    },
    expandedTableRow: {
      backgroundColor: theme.palette.grey[200],
    },
    infoItem: {
      marginLeft: theme.spacing(1),
    },
    buttonEdit: {
      marginLeft: theme.spacing(1),
    },
    wordPressChip: {
      backgroundColor: '#0073AA',
      color: '#fff',
      marginLeft: theme.spacing(1),
    },
  }),
  {
    name: 'Order',
  }
);
