import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  autocomplete: {
    marginBottom: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  theme: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  textarea: {
    height: '100%',
    minHeight: '100px',
    outline: 0,
    fontSize: '0.85rem',
    marginBottom: theme.spacing(1),
    border: 0,
  },
  label: {
    color: theme.palette.primary.main,
    alignItems: 'center',
    display: 'flex',
  },
}), {
  name: 'CreateDialog',
});
