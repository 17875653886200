import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import HarnessDialog from './HarnessDialog';
import ParachuteDialog from './ParachuteDialog';
import SelectTypeDialog from './SelectTypeDialog';
import { EQUIPMENT_TYPES } from '../constants';

const EquipmentDialog = (props) => {
  const {
    open, onClose, data, mode,
  } = props;

  const [type, seType] = useState(null);

  const handleMethodChange = useCallback((value) => {
    seType(value);
  }, []);

  if (mode === 'edit' && data.harness) {
    return <HarnessDialog open={open} onClose={onClose} data={data} mode={mode} />;
  }

  if (mode === 'edit' && !data.harness) {
    return <ParachuteDialog open={open} onClose={onClose} data={data} mode={mode} />;
  }

  if (type === EQUIPMENT_TYPES.harness) {
    return <HarnessDialog open={open} onClose={onClose} mode={mode} />;
  }

  if (type === EQUIPMENT_TYPES.parachute) {
    return <ParachuteDialog open={open} onClose={onClose} mode={mode} />;
  }

  return (
    <SelectTypeDialog open={open} onClose={onClose} onNextClick={handleMethodChange} />
  );
};

EquipmentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    userId: PropTypes.number,
    number: PropTypes.string,
    origin: PropTypes.string,
    disciplin: PropTypes.string,
    expirationDate: PropTypes.string,
    reserveNextPack: PropTypes.string,
    image: PropTypes.string,
    mainProductDate: PropTypes.string,
    reserveProductDate: PropTypes.string,
    location: PropTypes.number,
    harness: PropTypes.bool,
  }),
  mode: PropTypes.string,
};

EquipmentDialog.defaultProps = {
  open: false,
  data: {},
  mode: 'create',
};

export default EquipmentDialog;
