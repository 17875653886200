import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Agents from './components/agents/Agents';
import ProductSets from './components/product-sets/ProductSets';
import PageLoader from '../../components/page-loader/PageLoader';
import { getProductSets } from '../../slices/agents.slice';
import { getAll as getAllLocations } from '../../slices/locations.slice';
import { getAll as getDiscount } from '../../slices/discounts.slice';

const AgentsPanel = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const get = async () => {
      const requests = [
        dispatch(getAllLocations()),
        dispatch(getProductSets()),
        dispatch(getDiscount()),
      ];
      setIsLoading(true);
      await Promise.all(requests);
      setIsLoading(false);
    };
    get();
  }, [dispatch]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Switch>
      <Route path={`${url}/list`} component={Agents} />
      <Route path={`${url}/orders-templates`} component={ProductSets} />
    </Switch>
  );
};

export default AgentsPanel;
