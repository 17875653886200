import { createSelector } from 'reselect';
import OrdersSelectors from './orders.selectors';
import LocationsSelectors from './locations.selectors';

const shops = (state) => state.shops;

const byDomain = createSelector(shops, (state) => state.byDomain);
const loading = createSelector(shops, (state) => state.loading);

const getOrdersByDomain = createSelector(
  byDomain,
  OrdersSelectors.byId,
  (_, domain) => domain,
  (state, ordersById, domain) => {
    if (!state || !state[domain]) {
      return [];
    }

    return state[domain]?.orders.map((id) => ordersById[id]);
  }
);

const formattedOrders = createSelector(getOrdersByDomain, (state) => {
  if (!state) {
    return [];
  }
  const parentOrders = state.filter((order) => !order.parentId);

  const parentOrdersWithSubOrders = parentOrders.map((parentOrder) => ({
    ...parentOrder,
    subOrders: state.filter((order) => {
      return order.parentId === parentOrder.id;
    }),
  }));

  return parentOrdersWithSubOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
});

const parentOrders = createSelector(getOrdersByDomain, (state) =>
  state.filter((order) => !order.parentId)
);

const locationsByDomain = createSelector(
  byDomain,
  LocationsSelectors.byId,
  (_, domain) => domain,
  (state, locationsById, domain) => {
    if (!state || !state[domain] || !state[domain].locations) {
      return [];
    }

    return state[domain]?.locations.map((id) => locationsById[id]);
  }
);

const ShopsSelectors = {
  formattedOrders,
  getOrdersByDomain,
  loading,
  parentOrders,
  locationsByDomain,
};
export default ShopsSelectors;
