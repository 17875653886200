import { createSelector } from 'reselect';
import AuthSelectors from './auth.selectors';

const chats = (state) => state.chats;
const loading = createSelector(chats, (state) => state.loading);
const byId = createSelector(chats, (state) => state.byId);
const order = createSelector(chats, (state) => state.order);
const all = createSelector(byId, order, AuthSelectors.user, (state, orderState, user) => {
  const chatsArr = orderState.map((id) => state[id]);

  const notEmptyChats = chatsArr.filter((el) => el.messages.length > 0);

  return notEmptyChats.map((el) => ({
    ...el,
    unreadNumber: el.messages.filter((message) => !message.read && message.userId !== user.id)
      .length,
  }));
});

const active = createSelector(all, (state) => state.filter((chat) => chat.active));

const getById = createSelector(
  byId,
  (_, id) => id,
  (state, id) => state[id]
);

const isNewMessages = createSelector(active, (state) => state.some((el) => el.unreadNumber > 0));

const ChatsSelectors = {
  all,
  loading,
  getById,
  active,
  isNewMessages,
};

export default ChatsSelectors;
