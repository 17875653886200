import { createSelector } from 'reselect';

const auth = (state) => state.auth;

const user = createSelector(auth, (state) => state.user);

const isShopAdmin = createSelector(user, (state) =>
  state?.roles?.some((el) => el.name === 'shop-admin')
);

const isSuperAdmin = createSelector(user, (state) =>
  state?.roles?.some((el) => el.name === 'super-admin')
);

const AuthSelectors = {
  user,
  isShopAdmin,
  isSuperAdmin,
};

export default AuthSelectors;
