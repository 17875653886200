import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    tabs: {
      width: '100%',
      paddingTop: theme.spacing(2),
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(3),
      },
    },
    toolbarOffset: {
      ...theme.mixins.toolbar,
    },
    layout: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden',
    },
    link: {
      textDecoration: 'none',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }),
  {
    name: 'ShopPanel',
  }
);
