/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { discountApi } from '../api/api';
import { parseDiscounts, parseDiscount } from '../services/discounts.services';
import { setNotification } from './notifications.slice';
import { MESSAGES_TYPES, MESSAGES } from '../constants/constants';

export const getAll = createAsyncThunk(
  'discounts/getAll',
  async (data, { signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await discountApi.getAll({ cancelToken: source.token });

      return parseDiscounts(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const getByUserId = createAsyncThunk(
  'discounts/getByUserId',
  async (id, { rejectWithValue }) => {
    try {
      const response = await discountApi.getByUserId(id);

      return parseDiscounts(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const create = createAsyncThunk(
  'discounts/create',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        origin: rawData.origin,
      };

      if (rawData.description) {
        data.description = rawData.description;
      }

      if (rawData.asGift) {
        data.as_gift = rawData.asGift;
      }

      if (rawData.inPercents) {
        data.in_percents = rawData.inPercents;
      }

      const response = await discountApi.create(data);

      return parseDiscount(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const edit = createAsyncThunk(
  'discounts/edit',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        origin: rawData.origin,
      };

      if (rawData.description) {
        data.description = rawData.description;
      }

      if (rawData.asGift) {
        data.as_gift = rawData.asGift;
      }

      if (rawData.inPercents) {
        data.in_percents = rawData.inPercents;
      }

      await discountApi.edit(rawData.id, data);

      return rawData;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const remove = createAsyncThunk(
  'discounts/remove',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await discountApi.delete(id);

      dispatch(
        setNotification({
          message: MESSAGES.successDeleted,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const assignToUser = createAsyncThunk(
  'discounts/assignToUser',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      if (data.assignIds.length > 0) {
        const response = await discountApi.assignToUser(
          data.userId.toString(),
          data.assignIds
        );
        if (response.status !== 201) {
          return rejectWithValue(response.data);
        }
      }
      if (data.unassignIds.length > 0) {
        const response = await discountApi.unasignFromUser(
          data.userId.toString(),
          data.unassignIds
        );

        if (response.status !== 204) {
          return rejectWithValue(response.data);
        }
      }

      dispatch(
        setNotification({
          message: MESSAGES.successAssigned,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return null;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const unassignFromUser = createAsyncThunk(
  'discounts/unassignFromUser',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await discountApi.unasignFromUser(
        data.userId.toString(),
        data.ids
      );

      if (response.status !== 204) {
        return rejectWithValue(response.data);
      }

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

const discounts = createSlice({
  name: 'discounts',
  initialState: {
    loading: false,
    error: '',
    all: [],
    isSuccess: false,
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
    },
    [getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.all = action.payload;
    },
    [getAll.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
      state.loading = false;
    },
    [create.fulfilled]: (state, action) => {
      state.all = [...state.all, action.payload];
      state.isSuccess = true;
    },
    [create.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [edit.fulfilled]: (state, action) => {
      state.all = state.all.map((el) => {
        if (el.id === action.payload.id) {
          return {
            ...el,
            ...action.payload,
          };
        }

        return el;
      });
      state.isSuccess = true;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [remove.fulfilled]: (state, action) => {
      state.all = state.all.filter((el) => el.id !== action.payload.id);
    },
    [remove.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
  },
});

export const { resetSuccess, resetError } = discounts.actions;

export default discounts.reducer;
