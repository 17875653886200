export const loadState = (name) => {
  try {
    const serializedState = localStorage.getItem(name);
    if (serializedState === null) {
      return null;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

export const saveState = (state) => {
  try {
    Object.keys(state).forEach((key) => {
      const keyState = loadState(key);

      if (keyState) {
        const serializedState = JSON.stringify({ ...keyState, ...state[key] });
        localStorage.setItem(key, serializedState);
      } else {
        const serializedState = JSON.stringify(state[key]);
        localStorage.setItem(key, serializedState);
      }
    });
    // const serializedState = JSON.stringify(state);
    // localStorage.setItem('state', serializedState);
  } catch (err) {
    // err
  }
};

export const clearState = (name) => {
  localStorage.removeItem(name);
};
