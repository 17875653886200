import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `solid 1px ${fade(theme.palette.primary.light, 0.5)}`,
    position: 'relative',
  },
  main: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'hidden',
    flex: 1,
  },
  title: {
    fontSize: '1rem',
    lineHeight: 1,
  },
  time: {
    flexShrink: 0,
  },
  message: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    display: 'block',
    overflow: 'hidden',
  },
  unread: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 11,
    padding: 5,
    borderRadius: 11,
    minWidth: 21,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
  },
  unreadText: {
    lineHeight: 1,
  },
}), {
  name: 'ChatPreview',
});
