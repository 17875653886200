/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { productsSetsApi } from '../api/api';
import { parseProductSet, parseProductSets } from '../services/productSets.services';
import { setNotification } from './notifications.slice';
import { MESSAGES_TYPES, MESSAGES } from '../constants/constants';
import { fromArray } from '../utils/array.utils';

export const getAll = createAsyncThunk(
  'productSets/getAll',
  async (_, { signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await productsSetsApi.getAll({
        cancelToken: source.token,
      });

      return parseProductSets(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const create = createAsyncThunk(
  'productSets/create',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        name: rawData.name,
        for_cp: rawData.forCp,
        for_tn: rawData.forTn,
        active: rawData.active,
        for_shop: rawData.forShop,
        equipment_is_tn: rawData.equipmentIsTn
      };

      if (rawData.description) {
        data.description = rawData.description;
      }

      if (rawData.discount) {
        data.discount = rawData.discount;
      }

      if (rawData.slotNumber) {
        data.slot_number = rawData.slotNumber;
      }

      if (rawData.profit) {
        data.profit = rawData.profit;
      }

      const response = await productsSetsApi.create(
        rawData.locations,
        rawData.productIds,
        rawData.competenceIds,
        rawData.salaryRateIds,
        data
      );

      return parseProductSet(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const edit = createAsyncThunk('productSets/edit', async (rawData, { rejectWithValue }) => {
  try {
    const data = {
      name: rawData.name,
      description: rawData.description,
      discount: rawData.discount,
      slot_number: rawData.slotNumber,
      profit: rawData.profit,
      for_cp: rawData.forCp,
      for_tn: rawData.forTn,
      equipment_is_tn: rawData.equipmentIsTn,
      active: rawData.active,
    };

    await productsSetsApi.edit(
      rawData.id,
      rawData.locations.map((el) => el.id || el),
      rawData.productIds.map((el) => el.id),
      rawData.competenceIds.map((el) => el.id),
      rawData.salaryRateIds.map((el) => el.id),
      data
    );

    return rawData;
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const remove = createAsyncThunk(
  'productSets/remove',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await productsSetsApi.delete(id);

      dispatch(
        setNotification({
          message: MESSAGES.successDeleted,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const productSets = createSlice({
  name: 'productSets',
  initialState: {
    byId: {},
    error: '',
    loading: false,
    isSuccess: false,
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.byId = fromArray(action.payload, 'id');
    },
    [getAll.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
      state.loading = false;
    },
    [create.pending]: (state) => {
      state.error = '';
    },
    [create.fulfilled]: (state, action) => {
      state.byId[action.payload.id] = action.payload;
      state.isSuccess = true;
    },
    [create.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [edit.pending]: (state) => {
      state.error = '';
    },
    [edit.fulfilled]: (state, action) => {
      state.byId[action.payload.id] = {
        ...state.byId[action.payload.id],
        ...action.payload,
      };
      state.isSuccess = true;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [remove.pending]: (state) => {
      state.error = '';
    },
    [remove.fulfilled]: (state, action) => {
      delete state.byId[action.payload.id];
    },
    // [remove.rejected]: (state, action) => {
    //   if (action.payload && action.payload.message) {
    //     state.error = action.payload.message;
    //   }
    //   state.error = action.payload;
    // },
  },
});

export const { resetError, resetSuccess } = productSets.actions;

export default productSets.reducer;
