// eslint-disable-next-line import/prefer-default-export
export const parseEquipmentEl = (el) => ({
  id: el.id,
  aad: el.aad,
  aadCounter: el.aad_counter,
  bagType: el.bag_type,
  canRent: el.can_rent,
  createdAt: el.created_at,
  main: el.main,
  mainProductDate: el.main_product_date,
  mainSerialNumber: el.main_serial_number,
  mainSize: el.main_size,
  packed: el.packed,
  rent: el.rent,
  reserve: el.reserve,
  reserveNextPack: el.reserve_next_pack,
  reserveProductDate: el.reserve_product_date,
  reserveSerialNumber: el.reserve_serial_number,
  reserveSize: el.reserve_size,
  riggerFullName: el.rigger_full_name,
  state: el.state,
  size: el.size,
  updatedAt: el.updated_at,
  userId: el.user_id,
  user: el.user,
  rentPrice: el.rent_price,
  isTN: el.is_tn,
  location: el.location,
  selfPack: el.self_pack,
  harness: el.harness,
  harnessName: el.harness_name,
  weight: el.weight
});

export const parseEquipment = (equipment) => (
  equipment.map((el) => parseEquipmentEl(el))
);
