import { createMuiTheme } from '@material-ui/core/styles';

// eslint-disable-next-line
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#464646',
    },
    secondary: {
      main: process.env.REACT_APP_ACCENT_COLOR, // '#e42221'
    },
  },
  typography: {
    fontFamily: '"Poppins", "Helvetica", "sans-serif"',
    button: {
      fontWeight: 400,
      fontSize: '0.72rem',
      letterSpacing: '3.8px',
      lineHeight: '30px',
    },
    body1: {
      fontSize: '0.88rem',
      letterSpacing: '0.5px',
      fontWeight: 400,
    },
  },
  overrides: {
    MuiListItem: {
      root: {
        '&$selected': {
          // <-- mixing the two classes
          backgroundColor: process.env.REACT_APP_ACCENT_COLOR, // '#e42221',
          color: '#ffffff',

          '&:hover': {
            backgroundColor: process.env.REACT_APP_ACCENT_COLOR, // '#e42221',
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '5px',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        width: '25px',
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: '150px',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 44,
        marginBottom: 20,
      },
    },
    MuiTab: {
      root: {
        minWidth: 150,
        flexGrow: 1,
        minHeight: 44,
        borderBottom: 'solid 1px #cccccc',
        fontWeight: 600,
      },
      textColorInherit: {
        color: '#4A4A4A',
        opacity: 0.6,

        '&$selected': {
          color: '#4A4A4A',
        },
      },
      selected: {},
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"] $input[class*="MuiOutlinedInput-input"]': {
          padding: '5px 4px !important',
        },
      },
    },
    MuiSelect: {
      outlined: {
        padding: '14px 14px',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 16px) scale(1)',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '14px 14px',
      },
    },
  },
});
