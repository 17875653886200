import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    container: {
      position: 'relative',
      width: '100%',
      paddingBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,

      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(3),
      },
    },
    toolbar: {
      flexDirection: 'column',
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
      },
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(3),
      },
    },
    controls: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    controlWrapper: {
      flex: 1,
    },
    control: {
      width: '100%',
      minHeight: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        marginRight: theme.spacing(2),
        maxWidth: `calc(50% - ${theme.spacing(2)}px)`,
      },

      [theme.breakpoints.up('xl')]: {
        maxWidth: 'calc(100% / 3)',
      },
    },
    paper: {
      flex: 1,
      display: 'flex',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
    },
    chatList: {
      listStyle: 'none',
    },
    link: {
      textDecoration: 'none',
    },
  }),
  {
    name: 'Chats',
  }
);
