export const getFullMenu = (token) => [
  {
    titleKey: 'forShop',
    path: 'shop',
    nestedList: [
      {
        titleKey: 'olimpicSkydive',
        path: '/shop/olimpic-skydive.pl/orders',
      },
    ],
  },
  {
    titleKey: 'offer',
    path: 'offer',
    nestedList: [
      {
        titleKey: 'olimpicSkydive',
        path: 'https://olimpic-skydive.pl/wp-admin',
        outLink: true,
      },
      {
        titleKey: 'checkInJumps',
        path: process.env.REACT_APP_CHECKIN_URL,
        outLink: true,
      },
      {
        titleKey: 'speedfly',
        path: 'https://speedfly.pl/wp-admin',
        outLink: true,
      },
      {
        titleKey: 'checkInTunnel',
        path: '/offer/check-in-tinnel',
      },
      {
        titleKey: 'mailWizard',
        path: '/offer/mail-wizard',
      },
      {
        titleKey: 'offerCreator',
        path: '/offer/creator',
      },
      {
        titleKey: 'club',
        path: '/offer/club',
      },
      {
        titleKey: 'training',
        path: '/offer/training',
      },
    ],
  },
  {
    titleKey: 'communication',
    path: '/communication',
    nestedList: [
      {
        titleKey: 'usersCommunication',
        path: '/communication-panel',
      },
      {
        titleKey: 'teamsCommunication',
        path: '/communication/teams/incoming',
      },
    ],
  },
  {
    titleKey: 'userDatabase',
    path: '/users-database',
  },
  {
    titleKey: 'reservations',
    path: '/reservations',
  },
  {
    titleKey: 'dropZones',
    path: '/drop-zones',
  },
  {
    titleKey: 'organizations',
    path: '/organizations',
  },
  {
    titleKey: 'tunnel',
    path: '/tunnel',
  },
  {
    titleKey: 'reports',
    path: '/reports',
  },
  {
    titleKey: 'salaryRates',
    path: '/salary-rates',
  },
  // {
  //   titleKey: 'api',
  //   path: `${process.env.REACT_APP_API_ENDPOINT}/admin?token=${token}`,
  //   outLink: true,
  // },
  {
    titleKey: 'regulations',
    path: '/regulations',
  },
  {
    titleKey: 'discounts',
    path: '/discounts',
  },
  {
    titleKey: 'planes',
    path: '/planes',
  },
  {
    titleKey: 'canopies',
    path: '/equipment',
  },
  {
    titleKey: 'dictionaries',
    path: '/dictionaries',
    nestedList: [
      {
        titleKey: 'products',
        path: '/dictionaries/products',
      },
      {
        titleKey: 'documentTypes',
        path: '/dictionaries/document-types',
      },
      {
        titleKey: 'tasks',
        path: '/dictionaries/tasks',
      },
      {
        titleKey: 'competences',
        path: '/dictionaries/user-competences',
      },
      {
        titleKey: 'roles',
        path: '/dictionaries/users-roles',
      },
      {
        titleKey: 'courses',
        path: '/dictionaries/courses',
      },
    ],
  },
  {
    titleKey: 'cash',
    path: '/cash-bank',
  },
  {
    titleKey: 'paymentSustems',
    path: '/paymentSustems',
    nestedList: [
      {
        titleKey: 'dotPay',
        path: '/paymentSustems/dotPay',
      },
      {
        titleKey: 'payU',
        path: '/paymentSustems/payU',
      },
      {
        titleKey: 'payPal',
        path: '/paymentSustems/payPal',
      },
      {
        titleKey: 'przelewy24',
        path: '/paymentSustems/przelewy24',
      },
      {
        titleKey: 'tpay',
        path: '/paymentSustems/tpay',
      },
      {
        titleKey: 'googlePay',
        path: '/paymentSustems/googlePay',
      },
      {
        titleKey: 'paybylink',
        path: '/paymentSustems/paybylink',
      },
    ],
  },
  {
    titleKey: 'kiosk',
    path: '/kiosk',
    nestedList: [
      {
        titleKey: 'passenger',
        path: '/kiosk/passenger',
      },
      {
        titleKey: 'jumper',
        path: '/kiosk/jumper',
      },
    ],
  },
  {
    titleKey: 'apps',
    path: '/apps',
    nestedList: [
      {
        titleKey: 'android',
        path: '/apps/android',
      },
      {
        titleKey: 'ios',
        path: '/apps/ios',
      },
    ],
  },
  {
    titleKey: 'agents',
    path: '/agents',
    nestedList: [
      {
        titleKey: 'agentsList',
        path: '/agents/list',
      },
      {
        titleKey: 'ordersTemplates',
        path: '/agents/orders-templates',
      },
    ],
  },
  {
    titleKey: 'subcontractors',
    path: '/subcontractors',
  },
];

export const getShopAdminMenu = () => [
  {
    titleKey: 'olimpicSkydive',
    path: '/shop/olimpic-skydive.pl',
  },
];

export const getNavigationMenu = (isSuperAdmin, token) =>
  isSuperAdmin ? getFullMenu(token) : getShopAdminMenu();
