import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    flex: 1,
    width: '100%',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  rowSelected: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.dark,
  },
  selected: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  toolbar: {
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  title: {
    flex: '1 1 100%',
  },
  selectedTitle: {
    flex: '1 1 100%',
    color: theme.palette.secondary.contrastText,
  },
  pagination: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sendButton: {
    color: theme.palette.secondary.contrastText,
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  filter: {
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: theme.spacing(2),

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  caption: {
    '&:nth-child(2)': {
      display: 'none',
    },
  },
  paginationInput: {
    display: 'none',
  },
}));
