import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  InputLabel,
  TextField,
  AccordionDetails,
  Divider,
  AccordionActions,
  Button,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import TimeTable from '../time-table/TimeTable';
import MultipleInput from '../multiple-input/MultipleInput';
import { DROP_ZONES_THEME, DROP_ZONES_DEFAULT } from '../../constants';
import { edit } from '../../../../slices/locations.slice';
import useStyles from './styles';

const DropZoneEditable = (props) => {
  const { endEditMode, dz } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const { control, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    const values = [];
    Object.keys(dz).forEach((el) => {
      if (dz[el]) {
        values.push({ [el]: dz[el] });
      }
    });
    setValue(values);
  }, [dz, setValue]);

  const handleEndEditMode = useCallback(() => {
    endEditMode();
  }, [endEditMode]);

  const onSubmit = useCallback(
    async (values) => {
      await dispatch(
        edit({
          ...dz,
          ...values,
          lat: +values.lat,
          long: +values.long,
          runwaySize: +values.runwaySize,
          placeCost: +values.placeCost,
          commercialPlaceCost: +values.commercialPlaceCost,
        })
      );
      endEditMode();
    },
    [dispatch, endEditMode, dz]
  );

  return (
    <>
      <AccordionDetails>
        <form className={classes.form}>
          <div className={classes.column}>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="name">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.name')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                  inputProps: {
                    maxLength: '20',
                  },
                }}
                defaultValue=""
                id="name"
                name="name"
                variant="outlined"
                required
                placeholder={t('dropZones.name')}
                margin="dense"
                helperText={errors.name && errors.name.message}
                error={!!errors.name}
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="currency">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.currency')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                  inputProps: {
                    maxLength: '8',
                  },
                }}
                defaultValue=""
                id="currency"
                name="currency"
                variant="outlined"
                required
                placeholder={t('dropZones.currency')}
                margin="dense"
                helperText={errors.currency && errors.currency.message}
                error={!!errors.currency}
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="locustProfit">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.locustProfit')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                  inputProps: {
                    maxLength: '8',
                  },
                }}
                defaultValue=""
                id="locustProfit"
                name="locustProfit"
                variant="outlined"
                required
                placeholder={t('dropZones.locustProfit')}
                margin="dense"
                helperText={errors.locustProfit && errors.locustProfit.message}
                error={!!errors.locustProfit}
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="runwaySize">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.runway')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="runwaySize"
                name="runwaySize"
                variant="outlined"
                type="number"
                placeholder={t('dropZones.runwaySize')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="runwayDirections">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.runwayDirections')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="runwayDirections"
                name="runwayDirections"
                variant="outlined"
                placeholder={t('dropZones.runwayDirections')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="planes">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.planes')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={MultipleInput}
                control={control}
                className={classes.input}
                defaultValue={[]}
                id="planes"
                name="planes"
                placeholder={t('dropZones.planes')}
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="workingAltitudes">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.altitudes')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={MultipleInput}
                control={control}
                className={classes.input}
                defaultValue={[]}
                id="workingAltitudes"
                name="workingAltitudes"
                type="number"
                placeholder={t('dropZones.altitudes')}
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="services">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.services')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={MultipleInput}
                control={control}
                className={classes.input}
                defaultValue={[]}
                id="services"
                name="services"
                placeholder={t('dropZones.services')}
              />
            </div>
            <div className={classes.row}>
              <InputLabel
                className={classes.label}
                htmlFor="flightsPerDay"
              >
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.flightsPerDay')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="flightsPerDay"
                name="flightsPerDay"
                variant="outlined"
                type="number"
                placeholder={t('dropZones.flightsPerDay')}
                margin="dense"
                required
              />
            </div>
            <div className={classes.row}>
              <InputLabel
                className={classes.label}
                htmlFor="timeBetweenFlights"
              >
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.timeBetweenFlightsInMin')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="timeBetweenFlights"
                name="timeBetweenFlights"
                variant="outlined"
                type="number"
                placeholder={t('dropZones.timeBetweenFlightsInMin')}
                margin="dense"
                required
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="placeCost">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.placeCost')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="placeCost"
                name="placeCost"
                variant="outlined"
                type="number"
                placeholder={t('dropZones.placeCost')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel
                className={classes.label}
                htmlFor="commercialPlaceCost"
              >
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.commercialPlaceCost')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="commercialPlaceCost"
                name="commercialPlaceCost"
                variant="outlined"
                type="number"
                placeholder={t('dropZones.commercialPlaceCost')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <Controller
                as={
                  <FormControlLabel control={<Checkbox color="secondary" />} />
                }
                control={control}
                className={classes.checkbox}
                defaultValue={false}
                id="useSensors"
                name="useSensors"
                label={t('dropZones.useSensors')}
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="email">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.email')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                type="email"
                id="email"
                name="email"
                required
                placeholder={t('dropZones.email')}
                margin="dense"
                variant="outlined"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="phones">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.phones')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={MultipleInput}
                control={control}
                className={classes.input}
                defaultValue={[]}
                id="phones"
                name="phones"
                required
                type="phone"
                placeholder={t('dropZones.phones')}
              />
            </div>
          </div>
          <div className={classes.column}>
            {/* <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="primed">
                <Typography className={classes.labelText}
                color="textSecondary" variant="subtitle1" component="div">
                  {`${t('dropZones.primed')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={Checkbox}
                control={control}
                className={classes.chckbox}
                defaultValue={false}
                id="primed"
                name="primed"
                required
                placeholder={t('dropZones.primed')}
                type="checkbox"
              />
            </div> */}
            {/* <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="primedAmount">
                <Typography className={classes.labelText}
                color="textSecondary" variant="subtitle1" component="div">
                  {`${t('dropZones.primedAmount')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="primedAmount"
                name="primedAmount"
                variant="outlined"
                type="number"
                required
                placeholder={t('dropZones.primedAmount')}
                margin="dense"
              />
            </div> */}
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="address">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.address')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="address"
                name="address"
                variant="outlined"
                required
                placeholder={t('dropZones.address')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="lat">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.lat')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                type="number"
                id="lat"
                name="lat"
                variant="outlined"
                required
                placeholder={t('dropZones.lat')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="long">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.lng')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                type="number"
                id="long"
                name="long"
                variant="outlined"
                required
                placeholder={t('dropZones.lng')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="localIp">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.serverIP')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="localIp"
                name="localIp"
                variant="outlined"
                required
                placeholder={t('dropZones.serverIP')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="moderationUrl">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.moderationUrl')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="moderationUrl"
                name="moderationUrl"
                variant="outlined"
                required
                placeholder={t('dropZones.moderationUrl')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="radioFrequency">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.radioFrequency')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                type="number"
                id="radioFrequency"
                name="radioFrequency"
                variant="outlined"
                placeholder={t('dropZones.radioFrequency')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="atz">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.atz')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="atz"
                name="atz"
                variant="outlined"
                placeholder={t('dropZones.atz')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="icao">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.icao')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="icao"
                name="icao"
                variant="outlined"
                placeholder={t('dropZones.icao')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="long">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.mode')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={
                  <RadioGroup aria-label={t('dropZones.mode')}>
                    <FormControlLabel
                      value="high"
                      control={<Radio />}
                      label={t('dropZones.high')}
                    />
                    <FormControlLabel
                      value="regular"
                      control={<Radio />}
                      label={t('dropZones.regular')}
                    />
                    <FormControlLabel
                      value="low"
                      control={<Radio />}
                      label={t('dropZones.low')}
                    />
                    <FormControlLabel
                      value="owner"
                      control={<Radio />}
                      label={t('dropZones.owner')}
                    />
                  </RadioGroup>
                }
                control={control}
                className={classes.radioGroup}
                defaultValue=""
                id="mode"
                name="mode"
                variant="outlined"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="workingHours">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.workingHours')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="workingHours"
                name="workingHours"
                variant="outlined"
                placeholder={t('dropZones.workingHours')}
                margin="dense"
              />
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.label} htmlFor="messageInfo">
                <Typography
                  className={classes.labelText}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.messageInfo')}: `}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.inputOutlinened,
                  },
                }}
                defaultValue=""
                id="messageInfo"
                name="messageInfo"
                variant="outlined"
                placeholder={t('dropZones.messageInfo')}
                margin="dense"
              />
            </div>
          </div>
          <div className={classes.column}>
            {/*
              <Typography
                className={classes.label} color="textSecondary" variant="subtitle1" component="div">
                {`${t('dropZones.timeTable')}: `}
              </Typography>
            */}
            <Controller
              as={TimeTable}
              control={control}
              className={classes.timeTable}
              isEditable
              name="dates"
              defaultValue={[]}
              rules={{
                validate: (value) =>
                  (value && value.length > 0) || t('requiredField'),
              }}
            />
            <ErrorMessage errors={errors} name="dates">
              {({ message }) => (
                <Typography color="error" component="div" variant="caption">
                  {message}
                </Typography>
              )}
            </ErrorMessage>
          </div>
        </form>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <div className={classes.checkboxes}>
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="forCP"
            name="forCP"
            label={t('dropZones.forCP')}
          />
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="forCheckin"
            name="forCheckin"
            label={t('dropZones.forCheckin')}
          />
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="forShop"
            name="forShop"
            label={t('dropZones.forShop')}
          />
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="uspa"
            name="uspa"
            label={t('dropZones.uspa')}
          />
        </div>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleEndEditMode}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          {t('save')}
        </Button>
      </AccordionActions>
    </>
  );
};

DropZoneEditable.propTypes = {
  ...DROP_ZONES_THEME,
};

DropZoneEditable.defaultProps = {
  ...DROP_ZONES_DEFAULT,
};

export default DropZoneEditable;
