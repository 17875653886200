export const parseRegulation = (regulation) => ({
  id: regulation.id,
  createdAt: regulation.created_at,
  updatedAt: regulation.updated_at,
  name: regulation.name,
  description: regulation.description,
  locations: regulation.locations,
  file: regulation.file,
});

export const parseRegulations = (regulations) => (
  regulations.map((regulation) => parseRegulation(regulation))
);
