import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import Loader from '../page-loader/PageLoader';
import useStyles from './styles';

const LoadingButton = (props) => {
  const {
    loading, done, children, variant, color, size, disabled, ...other
  } = props;

  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled || loading}
      {...other}
    >
      {loading && <Loader size={size} />}
      <div className={cn({ [classes.childrenInvisible]: loading })}>{children}</div>
    </Button>
  );
};

LoadingButton.defaultProps = {
  loading: false,
  done: false,
  variant: 'text',
  color: '',
  children: null,
  size: 'medium',
  disabled: false,
};

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  done: PropTypes.bool,
  children: PropTypes.node,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
};

export default LoadingButton;
