import React from 'react';
import useStyles from './styles';

const Textarea = (props) => {
  const classes = useStyles();

  return (
    <textarea
      className={classes.container}
      {...props}
    />
  );
};

Textarea.defaultProps = {
  value: '',
};

export default Textarea;
