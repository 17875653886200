import { makeStyles } from '@material-ui/core/styles';
import BackgroundImg from '../../assets/images/background.png';

export default makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  logoWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    background: `url(${BackgroundImg})`,
    [theme.breakpoints.up('md')]: {
      order: 1,
      flex: 1,
      width: '50%',
      height: 'auto',
    },
  },
  logo: {
    width: '200px',
    [theme.breakpoints.up('md')]: {
      width: '80%',
      maxWidth: '320px',
    },
  },
  formWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      order: 0,
      width: '50%',
    },
  },
  form: {
    width: '100%',
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60%',
    },
  },
  input: {
    marginBottom: '20px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '30px',
    fontSize: '1.86rem',
    fontWeight: 500,
    lineHeight: '26px',
  },
  switch: {
    position: 'absolute',
    top: '15px',
    right: '15px',
  },
}),
{
  name: 'Login',
});
