import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  row: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  rowSelected: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.dark,
  },
  selected: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  toolbar: {
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(3),
    },

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  selectedTitle: {
    flex: '1 1 100%',
    color: theme.palette.secondary.contrastText,
  },
  selectedButton: {
    color: theme.palette.secondary.contrastText,
  },
  control: {
    minWidth: '100%',
    marginTop: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
      minWidth: 200,
      marginTop: 0,
    },
  },
  table: {
    marginBottom: 0,
  },
}), {
  name: 'CompetencesTable',
});
