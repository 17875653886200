import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import PageLoader from '../../components/page-loader/PageLoader';
import SortableTable from '../../components/sortable-table/SortableTable';
import DocumentTypeDialog from './components/document-type-dialog/DocumentTypeDialog';
import { getTypes, deleteType } from '../../slices/documents.slice';
import { getAll as getCompetences } from '../../slices/competences.slice';
import useStyles from './styles';

const TABLE_COLUMNS = [
  { id: 'name', disablePadding: true, isSortable: true },
  { id: 'origin', isSortable: true },
  {
    id: 'competences',
    render: (value) => value.map((el) => el.name).join(', '),
  },
  { id: 'description' },
];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { numSelected, onDelete, types, onSelectType } = props;
  const { t } = useTranslation();
  const [isCreatDialog, setIsCreateDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsCreateDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsCreateDialog(false);
  }, []);

  const handleSearchChange = useCallback(
    (e, value) => {
      onSelectType(value);
    },
    [onSelectType]
  );

  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.row}>
        <Typography className={classes.title} variant='h5' id='tableTitle' component='h2'>
          {t('dictionaries.documentTypes')}
        </Typography>
      </div>

      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography
              className={classes.selectedTitle}
              color='inherit'
              variant='subtitle1'
              component='div'
            >
              {`${numSelected} ${t('selected')}`}
            </Typography>

            <Tooltip title={t('delete')}>
              <IconButton
                className={classes.selectedButton}
                aria-label={t('delete')}
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className={classes.controls}>
        <div className={classes.controlWrapper}>
          <Autocomplete
            className={classes.control}
            type='search'
            name='search'
            variant='outlined'
            size='medium'
            options={types}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={handleSearchChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                placeholder={t('search')}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        <Button
          className={classes.createButton}
          variant='contained'
          color='primary'
          onClick={openCreateDialog}
        >
          {t('dictionaries.create')}
        </Button>
      </div>
      {isCreatDialog && <DocumentTypeDialog isOpen={isCreatDialog} onClose={closeCreateDialog} />}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onSelectType: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { documents } = useSelector((state) => state);
  const [selected, setSelected] = useState([]);
  const [searchResult, setSearchResult] = useState(null);

  useEffect(() => {
    const typesPromise = dispatch(getTypes());
    const competencesPromise = dispatch(getCompetences());

    return () => {
      typesPromise.abort();
      competencesPromise.abort();
    };
  }, [dispatch]);

  const [isDialog, setIsDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dialogMode, setDialogMode] = useState('');

  const openDialog = useCallback((data, mode) => {
    setIsDialog(true);
    setDialogData(data);
    setDialogMode(mode);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialog(false);
    setDialogData(null);
    setDialogMode('');
  }, []);

  const handleDelete = useCallback(
    async (data) => {
      await dispatch(deleteType(data.id));
    },
    [dispatch]
  );

  const handleDeleteSelected = useCallback(() => {
    selected.forEach((id) => {
      dispatch(deleteType(id));
    });
    setSelected([]);
  }, [selected, dispatch]);

  const handleSetSearchResult = useCallback((type) => {
    setSearchResult(type);
  }, []);

  if (documents.loading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        onDelete={handleDeleteSelected}
        types={documents.types}
        onSelectType={handleSetSearchResult}
      />
      <SortableTable
        columns={TABLE_COLUMNS}
        data={searchResult ? [searchResult] : documents.types}
        translationNamespace='dictionaries'
        onSelect={setSelected}
        selected={selected}
        onShow={(data) => openDialog(data, 'view')}
        onEdit={(data) => openDialog(data, 'edit')}
        onDelete={handleDelete}
      />
      {isDialog && (
        <DocumentTypeDialog
          isOpen={isDialog}
          onClose={closeDialog}
          mode={dialogMode}
          data={dialogData}
        />
      )}
    </main>
  );
}
