/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ordersApi, billingsApi } from '../api/api';
import { parseOrders } from '../services/orders.services';
import { setNotification } from './notifications.slice';
import { fromArray } from '../utils/array.utils';
import { MESSAGES, MESSAGES_TYPES } from '../constants/constants';

export const getByLocationId = createAsyncThunk(
  'orders/getByLocationId',
  async (locationId, { signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await ordersApi.getOrders({
        cancelToken: source.token,
        location_id: locationId,
        parent_id: true,
      });

      const parsedOrders = parseOrders(response.data);

      return {
        locationId,
        orders: parsedOrders,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
  // {
  //   condition: (locationId, { getState }) => {
  //     const { orders } = getState();

  //     if (orders.byLocationId[locationId]?.length > 0) {
  //       return false;
  //     }

  //     return true;
  //   },
  // }
);

export const getByDomain = createAsyncThunk(
  'orders/getByDomain',
  async (domain, { signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await ordersApi.getByDomain({
        cancelToken: source.token,
        domain: domain,
      });
      const parsedOrders = parseOrders(response.data);

      return {
        domain,
        orders: parsedOrders,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
  // {
  //   condition: (domain, { getState }) => {
  //     const { orders } = getState();

  //     if (orders.byDomain[domain]?.length > 0) {
  //       return false;
  //     }

  //     return true;
  //   },
  // }
);

export const editOrder = createAsyncThunk(
  'orders/editOrder',
  async (
    { orderId, id, userId, billing, tbxSend, cancelled, totalPrice },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await billingsApi.edit(id, {
        user_id: userId.toString(),
        billing: {
          name: billing.name,
          surname: billing.surname,
          email: billing.email,
          phone: billing.phone,
          address: billing.address,
          city: billing.city,
          country: billing.country,
          zip_code: billing.zipCode,
          nip: billing.nip,
        },
      });

      await ordersApi.edit(orderId, {
        order: {
          tbx_send: tbxSend,
          canceled: cancelled,
          total_price: totalPrice.toString(),
        },
        user_id: userId.toString(),
      });

      dispatch(
        setNotification({
          isLocalMessage: true,
          message: MESSAGES.successEdited,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return {
        orderId,
        billing,
        tbxSend,
        cancelled,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (locationId, { getState }) => {
      const { orders } = getState();

      if (orders.byLocationId[locationId]?.length > 0) {
        return false;
      }

      return true;
    },
  }
);

const order = createSlice({
  name: 'orders',
  initialState: {
    error: '',
    loading: false,
    isSuccess: false,
    byId: {},
  },
  reducers: {
    setOrders: (state, action) => {
      console.log(action.payload, 'action.payload');
      state.byId = {
        ...state.byId,
        ...fromArray(action.payload, 'id'),
      };
    },
  },
  extraReducers: {
    [editOrder.fulfilled]: (state, action) => {
      state.byId[action.payload.orderId] = {
        ...state.byId[action.payload.orderId],
        billing: {
          ...state.byId[action.payload.orderId].billing,
          ...action.payload.billing,
        },
        tbxSend: action.payload.tbxSend,
        cancelled: action.payload.cancelled,
      };
    },
  },
});
export const { setOrders } = order.actions;
export default order.reducer;
