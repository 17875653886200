import { createSelector } from 'reselect';

const competences = (state) => state.competences;
const all = createSelector(competences, (state) => state.all);

const noNeedDoc = createSelector(competences, all, (state, allState) =>
  state.noNeedDocIds.map((id) => {
    let find;

    allState.forEach((el) => {
      if (el.id === id) {
        find = el;
      }
    });

    return find;
  })
);

const CompetencesSelectors = {
  all,
  noNeedDoc,
};

export default CompetencesSelectors;
