import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Chip from '@material-ui/core/Chip';
import useStyles from './styles';

function OrderStatus(props) {
  const { status, translationNameSpace, className } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Chip
      label={t(translationNameSpace ? `${translationNameSpace}.${status}` : status)}
      className={cn(classes[status], className)}
    />
  );
}

export default OrderStatus;
