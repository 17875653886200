import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import {
  DialogContent,
  InputLabel,
  Typography,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import Textarea from '../../../../components/textarea/Textarea';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import FormDialogActions from '../../../../components/form-dialog/FormDialogActions';
import PageLoader from '../../../../components/page-loader/PageLoader';
import { ORIGINS } from '../../constants';
import { create, resetSuccess, resetError, edit } from '../../../../slices/discounts.slice';
import useStyles from './styles';

const DiscountDialog = (props) => {
  const { isOpen, handleClose, data, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { discounts } = useSelector((state) => state);

  useEffect(() => {
    let timeout;
    if (discounts.isSuccess) {
      timeout = setTimeout(() => {
        dispatch(resetSuccess());
        handleClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, handleClose, discounts.isSuccess]);

  const { control, handleSubmit, errors, formState } = useForm({
    defaultValues: data,
  });

  const onSubmit = useCallback(
    (values) => {
      if (mode === 'edit') {
        dispatch(
          edit({
            id: data.id,
            ...values,
          })
        );
      } else {
        dispatch(
          create({
            ...values,
          })
        );
      }
    },
    [dispatch, data.id, mode]
  );

  const onClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    handleClose();
  }, [dispatch, handleClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <FormDialog
      onClose={onClose}
      title={t('discounts.discount')}
      onSubmit={memoSubmit}
      isOpen={isOpen}
    >
      <DialogContent className={classes.content}>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="origin">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('discounts.origin')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={
              <Select variant="outlined" margin="dense">
                <MenuItem value="none">{t('none')}</MenuItem>
                {ORIGINS.map((item) => (
                  <MenuItem value={item} key={item}>
                    {t(`discounts.${item}`)}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            className={cn(classes.input, classes.select)}
            rules={{
              validate: (value) => (!!value && value !== 'none') || t('requiredField'),
            }}
            onChange={([selected]) => selected.target.value}
            name="origin"
            id="origin"
            defaultValue="none"
            error={!!errors.origin}
            disabled={mode === 'view'}
          />
          <ErrorMessage errors={errors} name="origin">
            {({ message }) => <Typography>{message}</Typography>}
          </ErrorMessage>
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="inPercents">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('discounts.inPercents')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="inPercents"
            name="inPercents"
            variant="outlined"
            placeholder={t('discounts.inPercents')}
            margin="dense"
            type="number"
            inputProps={{
              min: 0,
              step: 1,
            }}
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="asGift">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('discounts.asGift')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="asGift"
            name="asGift"
            variant="outlined"
            placeholder={t('discounts.asGift')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="description">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('discounts.description')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Textarea}
            control={control}
            placeholder={t('discounts.description')}
            required
            name="description"
            id="description"
            defaultValue=""
            disabled={mode === 'view'}
          />
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <FormDialogActions
        mode={mode}
        isSuccess={discounts.isSuccess}
        error={discounts.error}
        onCancel={onClose}
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

DiscountDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    inPercents: PropTypes.string,
    origin: PropTypes.number,
  }),
  mode: PropTypes.string,
};

DiscountDialog.defaultProps = {
  isOpen: false,
  data: {},
  mode: 'create',
};

export default DiscountDialog;
