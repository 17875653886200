import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import {
  DialogContent,
  DialogActions,
  FormControlLabel,
  Button,
  Typography,
  TextField,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import PageLoader from '../../../components/page-loader/PageLoader';
import LocationsSelectors from '../../../selectors/locations.selectors';
import FormDialog from '../../../components/form-dialog/FormDialog';
import Textarea from '../../../components/textarea/Textarea';
import {
  create,
  edit,
  resetError,
  resetSuccess,
} from '../../../slices/products.slice';
import { VATS } from '../../../constants/constants';
import { formatDouble } from '../../../utils/number.utils';
import useStyles from './styles';

const ProductDialog = (props) => {
  const { isOpen, onClose, data, mode } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { products } = useSelector((state) => state);
  const locations = useSelector(LocationsSelectors.all);

  useEffect(() => {
    let timeout;
    if (products.isSuccess) {
      timeout = setTimeout(() => {
        dispatch(resetSuccess());
        onClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  });

  const { control, errors, handleSubmit, formState, watch, setValue } = useForm(
    {
      defaultValues: {
        ...data,
        location: data.location,
        vat: data.vat,
      },
    }
  );

  const price = watch('price', data.price || 0);
  const vat = watch('vat', data.vat || 0);
  const forCheckin = watch('forCheckin', data.forCheckin);

  const onSubmit = useCallback(
    async (values) => {
      if (mode === 'edit') {
        await dispatch(
          edit({
            id: data.id,
            ...values,
            price: +values.price,
            vat: values.vat === 'none' ? null : values.vat,
            location: values.location,
          })
        );
      } else {
        await dispatch(
          create({
            ...values,
            price: +values.price,
            vat: values.vat === 'none' ? null : values.vat,
            location: values.location,
          })
        );
      }
    },
    [dispatch, mode, data.id]
  );

  const handleClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    onClose();
  }, [dispatch, onClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      isOpen={isOpen}
      onSubmit={memoSubmit}
      title={t('products.title')}
    >
      <DialogContent className={classes.content}>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('products.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="name"
            name="name"
            placeholder={t('products.name')}
            variant="outlined"
            required
            margin="dense"
            disabled={mode === 'view' || (mode === 'edit' && forCheckin)}
            rules={{
              validate: (value) => {
                if (forCheckin && value !== data.name) {
                  setValue('name', data.name);
                  return t('products.editError');
                }

                return true;
              },
            }}
            error={errors.name}
            helperText={errors.name?.message}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="price">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('products.grossPrice')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            inputProps={{
              min: 0,
            }}
            defaultValue=""
            id="price"
            name="price"
            placeholder={t('products.price')}
            variant="outlined"
            required
            margin="dense"
            type="number"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="sfPrice">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('products.sfDiscount')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            inputProps={{
              min: 0,
              max: 100,
            }}
            defaultValue=""
            id="sfPrice"
            name="sfPrice"
            placeholder={t('products.sfDiscount')}
            variant="outlined"
            required
            margin="dense"
            type="number"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="location">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('products.vat')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={({ onChange, value, ...otherProps }) => (
              <FormControl {...otherProps}>
                <InputLabel
                  classes={{ root: classes.formLabel, shrink: classes.shrink }}
                >
                  {t('products.vat')}
                </InputLabel>
                <Select defaultValue="" value={value} onChange={onChange}>
                  <MenuItem value="none">{t('none')}</MenuItem>
                  {VATS.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                  <MenuItem value="not_charged" key="notCharged">
                    {t('not_charged')}
                  </MenuItem>
                  <MenuItem value="tax_free" key="taxFree">
                    {t('tax_free')}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            control={control}
            className={cn(classes.input, classes.select)}
            onChange={([selected]) => selected.target.value}
            name="vat"
            id="vat"
            variant="outlined"
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div>
          <InputLabel className={classes.label} htmlFor="location">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('location')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={({ onChange, value, ...otherProps }) => (
              <FormControl {...otherProps}>
                <InputLabel
                  classes={{ root: classes.formLabel, shrink: classes.shrink }}
                >
                  {t('location')}
                </InputLabel>
                <Select defaultValue="" value={value} onChange={onChange}>
                  {locations.map((el) => (
                    <MenuItem value={el.id} key={el.id}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.location && (
                  <FormHelperText>{errors.location.message}</FormHelperText>
                )}
              </FormControl>
            )}
            control={control}
            className={cn(classes.input, classes.select)}
            onChange={([e]) => e.target.value}
            name="location"
            id="location"
            variant="outlined"
            margin="dense"
            disabled={mode === 'view'}
            error={!!errors.location}
          />
        </div>
        <div className={classes.row}>
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="isService"
            name="isService"
            label={t('products.isService')}
            disabled={mode === 'view'}
          />
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="isProduct"
            name="isProduct"
            label={t('products.isProduct')}
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="type">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('products.description')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Textarea}
            control={control}
            defaultValue=""
            id="description"
            name="description"
            label={t('products.description')}
            disabled={mode === 'view'}
            required
          />
          <ErrorMessage errors={errors} name="description">
            {(message) => (
              <Typography variant="body1" component="div" color="error">
                {message}
              </Typography>
            )}
          </ErrorMessage>
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {mode !== 'view' && (
          <>
            <Button
              className={classes.submit}
              autoFocus
              type="submit"
              variant="contained"
              color="primary"
              disabled={formState.isSubmitting}
            >
              {mode === 'edit' ? t('save') : t('create')}
            </Button>
            {mode === 'edit' && (
              <Button
                className={classes.submit}
                onClick={handleClose}
                variant="outlined"
                color="primary"
              >
                {t('cancel')}
              </Button>
            )}
            {products.error && typeof products.error === 'string' && (
              <Typography color="error" variant="body1" component="div">
                {products.error}
              </Typography>
            )}
            {products.isSuccess && (
              <Typography
                className={classes.success}
                component="span"
                variant="body1"
              >
                {mode === 'edit' ? t('successEdited') : t('successCreated')}
              </Typography>
            )}
          </>
        )}
        {!products.isSuccess && (
          <div className={classes.total}>
            <div className={classes.price}>
              <Typography
                className={classes.totalLabel}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('products.price')}: `}
              </Typography>
              <Typography
                className={classes.totalValue}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {Number.parseInt(vat, 10)
                  ? formatDouble(
                      price -
                        price *
                          (Number.parseInt(vat, 10) /
                            (100 + Number.parseInt(vat, 10))),
                      2
                    )
                  : price}
              </Typography>
            </div>
          </div>
        )}
      </DialogActions>
    </FormDialog>
  );
};

ProductDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.number,
    forCheckin: PropTypes.bool,
    isProduct: PropTypes.bool,
    isService: PropTypes.bool,
    price: PropTypes.string,
    id: PropTypes.number,
    vat: PropTypes.string,
  }),
  mode: PropTypes.string,
};

ProductDialog.defaultProps = {
  isOpen: false,
  data: {},
  mode: 'create',
};

export default ProductDialog;
