import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Sidebar from '../../components/sidebar/Sidebar';
import NavList from '../../components/nav-list/NavList';
import Messages from '../messages/Messages';
import OfferCreator from '../offer-creator/OfferCreator';
import Header from '../../components/header/Header';
import UsersDatabase from '../users-database/UsersDatabase';
import DropZones from '../drop-zones/DropZones';
import SalaryRates from '../salary-rates/SalaryRates';
import Regulations from '../regulations/Regulations';
import Dictionaries from '../dictionaries/Dictionaries';
import Club from '../club/Club';
import Training from '../training/Training';
import Discounts from '../discounts/Discounts';
import Planes from '../planes/Planes';
import Equipment from '../equipment/Equipment';
import Errors from '../errors/Errors';
import Organizations from '../organizations/Organizations';
import PaymentRequests from '../payment-requests/PaymentRequests';
import Agents from '../agents/Agents-panel';
import Subcontractors from '../subcontractors/Subcontractors';
import CommunicationPanel from '../communication-panel/CommunicationPanel';
import { getFullMenu } from './constants';
import useStyles from './styles';

const DashboardContent = React.memo(() => (
  <Switch>
    <Route path="/communication" component={Messages} />
    <Route path="/offer/creator" component={OfferCreator} />
    <Route path="/users-database" component={UsersDatabase} />
    <Route path="/drop-zones" component={DropZones} />
    <Route path="/organizations" component={Organizations} />
    <Route path="/salary-rates" component={SalaryRates} />
    <Route path="/discounts" component={Discounts} />
    <Route path="/regulations" component={Regulations} />
    <Route path="/dictionaries" component={Dictionaries} />
    <Route path="/offer/club" component={Club} />
    <Route path="/offer/training" component={Training} />
    <Route path="/planes" component={Planes} />
    <Route path="/equipment" component={Equipment} />
    <Route path="/cash-bank" component={PaymentRequests} />
    <Route path="/errors" component={Errors} />
    <Route path="/agents" component={Agents} />
    <Route path="/subcontractors">
      <Subcontractors />
    </Route>
    <Route path="/communication-panel">
      <CommunicationPanel />
    </Route>
  </Switch>
));

const Dashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state);

  const navMenu = useMemo(() => getFullMenu(auth.token), [auth]);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Header onDrawerToggle={handleDrawerToggle} title={t('dashboard.title')} />
      <Sidebar toggle={handleDrawerToggle} isOpenMobile={mobileOpen}>
        <NavList navMenu={navMenu} />
      </Sidebar>
      <div className={classes.layout}>
        <div className={classes.toolbarOffset} />
        <DashboardContent />
      </div>
    </>
  );
};

export default Dashboard;
