/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { planesApi } from '../api/api';
import { parsePlanes, parsePlane } from '../services/planes.services';
import { setNotification } from './notifications.slice';
import { MESSAGES_TYPES, MESSAGES } from '../constants/constants';

export const getAll = createAsyncThunk(
  'planes/getAll',
  async (data, { signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await planesApi.getAll({ cancelToken: source.token });

      return parsePlanes(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const create = createAsyncThunk(
  'planes/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await planesApi.create(data.locationId.toString(), {
        name: data.name,
        number: data.number,
        plane_type: data.planeType,
        max_crew: data.maxCrew,
        max_passenger: data.maxPassenger,
        description: data.description,
        icao24: data.icao24
      });

      return parsePlane(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const edit = createAsyncThunk(
  'planes/edit',
  async (data, { rejectWithValue }) => {
    try {
      await planesApi.edit(data.id, data.locationId.toString(), {
        name: data.name,
        number: data.number,
        plane_type: data.planeType,
        max_crew: data.maxCrew,
        max_passenger: data.maxPassenger,
        description: data.description,
        icao24: data.icao24
      });

      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const remove = createAsyncThunk(
  'planes/remove',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      await planesApi.delete(data.id, data.locationId.toString());

      dispatch(
        setNotification({
          message: MESSAGES.successDeleted,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return { id: data.id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const planes = createSlice({
  name: 'planes',
  initialState: {
    all: [],
    error: '',
    loading: false,
    isSuccess: false,
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.all = action.payload;
    },
    [getAll.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
      state.loading = false;
    },
    [create.fulfilled]: (state, action) => {
      state.all = [...state.all, action.payload];
      state.isSuccess = true;
    },
    [create.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [edit.fulfilled]: (state, action) => {
      state.all = state.all.map((el) => {
        if (el.id === action.payload.id) {
          return {
            ...el,
            ...action.payload,
          };
        }

        return el;
      });
      state.isSuccess = true;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [remove.fulfilled]: (state, action) => {
      state.all = state.all.filter((el) => el.id !== action.payload.id);
    },
    [remove.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
  },
});

export const { resetSuccess, resetError } = planes.actions;

export default planes.reducer;
