import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
  },
  buttonLeft: {
    display: 'none',
  },
  buttonRight: {
    display: 'none',
  },
  day: {
    width: 25,
    height: 25,
    margin: '0 2px',
    color: theme.palette.primary.light,
  },
  selectedDay: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
  },
}));
