import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Textarea from '../../../components/textarea/Textarea';
import FormDialog from '../../../components/form-dialog/FormDialog';
import SearchAutocomplete from '../../../components/search-autocomplete/SearchAutocomplete';
import { searchByName } from '../../../slices/users.slice';
import { sendGroupMessage } from '../../../slices/chats.slice';
import useStyles from './styles';

const GroupMessageDialog = (props) => {
  const { open, onClose } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { handleSubmit, control, setValue, formState } = useForm();

  const handleSearchByName = useCallback(
    async (value, setOptions, setErrors) => {
      if (value.length > 3) {
        try {
          const responseAction = await dispatch(searchByName(value));
          const response = unwrapResult(responseAction);
          setOptions(response);

          if (
            response.length === 1 &&
            response[0].name.toLowerCase() === value.toLowerCase()
          ) {
            setValue('users', response[0]);
          }
        } catch (err) {
          setErrors(err.message);
        }
      }
    },
    [dispatch, setValue]
  );

  const debouncedSearchByName = debounce(handleSearchByName, 500);

  const onSubmit = useCallback(
    async (values) => {
      const response = await dispatch(
        sendGroupMessage({
          recipientIds: values.users.map((el) => el.id),
          body: values.message,
        })
      );

      if (!response.error) {
        onClose();
      }
    },
    [dispatch, onClose]
  );

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      onClose={onClose}
      isOpen={open}
      title={t('communication.groupMessage')}
      onSubmit={memoSubmit}
    >
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="competences">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('communication.recipients')}: `}
            </Typography>
          </InputLabel>
          <Controller
            control={control}
            as={SearchAutocomplete}
            className={classes.search}
            onSearch={debouncedSearchByName}
            onChange={([, value]) => value}
            defaultValue={[]}
            size="medium"
            label={t('users.searchByName')}
            variant="outlined"
            margin="dense"
            id="users"
            name="users"
            multiple
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            renderOption={(option) => option.name}
            inputProps={{
              margin: 'dense',
            }}
            rules={{
              validate: (value) => (value && value.length) || 'requiredField',
            }}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="competences">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('communication.message')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Textarea}
            control={control}
            placeholder={t('message')}
            name="message"
            id="message"
            defaultValue=""
            required
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          disabled={formState.isSubmitting}
          type="submit"
        >
          {t('communication.send')}
        </Button>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t('cancel')}
        </Button>
      </DialogActions>
    </FormDialog>
  );
};

GroupMessageDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

GroupMessageDialog.defaultProps = {
  open: false,
};

export default GroupMessageDialog;
