/* eslint-disable import/prefer-default-export */
export const download = (blob, fileName) => {
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('download', fileName);
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
