import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from './styles';

const EnhancedTableHead = (props) => {
  const classes = useStyles();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    translationNamespace,
    cellClassName,
    isSelectAvailable,
    isActions,
  } = props;
  const { t } = useTranslation();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {isSelectAvailable && (
          <TableCell padding='checkbox' className={cn(classes.cellCheckbox, cellClassName)}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={cellClassName}
          >
            {headCell.isSortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {t(`${translationNamespace}.${headCell.name || headCell.id}`)}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
            {!headCell.isSortable && t(`${translationNamespace}.${headCell.name || headCell.id}`)}
          </TableCell>
        ))}
        {isActions && (
          <TableCell align='right' className={classes.cellActions}>
            {t('dictionaries.actions')}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      isSortable: PropTypes.bool,
      disablePadding: PropTypes.bool,
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  translationNamespace: PropTypes.string.isRequired,
  cellClassName: PropTypes.string,
  isSelectAvailable: PropTypes.bool,
};

EnhancedTableHead.defaultProps = {
  cellClassName: '',
  isSelectAvailable: false,
};

export default EnhancedTableHead;
