import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Accordion, AccordionSummary, Typography, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AgentStaticContent from './AgentStaticContent';
import AgentEditableContent from './AgentEditableContent';
import OrdersGenerationDialog from '../orders-generation-dialog/OrdersGenerationDialog';
import AgentsSelectors from '../../../../selectors/agents.selectors';
import useStyles from './styles';

const Agent = React.memo((props) => {
  const { id } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const agent = useSelector((state) => AgentsSelectors.getAgentById(state, id));

  const { name, email, phone, totalOrders, soldOrders, discounts, orders } = agent;

  const matches = useMediaQuery('(min-width:920px)');

  const [expanded, setExpanded] = useState(false);

  const togglePanel = () => {
    setExpanded((prevState) => !prevState);
  };

  const [editMode, setEditMode] = useState(false);

  const startEditMode = useCallback(() => {
    setEditMode(true);
  }, []);

  const endEditMode = useCallback(() => {
    setEditMode(false);
  }, []);

  const [ordersGenerationDialog, serOrdersGenerationDialog] = useState(false);

  const openOrdersGenerateDialog = useCallback((e) => {
    e.stopPropagation();
    serOrdersGenerationDialog(true);
  }, []);

  const closeOrdersGenerateDialog = useCallback(() => {
    serOrdersGenerationDialog(false);
  }, []);

  return (
    <>
      <Accordion expanded={expanded} onChange={togglePanel}>
        <AccordionSummary
          classes={{ content: classes.summary }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${id}-content`}
          id={id}
        >
          <Typography variant="h5" component="h3">
            {name}
          </Typography>
          <div className={classes.controls}>
            <Button
              className={classes.managerButton}
              variant="contained"
              size="small"
              color="primary"
              onClick={openOrdersGenerateDialog}
            >
              {matches ? t('agents.generateOrders') : t('generate')}
            </Button>
          </div>
        </AccordionSummary>
        {!editMode && (
          <AgentStaticContent
            email={email}
            phone={phone}
            onStartEditMode={startEditMode}
            totalOrders={totalOrders}
            soldOrders={soldOrders}
            orders={orders}
            id={id}
            discounts={discounts}
          />
        )}
        {editMode && (
          <AgentEditableContent
            id={id}
            name={name}
            email={email}
            phone={phone}
            onEndEditMode={endEditMode}
            discounts={discounts}
          />
        )}
      </Accordion>
      {ordersGenerationDialog && (
        <OrdersGenerationDialog
          open={ordersGenerationDialog}
          onClose={closeOrdersGenerateDialog}
          agentId={id}
        />
      )}
    </>
  );
});

Agent.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  soldOrders: PropTypes.number,
  totalOrders: PropTypes.number,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
};

Agent.defaultProps = {
  name: '',
  email: '',
  phone: '',
  soldOrders: 0,
  totalOrders: 0,
  orders: [],
};

export default Agent;
