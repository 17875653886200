import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableRow, Checkbox, Tooltip, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import useStyles from './styles';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function renderValue(value, t, render) {
  if (render) {
    return render(value);
  }

  if (typeof value === 'boolean') {
    return value ? t('yes') : t('no');
  }
  return value;
}

const EnhancedTableRow = (props) => {
  const {
    data,
    id,
    isSelected,
    onClick,
    columns,
    onShow,
    onEdit,
    onDelete,
    cellClassName,
    isSelectAvailable,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(() => {
    onClick(data.id);
  }, [onClick, data.id]);

  const handleShow = useCallback(
    (e) => {
      e.stopPropagation();
      onShow(data);
    },
    [onShow, data]
  );

  const handleEdit = useCallback(
    (e) => {
      e.stopPropagation();
      onEdit(data);
    },
    [onEdit, data]
  );

  const handleDelete = useCallback(
    async (e) => {
      e.stopPropagation();
      setIsLoading(true);
      await onDelete(data);
    },
    [onDelete, data]
  );

  useEffect(
    () => () => {
      setIsLoading(false);
    },
    []
  );

  return (
    <TableRow
      hover
      onClick={handleClick}
      role={isSelectAvailable ? 'checkbox' : 'table'}
      aria-checked={isSelected}
      tabIndex={-1}
      selected={isSelected}
      className={cn(classes.row, { [classes.rowLoading]: isLoading })}
    >
      {isSelectAvailable && (
        <TableCell padding="checkbox" className={cn(classes.cellCheckbox, cellClassName)}>
          <Checkbox checked={isSelected} inputProps={{ 'aria-labelledby': id }} />
        </TableCell>
      )}
      {columns.map((col) => (
        <TableCell
          key={col.id}
          align="left"
          padding={col.disablePadding ? 'none' : 'default'}
          className={cellClassName}
        >
          {renderValue(data[col.id], t, col.render)}
        </TableCell>
      ))}
      {(onShow || onEdit || onDelete) && (
        <TableCell align="right" className={classes.cellActions}>
          {onShow && (
            <Tooltip title={t('show')}>
              <IconButton
                className={classes.control}
                aria-label={t('show')}
                onClick={handleShow}
                disabled={isLoading}
              >
                <VisibilityIcon className={classes.controlIcon} />
              </IconButton>
            </Tooltip>
          )}
          {onEdit && !data.disableEdit && (
            <Tooltip title={t('edit')}>
              <IconButton
                className={classes.control}
                aria-label={t('edit')}
                onClick={handleEdit}
                disabled={isLoading}
              >
                <EditIcon className={classes.controlIcon} />
              </IconButton>
            </Tooltip>
          )}
          {onDelete && !data.disableDelete && (
            <Tooltip title={t('delete')}>
              <IconButton
                className={classes.control}
                aria-label={t('delete')}
                onClick={handleDelete}
                disabled={isLoading}
              >
                <DeleteIcon className={classes.controlIcon} />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

EnhancedTableRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    disableEdit: PropTypes.bool,
    disableDelete: PropTypes.bool,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      isSortable: PropTypes.bool,
      disablePadding: PropTypes.bool,
      id: PropTypes.string,
      name: PropTypes.string,
      render: PropTypes.func,
    })
  ).isRequired,
  onShow: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  cellClassName: PropTypes.string,
  isSelectAvailable: PropTypes.bool,
};

EnhancedTableRow.defaultProps = {
  isSelected: false,
  onShow: null,
  onEdit: null,
  onDelete: null,
  cellClassName: '',
  isSelectAvailable: false,
};

const EnhancedTableBody = (props) => {
  const {
    data,
    selected,
    order,
    orderBy,
    onClick,
    columns,
    onShow,
    onEdit,
    onDelete,
    cellClassName,
    isSelectAvailable,
  } = props;

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (name) => {
    onClick(name);
  };

  return (
    <TableBody>
      {stableSort(data, getComparator(order, orderBy)).map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;
        const isItemSelected = isSelected(row.id);

        return (
          <EnhancedTableRow
            data={row}
            id={labelId}
            isSelected={isItemSelected}
            onClick={handleClick}
            key={row.id}
            onShow={onShow}
            onEdit={onEdit}
            onDelete={onDelete}
            columns={columns}
            cellClassName={cellClassName}
            isSelectAvailable={isSelectAvailable}
          />
        );
      })}
    </TableBody>
  );
};

EnhancedTableBody.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.number),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      isSortable: PropTypes.bool,
      disablePadding: PropTypes.bool,
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  onShow: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  cellClassName: PropTypes.string,
  isSelectAvailable: PropTypes.bool,
};

EnhancedTableBody.defaultProps = {
  selected: [],
  onShow: null,
  onEdit: null,
  onDelete: null,
  cellClassName: '',
  isSelectAvailable: false,
};

export default EnhancedTableBody;
