import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { ButtonBase } from '@material-ui/core';
import { MuiPickersUtilsProvider, Calendar } from '@material-ui/pickers';
import enLocale from 'date-fns/locale/en-US';
import lpLocale from 'date-fns/locale/pl';
import ruLocale from 'date-fns/locale/ru';
import useStyles from './styles';
import { dateToString } from '../../../../utils/date.utils';

const LOCALE_MAP = {
  en: enLocale,
  pl: lpLocale,
  ru: ruLocale,
};

const TimeTableDay = React.memo((props) => {
  const {
    isEditable, isSelected, children, onClick,
  } = props;
  const classes = useStyles();

  if (isEditable) {
    return (
      <ButtonBase
        className={cn(classes.day, { [classes.selectedDay]: isSelected })}
        onClick={onClick}
      >
        {children}
      </ButtonBase>
    );
  }

  return (
    <div className={cn(classes.day, { [classes.selectedDay]: isSelected })}>
      {children}
    </div>
  );
});

TimeTableDay.propTypes = {
  isEditable: PropTypes.bool,
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

TimeTableDay.defaultProps = {
  isEditable: false,
  isSelected: false,
  onClick: null,
  children: null,
};

const TimeTable = (props) => {
  const {
    isEditable, value, className, onChange,
  } = props;
  const [values, setValues] = useState(value);
  const [lastSelectedValue, setLastSelectedValue] = useState(new Date());
  const classes = useStyles();
  const { i18n } = useTranslation();
  const activeLang = i18n.language.slice(0, 2);
  const today = useRef(new Date());

  useEffect(() => {
    setValues(value);
  }, [value]);

  const handleDaySelect = useCallback((rawDate) => {
    const date = dateToString(rawDate);
    setValues((prevState) => {
      let newState = [];
      if (prevState.indexOf(date) > -1) {
        newState = prevState.filter((el) => el !== date);
      } else {
        newState = [...prevState, date];
      }

      if (onChange) {
        onChange(newState);
      }

      return newState;
    });
  }, [onChange]);

  const handleSetLastSelectedValue = useCallback((date) => {
    setLastSelectedValue(date);
  }, []);

  return (
    <div className={cn(classes.container, className)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={LOCALE_MAP[activeLang]}>
        <Calendar
          date={lastSelectedValue}
          onChange={handleSetLastSelectedValue}
          allowKeyboardControl={false}
          renderDay={(day, selectedDay, dayInCurrentMonth) => {
            const date = day.getDate();

            if (dayInCurrentMonth) {
              return (
                <TimeTableDay
                  isEditable={isEditable}
                  isSelected={values.indexOf(dateToString(day)) > -1}
                  onClick={() => handleDaySelect(day)}
                  value={day}
                >
                  {date}
                </TimeTableDay>
              );
            }
            return <TimeTableDay />;
          }}
          maxDate={new Date(today.current.getFullYear() + 1, today.current.getMonth())}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

TimeTable.propTypes = {
  isEditable: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

TimeTable.defaultProps = {
  isEditable: false,
  value: [],
  className: '',
  onChange: null,
};

export default TimeTable;
