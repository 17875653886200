import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
  },
  listItem: {
    marginRight: theme.spacing(1),
  },
  value: {
    fontSize: '1.14rem',
    marginBottom: 0,
    lineHeight: 1.75,
    textAlign: 'left',
  },
}), {
  name: 'ManagerDialog',
});
