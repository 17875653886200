import { makeStyles } from '@material-ui/core/styles';
import BackgroundImg from '../../assets/images/background.png';
import { drawerWidth } from '../../constants/styles';

export default makeStyles(
  (theme) => ({
    list: {
      overflow: 'auto',
      paddingTop: 0,
      overflowY: 'scroll',
      flex: 1,
    },
    listText: {
      display: 'flex',
      textTransform: 'uppercase',
    },
    listTextRoot: {
      margin: 0,
    },
    listItem: {
      position: 'relative',
      padding: 0,
      borderLeft: 'solid 3px transparent',
      color: '#dadee1',
      transitionProperty: 'color',
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      transitionDuration: theme.transitions.duration.shortest,

      '&:hover': {
        backgroundColor: '#666666',
        borderLeft: `solid 3px ${theme.palette.secondary.main}`,
        color: '#ffffff',
      },
    },
    listItemButton: {
      position: 'relative',
      padding: '6px 5px 5px 15px',
      borderLeft: 'solid 3px transparent',
      color: '#dadee1',
      transitionProperty: 'color',
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      transitionDuration: theme.transitions.duration.shortest,

      '&:hover': {
        backgroundColor: '#666666',
        borderLeft: `solid 3px ${theme.palette.secondary.main}`,
        color: '#ffffff',
      },

      '& $listText': {
        fontWeight: 600,
        fontSize: '1.07rem',
        lineHeight: '21px',
      },
    },
    listItemButton_isOpen: {
      backgroundColor: '#666666',
      borderLeft: `solid 3px ${theme.palette.secondary.main}`,
      color: '#ffffff',
    },
    nestedList: {
      backgroundColor: 'rgb(44, 59, 65)',
      borderLeft: `1px solid ${theme.palette.secondary.main}`,
      borderRight: `1px solid ${theme.palette.secondary.main}`,
      '& $link': {
        padding: '5px 5px 5px 45px',
      },
    },
    nestedListItem: {
      padding: 0,
      color: 'rgb(138, 164, 175)',
      transitionProperty: 'color',
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      transitionDuration: theme.transitions.duration.shortest,

      '&:hover': {
        color: '#ffffff',
      },
    },
    nestedListText: {
      display: 'flex',
      textTransform: 'initial',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      overflowY: 'initial',
      width: drawerWidth,
      backgroundImage: `url(${BackgroundImg})`,
      border: 0,
    },
    link: {
      flex: 1,
      padding: '6px 5px 5px 15px',
      color: 'inherit',
      textDecoration: 'none',
      fontSize: '1.07rem',
      lineHeight: '21px',
      fontWeight: 600,
    },
    activeLink: {
      color: theme.palette.primary.contrastText,
    },
    selected: {
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        borderLeft: `solid 3px ${theme.palette.secondary.main}`,
        color: '#ffffff',
      },
    },
  }),
  {
    name: 'NavList',
  }
);
