import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Chat from '../chat/Chat';
import ChatSelectors from '../../../selectors/chats.selectors';
import useStyles from './styles';

const ChatPage = () => {
  const classes = useStyles();

  const params = useParams();

  const recipientId = +params.id;

  const chat = useSelector((state) => ChatSelectors.getById(state, recipientId));

  return (
    <main className={classes.page}>
      <div>
        <div className={classes.row}>
          <Avatar className={classes.avatar} src={chat?.recipient?.avatar} />
          <Typography className={classes.title} variant="h5" id="tableTitle" component="h2">
            {chat?.recipient?.name}
          </Typography>
        </div>
      </div>
      <Paper className={classes.paper}>
        <Chat recipientId={recipientId} />
      </Paper>
    </main>
  );
};

export default ChatPage;
