export const parseDiscount = (discount) => ({
  createdAt: discount.created_at,
  description: discount.description,
  id: discount.id,
  origin: discount.origin,
  inPercents: discount.in_percents,
  updatedAt: discount.updated_at,
  asGift: discount.as_gift,
});

export const parseDiscounts = (discounts) => (
  discounts.map((discount) => parseDiscount(discount))
);
