import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import SearchAutocomplete from '../../../../components/search-autocomplete/SearchAutocomplete';
import PageLoader from '../../../../components/page-loader/PageLoader';
import { searchByName } from '../../../../slices/users.slice';
import {
  getNotNeedDoc as getCompetencesWithNoNeedDoc,
  getForUser,
  manageUserCompetences,
} from '../../../../slices/competences.slice';
import CompetencesSelectors from '../../../../selectors/competences.selectors';
import { matchSorter } from '../../../../utils/array.utils';
import useStyles from './styles';

const AssignRole = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const competences = useSelector(CompetencesSelectors.noNeedDoc);

  const [inputValue, setInputValue] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [assignCompetencesIds, setAssignCompetencesIds] = useState([]);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let successTimeoutId;
    if (isSuccess) {
      successTimeoutId = setTimeout(() => {
        setIsSuccess(false);
        setCurrentUser(null);
        setInputValue('');
        setAssignCompetencesIds([]);
      }, 1000);
    }

    return () => {
      clearTimeout(successTimeoutId);
    };
  });

  useEffect(() => {
    const promise = dispatch(getCompetencesWithNoNeedDoc());

    return () => promise.abort();
  }, [dispatch]);

  const handleSetCurrentUser = useCallback(
    async (e, newValue) => {
      if (newValue && newValue.id) {
        const competencesForUser = await dispatch(getForUser(newValue.id));

        if (!competencesForUser.error) {
          setCurrentUser({
            ...newValue,
            competences: competencesForUser.payload,
          });

          setAssignCompetencesIds(
            competencesForUser.payload.map((el) => el.id)
          );
        }
      }
    },
    [dispatch]
  );

  const handleSearchByName = useCallback(
    async (value, setOptions, setErrors) => {
      setInputValue(value);
      if (value.length > 3) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const timeout = setTimeout(async () => {
          try {
            const responseAction = await dispatch(searchByName(value));
            const response = unwrapResult(responseAction);
            setOptions(response);

            if (
              response.length === 1 &&
              response[0].name.toLowerCase() === value.toLowerCase()
            ) {
              handleSetCurrentUser({}, response[0]);
            }
          } catch (err) {
            setErrors(err.message);
          }
        }, 500);
        setTimeoutId(timeout);
      }
    },
    [dispatch, timeoutId, handleSetCurrentUser]
  );

  const toggleRoles = useCallback((e) => {
    const { value } = e.target;

    setAssignCompetencesIds((prevState) => {
      if (prevState.indexOf(+value) > -1) {
        return prevState.filter((roleId) => roleId !== +value);
      }
      return [...prevState, +value];
    });
  }, []);

  const handleSubmit = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      const userCompetencesIds = currentUser.competences.map((el) => el.id);
      const assignIds = assignCompetencesIds.filter(
        (id) => userCompetencesIds.indexOf(id) === -1
      );
      const unassignIds = userCompetencesIds.filter(
        (id) => assignCompetencesIds.indexOf(id) === -1
      );
      const actionResponse = dispatch(
        manageUserCompetences({
          userId: currentUser.id,
          assignIds,
          unassignIds,
        })
      );

      await unwrapResult(actionResponse);

      setIsLoading(false);
      setIsSuccess(true);
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.message) {
        setError(err.response.message);
      } else {
        setError(err);
      }
    }
  };

  return (
    <Box className={cn(classes.container, className)}>
      <Typography
        className={classes.title}
        variant="h5"
        id="tableTitle"
        component="h3"
      >
        {t('competences.assignCompetencesToUser')}
      </Typography>
      <SearchAutocomplete
        className={classes.search}
        onSearch={handleSearchByName}
        onChange={handleSetCurrentUser}
        size="medium"
        label={t('users.searchByName')}
        variant="standard"
        getOptionLabel={(option) =>
          `${option.name} ${option.nickname ? `(${option.nickname})` : ''}`
        }
        getOptionSelected={(option, value) => option.name === value.name}
        filterOptions={(opts, state) =>
          matchSorter(opts, state.inputValue, ['name', 'nickname'])
        }
        inputValue={inputValue}
      />
      {currentUser && (
        <div className={classes.user}>
          <Typography
            className={classes.title}
            variant="h5"
            id="tableTitle"
            component="div"
          >
            {currentUser.name}
          </Typography>
          {currentUser.competences && (
            <>
              <Typography
                variant="body1"
                color="textSecondary"
                component="div"
                gutterBottom
              >
                {t('competences.userCompetences')}
              </Typography>
              <ul className={classes.list}>
                {currentUser.competences.map((el) => (
                  <li className={classes.listItem}>
                    <Typography>{`${el.name},`}</Typography>
                  </li>
                ))}
              </ul>
            </>
          )}
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend" gutterBottom>
                {t('competences.manageCompetences')}
              </FormLabel>
              <FormGroup className={classes.formGroup}>
                {competences.map((competence) => (
                  <FormControlLabel
                    key={competence.id}
                    control={
                      <Checkbox
                        checked={
                          assignCompetencesIds.indexOf(competence.id) > -1
                        }
                        value={competence.id}
                        onChange={toggleRoles}
                      />
                    }
                    label={competence.name}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <div className={classes.controls}>
              <Button
                className={classes.control}
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('users.assign')}
              </Button>
              {error && typeof error === 'string' && (
                <Typography variant="body1" color="error" component="div">
                  {error}
                </Typography>
              )}
              {isSuccess && (
                <Typography
                  variant="body1"
                  className={classes.success}
                  component="div"
                >
                  {t('users.successAssigned')}
                </Typography>
              )}
            </div>
            {isLoading && <PageLoader />}
          </form>
        </div>
      )}
    </Box>
  );
};

AssignRole.propTypes = {
  className: PropTypes.string,
};

AssignRole.defaultProps = {
  className: '',
};

export default AssignRole;
