import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: '100px',
    padding: '10.5px 14px',
    outline: 0,
    fontSize: 'inherit',
    marginTop: theme.spacing(1),
    color: theme.palette.text.primary,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: 4,

    '&:not([disabled]):hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&:not([disabled]):focus': {
      borderWidth: 2,
      borderColor: 'rgba(70, 70, 70)',
    },

    '&:disabled': {
      backgroundColor: 'initial',
      color: theme.palette.text.disabled,
    },

    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.4)',
    },
  },
}), {
  name: 'Textarea',
});
