import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      flex: 1,
      width: '100%',
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(3),
      },
    },
    toolbar: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
      },
    },
    title: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(3),
      },
    },
    controls: {
      display: 'flex',
      width: '100%',
    },
    control: {
      width: `calc((100% - ${theme.spacing(2) * 2}px) / 3)`,
      marginRight: theme.spacing(2),
    },
    infoText: {
      margin: 'auto',
    },
    paper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
    button: {
      marginRight: theme.spacing(1),

      '&:last-child': {
        marginRight: 0,
      },
    },
  }),
  {
    name: 'Invoices',
  }
);
