import { createSelector } from 'reselect';

const productSets = (state) => state.productSets;
const loading = createSelector(productSets, (state) => state.loading);
const byId = createSelector(productSets, (state) => state.byId);
const all = createSelector(byId, (state) => Object.values(state));

const getByLocationId = createSelector(
  all,
  (_, locationId) => locationId,
  (state, locationId) => {
    if (!locationId) {
      return [];
    }

    return state.filter((set) => set.locations.indexOf(locationId) > -1);
  }
);

const forShop = createSelector(all, (state) => {
  if (state) {
    return state.filter((el) => el.forShop);
  }

  return [];
});

const ProductSelectors = {
  getByLocationId,
  forShop,
  loading,
  all,
};

export default ProductSelectors;
