import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Subcontractor from './components/subcontractor/Subcontractor';
import SubcontractorDialog from './components/subcontractor-dialog/SubcontractorDialog';
import SubcontractorsSelectors from '../../selectors/subcontractors.selectors';
import { getSalaryRates, getAll } from '../../slices/subcontractors.slice';
import useStyles from './styles';

const Subcontractors = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const all = useSelector(SubcontractorsSelectors.all);

  useEffect(() => {
    const promiseSalaryRates = dispatch(getSalaryRates());
    const promiseSubcontractors = dispatch(getAll());

    return () => {
      promiseSalaryRates.abort();
      promiseSubcontractors.abort();
    };
  }, [dispatch]);

  const [isDialog, setIsDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsDialog(false);
  }, []);

  return (
    <main className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h5" component="h2">{t('subcontractors.title')}</Typography>
        <Button variant="contained" color="primary" onClick={openCreateDialog}>{t('create')}</Button>
      </Toolbar>
      <div>
        {all.map((item) => (
          <Subcontractor key={item.id} id={item.id} {...item} />
        ))}
      </div>
      {isDialog && <SubcontractorDialog open={isDialog} onClose={closeCreateDialog} />}
    </main>
  );
};

export default Subcontractors;
