import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ExpandTableRow from '../../../../components/expand-table-row/ExpandTableRow';
import Loader from '../../../../components/page-loader/PageLoader';
import SubcontractorsSelectors from '../../../../selectors/subcontractors.selectors';
import { getRateTypeByCompetences } from '../../utils';
import { getOrderInfo } from '../../../../slices/subcontractors.slice';
import useStyles from './styles';

const SubcontractorStaticContent = (props) => {
  const {
    id, email, phone, onStartEditMode, rates, orders, ordersInfo,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const salaryRates = useSelector(SubcontractorsSelectors.salaryRates);

  return (
    <>
      <AccordionDetails className={classes.details}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6} xl={3}>
            <div className={classes.row}>
              <Typography className={classes.label} color="textSecondary" variant="subtitle1" component="div">
                {`${t('email')}: `}
              </Typography>
              <a href={`mailto:${email}`} className={classes.link}>
                <Typography className={classes.value} color="textPrimary">
                  {email}
                </Typography>
              </a>
            </div>
            <div className={classes.row}>
              <Typography className={classes.label} color="textSecondary" variant="subtitle1" component="div">
                {`${t('phone')}: `}
              </Typography>
              <a className={classes.link} href={`tel:${phone}`}>
                <Typography className={classes.value} color="textPrimary">
                  {phone}
                </Typography>
              </a>
            </div>
            <Typography className={classes.labelText} color="textPrimary" variant="h6" component="h3" gutterBottom>
              {t('salary.title')}
            </Typography>
            {Object.values(rates).length > 0 && salaryRates.map((el) => (
              <div className={classes.row} key={el.id}>
                <Typography className={classes.label} color="textSecondary" variant="subtitle1" component="div">
                  {`${el.name}: `}
                </Typography>
                <Typography className={classes.value} color="textPrimary">
                  {rates[getRateTypeByCompetences(el.competences)]?.rate}
                </Typography>
              </div>
            ))}
          </Grid>
          {orders.length > 0 && (
            <Grid item xs={12} lg={6}>
              <TableContainer className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('agents.externalId')}</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.body}>
                    {orders.map((externalId) => (
                      <ExpandTableRow
                        key={externalId}
                        classes={{
                          row: classes.listItem,
                          expandRow: classes.expandRow,
                        }}
                        colNumber={4}
                        mainRowContent={(
                          <TableCell>{externalId}</TableCell>
                        )}
                        expandContent={(expanded) => {
                          if (expanded && !ordersInfo[externalId]) {
                            dispatch(getOrderInfo({ externalId, subcontractorId: id }));
                          }

                          if (!ordersInfo[externalId]) {
                            return (
                              <Box position="relative" height={50}>
                                <Loader />
                              </Box>
                            );
                          }

                          return (
                            <Box>
                              <Box marginBottom={1}>
                                <Box display="flex">
                                  <Typography variant="h6">{`${t('order')} ${externalId}`}</Typography>
                                </Box>
                                <Typography variant="body1">{ordersInfo[externalId]?.extra}</Typography>
                              </Box>
                              <Box display="flex" marginBottom={1}>
                                <Typography className={classes.subtitle} variant="subtitle2">{`${t('agents.totalPrice')}:`}</Typography>
                                <Typography variant="body1">{`${ordersInfo[externalId]?.totalPrice} zł`}</Typography>
                              </Box>
                              {ordersInfo[externalId]?.place && (
                                <Box display="flex" marginBottom={1}>
                                  <Typography className={classes.subtitle} variant="subtitle2">{`${t('dropZone')}:`}</Typography>
                                  <Typography variant="body1">{ordersInfo[externalId]?.place}</Typography>
                                </Box>
                              )}
                              {ordersInfo[externalId]?.time && (
                                <Box display="flex" marginBottom={1}>
                                  <Typography className={classes.subtitle} variant="subtitle2">{`${t('date')}:`}</Typography>
                                  <Typography variant="body1">{new Date(ordersInfo[externalId]?.time).toLocaleDateString()}</Typography>
                                </Box>
                              )}
                            </Box>
                          );
                        }}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button variant="contained" size="small" color="primary" onClick={onStartEditMode}>{t('edit')}</Button>
      </AccordionActions>
    </>
  );
};

SubcontractorStaticContent.propTypes = {
  id: PropTypes.number.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  onStartEditMode: PropTypes.func,
  rates: PropTypes.shape({
    base: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
    handy: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
    camera: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
    camera_handy: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
  }),
  orders: PropTypes.arrayOf(PropTypes.string),
  ordersInfo: PropTypes.objectOf(PropTypes.shape({
    externalId: PropTypes.string,
    totalPrice: PropTypes.string,
    place: PropTypes.string,
    time: PropTypes.string,
    extra: PropTypes.string,
  })),
};

SubcontractorStaticContent.defaultProps = {
  email: '',
  phone: '',
  onStartEditMode: null,
  rates: {},
  orders: [],
  ordersInfo: {},
};

export default SubcontractorStaticContent;
