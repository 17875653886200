import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  IconButton,
  TableRow,
  TableCell,
  Collapse,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import useStyles from './styles';

const ExpandTableRow = ({
  mainRowContent,
  expandContent,
  colNumber,
  classes: propsClasses,
}) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleRow = useCallback(() => {
    setIsExpanded((prevState) => !prevState);
  }, []);

  return (
    <>
      <TableRow
        className={cn(classes.mainRow, propsClasses.row)}
        onClick={toggleRow}
      >
        {mainRowContent}
        <TableCell className={classes.tableCell} align="right">
          <IconButton>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={propsClasses.expandRow}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={colNumber}
        >
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            {isExpanded && <Box margin={1}>{expandContent(isExpanded)}</Box>}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

ExpandTableRow.propTypes = {
  mainRowContent: PropTypes.node.isRequired,
  expandContent: PropTypes.func.isRequired,
  colNumber: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
};

ExpandTableRow.defaultProps = {
  classes: {},
};

export default ExpandTableRow;
