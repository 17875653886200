export const parseRate = (rate) => ({
  id: rate.id,
  createdAt: rate.created_at,
  orderExternalId: rate.order_external_id,
  rate: rate.rate,
  rateType: rate.rate_type,
  updatedAt: rate.updated_at,
  userId: rate.user_id,
});

export const parseRates = (rates) => rates.map((rate) => parseRate(rate));
