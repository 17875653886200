import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemText, Collapse, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const NavDropdown = React.memo(({ titleKey, nestedList, isActive }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(isActive);

  const toggleMenu = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  return (
    <>
      <ListItem
        button
        classes={{
          root: cn(classes.listItemButton, {
            [classes.listItemButton_isOpen]: isOpen,
          }),
          selected: classes.selected,
        }}
        disableRipple
        selected={isActive}
        onClick={toggleMenu}
      >
        <ListItemText
          classes={{ root: classes.listTextRoot, primary: classes.listText }}
          primary={t(`nav.${titleKey}`)}
        />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List className={classes.nestedList} component="ul" disablePadding>
          {nestedList.map((navItem) => {
            const navItemEl = navItem.outLink ? (
              <Typography className={classes.nestedListText}>
                <a
                  className={classes.link}
                  href={navItem.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t(`nav.${navItem.titleKey}`)}
                </a>
              </Typography>
            ) : (
              <NavLink
                className={classes.link}
                activeClassName={classes.activeLink}
                to={`${navItem.path}`}
              >
                {t(`nav.${navItem.titleKey}`)}
              </NavLink>
            );

            return (
              <ListItem
                key={navItem.titleKey}
                classes={{
                  root: classes.nestedListItem,
                  selected: classes.selected,
                }}
              >
                <ListItemText
                  classes={{
                    root: classes.listTextRoot,
                    primary: classes.nestedListText,
                  }}
                  primary={navItemEl}
                />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
});

NavDropdown.propTypes = {
  titleKey: PropTypes.string.isRequired,
  nestedList: PropTypes.arrayOf(
    PropTypes.shape({
      titleKey: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default NavDropdown;
