import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    marginTop: 'auto',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  submit: {
    flexShrink: 0,
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
  textarea: {
    width: '100%',
    minHeight: '100px',
    outline: 0,
    fontSize: '1rem',
    marginTop: theme.spacing(1),
  },
}), {
  name: 'CourseDialog',
});
