import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const LangSwitcher = ({ className }) => {
  const { i18n } = useTranslation();
  const activeLang = i18n.language.slice(0, 2);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLangChange = (lang) => {
    changeLanguage(lang);
    handleClose();
  };

  const classes = useStyles();
  return (
    <div className={cn(classes.container, className)}>
      <Button className={classes.button} aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpen}>
        {activeLang}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant="selectedMenu"
      >
        <MenuItem onClick={() => handleLangChange('pl')} selected={activeLang === 'pl'}>pl</MenuItem>
        <MenuItem onClick={() => handleLangChange('en')} selected={activeLang === 'en'}>en</MenuItem>
        <MenuItem onClick={() => handleLangChange('ru')} selected={activeLang === 'ru'}>ru</MenuItem>
      </Menu>
    </div>
  );
};

LangSwitcher.propTypes = {
  className: PropTypes.string,
};

LangSwitcher.defaultProps = {
  className: '',
};

export default LangSwitcher;
