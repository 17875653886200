import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './app/store';
import App from './app/App';
import './index.css';
import './i18n';
import api, { APP_KEY } from './api/instance';
import { resetAuth } from './slices/auth.slice';

const setupResponseInterceptor = (storeInstance) =>
  api.interceptors.response.use(null, (error) => {
    if (error.response && error.response.status === 401) {
      storeInstance.dispatch(resetAuth());
    } else if (error.response && error.response.status === 500) {
      // window.open(`${window.location.origin}/#/errors/server`, '_self');
    }

    return Promise.reject(error);
  });

setupResponseInterceptor(store);

api.interceptors.request.use((config) => {
  const locale = localStorage.getItem('i18nextLng')
    ? localStorage.getItem('i18nextLng').split('-')[0]
    : 'pl';

  if (config.method === 'get') {
    return {
      ...config,
      params: {
        ...config.params,
        client_app: APP_KEY,
        locale,
      },
      headers: {
        ...config.headers,
        USERTOKEN: store.getState().auth.token,
      },
    };
  }

  if (config.data instanceof FormData) {
    config.data.append('client_app', APP_KEY);
    config.data.append('locale', locale);

    return {
      ...config,
      headers: {
        ...config.headers,
        USERTOKEN: store.getState().auth.token,
      },
    };
  }

  return {
    ...config,
    data: {
      ...config.data,
      client_app: APP_KEY,
      locale,
    },
    headers: {
      ...config.headers,
      USERTOKEN: store.getState().auth.token,
    },
  };
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
