import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Accordion, AccordionSummary, Typography, Button, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import DropZoneContent from '../drop-zone-content/DropZoneContent';
import { DROP_ZONES_THEME } from '../../constants';
import useStyles from './styles';

const DropZone = (props) => {
  const { dz, onDelete } = props;
  const { name, logo, id } = dz;
  const classes = useStyles();
  const { auth } = useSelector((state) => state);
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const togglePanel = () => {
    setExpanded((prevState) => !prevState);
  };

  const redirectToManifest = useCallback(
    async (e) => {
      e.stopPropagation();
      const timeZoneOffset = (new Date().getTimezoneOffset() / 60) * -1;
      // in hours, (-1) need to get offset in standard format instead of "utc - local time" value
      window.open(
        `${process.env.REACT_APP_MANIFEST_URL}?admin_token=${auth.token}&client_app=admin_panel&timezone_offset=${timeZoneOffset}&location_id=${id}`
      );
    },
    [auth, id]
  );

  return (
    <Accordion expanded={expanded} onChange={togglePanel}>
      <AccordionSummary
        classes={{ content: classes.summary }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={id}
      >
        {logo && <img className={classes.logo} src={logo} alt="logo" />}
        <Typography variant="h5" component="h3">
          {name}
        </Typography>
        {dz.forCP && !dz.localIp && (
          <Tooltip className={classes.tooltip} title={t('multimediaNotAvailable')}>
            <VideocamOffIcon />
          </Tooltip>
        )}
        {dz.useSensors && (
          <Tooltip className={classes.tooltip} title={t('dropZones.useSensors')}>
            <SettingsInputAntennaIcon />
          </Tooltip>
        )}
        <div className={classes.controls}>
          <Button
            className={classes.managerButton}
            variant="contained"
            size="small"
            color="primary"
            onClick={redirectToManifest}
          >
            {t('dropZones.manager')}
          </Button>
        </div>
      </AccordionSummary>
      <DropZoneContent dz={dz} expanded={expanded} onDelete={onDelete} />
    </Accordion>
  );
};

DropZone.propTypes = {
  dz: PropTypes.shape(DROP_ZONES_THEME).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DropZone;
