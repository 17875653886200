import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  actions: {
    marginTop: 'auto',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  submit: {
    flexShrink: 0,
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  textarea: {
    height: '100%',
    minHeight: '100px',
    outline: 0,
    fontSize: 'inherit',
    marginBottom: theme.spacing(1),
    border: 0,
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
  fileName: {
    flex: 1,
  },
  delete: {
    padding: 0,
  },
  link: {
    color: theme.palette.text.primary,
    flex: 1,
  },
  success: {
    color: theme.palette.success.main,
  },
}), {
  name: 'RegulationDialog',
});
