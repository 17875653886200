import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  button: {
    fontSize: '1rem',
    minWidth: '30px',
    padding: 0,
    backgroundColor: '#ffffff',
    fontWeight: 500,
    textTransform: 'lowercase',
    letterSpacing: 'initial',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#e42221',
    },
  },
}),
{
  name: 'LangSwitch',
});
