import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  DialogContent,
  InputLabel,
  DialogActions,
  Hidden,
  Button,
  Typography,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import FormDialog from '../../../components/form-dialog/FormDialog';
import PageLoader from '../../../components/page-loader/PageLoader';
import Textarea from '../../../components/textarea/Textarea';

import ProductsSelectors from '../../../selectors/products.selectors';
import DiscountsSelectors from '../../../selectors/discounts.selectors';
import { create, resetSuccess, resetError, edit } from '../../../slices/productSets.slice';
import { formatDouble } from '../../../utils/number.utils';
import useStyles from './styles';

const ProductSetDialog = (props) => {
  const { isOpen, onClose, data, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { productSets } = useSelector((state) => state);

  const discounts = useSelector(DiscountsSelectors.discountsInPersents);
  const products = useSelector(ProductsSelectors.all);

  const { control, handleSubmit, errors, watch, formState } = useForm({
    defaultValues: data,
  });

  const productsSet = watch('products', data.products || []);
  const discount = watch('discount', data.discount || 0);

  const totalPrice = useMemo(() => {
    const productsPrice = productsSet.reduce((summ, currentValue) => summ + +currentValue.price, 0);

    return productsPrice;
  }, [productsSet]);

  const onSubmit = useCallback(
    async (values) => {
      if (mode === 'edit') {
        const price = values.products.reduce((summ, currentValue) => summ + +currentValue.price, 0);

        await dispatch(
          edit({
            id: data.id,
            ...values,
            productIds: values.products,
            price: values.discount ? price * (1 - values.discount / 100) : price,
          })
        );
      } else {
        await dispatch(
          create({
            ...values,
            productIds: values.products.map((el) => el.id),
            forShop: true,
          })
        );
      }
    },
    [dispatch, data.id, mode]
  );

  const handleClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    onClose();
  }, [dispatch, onClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <FormDialog
      onClose={handleClose}
      isOpen={isOpen}
      title={t('productSets.title')}
      onSubmit={memoSubmit}
    >
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="name"
            name="name"
            variant="outlined"
            required
            placeholder={t('productSets.name')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="products">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.products')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={[]}
            id="products"
            name="products"
            required
            size="small"
            multiple
            onChange={([, value]) => value}
            options={products}
            renderOption={(option) => `${option.name} ${option.location ? '' : t('common')}`}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('productSets.products')}
                helperText={errors.products && errors.products.message}
                error={!!errors.products}
              />
            )}
            rules={{
              validate: (value) => value.length > 0 || t('requiredField'),
            }}
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="discount">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.discount')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={null}
            id="discount"
            name="discount"
            size="small"
            onChange={([, value]) => value}
            options={discounts}
            renderOption={(option) => `${option}%`}
            getOptionLabel={(option) => `${option}%`}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('productSets.products')}
                helperText={errors.productIds && errors.productIds.message}
                error={!!errors.productIds}
              />
            )}
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="description">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.description')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Textarea}
            control={control}
            placeholder={t('productSets.description')}
            name="description"
            id="description"
            defaultValue=""
            disabled={mode === 'view'}
          />
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Hidden smUp>
          <div className={classes.total}>
            <div className={classes.price}>
              <Typography
                className={classes.totalLabel}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('productSets.totalPrice')}: `}
              </Typography>
              <Typography
                className={classes.totalValue}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {formatDouble(totalPrice, 2)}
              </Typography>
            </div>
            {discount > 0 && (
              <div className={classes.price}>
                <Typography
                  className={classes.totalLabel}
                  color="textPrimary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('productSets.discountPrice')}: `}
                </Typography>
                <Typography
                  className={classes.totalValue}
                  color="textPrimary"
                  variant="subtitle1"
                  component="div"
                >
                  {formatDouble(totalPrice * (1 - discount / 100), 2)}
                </Typography>
              </div>
            )}
          </div>
        </Hidden>
        {mode !== 'view' && (
          <div className={classes.actionsMain}>
            <Button
              className={classes.submit}
              autoFocus
              type="submit"
              variant="contained"
              color="primary"
            >
              {mode === 'edit' ? t('save') : t('create')}
            </Button>
            {mode === 'edit' && (
              <Button onClick={handleClose} variant="outlined" color="primary">
                {t('cancel')}
              </Button>
            )}
            {productSets.error && typeof productSets.error === 'string' && (
              <Typography color="error" variant="body1" component="div">
                {productSets.error}
              </Typography>
            )}
            {productSets.isSuccess && (
              <Typography className={classes.success} component="span" variant="body1">
                {mode === 'edit' ? t('successEdited') : t('successCreated')}
              </Typography>
            )}
          </div>
        )}
        <Hidden xsDown>
          {!productSets.isSuccess && (
            <div className={classes.total}>
              <div className={classes.price}>
                <Typography
                  className={classes.totalLabel}
                  color="textPrimary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('productSets.totalPrice')}: `}
                </Typography>
                <Typography
                  className={classes.totalValue}
                  color="textPrimary"
                  variant="subtitle1"
                  component="div"
                >
                  {formatDouble(totalPrice, 2)}
                </Typography>
              </div>
              {discount > 0 && (
                <div className={classes.price}>
                  <Typography
                    className={classes.totalLabel}
                    color="textPrimary"
                    variant="subtitle1"
                    component="div"
                  >
                    {`${t('productSets.discountPrice')}: `}
                  </Typography>
                  <Typography
                    className={classes.totalValue}
                    color="textPrimary"
                    variant="subtitle1"
                    component="div"
                  >
                    {formatDouble(totalPrice * (1 - discount / 100), 2)}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </Hidden>
      </DialogActions>
    </FormDialog>
  );
};

ProductSetDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
    discount: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
    salaryRates: PropTypes.arrayOf(PropTypes.object),
    profit: PropTypes.number,
    competences: PropTypes.arrayOf(
      PropTypes.shape({
        canHaveStudent: PropTypes.bool,
        competenceType: PropTypes.string,
        createdAt: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
  mode: PropTypes.string,
};

ProductSetDialog.defaultProps = {
  isOpen: false,
  data: {},
  mode: 'create',
};

export default ProductSetDialog;
