import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  cellCheckbox: {
    paddingLeft: '7px',

    [theme.breakpoints.up('md')]: {
      paddingLeft: '15px',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cellActions: {
    paddingRight: theme.spacing(2),
    minWidth: 110,

    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
    },
  },
  control: {
    padding: '2px',
  },
  controlIcon: {
    width: '0.8em',
    height: '0.8em',
  },
  rowLoading: {
    opacity: 0.6,
  },
}), {
  name: 'SortableTable',
});
