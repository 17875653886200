import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-start',
  },
  price: {
    display: 'flex',
  },
  item: {
    marginBottom: theme.spacing(1),
  },
  totalValue: {
    marginLeft: 5,
  },
  row: {
    display: 'flex',
  },
}),
{
  name: 'OrdersGenerationDialog',
});
