import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  tableRow: {
    display: 'flex',
  },
  col: {
    '&:first-child': {
      width: '30%',
      [theme.breakpoints.up('sm')]: {
        width: '20%',
      },
    },

    '&:nth-child(2)': {
      width: '50%',
      [theme.breakpoints.up('sm')]: {
        width: '70%',
      },
    },
    '&:last-child': {
      width: '20%',
      [theme.breakpoints.up('sm')]: {
        width: '10%',
      },
    },
  },
  name: {
    display: 'flex',
    maxWidth: '160px',
    paddingRight: theme.spacing(4),
    fontSize: '1rem',
    fontWeight: 600,
  },
  title: {
    flex: 1,
    fontSize: '1rem',
  },
  time: {
    fontSize: '0.85rem',
    fontWeight: 600,
  },
}),
{
  name: 'Message',
});
