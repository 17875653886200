export const TIME_RANGE = {
  week: 'week',
  currentMonth: 'currentMonth',
  month: 'month',
  year: 'year',
  custom: 'custom',
};

export const getShopAdminMenu = (path) => [
  {
    titleKey: 'orders',
    path: `${path}/orders`,
  },
  {
    titleKey: 'sets',
    path: `${path}/product-sets`,
  },
  {
    titleKey: 'products',
    path: `${path}/products`,
  },
  {
    titleKey: 'stats',
    path: `${path}/stats`,
  },
  {
    titleKey: 'communication',
    path: `${path}/communication-panel`,
  },
  {
    titleKey: 'invoices',
    path: `${path}/invoices`,
  },
  {
    titleKey: 'agents',
    path: `${path}/agents`,
    nestedList: [
      {
        titleKey: 'agentsList',
        path: `${path}/agents/list`,
      },
      {
        titleKey: 'ordersTemplates',
        path: `${path}/agents/orders-templates`,
      },
    ],
  },
];
