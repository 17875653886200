// eslint-disable-next-line import/prefer-default-export
export const fromArray = (array, key) => {
  const obj = {};

  array.forEach((el) => {
    obj[el[key]] = el;
  });

  return obj;
};

export const matchSorter = (items, search, keys) => {
  if (!search) {
    return items;
  }

  const searchRequest = search.toLowerCase().trim();

  return items.filter((item) => {
    if (keys) {
      return keys.some((key) => item[key]?.toLowerCase().trim().includes(searchRequest));
    }

    return item.toLowerCase().trim().includes(searchRequest);
  });
};
