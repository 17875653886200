/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { jumpOrganisersApi } from '../api/api';
import {
  parseOrganizations,
  parseOrganization,
} from '../services/organizations.services';
import { setNotification } from './notifications.slice';
import { MESSAGES_TYPES, MESSAGES } from '../constants/constants';

export const getAll = createAsyncThunk(
  'organizations/getAll',
  async (data, { signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await jumpOrganisersApi.getAll({
        cancelToken: source.token,
      });

      return parseOrganizations(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const create = createAsyncThunk(
  'organizations/create',
  async (data, { rejectWithValue }) => {
    try {
      const { locationIds, ...info } = data;

      const response = await jumpOrganisersApi.create(info, locationIds);

      return parseOrganization(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const edit = createAsyncThunk(
  'organizations/edit',
  async (data, { rejectWithValue }) => {
    try {
      const { id, locationIds, ...info } = data;

      await jumpOrganisersApi.edit(id, info, locationIds);

      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const remove = createAsyncThunk(
  'organizations/remove',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await jumpOrganisersApi.delete(id);

      dispatch(
        setNotification({
          message: MESSAGES.successDeleted,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

const organizations = createSlice({
  name: 'organizations',
  initialState: {
    all: [],
    error: '',
    loading: false,
    isSuccess: false,
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.all = action.payload;
    },
    [getAll.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
      state.loading = false;
    },
    [create.fulfilled]: (state, action) => {
      state.all = [...state.all, action.payload];
      state.isSuccess = true;
    },
    [create.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [edit.fulfilled]: (state, action) => {
      state.all = state.all.map((el) => {
        if (el.id === action.payload.id) {
          return {
            ...el,
            ...action.payload,
          };
        }

        return el;
      });
      state.isSuccess = true;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [remove.fulfilled]: (state, action) => {
      state.all = state.all.filter((el) => el.id !== action.payload.id);
    },
    [remove.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
  },
});

export const { resetSuccess, resetError } = organizations.actions;

export default organizations.reducer;
