export const parseProduct = (product) => ({
  createdAt: product.created_at,
  description: product.description,
  forCheckin: product.for_checkin,
  id: product.id,
  isProduct: product.is_product,
  isService: product.is_service,
  location: product.location,
  name: product.name,
  price: Number.parseInt(product.price, 10),
  updatedAt: product.updated_at,
  vat: product.vat,
  createdByLocation: product.created_by_location,
  disableDelete: !!product.created_by_location,
  sfPrice: product.sf_price,
  // sfPriceValue: product.sf_price
  //   ? Number.parseInt(product.price, 10) * (1 - product.sf_price / 100)
  //   : Number.parseInt(product.price, 10),
});

export const parseProducts = (products) => (
  products.map((product) => parseProduct(product))
);
