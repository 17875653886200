import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Hidden } from '@material-ui/core';
import LogoPng from '../../assets/images/logo.png';
import LogoStagingPng from '../../assets/images/logo-staging.png';
import useStyles from './styles';

const AsideContent = React.memo((props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.toolbar}>
        <img
          className={classes.logo}
          src={process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production' ? LogoStagingPng : LogoPng}
          alt="logo"
        />
      </div>
      {children}
    </div>
  );
});

const Sidebar = (props) => {
  const { window, toggle, isOpenMobile, children } = props;
  const classes = useStyles();

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={isOpenMobile}
          onClose={toggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <AsideContent>{children}</AsideContent>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
          color="secondary"
        >
          <AsideContent>{children}</AsideContent>
        </Drawer>
      </Hidden>
    </nav>
  );
};

Sidebar.propTypes = {
  window: PropTypes.func,
  toggle: PropTypes.func.isRequired,
  isOpenMobile: PropTypes.bool,
};

Sidebar.defaultProps = {
  window: undefined,
  isOpenMobile: false,
};

export default Sidebar;
