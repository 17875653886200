import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  controls: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  link: {
    marginRight: theme.spacing(2),
    color: 'inherit',
    textDecoration: 'none',
    borderBottom: 'solid 1px transparent',
  },
  link_isActive: {
    borderBottom: `solid 1px ${theme.palette.secondary.main}`,
    // color: theme.palette.secondary.main,
  },
  create: {
    padding: '5px',
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  content: {
    flex: 1,
  },
  search: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      marginBottom: 0,
      marginRight: theme.spacing(2),
    },
  },
  input: {
    padding: theme.spacing(2),
  },
  searchIcon: {
    color: theme.palette.primary,
  },
}),
{
  name: 'Messages',
});
