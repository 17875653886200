import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { loadState } from '../utils/local-storage.utils';
import rootReducer from './rootReducer';

const loadedStateNames = ['auth'];
const persistedState = {};
loadedStateNames.forEach((name) => {
  const loadedState = loadState(name);
  if (loadedState) {
    persistedState[name] = loadState(name);
  }
});

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: getDefaultMiddleware(),
});

// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     const newRootReducer = rootReducer.default;
//     store.replaceReducer(newRootReducer);
//   });
// }

export default store;
