export const parsePlane = (plane) => ({
  id: plane.id,
  createdAt: plane.created_at,
  name: plane.name,
  planeType: plane.plane_type,
  maxCrew: plane.max_crew,
  maxPassenger: plane.max_passenger,
  updatedAt: plane.updated_at,
  locationId: plane.location_id,
  description: plane.description,
  number: plane.number,
  icao24: plane.icao24
});

export const parsePlanes = (planes) => (
  planes.map((plane) => parsePlane(plane))
);
