import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControlLabel,
  RadioGroup, Radio,
} from '@material-ui/core';
import useStyles from './styles';

const EQUIPMENT_TYPES = {
  parachute: 'parachute',
  harness: 'harness',
};

const EquipmentDialog = (props) => {
  const {
    open, onNextClick, onClose,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [type, setType] = useState('');

  const handleSetType = useCallback((e) => {
    setType(e.target.value);
  }, []);

  const handleNextClick = useCallback(() => {
    onNextClick(type);
  }, [onNextClick, type]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="equipment-dialog"
      open={open}
      paperClassName={classes.dialog}
    >
      <DialogTitle>
        {t('equipment.selectType')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <RadioGroup aria-label="type" name="type" value={type} onChange={handleSetType}>
          <FormControlLabel value={EQUIPMENT_TYPES.parachute} control={<Radio />} label={t('equipment.parachute')} />
          <FormControlLabel value={EQUIPMENT_TYPES.harness} control={<Radio />} label={t('equipment.harness')} />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextClick}
        >
          {t('next')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EquipmentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
};

EquipmentDialog.defaultProps = {
  open: false,
};

export default EquipmentDialog;
