import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
  inputRoot: {
    '& $disabled': {
      color: theme.palette.text.primary,
    },
  },
  autocomplete: {
    marginTop: 8,
    marginBottom: 4,
  },
  disabled: {},
  textarea: {
    width: '100%',
    minHeight: '100px',
    padding: '10.5px 14px',
    outline: 0,
    fontSize: 'inherit',
    marginTop: theme.spacing(1),
    color: theme.palette.text.primary,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: 4,

    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&:focus': {
      borderWidth: 2,
      borderColor: 'rgba(70, 70, 70)',
    },

    '&:disabled': {
      backgroundColor: 'initial',
    },
  },
}), {
  name: 'DocumentTypeDialog',
});
