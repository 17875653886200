import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  summary: {
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(3)}px 0`,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  salary: {
    marginBottom: theme.spacing(2),
  },
  label: {
    marginRight: theme.spacing(1),
    marginBottom: 0,
    fontSize: '1.14rem',
    whiteSpace: 'nowrap',
  },
  link: {
    textDecoration: 'none',
  },
  value: {
    fontSize: '1.14rem',
    lineHeight: 1.75,
    textAlign: 'left',
  },
  details: {
    flexDirection: 'column',
  },
  list: {
    listStyle: 'none',
    maxHeight: 150,
    overflow: 'scroll',
    border: '1px solid black',
    padding: theme.spacing(1),
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  button: {
    border: 0,
    background: 'none',
    padding: 5,
  },
  tableContainer: {
    maxHeight: 300,
  },
  body: {
    overflow: 'scroll',
  },
  subtitle: {
    marginRight: 5,
  },
  sold: {
    '& > td': {
      color: theme.palette.success.main,
    },
  },
  used: {
    '& > td': {
      color: theme.palette.error.main,
    },
  },
  expandRow: {
    backgroundColor: theme.palette.grey[100],
    position: 'relative',
  },
  orders: {
    order: 2,
    overflow: 'hidden',
  },
}),
{
  name: 'Subcontractor',
});
