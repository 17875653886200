import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import TelInput from '../../../../components/tel-input/TelInput';
import SubcontractorsSelectors from '../../../../selectors/subcontractors.selectors';
import { edit } from '../../../../slices/subcontractors.slice';
import { getRateTypeByCompetences } from '../../utils';
import useStyles from './styles';

const SubcontractorEditableContent = (props) => {
  const {
    id, email, phone, name, onEndEditMode, rates,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const salaryRates = useSelector(SubcontractorsSelectors.salaryRates);

  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: {
      email,
      phone,
      name,
      rates,
    },
  });

  const onSubmit = useCallback(async (values) => {
    const newRates = {};

    Object.values(rates).forEach((el) => {
      if (el.rate !== +values.rates[el.rateType].rate) {
        newRates[el.rateType] = {
          ...el,
          rate: +values.rates[el.rateType].rate,
        };
      }
    });

    const response = await dispatch(edit({
      ...values,
      id,
      rates: newRates,
    }));

    if (!response.error) {
      onEndEditMode();
    }
  }, [dispatch, id, onEndEditMode, rates]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <>
      <AccordionDetails>
        <form title={t('agents.title')} maxWidth="sm">
          <div className={classes.row}>
            <InputLabel className={classes.label} htmlFor="name">
              <Typography className={classes.label} color="textSecondary" variant="subtitle1" component="div">
                {`${t('name')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              defaultValue=""
              id="name"
              name="name"
              variant="outlined"
              placeholder={t('users.name')}
              margin="dense"
              fullWidth
            />
          </div>
          <div className={classes.row}>
            <InputLabel className={classes.label} htmlFor="phone">
              <Typography className={classes.label} color="textSecondary" variant="subtitle1" component="div">
                {`${t('phone')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TelInput}
              control={control}
              className={classes.input}
              defaultValue=""
              id="phone"
              name="phone"
              variant="outlined"
              required
              placeholder={t('users.phone')}
              margin="dense"
              fullWidth
            />
          </div>
          <div className={classes.row}>
            <InputLabel className={classes.label} htmlFor="email">
              <Typography className={classes.label} color="textSecondary" variant="subtitle1" component="div">
                {`${t('email')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              defaultValue=""
              id="email"
              name="email"
              variant="outlined"
              placeholder={t('email')}
              margin="dense"
              fullWidth
            />
          </div>
          <Typography className={classes.labelText} color="textPrimary" variant="h6" component="h3" gutterBottom>
            {t('salary.title')}
          </Typography>
          {salaryRates.map((el) => (
            <div className={classes.salary} key={el.id}>
              <InputLabel className={classes.label} htmlFor={el.id}>
                <Typography className={classes.label} color="textSecondary" variant="subtitle1" component="div">
                  {el.name}
                </Typography>
              </InputLabel>
              <Controller
                as={TextField}
                control={control}
                className={classes.input}
                defaultValue=""
                id={el.id}
                name={`rates.${getRateTypeByCompetences(el.competences)}.rate`}
                variant="outlined"
                type="number"
                inputProps={{
                  step: 0.1,
                  min: 0,
                }}
                placeholder={t('salaryRate')}
                margin="dense"
                fullWidth
                required
              />
            </div>
          ))}
        </form>
      </AccordionDetails>
      <AccordionActions>
        <Button variant="contained" size="small" color="primary" onClick={onEndEditMode}>{t('cancel')}</Button>
        <Button variant="contained" size="small" color="primary" onClick={memoSubmit}>{t('save')}</Button>
      </AccordionActions>
    </>
  );
};

SubcontractorEditableContent.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  name: PropTypes.string,
  onEndEditMode: PropTypes.func,
  id: PropTypes.number,
  rates: PropTypes.shape({
    base: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
    handy: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
    camera: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
    camera_handy: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
  }),
};

SubcontractorEditableContent.defaultProps = {
  email: '',
  phone: '',
  name: '',
  onEndEditMode: null,
  id: null,
  rates: {},
};

export default SubcontractorEditableContent;
