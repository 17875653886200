import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IntlTelInput from 'react-intl-tel-input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import 'react-intl-tel-input/dist/main.css';
import useStyles from './styles';

const TelInput = (props) => {
  const {
    onChange,
    inputClassName,
    className,
    countries,
    inputProps,
    value,
    label,
    fullWidth,
    margin,
    required,
    classNamePhone,
    ...otherProps
  } = props;
  const classes = useStyles();

  const [phone, setPhone] = useState(value);
  const [code, setCode] = useState('+48');

  useEffect(() => {
    if (phone !== value) {
      setPhone(value);
    }
  }, [value, phone]);

  const handelPhoneChange = (data, phoneNumber, country) => {
    setCode(`+${country.dialCode}`);

    if (!phone && phoneNumber.charAt(0) !== '+') {
      setPhone(`+${phoneNumber}`);
      if (onChange) {
        onChange(`+${phoneNumber}`, `+${country.dialCode}`);
      }
    } else {
      setPhone(phoneNumber);

      if (onChange) {
        onChange(phoneNumber, `+${country.dialCode}`);
      }
    }
  };

  const handleCodeChange = (data, country) => {
    setCode(`+${country.dialCode}`);
    if (onChange) {
      onChange(phone, `+${country.dialCode}`);
    }
  };

  return (
    <FormControl className={className} fullWidth={fullWidth} margin={margin} variant="outlined">
      {label && (
        <InputLabel shrink={!!value} className={cn({ [classes.label]: !value })}>
          {required ? `${label} *` : label}
        </InputLabel>
      )}
      <IntlTelInput
        containerClassName={cn('intl-tel-input', classes.phone, classNamePhone)}
        inputClassName={cn(classes.phoneInput, inputClassName, { [classes.inputWithLabel]: label })}
        preferredCountries={countries}
        placeholder={code}
        telInputProps={{
          type: 'tel',
          required,
          name: 'phone',
          autoComplete: 'tel',
          pattern: '^\\+\\d{0,16}$',
          ...inputProps,
        }}
        onSelectFlag={handleCodeChange}
        onPhoneNumberChange={handelPhoneChange}
        value={phone}
        formatOnInit={false}
        required={required}
        {...otherProps}
      />
      {label && (
        <legend className={cn(classes.legend, { [classes.legendShrink]: !!value })}>
          <span className={classes.legendLabel}>{label}</span>
        </legend>
      )}
    </FormControl>
  );
};

TelInput.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  classNamePhone: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};

TelInput.defaultProps = {
  onChange: null,
  className: '',
  inputClassName: '',
  countries: ['pl', 'us', 'ru', 'ua', 'by'],
  inputProps: {},
  value: '',
  fullWidth: false,
  margin: '',
  label: '',
  classNamePhone: '',
  required: false,
};

export default TelInput;
