import api from './instance';

// eslint-disable-next-line import/prefer-default-export
export const authApi = {
  login: (data) => api.post('authentication', data),
  findBy: (params) => api.get('users/find_by_', params),
  logout: () => api.post('logout'),
};

export const usersApi = {
  create: (data) => api.post('users', data),
  get: (params, config) => api.get('users', { params, ...config }),
  getUser: (id, config) => api.get(`/users/${id}`, config),
  edit: (id, data) => api.put(`users/${id}`, { ...data }),
  searchByName: (key) => api.get('users/search_by_name', { params: { key } }),
  searchByEmail: (email) => api.get('users/search_by_email', { params: { email } }),
  searchByPhone: (phone) => api.get('users/by_phone', { params: { phone } }),
  searchByType: (profileType) =>
    api.get('users/by_profile_type', { params: { profile_type: profileType } }),
  remove: (id) => api.delete(`users/${id}`),
  getAgents: (config) => api.get('users/agents', { ...config }),
  getSubcontractors: (config) => api.get('users/subcontractors', { ...config }),
  getByCompetence: (params, config) => api.get('users/by_competence', { params, ...config }),
  getUserRoles: (params) => api.get('users/roles_by_location', { params })
};

export const documentsApi = {
  getTypes: (config) => api.get('document_types', config),
  createType: (data, competenceIds) =>
    api.post('document_types', {
      competence_ids: competenceIds,
      document_type: data,
    }),
  editType: (id, competenceIds, data) =>
    api.put(`document_types/${id}`, {
      competence_ids: competenceIds,
      document_type: data,
    }),
  deleteType: (id) => api.delete(`document_types/${id}`),
};

export const locationsApi = {
  getAll: (config) => api.get('locations_api', config),
  create: (data) => api.post('locations_api', data),
  edit: (id, data) => api.put(`locations_api/${id}`, data),
  delete: (id, key) => api.delete(`locations_api/${id}`, { data: { key } }),
};

export const rolesApi = {
  getAll: (config) => api.get('roles', config),
  create: (data) => api.post('roles', data),
  edit: (id, data) => api.put(`roles/${id}`, data),
  delete: (id) => api.delete(`roles/${id}`),
  manageUserRoles: (id, data) => api.post('manage_user_roles', { user_id: id, ...data }),
};

export const planesApi = {
  getAll: (config) => api.get('planes', config),
  create: (locationId, data) => api.post('planes', { location_id: locationId, plane: data }),
  edit: (id, locationId, data) => api.put(`planes/${id}`, { location_id: locationId, plane: data }),
  delete: (id, locationId) => api.delete(`planes/${id}`, { data: { location_id: locationId } }),
};

export const equipmentApi = {
  get: (config) => api.get('equipment', config),
  getTandems: (locationId) => api.get('equipment/tandems', { params: { location_id: locationId } }),
  create: (userId, data) => api.post('equipment', { user_id: userId, equipment: data }),
  edit: (id, userId, data) => api.put(`equipment/${id}`, { user_id: userId, equipment: data }),
  delete: (id, userId) => api.delete(`equipment/${id}`, { data: { user_id: userId } }),
  searchByMain: (key) => api.get('equipment/by_main', { params: { key } }),
  searchByReserve: (key) => api.get('equipment/by_reserve', { params: { key } }),
  searchByBagType: (key) => api.get('equipment/by_bag_type', { params: { key } }),
};

export const productsApi = {
  getAll: (config) => api.get('products_api', config),
  getByLocationId: (locationId) => api.get('products_api', { params: { location_id: locationId } }),
  create: (product) => api.post('products_api', { product }),
  edit: (id, product) => api.put(`products_api/${id}`, { product }),
  delete: (id) => api.delete(`products_api/${id}`),
};

export const salaryRatesApi = {
  getAll: (locationId, cancelToken) =>
    api.get('salary_rates', {
      params: { location_id: locationId },
      cancelToken,
    }),
  create: (locations, competenceIds, salaryRate) =>
    api.post('salary_rates', {
      locations,
      competence_ids: competenceIds,
      salary_rate: salaryRate,
    }),
  edit: (id, locationId, competenceIds, salaryRate) =>
    api.put(`salary_rates/${id}`, {
      location_id: locationId,
      competence_ids: competenceIds,
      salary_rate: salaryRate,
    }),
  delete: (id, locationId) =>
    api.delete(`salary_rates/${id}`, { data: { location_id: locationId } }),
  getForSubcontractors: (cancelToken) => api.get('salary_rates/subcontractors', { cancelToken }),
};

export const productsSetsApi = {
  getAll: (params) => api.get('product_sets', { params }),
  getByLocationId: (locationId, config) =>
    api.get('product_sets', { params: { location_id: locationId }, ...config }),
  create: (locations, productIds, competenceIds, salaryRateIds, productSet) =>
    api.post('product_sets', {
      locations,
      product_ids: productIds,
      product_set: productSet,
      competence_ids: competenceIds,
      salary_rate_ids: salaryRateIds,
    }),
  edit: (id, locations, productIds, competenceIds, salaryRateIds, productSet) =>
    api.put(`product_sets/${id}`, {
      locations,
      product_ids: productIds,
      competence_ids: competenceIds,
      salary_rate_ids: salaryRateIds,
      product_set: productSet,
    }),
  delete: (id) => api.delete(`product_sets/${id}`),
};

export const discountApi = {
  getAll: (config) => api.get('discounts', config),
  getByUserId: (id) => api.get('discounts', { params: { user_id: id } }),
  create: (discount) => api.post('discounts', { discount }),
  edit: (id, discount) => api.put(`discounts/${id}`, { discount }),
  delete: (id) => api.delete(`discounts/${id}`),
  assignToUser: (userId, ids) => api.post('discounts', { user_id: userId, ids }),
  unasignFromUser: (userId, ids) =>
    api.delete(`discounts/${ids[0]}`, { data: { user_id: userId, ids } }),
};

export const regulationsApi = {
  getAll: (config) => api.get('regulations', config),
  create: (regulation) => api.post('regulations', regulation),
  edit: (id, regulation) => api.put(`regulations/${id}`, regulation),
  delete: (id) => api.delete(`regulations/${id}`),
};

export const competencesApi = {
  getAll: (config) => api.get('competences', config),
  create: (competence) => api.post('competences', competence),
  assignToUser: (data) => api.post('competences/assign', data),
  unssignFromUser: (data) => api.delete('competences/unassign', { data }),
  getNotNeedDoc: (config) => api.get('competences/not_need_doc', config),
  getForUser: (params, config) => api.get('competences/by_user', { params, ...config }),
};

export const jumpOrganisersApi = {
  getAll: (config) => api.get('/jump_organisers', config),
  create: (data, locationIds) =>
    api.post('jump_organisers', {
      location_ids: locationIds,
      jump_organiser: data,
    }),
  edit: (id, data, locationIds) =>
    api.put(`jump_organisers/${id}`, {
      location_ids: locationIds,
      jump_organiser: data,
    }),
  delete: (id) => api.delete(`jump_organisers/${id}`),
};

export const paymentRequestsApi = {
  getAll: (locationId, cancelToken) =>
    api.get('users/payment_request', {
      params: { location_id: locationId },
      cancelToken,
    }),
};

export const revolutApi = {
  getAll: () => api.get('revolut_accounts'),
  pay: (data) => api.post('revolut_accounts/pay', data),
};

export const requestsApi = {
  getAll: (config) => api.get('requests', config),
};

export const ordersApi = {
  get: (params) => api.get('/orders', params),
  createOrderForAgent: (data) => api.post('orders/for_agent', data),
  findForAgent: (params) => api.get('orders/find_order_for_agent', { params }),
  getByExternalId: (externalId) =>
    api.get('orders/by_external_id', { params: { order_id: externalId } }),
  updateForAgent: (data) => api.post('orders/for_agent_code', data),
  getOrders: (params) => api.get('/orders/by_location', { params }),
  edit: (id, data) => api.put(`/orders/${id}`, data),
  getByDomain: (params) => api.get('orders/by_domain', { params }),
};

export const subcontractorRatesApi = {
  getBySubcontractor: (params) => api.get('subcontractor_rates', { params }),
  create: (data) => api.post('subcontractor_rates', data),
  edit: (id, data) => api.put(`subcontractor_rates/${id}`, data),
};

export const conversationsApi = {
  getAll: (params) => api.get('/conversations', { params }),
  create: (data) => api.post('/conversations', data),
  hide: (id) => api.put('/conversations/hide', { id }),
};

export const messageApi = {
  create: (data) => api.post('/messages', data),
  markAsRead: (id) => api.put(`/messages/${id}`),
  edit: (params) => api.get(`/messages/${params.id}/edit`, { params }),
  delete: (id) => api.delete(`/messages/${id}`),
  sendToUsers: (data) => api.post('messages/send_to_users', data),
};

export const billingsApi = {
  edit: (id, data) => api.put(`/billings/${id}`, data),
};

export default api;
