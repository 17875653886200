import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import AgentOrder from '../agent-order/AgentOrder';
import AgentsSelectors from '../../../../selectors/agents.selectors';
import { useObserver } from '../../../../hooks/useObserver.hook';
import { usePagination } from '../../../../hooks/usePagination.hook';
import { ORDER_STATUSES } from '../../../../constants/orders.constants';
import useStyles from './styles';

const STATUSES = {
  all: 'all',
  sold: ORDER_STATUSES.sold,
  used: ORDER_STATUSES.used,
  notUsed: ORDER_STATUSES.notUsed,
  cancelled: ORDER_STATUSES.cancelled,
};

const ITEMS_PER_PAGE = 20;

const filterOrdersByTemplate = (templateType, orders) =>
  orders.filter((el) => el.extra === templateType);

const filterOrdersByStatuses = (statuses, orders) => {
  let result = [];

  if (statuses.indexOf(STATUSES.all) > -1) {
    return orders;
  }

  if (statuses.indexOf(STATUSES.sold) > -1) {
    const soldArr = orders.filter((el) => el.flyers.length > 0);
    result = [...result, ...soldArr];
  }

  if (statuses.indexOf(STATUSES.used) > -1) {
    const usedArr = orders.filter((el) => el.used);
    result = [...result, ...usedArr];
  }

  if (statuses.indexOf(STATUSES.notUsed) > -1) {
    const notUsedArr = orders.filter((el) => !el.used && el.flyers.length === 0);
    result = [...result, ...notUsedArr];
  }

  if (statuses.indexOf(STATUSES.cancelled) > -1) {
    const cancelledOrders = orders.filter((el) => el.cancelled);

    result = [...result, ...cancelledOrders];
  }

  return result;
};

const AgentOrders = (props) => {
  const { orders, agentId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const productSets = useSelector(AgentsSelectors.productSets);

  const [searchResult, setSearchResults] = useState(null);

  const handleSearchOrders = useCallback((e, newOrder) => {
    setSearchResults(newOrder);
  }, []);

  const [templateExtra, setTemplateExtra] = useState('');

  const handleSetTemplateId = (e) => {
    setTemplateExtra(e.target.value);
  };

  const [statuses, setStatuses] = useState([STATUSES.all]);

  const handleSetStatus = (e) => {
    const { checked, name } = e.target;

    setStatuses((prevState) => {
      if (checked && name === STATUSES.all) {
        return [name];
      }
      if (!checked) {
        return prevState.filter((el) => el !== name);
      }

      return [...prevState.filter((el) => el !== STATUSES.all), name];
    });
  };

  const filteredOrders = useMemo(() => {
    let result = orders;
    if (templateExtra) {
      result = filterOrdersByTemplate(templateExtra, result);
    }

    if (statuses.length > 0) {
      result = filterOrdersByStatuses(statuses, result);
    }

    return result;
  }, [orders, templateExtra, statuses]);

  const { currentPage, goToNextPage } = usePagination(filteredOrders.length, ITEMS_PER_PAGE);

  const { ref } = useObserver(goToNextPage);

  const ordersToDisplay = filteredOrders.slice(0, (currentPage + 1) * ITEMS_PER_PAGE);

  return (
    <>
      <Box display="flex" className={classes.controls}>
        <Autocomplete
          className={classes.control}
          name="type"
          size="medium"
          options={orders}
          onChange={handleSearchOrders}
          renderOption={(option) => option.externalId}
          getOptionLabel={(option) => option.externalId}
          getOptionSelected={(option, value) => option.externalId === value.externalId}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('search')}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        {productSets && (
          <FormControl className={classes.control} variant="outlined">
            <InputLabel id="templateId">{t('agents.template')}</InputLabel>
            <Select
              id="templateId"
              label={t('agents.template')}
              onChange={handleSetTemplateId}
              value={templateExtra}
            >
              {productSets.map((item) => (
                <MenuItem value={item.products.map((el) => el.name).join(', ')} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      <FormGroup row>
        {Object.values(STATUSES).map((status) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={statuses.indexOf(status) > -1}
                onChange={handleSetStatus}
                name={status}
              />
            }
            label={t(status)}
          />
        ))}
      </FormGroup>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('agents.externalId')}</TableCell>
              <TableCell>{t('agents.status')}</TableCell>
              <TableCell>{t('agents.extra')}</TableCell>
              <TableCell>{t('agents.totalPrice')}</TableCell>
              <TableCell className={classes.actionsCell} />
              <TableCell className={classes.expandCell} />
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {(searchResult ? [searchResult] : ordersToDisplay).map((el) => (
              <AgentOrder
                key={el.id}
                id={el.id}
                totalPrice={el.totalPrice}
                used={el.used}
                externalId={el.externalId}
                createdAt={el.createdAt}
                flyers={el.flyers}
                agentId={agentId}
                time={el.time}
                flightType={el.flightType}
                cancelled={el.cancelled}
                userId={el.userId}
                extra={el.extra}
              />
            ))}
            <tr ref={ref} />
            {!orders.length && (
              <Typography className={classes.noResultsText} color="textSecondary" component="span">
                {t('noResults')}
              </Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

AgentOrders.propTypes = {
  agentId: PropTypes.number.isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
};

AgentOrders.defaultProps = {
  orders: [],
};

export default AgentOrders;
