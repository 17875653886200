import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import DropZoneEditable from './DropZoneEditable';
import DropZoneStatic from './DropZoneStatic';
import { DROP_ZONES_THEME, DROP_ZONES_DEFAULT } from '../../constants';

const DropZoneContent = (props) => {
  const { dz, expanded, onDelete } = props;

  const [isEditMode, setIsEditMode] = useState(false);

  const startEditMode = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const endEditMode = useCallback(() => {
    setIsEditMode(false);
  }, []);

  if (isEditMode) {
    return <DropZoneEditable endEditMode={endEditMode} dz={dz} />;
  }
  return (
    <DropZoneStatic startEditMode={startEditMode} expanded={expanded} onDelete={onDelete} {...dz} />
  );
};

DropZoneContent.propTypes = {
  dz: PropTypes.shape(DROP_ZONES_THEME).isRequired,
  expanded: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

DropZoneContent.defaultProps = {
  ...DROP_ZONES_DEFAULT,
  expanded: false,
};

export default DropZoneContent;
