import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Tabs, Tab, Box,
} from '@material-ui/core';
import useStyles from './styles';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.tabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.box}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const Club = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <main className={classes.container}>
      <Tabs
        classes={{ indicator: classes.indicator }}
        value={activeTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="off"
        aria-label="scrollable auto tabs example"
      >
        <Tab
          classes={{ root: classes.tab, selected: classes.selected }}
          disableRipple
          label={t('club.feesCreator')}
          {...a11yProps(0)}
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.selected }}
          disableRipple
          label={t('club.newsCreator')}
          {...a11yProps(1)}
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.selected }}
          disableRipple
          label={t('club.teamCreator')}
          {...a11yProps(2)}
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.selected }}
          disableRipple
          label={t('club.collectionsCreator')}
          {...a11yProps(3)}
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.selected }}
          disableRipple
          label={t('club.dpSpeedfly')}
          {...a11yProps(4)}
        />
      </Tabs>
      {/* <Switch>
        <Redirect exact from="/offer/club" to="/offer/club/fees" />
        <Route
          path="/offer/club/fees"
          component={() => (
            <TabPanel value={activeTab} index={0}>
              {t('club.feesCreator')}
            </TabPanel>
          )}
        />
        <Route
          path="/offer/club/news"
          component={() => (
            <TabPanel value={activeTab} index={1}>
              {t('club.newsCreator')}
            </TabPanel>
          )}
        />
        <Route
          path="/offer/club/team"
          component={() => (
            <TabPanel value={activeTab} index={2}>
              {t('club.teamCreator')}
            </TabPanel>
          )}
        />
        <Route
          path="/offer/club/collections"
          component={() => (
            <TabPanel value={activeTab} index={3}>
              {t('club.collectionsCreator')}
            </TabPanel>
          )}
        />
        <Route
          path="/offer/club/speedfly"
          component={() => (
            <TabPanel value={activeTab} index={4}>
              {t('club.dpSpeedfly')}
            </TabPanel>
          )}
        />
      </Switch> */}
      <TabPanel value={activeTab} index={0}>
        {t('club.feesCreator')}
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        {t('club.newsCreator')}
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        {t('club.teamCreator')}
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        {t('club.collectionsCreator')}
      </TabPanel>
      <TabPanel value={activeTab} index={4}>
        {t('club.collectionsCreator')}
      </TabPanel>
    </main>
  );
};

export default Club;
