export const parseDocumentType = (type) => ({
  createdAt: type.created_at,
  description: type.description,
  id: type.id,
  name: type.name,
  origin: type.origin,
  updatedAt: type.updated_at,
  needDoc: type.need_doc,
  active: type.active,
  competences: type.competences,
});

export const parseDocumentTypes = (types) => (
  types.map((type) => parseDocumentType(type))
);
