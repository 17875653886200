import { createSelector } from 'reselect';

const subcontractors = (state) => state.subcontractors;

const all = createSelector(subcontractors, (state) => state.all);
const isSuccess = createSelector(subcontractors, (state) => state.isSuccess);
const error = createSelector(subcontractors, (state) => state.error);
const loading = createSelector(subcontractors, (state) => state.loading);
// take salary rates only for Ostrow wielkopolski
const salaryRates = createSelector(subcontractors, (state) =>
  state.salaryRates.filter((el) => el.locationId === 10)
);

const SubcontractorsSelectors = {
  all,
  isSuccess,
  error,
  loading,
  salaryRates,
};

export default SubcontractorsSelectors;
