import React from 'react';
import PropTypes from 'prop-types';
import { Typography, InputLabel } from '@material-ui/core';
import useStyles from './styles';

function CustomInputLabel(props) {
  const { title, htmlFor } = props;
  const classes = useStyles();

  console.log(title, 'title');

  return (
    <InputLabel className={classes.label} htmlFor={htmlFor}>
      <Typography
        className={classes.labelText}
        color="textPrimary"
        variant="subtitle1"
        component="div"
      >
        {`${title}: `}
      </Typography>
    </InputLabel>
  );
}

CustomInputLabel.propTypes = {
  title: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
};

export default CustomInputLabel;
