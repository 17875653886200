import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Dialog, DialogTitle, IconButton, Typography, Paper,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

const FormDialog = React.memo((props) => {
  const {
    isOpen, onSubmit, onClose, title, children, paperClassName, ...dialogProps
  } = props;
  const classes = useStyles();

  const renderPaperComponent = useCallback(({ children: paperChildren, ...propsPaper }) => (
    <Paper {...propsPaper}>
      <form onSubmit={onSubmit} {...propsPaper}>
        {paperChildren}
      </form>
    </Paper>
  ), [onSubmit]);

  return (
    <Dialog
      PaperComponent={renderPaperComponent}
      classes={{ paper: cn(classes.paper, paperClassName) }}
      onClose={onClose}
      open={isOpen}
      {...dialogProps}
    >
      <DialogTitle className={classes.header} disableTypography id="simple-dialog-title">
        <Typography className={classes.title} variant="h5" component="h1">{title}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
});

FormDialog.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  paperClassName: PropTypes.string,
};

FormDialog.defaultProps = {
  isOpen: false,
  paperClassName: '',
};

export default FormDialog;
