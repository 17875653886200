import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { downloadOrder } from '../../../../slices/agents.slice';
import { usersApi } from '../../../../api/api';
import useStyles from './styles';

const AgentOrderDetails = (props) => {
  const { externalId, extra, totalPrice, place, time, flyers, flightType, createdAt } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [flyersInfo, setFlyersInfo] = useState([]);

  useEffect(() => {
    // Request user info for flyers in agent's orders
    const getFlyersInfo = async () => {
      const responseFlyers = await Promise.all(
        flyers.map(async (flyer) => {
          if (flyer.userId) {
            const responseUser = await usersApi.getUser(flyer.userId);

            return {
              ...flyer,
              email: responseUser.data.email,
              phone: responseUser.data.phone,
            };
          }

          return flyer;
        })
      );

      setFlyersInfo(responseFlyers);
    };

    getFlyersInfo();
  }, [flyers]);

  const handleDownloadOrder = useCallback(async () => {
    const response = await dispatch(
      downloadOrder({
        externalId,
        flightType,
      })
    );
    if (!response.error) {
      const pdfWindow = window.open('');
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(
          response.payload
        )}'></iframe>`
      );
    }
  }, [dispatch, externalId, flightType]);

  // one year from createdAt
  const expiredAt = new Date(createdAt);

  expiredAt.setFullYear(expiredAt.getFullYear() + 1);

  return (
    <Box>
      <Box marginBottom={1}>
        <Box display="flex">
          <Typography variant="h6">{`${t('order')} ${externalId}`}</Typography>
          <IconButton className={classes.buttonIcon} onClick={handleDownloadOrder}>
            <InsertDriveFileIcon />
          </IconButton>
        </Box>
        <Typography variant="body1">{extra}</Typography>
      </Box>
      <Box className={classes.columns}>
        <Box className={classes.column}>
          <Box display="flex" marginBottom={1}>
            <Typography className={classes.subtitle} variant="subtitle2">{`${t(
              'agents.totalPrice'
            )}:`}</Typography>
            <Typography variant="body1">{`${totalPrice} zł`}</Typography>
          </Box>
          {place && (
            <Box display="flex" marginBottom={1}>
              <Typography className={classes.subtitle} variant="subtitle2">{`${t(
                'dropZone'
              )}:`}</Typography>
              <Typography variant="body1">{place}</Typography>
            </Box>
          )}
          {time && (
            <Box display="flex" marginBottom={1}>
              <Typography className={classes.subtitle} variant="subtitle2">{`${t(
                'date'
              )}:`}</Typography>
              <Typography variant="body1">{new Date(time).toLocaleDateString()}</Typography>
            </Box>
          )}
          <Box display="flex" marginBottom={1}>
            <Typography className={classes.subtitle} variant="subtitle2">{`${t(
              'validUntil'
            )}:`}</Typography>
            <Typography variant="body1">{expiredAt.toLocaleDateString()}</Typography>
          </Box>
        </Box>
        {flyersInfo && flyersInfo.length > 0 && (
          <Box className={classes.column}>
            <Box display="flex" marginBottom={1}>
              <Typography className={classes.subtitle} variant="subtitle2">{`${t(
                'users.name'
              )}:`}</Typography>
              <Typography variant="body1">{flyersInfo[0].name}</Typography>
            </Box>
            {flyersInfo[0].email && (
              <Box display="flex" marginBottom={1}>
                <Typography className={classes.subtitle} variant="subtitle2">{`${t(
                  'users.email'
                )}:`}</Typography>
                <a className={classes.link} href={`mailto:+${flyersInfo[0].email}`}>
                  <Typography variant="body1">{flyersInfo[0].email}</Typography>
                </a>
              </Box>
            )}
            {flyersInfo[0].phone && (
              <Box display="flex" marginBottom={1}>
                <Typography className={classes.subtitle} variant="subtitle2">{`${t(
                  'users.phone'
                )}:`}</Typography>
                <a className={classes.link} href={`tel:+${flyersInfo[0].phone}`}>
                  <Typography variant="body1">{flyersInfo[0].phone}</Typography>
                </a>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

AgentOrderDetails.propTypes = {
  externalId: PropTypes.string.isRequired,
  extra: PropTypes.string,
  totalPrice: PropTypes.string,
  place: PropTypes.string,
  time: PropTypes.string,
  flightType: PropTypes.string.isRequired,
  flyers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    })
  ),
  createdAt: PropTypes.string.isRequired,
};

AgentOrderDetails.defaultProps = {
  extra: '',
  totalPrice: 0,
  place: '',
  time: null,
  flyers: [],
};

export default AgentOrderDetails;
