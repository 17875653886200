import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 900,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',

    '&:first-child': {
      paddingTop: theme.spacing(1),
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  item: {
    marginBottom: theme.spacing(1),
  },
  columns: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  column: {
    flex: 1,

    // [theme.breakpoints.up('md')]: {
    //   width: '50%',
    // },
  },
  input: {
    width: '100%',
    margin: 0,
    color: `${theme.palette.text.primary} !important`,

    [theme.breakpoints.up('md')]: {
      maxWidth: 220,
    },
  },
  inputRoot: {
    '& $disabled': {
      color: theme.palette.text.primary,
    },
  },
  disabled: {},
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  radioGroup: {
    flexDirection: 'row',
    marginLeft: theme.spacing(1),
  },
}), {
  name: 'EquipmentDialog',
});
