import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import ChatPreview from '../chat-preview/ChatPreview';
import { getAll } from '../../../slices/chats.slice';
import ChatsSelectors from '../../../selectors/chats.selectors';
import useStyles from './styles';

const ChatsList = (props) => {
  const { isDeleteMode, searchedUser } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const match = useRouteMatch();

  const all = useSelector(ChatsSelectors.active);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  if (!all.length && !searchedUser) {
    return (
      <Typography
        className={classes.emptyMessage}
        variant="subtitle1"
        color="textSecondary"
      >
        {t('communication.noChats')}
      </Typography>
    );
  }

  return (
    <ul className={classes.chatList}>
      {!searchedUser &&
        all.map((chat) => (
          <li key={chat.id}>
            <Link
              className={classes.link}
              to={`${match.url}/${chat.recipientId}`}
            >
              <ChatPreview
                recipientId={chat.recipientId}
                title={chat.title}
                message={chat.messages.slice(-1)[0]}
                img={chat.img}
                unreadNumber={chat.unreadNumber}
                isDeleteMode={isDeleteMode}
              />
            </Link>
          </li>
        ))}
      {searchedUser && (
        <li>
          <Link
            className={classes.link}
            to={{
              pathname: `${match.url}/${searchedUser.id}`,
            }}
          >
            <ChatPreview title={searchedUser.name} />
          </Link>
        </li>
      )}
    </ul>
  );
};

ChatsList.propTypes = {
  isDeleteMode: PropTypes.bool,
  searchedUser: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
};

ChatsList.defaultProps = {
  isDeleteMode: false,
  searchedUser: null,
};

export default ChatsList;
