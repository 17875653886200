import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  () => ({
    tabPanel: {
      display: 'flex',
      flexDirection: 'column',
      borderTop: 0,
    },
    active: {
      flex: 1,
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  }),
  {
    name: 'TabPanel',
  }
);
