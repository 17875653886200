import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './PageLoader.styles';

const PageLoader = ({ className }) => {
  const classes = useStyles();
  return (
    <div className={cn(classes.container, className)}>
      <span className={classes.dot} />
      <span className={classes.dot} />
      <span className={classes.dot} />
    </div>
  );
};

PageLoader.propTypes = {
  className: PropTypes.string,
};

PageLoader.defaultProps = {
  className: '',
};

export default PageLoader;
