import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import TelInput from '../../../../components/tel-input/TelInput';
import useStyles from './styles';

const MultipleInput = (props) => {
  const { label, placeholder, id, name, value: defaultValue, type, onChange } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [values, setValues] = useState(defaultValue);

  useEffect(() => {
    setValues(defaultValue);
  }, [defaultValue]);

  const handleSetInputValue = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleSetInputPhoneValue = useCallback((phone) => {
    setInputValue(phone);
  }, []);

  const addValue = useCallback(() => {
    if (inputValue) {
      setValues((prevState) => {
        const newState = [...prevState, inputValue];
        if (onChange) {
          onChange(newState);
        }
        return newState;
      });
      setInputValue('');
    }
  }, [inputValue, onChange]);

  const removeValue = useCallback(
    (val) => {
      setValues((prevState) => {
        const newState = prevState.filter((el) => el !== val);
        if (onChange) {
          onChange(newState);
        }

        return newState;
      });
    },
    [onChange]
  );

  return (
    <div className={classes.container}>
      <ul className={classes.list}>
        {values.map((val) => (
          <li key={val} className={classes.listItem}>
            {`${val},`}
            <IconButton className={classes.removeButton} onClick={() => removeValue(val)}>
              <CancelIcon className={classes.removeIcon} />
            </IconButton>
          </li>
        ))}
        <li className={classes.inputListItem}>
          {type !== 'phone' && (
            <TextField
              className={classes.input}
              id={id}
              name={name}
              label={label}
              variant="outlined"
              placeholder={placeholder}
              margin="dense"
              value={inputValue}
              onChange={handleSetInputValue}
              type={type}
            />
          )}
          {type === 'phone' && (
            <TelInput
              className={classes.input}
              id={id}
              name={name}
              label={label}
              placeholder={placeholder}
              margin="dense"
              value={inputValue}
              onChange={handleSetInputPhoneValue}
              required={false}
            />
          )}
          <IconButton
            className={classes.addButton}
            variant="contained"
            size="small"
            onClick={addValue}
          >
            <AddIcon />
          </IconButton>
        </li>
      </ul>
    </div>
  );
};

MultipleInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  type: PropTypes.string,
  onChange: PropTypes.func,
};

MultipleInput.defaultProps = {
  label: '',
  placeholder: '',
  value: [],
  type: 'text',
  onChange: null,
};

export default MultipleInput;
