import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  details: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  column: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,

    [theme.breakpoints.up('lg')]: {
      maxWidth: '32%',
    },
  },
  rowList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
  },
  rowListItem: {
    marginRight: theme.spacing(1),
  },
  label: {
    marginRight: theme.spacing(1),
    marginBottom: 0,
    fontSize: '1.14rem',
    whiteSpace: 'nowrap',
  },
  labelText: {
    fontSize: 'inherit',
    whiteSpace: 'inherit',
  },
  value: {
    fontSize: '1.14rem',
    lineHeight: 1.75,
    textAlign: 'left',
  },
  timeTable: {
    alignSelf: 'center',
    marginBottom: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
  block: {
    marginBottom: theme.spacing(2),
  },
  input: {
    margin: 0,
  },
  inputOutlinened: {
    minWidth: 200,
  },
  checkbox: {
    '& .disabled': {
      color: 'red',
    },
  },
  disabled: {},
  checkboxes: {
    marginRight: 'auto',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  loader: {
    position: 'static',
    transform: 'none',
  },
}),
{
  name: 'DropZoneContent',
});
