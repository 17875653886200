import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Toolbar,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Box,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PageLoader from '../../../components/page-loader/PageLoader';
import DateRange from '../../../components/date-range/DateRange';
import LoadingButton from '../../../components/loading-button/LoadingButton';
import InvoicesTable from './InvoicesTable';
import LocationsSelectors from '../../../selectors/locations.selectors';
import { getAll as getLocations } from '../../../slices/locations.slice';
import { getByLocationId as getOrders } from '../../../slices/orders.slice';
import OrdersSelectors from '../../../selectors/orders.selectors';
import { TIME_RANGE } from '../constants';
import { isDataUnderRange } from '../utils';
import useStyles from './styles';

function Stats() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locations = useSelector(LocationsSelectors.all);
  const locationsLoading = useSelector(LocationsSelectors.loading);

  const [currentLocation, setCurrentLocation] = useState(null);

  const handleSetCurrentLocation = useCallback((e, value) => {
    setCurrentLocation(value);
  }, []);

  useEffect(() => {
    dispatch(getLocations());
  }, [dispatch]);

  const orders = useSelector(OrdersSelectors.parentOrders);
  const loadingOrders = useSelector(OrdersSelectors.loading);

  useEffect(() => {
    if (currentLocation) {
      dispatch(getOrders(currentLocation.id));
    }
  }, [dispatch, currentLocation]);

  const [range, setRange] = useState(TIME_RANGE.week);

  const handleSetRange = (e, value) => {
    setRange(e.target.value);
  };

  const [customRange, setCustomRange] = useState({
    from: new Date(),
    to: new Date(),
  });

  const handleSetCustomRange = (from, to) => {
    setCustomRange({
      from,
      to,
    });
  };

  const ordersFilteredByTimeRange = useMemo(
    () =>
      orders.filter((order) =>
        isDataUnderRange(range, new Date(order.createdAt), customRange.from, customRange.to)
      ),
    [orders, range, customRange]
  );

  if (locationsLoading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h5" id="tableTitle" component="h2">
          {t('shop.invoices')}
        </Typography>
        <div className={classes.controls}>
          <Autocomplete
            className={classes.control}
            type="select"
            name="location"
            variant="outlined"
            size="medium"
            options={locations}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={handleSetCurrentLocation}
            value={currentLocation}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('location')}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
          <FormControl className={classes.control} variant="outlined">
            <InputLabel id="flightTypeId">{t('range')}</InputLabel>
            <Select id="locationId" label={t('range')} onChange={handleSetRange} value={range}>
              {Object.values(TIME_RANGE).map((range) => (
                <MenuItem value={range} key={range}>
                  {t(`shop.${range}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {range === TIME_RANGE.custom && (
            <DateRange
              placeholderFrom={t('from')}
              placeholderTo={t('to')}
              onChange={handleSetCustomRange}
            />
          )}
        </div>
      </Toolbar>
      {currentLocation && !loadingOrders && (
        <Paper className={classes.paper}>
          <Box marginLeft="auto">
            <LoadingButton className={classes.button} variant="outlined" color="secondary">
              {t('shop.downloadCSV')}
            </LoadingButton>
            <LoadingButton className={classes.button} variant="contained" color="secondary">
              {t('shop.downloadInvoices')}
            </LoadingButton>
          </Box>
          <InvoicesTable orders={ordersFilteredByTimeRange} />
        </Paper>
      )}
      {!currentLocation && (
        <Typography className={classes.infoText} color="textSecondary">
          {t('shop.selectDz')}
        </Typography>
      )}
      {loadingOrders && <PageLoader />}
    </main>
  );
}

export default Stats;
