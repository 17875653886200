/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

export const ORDER_PROP_TYPES = PropTypes.shape({
  id: PropTypes.number.isRequired,
  flightType: PropTypes.string,
  gift: PropTypes.bool,
  time: PropTypes.string,
  ticketType: PropTypes.string,
  externalId: PropTypes.string,
  extra: PropTypes.string,
  passengers: PropTypes.string,
  payedUbezPod: PropTypes.bool,
  canChooseTime: PropTypes.string,
  payedNNW: PropTypes.bool,
  firstPrice: PropTypes.string,
  expired: PropTypes.bool,
  sendNotification: PropTypes.bool,
  totalPrice: PropTypes.string,
  place: PropTypes.string,
  userId: PropTypes.number,
  flyers: PropTypes.array,
  used: PropTypes.bool,
  createdAt: PropTypes.string.isRequired,
  payments: PropTypes.array,
  tbx_send: PropTypes.bool,
  billing: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    zip_code: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    nip: PropTypes.string,
    company: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    user_id: PropTypes.number,
    tax_residence: PropTypes.string,
    active: PropTypes.bool,
  }),
});
