import axios from 'axios';

export const APP_KEY = 'global_admin_panel';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    TOKEN: 'sjrtrgijsaoqehosekwxhqojfwmdkbpzykxctfljrvvjtfuohp',
  },
});

export default api;
