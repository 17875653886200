import { parseMessages } from './messages.services';

export const parseConversation = (el, currentUserId) => {
  const messages = parseMessages(el.messages);

  let recipient;

  if (currentUserId === el.recipient_id) {
    recipient = el.sender;
  } else {
    recipient = el.recipient;
  }

  return ({
    active: el.active,
    id: el.id,
    createdAt: el.created_at,
    messages: messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
    recipientId: recipient.id,
    senderId: el.sender_id,
    updatedAt: el.updated_at,
    img: recipient?.avatar,
    title: recipient?.name,
    recipient,
  });
};

export const parseConversations = (conversations, currentUserId) => (
  conversations.map((el) => parseConversation(el, currentUserId))
);
