import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Toolbar,
  Typography,
} from '@material-ui/core';
import PageLoader from '../../../../components/page-loader/PageLoader';
import SortableTable from '../../../../components/sortable-table/SortableTable';
import UserDialog from '../../../users-database/components/user-dialog/UserDialog';
import { searchByCompetence } from '../../../../slices/users.slice';
import { getAll as getAllLocations } from '../../../../slices/locations.slice';
import { getAll as getAllCompetences } from '../../../../slices/competences.slice';
import LocationsSelectors from '../../../../selectors/locations.selectors';
import CompetencesSelectors from '../../../../selectors/competences.selectors';
import useStyles from './styles';

const TABLE_COLUMNS = [
  { id: 'name', isSortable: true },
  { id: 'phone', isSortable: true },
  { id: 'email', isSortable: true },
  { id: 'type', isSortable: true },
];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { numSelected, onChangeLocation, onChangeCompetence } = props;
  const { t } = useTranslation();

  const locations = useSelector(LocationsSelectors.relatedLocations);
  const competences = useSelector(CompetencesSelectors.all);

  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography variant="h5" id="tableTitle" component="h2">
        {t('competences.search')}
      </Typography>
      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography
              className={classes.selectedTitle}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {`${numSelected} ${t('selected')}`}
            </Typography>
          </div>
        </div>
      )}
      <Box className={classes.controls}>
        <FormControl className={classes.control} variant="outlined">
          <InputLabel id="flightTypeId">{t('dropZone')}</InputLabel>
          <Select
            id="locationId"
            label={t('dropZone')}
            onChange={onChangeLocation}
          >
            {locations &&
              locations.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl className={classes.control} variant="outlined">
          <InputLabel id="flightTypeId">
            {t('competence.competence')}
          </InputLabel>
          <Select
            id="competenceId"
            label={t('competence')}
            onChange={onChangeCompetence}
          >
            {competences &&
              competences.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onChangeLocation: PropTypes.func.isRequired,
  onChangeCompetence: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const promiseLocations = dispatch(getAllLocations());
    const promiseCompetences = dispatch(getAllCompetences());

    return () => {
      promiseLocations.abort();
      promiseCompetences.abort();
    };
  }, [dispatch]);

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [locationId, setLocationId] = useState(null);
  const [competenceId, setCompetenceId] = useState(null);

  const handleChangeLocation = useCallback((e) => {
    setLocationId(e.target.value);
  }, []);

  const handleChangeCompetence = useCallback((e) => {
    setCompetenceId(e.target.value);
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      setIsLoading(true);
      const response = await dispatch(
        searchByCompetence({ locationId, competenceId })
      );
      setIsLoading(false);

      if (!response.error) {
        setUsers(response.payload);
      }
    };

    if (locationId && competenceId) {
      getUsers();
    }
  }, [dispatch, locationId, competenceId]);

  const [isUserDialog, setIsUserDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const openUserDialog = useCallback((user) => {
    setIsUserDialog(true);
    setCurrentUser(user);
  }, []);

  const closeUserDialog = useCallback(() => {
    setIsUserDialog(false);
    setCurrentUser(null);
  }, []);

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        onChangeLocation={handleChangeLocation}
        onChangeCompetence={handleChangeCompetence}
      />
      <SortableTable
        className={classes.table}
        data={users}
        selected={selected}
        columns={TABLE_COLUMNS}
        translationNamespace="users"
        onSelect={setSelected}
        isSelectAvailable={false}
        onShow={(row) => openUserDialog(row)}
      />
      {isLoading && <PageLoader />}
      {isUserDialog && (
        <UserDialog
          isOpen={isUserDialog}
          handleClose={closeUserDialog}
          data={currentUser}
          mode="view"
        />
      )}
    </main>
  );
}
