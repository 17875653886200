import React, { useState, useCallback } from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Typography, Divider, IconButton, TextField, InputAdornment,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from '@material-ui/icons/Search';
import Incoming from './components/incoming/Incoming';
import Sent from './components/sent/Sent';
import MessageDialog from './components/message-dialog/MessageDialog';
import useStyles from './styles';

const Messages = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);

  const openMessageDialog = useCallback(() => {
    setIsMessageDialogOpen(true);
  }, []);

  const closeMessageDialog = useCallback(() => {
    setIsMessageDialogOpen(false);
  }, []);

  return (
    <main className={classes.container}>
      <div className={classes.controls}>
        <TextField
          classes={{
            root: classes.search,
          }}
          type="search"
          placeholder={t('search')}
          name="search"
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.input,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
        <NavLink className={classes.link} activeClassName={classes.link_isActive} to="incoming">
          <Typography>{t('messages.incoming')}</Typography>
        </NavLink>
        <NavLink className={classes.link} activeClassName={classes.link_isActive} to="sent">
          <Typography>{t('messages.outcoming')}</Typography>
        </NavLink>
        <IconButton className={classes.create} aria-label={t('messages.create')} onClick={openMessageDialog}>
          <CreateIcon />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.content}>
        <Switch>
          <Route path="/communication/:type/incoming" component={Incoming} />
          <Route path="/communication/:type/sent" component={Sent} />
        </Switch>
      </div>
      <MessageDialog isOpen={isMessageDialogOpen} handleClose={closeMessageDialog} />
    </main>
  );
};

export default Messages;
