import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cellCheckbox: {
    paddingLeft: '7px',

    [theme.breakpoints.up('md')]: {
      paddingLeft: '15px',
    },
  },
  cellActions: {
    paddingRight: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
    },
  },
}),
{
  name: 'TableHead',
});
