import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  adornment: {
    padding: 0,
  },
}),
{
  name: 'DatePicker',
});
