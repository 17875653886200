/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { productsApi } from '../api/api';
import { parseProducts, parseProduct } from '../services/products.services';
import { setNotification } from './notifications.slice';
import { MESSAGES_TYPES, MESSAGES } from '../constants/constants';

export const getAll = createAsyncThunk(
  'products/getAll',
  async (data, { signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await productsApi.getAll({ cancelToken: source.token });

      return parseProducts(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const getByLocationId = createAsyncThunk(
  'products/getByLocationId',
  async (locationId, { rejectWithValue, dispatch }) => {
    try {
      const response = await productsApi.getByLocationId(locationId);

      return parseProducts(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const create = createAsyncThunk(
  'products/create',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        name: rawData.name,
        price: rawData.price,
        description: rawData.description,
        for_checkin: rawData.forCheckin,
        is_service: rawData.isService,
        is_product: rawData.isProduct,
        sf_price: rawData.sfPrice,
      };

      if (rawData.vat) {
        data.vat = rawData.vat;
      }

      if (rawData.location) {
        data.location = rawData.location;
      }

      const response = await productsApi.create(data);

      return parseProduct(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const edit = createAsyncThunk(
  'products/edit',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        name: rawData.name,
        price: rawData.price,
        description: rawData.description,
        for_checkin: rawData.forCheckin,
        is_service: rawData.isService,
        is_product: rawData.isProduct,
        location: rawData.location,
        vat: rawData.vat,
        sf_price: rawData.sfPrice,
      };

      await productsApi.edit(rawData.id, data);

      return rawData;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const remove = createAsyncThunk(
  'products/remove',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await productsApi.delete(id);

      dispatch(
        setNotification({
          message: MESSAGES.successDeleted,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const products = createSlice({
  name: 'products',
  initialState: {
    all: [],
    error: '',
    loading: false,
    isSuccess: false,
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.all = action.payload;
    },
    [getAll.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
      state.loading = false;
    },
    [create.pending]: (state) => {
      state.error = '';
    },
    [create.fulfilled]: (state, action) => {
      state.all = [...state.all, action.payload];
      state.isSuccess = true;
    },
    [create.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [edit.pending]: (state) => {
      state.error = '';
    },
    [edit.fulfilled]: (state, action) => {
      state.all = state.all.map((el) => {
        if (el.id === action.payload.id) {
          return action.payload;
        }

        return el;
      });
      state.isSuccess = true;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [remove.pending]: (state) => {
      state.error = '';
    },
    [remove.fulfilled]: (state, action) => {
      state.all = state.all.filter((el) => el.id !== action.payload.id);
    },
    // [remove.rejected]: (state, action) => {
    //   if (action.payload && action.payload.message) {
    //     state.error = action.payload.message;
    //   }
    //   state.error = action.payload;
    // },
  },
});

export const { resetError, resetSuccess } = products.actions;

export default products.reducer;
