import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
  inputRoot: {
    '& $disabled': {
      color: theme.palette.text.primary,
    },
  },
  select: {
    color: `${theme.palette.text.primary} !important`,
    '& $disabled': {
      color: theme.palette.text.primary,
    },
  },
  autocomplete: {
    marginTop: theme.spacing(1),
    marginBottom: 4,
  },
  disabled: {},
}), {
  name: 'PlaneDialog',
});
