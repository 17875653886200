import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  name: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '168px',
    paddingRight: theme.spacing(4),
    fontSize: '1rem',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
    },
  },
  title: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '1rem',
  },
  time: {
    textAlign: 'right',
    fontSize: '0.85rem',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(3),
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flex: 1,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
}),
{
  name: 'Message',
});
