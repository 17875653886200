import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import {
  DialogContent,
  InputLabel,
  DialogActions,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Button,
  Typography,
  TextField,
  Checkbox,
  Radio,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import DatePicker from '../../../../components/date-picker/DatePicker';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import TelInput from '../../../../components/tel-input/TelInput';
import { PROFILE_TYPES } from '../../../../constants/constants';
import {
  edit,
  resetSuccess,
  resetError,
  create,
} from '../../../../slices/users.slice';
import { dateToString } from '../../../../utils/date.utils';
import useStyles from './styles';

const UserDialog = (props) => {
  const { isOpen, handleClose, data, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state);

  useEffect(() => {
    let timeout;
    if (users.isSuccess) {
      timeout = setTimeout(() => {
        dispatch(resetSuccess());
        handleClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, handleClose, users.isSuccess]);

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      ...data,
      birthDate: data && data.birthDate && new Date(data.birthDate),
      createdAt: data && data.createdAt && new Date(data.createdAt),
    },
  });

  const onSubmit = useCallback(
    (values) => {
      if (mode === 'edit') {
        dispatch(
          edit({
            ...data,
            ...values,
            birthDate: values.data && dateToString(values.data),
            height: values.height && +values.height,
            weight: values.weight && +values.weight,
            currentBalance: values.currentBalance || 0,
          })
        );
      } else {
        dispatch(
          create({
            ...values,
            birthDate: values.data && dateToString(values.data),
            height: values.height || 0,
            weight: values.weight || 0,
            currentBalance: values.currentBalance || 0,
          })
        );
      }
    },
    [dispatch, data, mode]
  );

  const onClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    handleClose();
  }, [dispatch, handleClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      onClose={onClose}
      isOpen={isOpen}
      onSubmit={memoSubmit}
      title={t('users.user')}
    >
      <DialogContent className={classes.content}>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="name"
            name="name"
            variant="outlined"
            placeholder={t('users.name')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="nickname">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.nickname')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="nickname"
            name="nickname"
            variant="outlined"
            placeholder={t('users.nickname')}
            margin="dense"
            disabled={mode === 'view'}
            required
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="email">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.email')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="email"
            name="email"
            variant="outlined"
            placeholder={t('users.email')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="phone">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.phone')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TelInput}
            control={control}
            className={classes.input}
            defaultValue=""
            id="phone"
            name="phone"
            variant="outlined"
            required
            placeholder={t('users.phone')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="profileType">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.profileType')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={(controllerProps) => (
              <FormControl className={classes.formControl} {...controllerProps}>
                <InputLabel id="profileTypeId">
                  {t('users.profileType')}
                </InputLabel>
                <Select
                  id="profileTypeId"
                  label={t('users.profileType')}
                  {...controllerProps}
                >
                  {PROFILE_TYPES.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            control={control}
            className={cn(classes.input)}
            defaultValue=""
            id="profileType"
            name="profileType"
            variant="outlined"
            label={t('users.profileType')}
            margin="dense"
            disabled={mode === 'view'}
            required
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="pesel">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.pesel')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="pesel"
            name="pesel"
            variant="outlined"
            placeholder={t('users.pesel')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="documentNumber">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.documentNumber')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="documentNumber"
            name="documentNumber"
            variant="outlined"
            placeholder={t('users.documentNumber')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="currentBalance">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.currentBalance')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="currentBalance"
            name="currentBalance"
            variant="outlined"
            placeholder={t('users.currentBalance')}
            margin="dense"
            type="number"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="birthDate">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.birthDate')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={DatePicker}
            control={control}
            className={classes.input}
            id="birthDate"
            name="birthDate"
            disableToolbar
            inputVariant="outlined"
            variant="inline"
            placeholder={t('users.birthDate')}
            margin="dense"
            format="dd/MM/yyyy"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="birthPlace">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.birthPlace')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="birthPlace"
            name="birthPlace"
            variant="outlined"
            placeholder={t('users.birthPlace')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="blood">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.blood')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="blood"
            name="blood"
            variant="outlined"
            placeholder={t('users.blood')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="height">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.height')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="height"
            name="height"
            variant="outlined"
            placeholder={t('users.height')}
            margin="dense"
            type="number"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="weight">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.weight')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="weight"
            name="weight"
            variant="outlined"
            placeholder={t('users.weight')}
            margin="dense"
            type="number"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="gender">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.sex')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={
              <RadioGroup>
                <FormControlLabel
                  disabled={mode === 'view'}
                  value="male"
                  control={<Radio />}
                  label={t('users.male')}
                />
                <FormControlLabel
                  disabled={mode === 'view'}
                  value="female"
                  control={<Radio />}
                  label={t('users.female')}
                />
              </RadioGroup>
            }
            defaultValue=""
            control={control}
            className={classes.radioGroup}
            id="gender"
            name="gender"
            aria-label={t('users.sex')}
            rules={{
              validate: (value) => !!value || t('requiredField'),
            }}
          />
          <ErrorMessage errors={errors} name="gender">
            {({ message }) => <FormHelperText error>{message}</FormHelperText>}
          </ErrorMessage>
        </div>
        <div className={classes.item}>
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            defaultValue={false}
            id="acceptedRegulations"
            name="acceptedRegulations"
            variant="outlined"
            label={t('users.acceptedRegulations')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            defaultValue={false}
            id="acceptedSpeedflyRegulations"
            name="acceptedSpeedflyRegulations"
            variant="outlined"
            label={t('users.acceptedSpeedflyRegulations')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="exported">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.exported')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="exported"
            name="exported"
            variant="outlined"
            placeholder={t('users.exported')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="createdAt">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.creationDate')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={DatePicker}
            control={control}
            className={classes.input}
            id="createdAt"
            name="createdAt"
            disableToolbar
            inputVariant="outlined"
            variant="inline"
            placeholder={t('users.creationDate')}
            margin="dense"
            format="dd/MM/yyyy"
            disabled
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {mode !== 'view' && (
          <>
            <Button
              className={classes.submit}
              autoFocus
              type="submit"
              variant="contained"
              color="primary"
            >
              {mode === 'edit' ? t('save') : t('create')}
            </Button>
            {mode === 'edit' && (
              <Button
                className={classes.submit}
                autoFocus
                onClick={onClose}
                variant="outlined"
                color="primary"
              >
                {t('cancel')}
              </Button>
            )}
            {users.error && typeof users.error === 'string' && (
              <Typography color="error" variant="body1" component="div">
                {users.error}
              </Typography>
            )}
            {users.isSuccess && (
              <Typography
                className={classes.success}
                component="span"
                variant="body1"
              >
                {mode === 'edit' ? t('successEdited') : t('successCreated')}
              </Typography>
            )}
          </>
        )}
      </DialogActions>
    </FormDialog>
  );
};

UserDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    createdAt: PropTypes.string,
    birthDate: PropTypes.string,
  }),
  mode: PropTypes.string,
};

UserDialog.defaultProps = {
  isOpen: false,
  data: {},
  mode: 'create',
};

export default UserDialog;
