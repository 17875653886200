import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  rowSelected: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.dark,
  },
  selected: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  toolbar: {
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  title: {
    flex: '1 1 100%',
  },
  selectedTitle: {
    flex: '1 1 100%',
    color: theme.palette.secondary.contrastText,
  },
  selectedButton: {
    color: theme.palette.secondary.contrastText,
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  control: {
    width: '100%',
    minHeight: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: theme.spacing(2),
      maxWidth: `calc(50% - ${theme.spacing(2)}px)`,
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 'calc(100% / 3)',
    },
  },
  createButton: {
    flexShrink: 0,

    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },
  },
  cell: {
    textAlign: 'center',
    '&:nth-child(2)': {
      textAlign: 'left',
    },
    '&:nth-child(3), &:nth-child(6), &:nth-child(7),  &:nth-child(9), &:nth-child(11), &:nth-child(12), &:nth-child(13), &:nth-child(14), &:nth-child(15), &:nth-child(16), &:nth-child(17), &:nth-child(18)': {
      borderRight: 'solid 1px rgba(224, 224, 224, 1)',
    },
  },
}));
