import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import Chart from 'react-google-charts';
import { getTimeRange } from '../utils';

function OrdersNumberChart(props) {
  const { orders, timeRange, customRange } = props;
  const { t } = useTranslation();

  const ordersChartData = useMemo(() => {
    const chartData = {};

    orders.forEach((order) => {
      const date = order.createdAt.split('T')[0];
      if (chartData[date]) {
        chartData[date] += order.totalPrice;
      } else {
        chartData[date] = order.totalPrice;
      }
    });

    getTimeRange(timeRange, customRange.from, customRange.to).forEach((el) => {
      if (!chartData[el]) {
        chartData[el] = 0;
      }
    });

    return Object.keys(chartData)
      .map((key) => {
        const dateKey = new Date(key);
        dateKey.setHours(0);
        return [new Date(dateKey), chartData[key]];
      })
      .sort((a, b) => a[0] - b[0]);
  }, [orders, timeRange, customRange]);

  return (
    <Paper>
      <Chart
        width={'100%'}
        height={'400px'}
        chartType='LineChart'
        loader={<div>Loading Chart</div>}
        data={[[t('date'), t('shop.sales')], ...ordersChartData]}
        options={{
          title: t('shop.amountSalesForPeriod'),
          hAxis: {
            title: t('date'),
            format: 'MMM dd',
            ticks: getTimeRange(timeRange, customRange.from, customRange.to),
          },
          vAxis: {
            title: t('price'),
            minValue: 0,
            format: '#',
          },
        }}
      />
    </Paper>
  );
}

export default OrdersNumberChart;
