import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'reltive',
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
}), {
  name: 'CompetenceDialog',
});
