// eslint-disable-next-line import/prefer-default-export
export const parsePaymentRequest = (request) => ({
  id: request.id,
  amount: request.amount,
  comment: request.comment,
  createdAt: request.created_at,
  payed: request.payed,
  updatedAt: request.updated_at,
  userId: request.user_id,
  invoice: request.invoice,
});

const flatRequests = (users) => (
  users.map((el) => (
    el.unpayed_requests.map((request) => ({
      ...parsePaymentRequest(request),
      name: el.name,
      currentBalance: el.current_balance,
    }))
  )).flat()
);

export const parsePaymentRequests = (requests) => ({
  totalSum: requests.total_sum,
  requests: flatRequests(requests.users),
});
