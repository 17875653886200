import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(1),
  },
  container: {
    position: 'relative',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  invisible: {
    display: 'none',
  },
  relative: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
}),
{
  name: 'SubcontractorDialog',
});
