export const parseCompetence = (competence) => ({
  createdAt: competence.created_at,
  id: competence.id,
  name: competence.name,
  competenceType: competence.competence_type,
  updatedAt: competence.updated_at,
  description: competence.description,
  canHaveStudent: competence.can_have_student,
});

export const parseCompetences = (competences) => (
  competences.map((competence) => parseCompetence(competence))
);
