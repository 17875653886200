import { makeStyles } from '@material-ui/core';

export default makeStyles(
  (theme) => ({
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    actions: {
      marginTop: 'auto',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
      overflow: 'hidden',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    actionsMain: {
      display: 'flex',
      alignItems: 'center',
    },
    submit: {
      flexShrink: 0,
      marginRight: theme.spacing(1),
    },
    row: {
      marginBottom: theme.spacing(1),
    },
    input: {
      width: '100%',
    },
    autocomplete: {
      marginTop: theme.spacing(1),
      marginBottom: 4,
    },
    total: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        marginBottom: 0,
      },
    },
    price: {
      display: 'flex',
      marginRight: theme.spacing(2),

      '&:last-child': {
        marginRight: 0,
      },
    },
    totalValue: {
      fontWeight: 500,
    },
    totalLabel: {
      marginRight: theme.spacing(1),
    },
    success: {
      color: theme.palette.success.main,
      marginLeft: theme.spacing(1),
    },
    shrink: {
      display: 'none',
    },
    formLabel: {
      color: 'rgba(0, 0, 0, 0.4)',
      '&$error': {
        color: theme.palette.text.secondary,
      },
    },
    subtitle: {
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
    error: {},
  }),
  {
    name: 'EditOrderDialog',
  }
);
