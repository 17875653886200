import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel as MuiInputLabel,
} from '@material-ui/core';
import FormDialog from '../../../components/form-dialog/FormDialog';
import PageLoader from '../../../components/page-loader/PageLoader';
import TelInput from '../../../components/tel-input/TelInput';
import InputLabel from '../../../components/input-label/InputLabel';
import { editOrder } from '../../../slices/orders.slice';
import { ORDER_PROP_TYPES } from '../../../prop-types/orders.types';
import { ORDER_STATUSES } from '../../../constants/orders.constants';
import { getOrderStatusType } from './utils';
import AuthSelectors from '../../../selectors/auth.selectors';
import useStyles from './EditOrdersDialog.styles';

const ProductSetDialog = (props) => {
  const { isOpen, onClose, data, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isSuperAdmin = useSelector(AuthSelectors.isSuperAdmin);

  const orderStatus = getOrderStatusType(data);

  const { control, handleSubmit, formState, register } = useForm({
    defaultValues: {
      ...data,
      status: orderStatus,
    },
  });

  const onSubmit = useCallback(
    async (values) => {
      console.log(values, 'values');
      console.log(values.billing, 'billing');
      const response = await dispatch(
        editOrder({
          orderId: data.id,
          id: data.billing.id,
          userId: data.userId,
          billing: values.billing,
          tbxSend: values.status === ORDER_STATUSES.sentToCustomer ? true : data.tbxSend,
          cancelled: values.status === ORDER_STATUSES.cancelled ? true : data.cancelled,
          totalPrice: data.totalPrice,
        })
      );

      if (!response.error) {
        onClose();
      }
    },
    [
      dispatch,
      data.id,
      data.billing.id,
      data.userId,
      data.tbxSend,
      data.cancelled,
      data.totalPrice,
      onClose,
    ]
  );

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <FormDialog
      onClose={onClose}
      isOpen={isOpen}
      title={`${t('shop.editOrder')} ${data.id}`}
      onSubmit={memoSubmit}
    >
      <DialogContent className={classes.content}>
        {orderStatus !== ORDER_STATUSES.used && (
          <Controller
            control={control}
            as={(controllerProps) => (
              <FormControl {...controllerProps}>
                <MuiInputLabel id="statusIdLabel">{t('shop.status')}</MuiInputLabel>
                <Select labelId="statusIdLabel" {...controllerProps}>
                  {orderStatus !== ORDER_STATUSES.cancelled &&
                    orderStatus !== ORDER_STATUSES.sentToCustomer && (
                      <MenuItem value={orderStatus}>{t(`shop.${orderStatus}`)}</MenuItem>
                    )}
                  <MenuItem value={ORDER_STATUSES.cancelled}>
                    {t(`shop.${ORDER_STATUSES.cancelled}`)}
                  </MenuItem>
                  <MenuItem value={ORDER_STATUSES.sentToCustomer}>
                    {t(`shop.${ORDER_STATUSES.sentToCustomer}`)}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            name="status"
            id="statusId"
            variant="outlined"
            fullWidth
            margin="dense"
            label={t('shop.status')}
          />
        )}
        {isSuperAdmin && (
          <>
            {' '}
            <Typography
              className={classes.subtitle}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {t('shop.payment')}
            </Typography>
            <div className={classes.row}>
              <InputLabel title={t('shop.name')} htmlFor="name" />
              <TextField
                inputRef={register}
                className={classes.input}
                defaultValue=""
                id="name"
                name="billing.name"
                variant="outlined"
                required
                placeholder={t('shop.name')}
                margin="dense"
                disabled={mode === 'view'}
              />
            </div>
            <div className={classes.row}>
              <InputLabel title={t('shop.surname')} htmlFor="surname" />
              <TextField
                inputRef={register}
                className={classes.input}
                defaultValue=""
                id="surname"
                name="billing.surname"
                variant="outlined"
                required
                placeholder={t('shop.surname')}
                margin="dense"
                disabled={mode === 'view'}
              />
            </div>
            <div className={classes.row}>
              <InputLabel title={t('users.phone')} htmlFor="phone" />
              <Controller
                as={TelInput}
                control={control}
                className={classes.input}
                defaultValue=""
                id="phone"
                name="billing.phone"
                variant="outlined"
                placeholder={t('users.phone')}
                margin="dense"
                type="number"
                disabled={mode === 'view'}
              />
            </div>
            <div className={classes.row}>
              <InputLabel title={t('users.email')} htmlFor="email" />
              <TextField
                inputRef={register}
                className={classes.input}
                defaultValue=""
                id="email"
                name="billing.email"
                variant="outlined"
                placeholder={t('users.email')}
                margin="dense"
                disabled={mode === 'view'}
              />
            </div>
            <div className={classes.row}>
              <InputLabel title={t('shop.nip')} htmlFor="nip" />
              <TextField
                inputRef={register}
                className={classes.input}
                defaultValue=""
                id="nip"
                name="billing.nip"
                variant="outlined"
                placeholder={t('shop.nip')}
                margin="dense"
                disabled={mode === 'view'}
              />
            </div>
          </>
        )}
        <Typography
          className={classes.subtitle}
          color="textPrimary"
          variant="subtitle1"
          component="div"
        >
          {t('shop.shipping')}
        </Typography>
        <div className={classes.row}>
          <InputLabel title={t('shop.country')} htmlFor="country" />
          <TextField
            inputRef={register}
            className={classes.input}
            defaultValue=""
            id="country"
            name="billing.country"
            variant="outlined"
            placeholder={t('shop.country')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel title={t('shop.city')} htmlFor="city" />
          <TextField
            inputRef={register}
            className={classes.input}
            defaultValue=""
            id="city"
            name="billing.city"
            variant="outlined"
            placeholder={t('shop.city')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel title={t('shop.address')} htmlFor="address" />
          <TextField
            inputRef={register}
            className={classes.input}
            defaultValue=""
            id="address"
            name="billing.address"
            variant="outlined"
            placeholder={t('shop.address')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel title={t('shop.zipCode')} htmlFor="zipCode" />
          <TextField
            inputRef={register}
            className={classes.input}
            defaultValue=""
            id="zipCode"
            name="billing.zipCode"
            variant="outlined"
            placeholder={t('shop.zipCode')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {mode !== 'view' && (
          <div className={classes.actionsMain}>
            <Button
              className={classes.submit}
              autoFocus
              type="submit"
              variant="contained"
              color="primary"
            >
              {mode === 'edit' ? t('save') : t('create')}
            </Button>
            {mode === 'edit' && (
              <Button onClick={onClose} variant="outlined" color="primary">
                {t('cancel')}
              </Button>
            )}
          </div>
        )}
      </DialogActions>
    </FormDialog>
  );
};

ProductSetDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(ORDER_PROP_TYPES),
  mode: PropTypes.string,
};

ProductSetDialog.defaultProps = {
  isOpen: false,
  data: {},
  mode: 'create',
};

export default ProductSetDialog;
