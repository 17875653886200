import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Typography,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import SearchAutocomplete from '../../../../components/search-autocomplete/SearchAutocomplete';
import { searchByName } from '../../../../slices/users.slice';
import { getByUserId, assignToUser } from '../../../../slices/discounts.slice';
import PageLoader from '../../../../components/page-loader/PageLoader';
import DiscountsSelectors from '../../../../selectors/discounts.selectors';
import { matchSorter } from '../../../../utils/array.utils';
import useStyles from './styles';

const AssignDiscount = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dzDiscounts = useSelector(DiscountsSelectors.dzDiscounts);
  const tunnelDiscounts = useSelector(DiscountsSelectors.tunnelDiscounts);
  const otherDiscounts = useSelector(DiscountsSelectors.otherDiscounts);

  const [inputValue, setInputValue] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [asignDiscounts, setAsignDiscounts] = useState([]);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let successTimeoutId;
    if (isSuccess) {
      successTimeoutId = setTimeout(() => {
        setIsSuccess(false);
        setCurrentUser(null);
        setInputValue('');
        setAsignDiscounts([]);
      }, 1500);
    }

    return () => {
      clearTimeout(successTimeoutId);
    };
  });

  const handleSetCutrrentUser = useCallback(
    async (e, newValue) => {
      setCurrentUser(newValue);
      if (newValue) {
        try {
          setIsLoading(true);
          const actionResponse = await dispatch(getByUserId(newValue.id));
          const response = unwrapResult(actionResponse);
          setCurrentUser((prevState) => ({
            ...prevState,
            discounts: response,
          }));
          setAsignDiscounts(response.map((el) => el.id));
          setIsLoading(false);
        } catch (err) {
          setError(err);
        }
      }
    },
    [dispatch]
  );

  const handleSearchByName = useCallback(
    async (value, setOptions, setErrors) => {
      setInputValue(value);
      if (value.length > 3) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const timout = setTimeout(async () => {
          try {
            const responsAction = await dispatch(searchByName(value));
            const response = unwrapResult(responsAction);
            setOptions(response);

            if (
              response.length === 1 &&
              response[0].name.toLowerCase() === value.toLowerCase()
            ) {
              handleSetCutrrentUser({}, response[0]);
            }
          } catch (err) {
            setErrors(err.message);
          }
        }, 500);
        setTimeoutId(timout);
      }
    },
    [dispatch, timeoutId, handleSetCutrrentUser]
  );

  const toogleRoles = useCallback((e) => {
    const { value } = e.target;

    setAsignDiscounts((prevState) => {
      if (prevState.indexOf(+value) > -1) {
        return prevState.filter((roleId) => roleId !== +value);
      }
      return [...prevState, +value];
    });
  }, []);

  const handleSubmit = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();

      const userDiscounts = currentUser.discounts.map((el) => el.id);
      const assignIds = asignDiscounts.filter(
        (id) => userDiscounts.indexOf(id) === -1
      );
      const unassignIds = userDiscounts.filter(
        (id) => asignDiscounts.indexOf(id) === -1
      );

      const actionResponse = await dispatch(
        assignToUser({
          userId: currentUser.id,
          assignIds,
          unassignIds,
        })
      );

      unwrapResult(actionResponse);

      setIsLoading(false);
      setIsSuccess(true);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Paper className={cn(classes.container, className)}>
      <Typography
        className={classes.title}
        variant="h5"
        id="tableTitle"
        component="h3"
      >
        {t('discounts.assignToUser')}
      </Typography>
      <SearchAutocomplete
        className={classes.search}
        onSearch={handleSearchByName}
        onChange={handleSetCutrrentUser}
        size="medium"
        label={t('users.searchByName')}
        variant="standard"
        getOptionLabel={(option) =>
          `${option.name} ${option.nickname ? `(${option.nickname})` : ''}`
        }
        getOptionSelected={(option, value) => option.name === value.name}
        filterOptions={(opts, state) =>
          matchSorter(opts, state.inputValue, ['name', 'nickname'])
        }
        inputValue={inputValue}
      />
      {currentUser && (
        <div className={classes.user}>
          <Typography
            className={classes.title}
            variant="h5"
            id="tableTitle"
            component="div"
          >
            {currentUser.name}
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            {dzDiscounts.length > 0 && (
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                  {t('discounts.dzDiscounts')}
                </FormLabel>
                <FormGroup className={classes.formGroup}>
                  {dzDiscounts.map((discount) => (
                    <FormControlLabel
                      key={discount.id}
                      control={
                        <Checkbox
                          checked={asignDiscounts.indexOf(discount.id) > -1}
                          value={discount.id}
                          onChange={toogleRoles}
                        />
                      }
                      label={discount.inPercents || discount.asGift}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            )}
            {tunnelDiscounts.length > 0 && (
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                  {t('discounts.tunnelDiscounts')}
                </FormLabel>
                <FormGroup className={classes.formGroup}>
                  {tunnelDiscounts.map((discount) => (
                    <FormControlLabel
                      key={discount.id}
                      control={
                        <Checkbox
                          checked={asignDiscounts.indexOf(discount.id) > -1}
                          value={discount.id}
                          onChange={toogleRoles}
                        />
                      }
                      label={discount.inPercents || discount.asGift}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            )}
            {otherDiscounts.length > 0 && (
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                  {t('discounts.otherDiscounts')}
                </FormLabel>
                <FormGroup className={classes.formGroup}>
                  {otherDiscounts.map((discount) => (
                    <FormControlLabel
                      key={discount.id}
                      control={
                        <Checkbox
                          checked={asignDiscounts.indexOf(discount.id) > -1}
                          value={discount.id}
                          onChange={toogleRoles}
                        />
                      }
                      label={discount.inPercents || discount.asGift}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            )}
            <div className={classes.controls}>
              <Button
                className={classes.control}
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('discounts.assign')}
              </Button>
              {error && typeof error === 'string' && (
                <Typography variant="body1" color="error" component="div">
                  {error}
                </Typography>
              )}
            </div>
            {isLoading && <PageLoader />}
          </form>
        </div>
      )}
    </Paper>
  );
};

AssignDiscount.propTypes = {
  className: PropTypes.string,
};

AssignDiscount.defaultProps = {
  className: '',
};

export default AssignDiscount;
