import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Typography,
  Tabs, Tab, Box,
} from '@material-ui/core';
import TabPanel from '../../components/tab-panel/TabPanel';
import CompetenceDialog from './components/competence-dialog/CompetenceDialog';
import CompetencesTable from './components/competences-table/CompetencesTable';
import AssignCompetence from './components/assign-competence/AssignCompetence';
import SearchUsers from './components/search-users/SearchUsers';
import useStyles from './styles';

function a11yProps(index) {
  return {
    id: `competences-tab-${index}`,
    'aria-controls': `competences-tabpanel-${index}`,
  };
}

export default function EnhancedTable() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [isDialog, setIsDialog] = useState(false);

  const openDialog = useCallback(() => {
    setIsDialog(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialog(false);
  }, []);

  return (
    <main className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.title} variant="h5" id="tableTitle" component="h2">
          {t('dictionaries.competences')}
        </Typography>
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={openDialog}
        >
          {t('dictionaries.create')}
        </Button>
      </Box>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="off"
        aria-label="scrollable auto tabs example"
      >
        <Tab
          classes={{ root: classes.tab, selected: classes.selected }}
          disableRipple
          label={t('competences.table')}
          {...a11yProps(0)}
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.selected }}
          disableRipple
          label={t('competences.assignToUser')}
          {...a11yProps(1)}
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.selected }}
          disableRipple
          label={t('competences.searchByCompetence')}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <CompetencesTable />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <AssignCompetence />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <SearchUsers />
      </TabPanel>
      {isDialog && <CompetenceDialog isOpen={isDialog} onClose={closeDialog} mode="create" />}
    </main>
  );
}
