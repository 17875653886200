/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { rolesApi } from '../api/api';

export const getAll = createAsyncThunk(
  'roles/getAll',
  async (data, { signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await rolesApi.getAll({ cancelToken: source.token });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const create = createAsyncThunk(
  'roles/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await rolesApi.create(data);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const edit = createAsyncThunk(
  'roles/edit',
  async (data, { rejectWithValue }) => {
    try {
      await rolesApi.edit(data.id, data);

      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const remove = createAsyncThunk(
  'roles/remove',
  async (id, { rejectWithValue }) => {
    try {
      await rolesApi.delete(id);

      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const manageUserRoles = createAsyncThunk(
  'roles/manageUserRoles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await rolesApi.manageUserRoles(
        data.userId.toString(),
        {
          assign: {
            role_ids: data.assignIds,
          },
          unassign: {
            role_ids: data.unassignIds,
          },
          location_id: data.locationId.toString(),
        },
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

const roles = createSlice({
  name: 'roles',
  initialState: {
    all: [],
    error: '',
    loading: false,
    isSuccess: false,
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.all = action.payload;
    },
    [getAll.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
      state.loading = false;
    },
    [create.fulfilled]: (state, action) => {
      state.all = [...state.all, action.payload];
      state.isSuccess = true;
    },
    [create.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [edit.fulfilled]: (state, action) => {
      state.all = state.all.map((el) => {
        if (el.id === action.payload.id) {
          return {
            ...el,
            ...action.payload,
          };
        }

        return el;
      });
      state.isSuccess = true;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [remove.fulfilled]: (state, action) => {
      state.all = state.all.filter((el) => el.id !== action.payload.id);
    },
    [remove.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
  },
});

export const { resetSuccess, resetError } = roles.actions;

export default roles.reducer;
