import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import PageLoader from '../../../../components/page-loader/PageLoader';
import { generateOrders } from '../../../../slices/agents.slice';
import { formatDouble } from '../../../../utils/number.utils';
import AgentsSelectors from '../../../../selectors/agents.selectors';
import LocationsSelectors from '../../../../selectors/locations.selectors';
import { download } from '../../../../utils/documents.utils';
import { FLIGHT_TYPES } from '../../../../constants/constants';
import useStyles from './styles';

const OrdersGenerationDialog = (props) => {
  const { open, onClose, agentId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productSets = useSelector(AgentsSelectors.productSets);
  const locations = useSelector(LocationsSelectors.all);
  const agent = useSelector((state) => AgentsSelectors.getAgentById(state, agentId));

  const agentDiscount = agent.discounts && agent.discounts[0]?.inPercents;

  const { control, handleSubmit, errors, watch, formState } = useForm();

  const productsSet = watch('productSet', null);

  const onSubmit = useCallback(
    async (values) => {
      const response = await dispatch(
        generateOrders({
          ...values,
          quantity: +values.quantity,
          productSetId: values.productSet.id,
          locationId: values.locationObj.id,
          agentId,
        })
      );

      if (values.csv && !response.error) {
        const blob = new Blob([response.payload.data], { type: 'text/csv' });
        download(blob, `codes_for_agent_${agentId}.csv`);
      }

      if (!response.error) {
        onClose();
      }
    },
    [dispatch, agentId, onClose]
  );

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <FormDialog onClose={onClose} isOpen={open} title={t('orders')} onSubmit={memoSubmit}>
      <DialogContent className={classes.content}>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="location">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('location')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={null}
            id="location"
            name="locationObj"
            required
            size="small"
            onChange={([, value]) => value}
            options={locations}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('location')}
                helperText={errors.location && errors.location.message}
                error={!!errors.location}
                margin="dense"
                required
                autoComplete="nope"
                inputProps={{
                  ...params.inputProps,
                  autocomplete: 'nope',
                }}
              />
            )}
            rules={{
              validate: (value) => value || t('requiredField'),
            }}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="products">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('agents.orderTemplate')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={null}
            id="productSet"
            name="productSet"
            size="small"
            onChange={([, value]) => value}
            options={productSets}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('agents.orderTemplate')}
                helperText={errors.productSet && errors.productSet.message}
                error={!!errors.productSet}
                margin="dense"
                required
              />
            )}
            rules={{
              validate: (value) => value || t('requiredField'),
            }}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="products">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('agents.flightType')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={(controllerProps) => (
              <FormControl className={classes.formControl} {...controllerProps}>
                <InputLabel id="flightTypeId">{t('agents.flightType')}</InputLabel>
                <Select id="flightTypeId" label={t('agents.flightType')} {...controllerProps}>
                  {Object.values(FLIGHT_TYPES).map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <ErrorMessage errors={errors} name="flightType">
                  {({ message }) => <FormHelperText>{message}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
            )}
            control={control}
            className={classes.input}
            rules={{
              validate: (value) => (!!value && value !== 'none') || t('requiredField'),
            }}
            required
            onChange={([selected]) => selected.target.value}
            name="flightType"
            id="flightType"
            defaultValue=""
            fullWidth
            margin="dense"
            variant="outlined"
            error={!!errors.flightType}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="profit">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('quantity')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            inputProps={{
              min: 0,
            }}
            defaultValue=""
            id="quantity"
            name="quantity"
            variant="outlined"
            placeholder={t('quantity')}
            margin="dense"
            type="number"
            fullWidth
            required
          />
        </div>
        <div className={classes.row}>
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="csv"
            name="csv"
            label={t('generateCSV')}
          />
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="sendToUser"
            name="sendToUser"
            label={t('sendOnEmail')}
          />
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" type="submit">
          {t('generate')}
        </Button>
        {productsSet && (
          <div className={classes.price}>
            <Typography
              className={classes.totalLabel}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.totalPrice')}: `}
            </Typography>
            <Typography
              className={classes.totalValue}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {agentDiscount
                ? `${formatDouble(
                    productsSet.price * (1 - agentDiscount / 100),
                    2
                  )} (-${agentDiscount}%)`
                : formatDouble(productsSet.price, 2)}
            </Typography>
          </div>
        )}
      </DialogActions>
    </FormDialog>
  );
};

OrdersGenerationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  agentId: PropTypes.number.isRequired,
};

OrdersGenerationDialog.defaultProps = {
  open: false,
};

export default OrdersGenerationDialog;
