import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NavDropdown from './NavDropdown';
import useStyles from './styles';

const NavigationMenu = (props) => {
  const { navMenu } = props;
  const classes = useStyles();

  const location = useLocation();

  const { t } = useTranslation();

  return (
    <List className={classes.list}>
      {navMenu.map((navItem) => {
        if (navItem.nestedList) {
          return (
            <NavDropdown
              key={navItem.titleKey}
              {...navItem}
              isActive={location.pathname.indexOf(navItem.path) > -1}
            />
          );
        }

        const navItemEl = navItem.outLink ? (
          <a className={classes.link} href={navItem.path} target="_blank" rel="noopener noreferrer">
            {t(`nav.${navItem.titleKey}`)}
          </a>
        ) : (
          <NavLink
            className={classes.link}
            activeClassName={classes.activeLink}
            to={`${navItem.path}`}
          >
            {t(`nav.${navItem.titleKey}`)}
          </NavLink>
        );

        return (
          <ListItem
            key={navItem.titleKey}
            classes={{ root: classes.listItem, selected: classes.selected }}
            selected={location.pathname.indexOf(navItem.path) > -1}
          >
            <ListItemText
              classes={{
                root: classes.listTextRoot,
                primary: classes.listText,
              }}
              primary={navItemEl}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

NavigationMenu.propTypes = {
  navMenu: PropTypes.arrayOf(
    PropTypes.shape({
      titleKey: PropTypes.string,
      path: PropTypes.string,
      nestedList: PropTypes.arrayOf(
        PropTypes.shape({
          titleKey: PropTypes.string,
          path: PropTypes.string,
        })
      ),
    })
  ),
};

NavigationMenu.defaultProps = {
  navMenu: [],
};

export default NavigationMenu;
