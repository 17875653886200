import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputFile: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  upload: {
    display: 'inline-block',
  },
  fileUploadText: {
    lineHeight: 1,
  },
  fileUploadLabel: {
    flex: 1,
  },
  fileUpload: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginRight: theme.spacing(1),

    '&:last-child': {
      marginRight: 1,
    },
  },
  fileUploadValue: {
    width: '100%',
    height: 150,
    objectFit: 'contain',
  },
  emptyFile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 150,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[200],
  },
  label: {
    flexShrink: 0,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
      marginBottom: 0,
    },
  },
  labelError: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 20,
    },
  },
  delete: {
    padding: 0,
  },
  row: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    listStyle: 'none',
  },
  listItem: {
    position: 'relative',
    padding: '2px 0 4px',
    marginRight: theme.spacing(2),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  timeTable: {
    alignSelf: 'center',
  },
  removeButton: {
    position: 'absolute',
    right: -15,
    top: -11,
  },
  removeIcon: {
    width: 13,
    height: 13,
  },
  multipleInputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    minWidth: 210,
    margin: 0,
  },
  inputLabel: {
    whiteSpace: 'nowrap',
  },
  checkbox: {
    padding: 0,
  },
  radios: {
    flexDirection: 'row',
  },
  success: {
    color: theme.palette.success.main,
  },
}), {
  name: 'DropZoneDialog',
});
