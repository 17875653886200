import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  containerIsOut: {
    '&:hover': {
      backgroundColor: fade('#E9EAF3', 0.2),
    },

    '&:hover $selectIcon': {
      opacity: 0.5,
    },
  },
  containerIsSelected: {
    '& $selectIcon': {
      opacity: 1,
    },

    '&:hover $selectIcon': {
      opacity: 1,
    },
  },
  selectIcon: {
    opacity: 0,
    color: theme.palette.secondary.main,
  },
  message: {
    position: 'relative',
    alignSelf: 'baseline',
    minWidth: 115,
    borderRadius: 12,
    padding: theme.spacing(1),
    marginTop: 5,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '85%',
  },

  messageIn: {
    backgroundColor: '#F5F5F6',
    // marginLeft: 45,

    // '&$grouped': {
    //   marginLeft: 5,
    // },

    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      left: -10,
      height: 21,
      width: 10,
      background: theme.palette.background.paper,
      borderBottomRightRadius: 10,
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      bottom: 0,
      left: -7,
      height: 20,
      width: 20,
      background: '#F5F5F6',
      borderBottomRightRadius: 15,
    },
  },

  messageOut: {
    marginLeft: 'auto',
    backgroundColor: '#E9EAF3',

    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      right: -10,
      width: 10,
      height: 20,
      background: theme.palette.background.paper,
      borderBottomLeftRadius: 10,
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      bottom: 0,
      right: -8,
      height: 20,
      width: 20,
      background: '#E9EAF3',
      borderBottomLeftRadius: 15,
    },
  },

  grouped: {
    '&:after': {
      content: 'none',
    },

    '&:before': {
      content: 'none',
    },
  },

  messageText: {
    color: theme.palette.grey[700],
  },

  messageTime: {
    color: theme.palette.grey[500],
    fontSize: '0.65rem',
    marginRight: 2,
  },
  icon: {
    color: theme.palette.grey[500],
    fontSize: '0.85rem',
  },
  readIcon: {
    color: theme.palette.success.light,
  },
  info: {
    display: 'flex',
    position: 'relative',
    bottom: 2,
    right: -1,
    height: 7,
    marginLeft: 'auto',
  },
}),
{
  name: 'Message',
});
