import React from 'react';
import {
  Table, TableBody, List, Divider,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Message from '../message/Message';
import useStyles from './styles';


const Sent = () => {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');

  if (matches) {
    return (
      <Table
        className={classes.table}
        aria-labelledby="messages"
        aria-label="message table"
      >
        <colgroup>
          <col className={classes.col} />
          <col className={classes.col} />
          <col className={classes.col} />
        </colgroup>
        <TableBody>
          <Message isSent name="Michael" title="This message about this title" time="16:30" />
          <Message isSent name="Michael" title="This message about this title" time="16:30" />
          <Message isSent name="Michael" title="This message about this title" time="16:30" />
        </TableBody>
      </Table>
    );
  }

  return (
    <List>
      <Message isSent name="Michael" title="This message about this title" time="16:30" />
      <Divider />
      <Message isSent name="Michael" title="This message about this title" time="16:30" />
      <Divider />
    </List>
  );
};

export default Sent;
