import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Toolbar,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import PageLoader from '../../../components/page-loader/PageLoader';
import DateRange from '../../../components/date-range/DateRange';
import OrdersNumberChart from './OrdersNumberChart';
import OrdersPriceChart from './OrdersPriceChart';
import OrdersStatusChart from './OrdersStatusChart';
import StatCard from './StatCard';
import { getOrdersByDomain as getOrders } from '../../../slices/shops.slice';
import ShopsSelectors from '../../../selectors/shops.selectors';
import { TIME_RANGE } from '../constants';
import { getTimeRange, isDataUnderRange } from '../utils';
import useStyles from './styles';

function Stats(props) {
  const { shop } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const orders = useSelector((state) => ShopsSelectors.getOrdersByDomain(state, shop));
  const loadingOrders = useSelector(ShopsSelectors.loading);

  useEffect(() => {
    dispatch(getOrders(shop));
  }, [dispatch, shop]);

  const [range, setRange] = useState(TIME_RANGE.week);

  const handleSetRange = (e, value) => {
    setRange(e.target.value);
  };

  const [customRange, setCustomRange] = useState({
    from: new Date(),
    to: new Date(),
  });

  const handleSetCustomRange = (from, to) => {
    setCustomRange({
      from,
      to,
    });
  };

  const rangeLength = getTimeRange(range, customRange.from, customRange.to).length;

  const ordersFilteredByTimeRange = useMemo(
    () =>
      orders.filter((order) =>
        isDataUnderRange(range, new Date(order.createdAt), customRange.from, customRange.to)
      ),
    [orders, range, customRange]
  );

  const ordersCount = ordersFilteredByTimeRange.length;
  const ordersSum = ordersFilteredByTimeRange.reduce((sum, order) => sum + order.totalPrice, 0);

  return (
    <main className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h5" id="tableTitle" component="h2">
          {t('shop.stats')}
        </Typography>
        <div className={classes.controls}>
          <FormControl className={classes.control} variant="outlined">
            <InputLabel id="flightTypeId">{t('range')}</InputLabel>
            <Select id="locationId" label={t('range')} onChange={handleSetRange} value={range}>
              {Object.values(TIME_RANGE).map((range) => (
                <MenuItem value={range} key={range}>
                  {t(`shop.${range}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {range === TIME_RANGE.custom && (
            <DateRange
              placeholderFrom={t('from')}
              placeholderTo={t('to')}
              onChange={handleSetCustomRange}
            />
          )}
        </div>
      </Toolbar>
      <Box className={classes.content}>
        {!loadingOrders && (
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <StatCard title={t('shop.ordersNumber')} value={ordersCount} />
            </Grid>
            <Grid item xs={6} md={3}>
              <StatCard title={t('shop.soldOn')} value={`${ordersSum} zł`} />
            </Grid>
            <Grid item xs={6} md={3}>
              <StatCard
                title={t('shop.averageOrderPrice')}
                value={`${(ordersSum / ordersCount || 0).toFixed(2)} zł`}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <StatCard
                title={t('shop.averageSoldDay')}
                value={`${(ordersSum / rangeLength || 0).toFixed(2)} zł`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OrdersNumberChart
                orders={ordersFilteredByTimeRange}
                timeRange={range}
                customRange={customRange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OrdersPriceChart
                orders={ordersFilteredByTimeRange}
                timeRange={range}
                customRange={customRange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OrdersStatusChart orders={ordersFilteredByTimeRange} />
            </Grid>
          </Grid>
        )}

        {loadingOrders && <PageLoader />}
      </Box>
    </main>
  );
}

export default Stats;
