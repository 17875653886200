import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import useStyles from './styles';

const FileUpload = (props) => {
  const {
    onChange, label, id, multiple,
  } = props;
  const classes = useStyles();

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.files);
    }
  };

  return (
    <label className={classes.label} htmlFor={id}>
      <AttachFileIcon />
      <Typography className={classes.fileText}>{label}</Typography>
      <input className={classes.fileInput} type="file" id={id} onChange={handleChange} multiple={multiple} />
    </label>
  );
};


FileUpload.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
};

FileUpload.defaultProps = {
  onChange: null,
  multiple: true,
};

export default FileUpload;
