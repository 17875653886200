import React, {
  useEffect, useState, useCallback, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import useStyles from './styles';

const ChatInput = forwardRef((props, ref) => {
  const { onSend, value, onEdit } = props;
  const classes = useStyles();

  const [message, setMessage] = useState('');

  useEffect(() => {
    if (value) {
      setMessage(value);
    }
  }, [value]);

  useEffect(() => {
    const handleSendOnEnter = (e) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        setMessage('');
        onSend(message);
      }
    };

    document.addEventListener('keypress', handleSendOnEnter);

    return () => {
      document.removeEventListener('keypress', handleSendOnEnter);
    };
  }, [onSend, message]);

  const handleMessageSet = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  const handleSend = () => {
    if (value && onEdit) {
      onEdit(message);
    } else {
      onSend(message);
    }
    setMessage('');
  };

  return (
    <TextField
      className={classes.chatInput}
      multiline
      variant="outlined"
      onChange={handleMessageSet}
      InputProps={{
        endAdornment: (
          <IconButton size="small" onClick={handleSend}>
            <SendIcon />
          </IconButton>
        ),
        className: classes.inputRoot,
      }}
      size="small"
      value={message}
      ref={ref}
    />
  );
});

ChatInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  value: PropTypes.string,
  onEdit: PropTypes.func,
};

ChatInput.defaultProps = {
  value: '',
  onEdit: null,
};

export default ChatInput;
