import React, { useState, useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../components/tab-panel/TabPanel';
import PaymentRequestList from './components/payment-requests-list/PaymentRequestsList';
import PaymentRequestHistory from './components/payment-requests-history/PaymentRequestsHistory';
import PageLoader from '../../components/page-loader/PageLoader';
import {
  getAll as getAllPaymentRequests,
  getRevolutAccount,
} from '../../slices/paymentRequests.slice';
import { getAll as getAllLocations } from '../../slices/locations.slice';
import PaymentRequestsSelectors from '../../selectors/paymentRequests.selectors';
import LocationsSelectors from '../../selectors/locations.selectors';
import { formatMoney } from '../../utils/number.utils';
import useStyles from './styles';

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const {
    onSelectSearchResult,
    locations,
    onLocationChange,
    users,
    currentLocation,
  } = props;
  const { t, i18n } = useTranslation();

  const revolutAccounts = useSelector(PaymentRequestsSelectors.revolutAccounts);

  const handleLocationChange = useCallback(
    (e, value) => {
      onLocationChange(value);
    },
    [onLocationChange]
  );

  const handleSearchChange = useCallback(
    (e, value) => {
      onSelectSearchResult(value);
    },
    [onSelectSearchResult]
  );

  return (
    <Toolbar className={cn(classes.toolbar)}>
      <div className={classes.row}>
        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          component="h2"
        >
          {t('paymentRequests.title')}
        </Typography>
      </div>

      <div className={classes.controls}>
        <Autocomplete
          className={classes.control}
          type="select"
          name="location"
          variant="outlined"
          size="medium"
          options={locations}
          renderOption={(option) => option.name}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={handleLocationChange}
          value={currentLocation}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('location')}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
        <Autocomplete
          className={classes.control}
          type="search"
          name="search"
          variant="outlined"
          size="medium"
          options={users}
          onChange={handleSearchChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('paymentRequests.search')}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>

      <Grid container spacing={2}>
        {revolutAccounts.map((el) => (
          <Grid item xs={6} sm={3} xl={2} key={el.id}>
            <Card className={classes.card}>
              <CardContent>
                <Typography color="textPrimary" variant="h6">
                  {formatMoney(el.balance, i18n.language, el.currency)}
                </Typography>
                <Box display="flex">
                  <Typography color="textPrimary">{el.currency}</Typography>
                  <Typography
                    className={classes.accountStatus}
                    color="textSecondary"
                  >
                    {t(el.state)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  onSelectSearchResult: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  onLocationChange: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.string),
  currentLocation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

EnhancedTableToolbar.defaultProps = {
  locations: [],
  onLocationChange: null,
  users: [],
  currentLocation: null,
};

const PaymentRequests = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = useCallback((e, value) => {
    setActiveTab(value);
  }, []);

  const locations = useSelector(LocationsSelectors.all);

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState('false');

  useEffect(() => {
    const getInfo = async () => {
      const request = [
        dispatch(getAllLocations()),
        dispatch(getRevolutAccount()),
      ];

      setIsLoading(true);
      await Promise.all(request);
      setIsLoading(false);
    };

    getInfo();
  }, [dispatch]);

  const [currentLocation, setCurrentLocation] = useState();

  const paymentRequests = useSelector((state) =>
    PaymentRequestsSelectors.getRequestsByLocationId(state, currentLocation?.id)
  );

  useEffect(() => {
    if (currentLocation) {
      dispatch(getAllPaymentRequests(currentLocation.id));
    }
  }, [dispatch, currentLocation]);

  useEffect(() => {
    const locationId = +query.get('location_id');

    if (locationId) {
      let locationEl = null;

      locations.forEach((el) => {
        if (el.id === locationId) {
          locationEl = el;
        }
      });

      setCurrentLocation(locationEl);
    }
  }, [query, locations]);

  const handleCurrentLocationChange = useCallback((newLocation) => {
    setCurrentLocation(newLocation);
  }, []);

  const users = useMemo(
    () => [...new Set(paymentRequests.map((el) => el.name))],
    [paymentRequests]
  );

  const [userNameSearch, setUserNameSearch] = useState(null);

  const handeUserSearchChange = useCallback((id) => {
    setUserNameSearch(id);
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        locations={locations}
        onLocationChange={handleCurrentLocationChange}
        currentLocation={currentLocation}
        users={users}
        onSelectSearchResult={handeUserSearchChange}
      />
      <Tabs
        classes={{ indicator: classes.indicator }}
        value={activeTab}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons="off"
        aria-label="scrollable auto tabs example"
      >
        <Tab
          classes={{ root: classes.tab, selected: classes.selected }}
          disableRipple
          label={t('paymentRequests.title')}
          {...a11yProps(0)}
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.selected }}
          disableRipple
          label={t('history')}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel className={classes.tabPanel} value={activeTab} index={0}>
        <PaymentRequestList
          searchByUserName={userNameSearch}
          locationId={currentLocation?.id}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={activeTab} index={1}>
        <PaymentRequestHistory />
      </TabPanel>
    </main>
  );
};

export default PaymentRequests;
