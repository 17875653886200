import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'reltive',
  },
  actions: {
    marginTop: 'auto',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  submit: {
    flexShrink: 0,
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
  autocomplete: {
    marginTop: theme.spacing(1),
    marginBottom: 4,
  },
  success: {
    color: theme.palette.success.main,
  },
}), {
  name: 'SalaryDialog',
});
