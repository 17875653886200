import { makeStyles } from '@material-ui/core/styles';
import { ORDER_STATUSES } from '../../constants/orders.constants';

export default makeStyles((theme) => ({
  [ORDER_STATUSES.used]: {
    background: '#c8d7e1',
    color: '#2e4453',
  },
  [ORDER_STATUSES.cancelled]: {
    background: '#e5e5e5',
    color: '#777',
  },
  [ORDER_STATUSES.inProgress]: {
    background: '#c6e1c6',
    color: '#5b841b',
  },
  [ORDER_STATUSES.paymentDelayed]: {
    background: '#f8dda7',
    color: '#94660c',
  },
  [ORDER_STATUSES.sold]: {
    background: '#c6e1c6',
    color: '#5b841b',
  },
  [ORDER_STATUSES.sentToCustomer]: {
    background: '#e5e5e5',
    color: '#777',
  },
  [ORDER_STATUSES.waitingDelivery]: {
    background: '#edafaf',
    color: theme.palette.error.dark,
  },
  [ORDER_STATUSES.notUsed]: {
    background: theme.palette.grey[400],
    color: theme.palette.text.primary,
  },
}));
