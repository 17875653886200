import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import RegulationDialog from './components/regulation-dialog/RegulationDialog';
import PageLoader from '../../components/page-loader/PageLoader';
import SortableTable from '../../components/sortable-table/SortableTable';
import { getAll, remove } from '../../slices/regulations.slice';
import useStyles from './styles';

const getTableColumns = (t, classes) => [
  { id: 'name', disablePadding: true, isSortable: true },
  { id: 'description' },
  {
    id: 'file',
    render: (file) => {
      const openFile = (e) => {
        e.stopPropagation();
        window.open(file);
      };

      if (!file) {
        return null;
      }

      return (
        <a
          className={classes.link}
          href={file}
          target="_blank"
          rel="noopener noreferrer"
          onClick={openFile}
        >
          {t('regulations.fileLink')}
        </a>
      );
    },
  },
];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { numSelected, regulations, onSelectSearchResult, onDelete } = props;
  const { t } = useTranslation();
  const [isCreatDialog, setIsCreateDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsCreateDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsCreateDialog(false);
  }, []);

  const handleSearchChange = useCallback(
    (e, value) => {
      onSelectSearchResult(value);
    },
    [onSelectSearchResult]
  );

  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.row}>
        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          component="h2"
        >
          {t('regulations.title')}
        </Typography>
      </div>

      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography
              className={classes.selectedTitle}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {`${numSelected} ${t('users.selected')}`}
            </Typography>

            <Tooltip title={t('delete')}>
              <IconButton
                className={classes.selectedButton}
                aria-label={t('delete')}
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className={classes.controls}>
        <Autocomplete
          className={classes.control}
          type="search"
          name="search"
          variant="outlined"
          size="medium"
          options={regulations}
          renderOption={(option) => option.name}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={handleSearchChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('search')}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={openCreateDialog}
        >
          {t('salary.create')}
        </Button>
      </div>
      {isCreatDialog && (
        <RegulationDialog
          isOpen={isCreatDialog}
          handleClose={closeCreateDialog}
        />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  regulations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onSelectSearchResult: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { regulations } = useSelector((state) => state);
  const { t } = useTranslation();

  const tableColumns = useMemo(() => getTableColumns(t, classes), [t, classes]);

  useEffect(() => {
    const promise = dispatch(getAll());

    return () => {
      promise.abort();
    };
  }, [dispatch]);

  const [selected, setSelected] = useState([]);
  const [searchResult, setSearchResult] = useState(null);

  const handleDeleteSelected = useCallback(() => {
    selected.forEach((id) => {
      dispatch(remove(id));
    });
    setSelected([]);
  }, [dispatch, selected]);

  const hendleSetSearchResult = useCallback((value) => {
    setSearchResult(value);
  }, []);

  const [isDialog, setIsDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dialogMode, setDialogMode] = useState('');

  const openDialog = useCallback((data, mode) => {
    setIsDialog(true);
    setDialogData(data);
    setDialogMode(mode);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialog(false);
    setDialogData(null);
    setDialogMode('');
  }, []);

  const handleDelete = useCallback(
    async (data) => {
      await dispatch(remove(data.id));
    },
    [dispatch]
  );

  if (regulations.loading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        regulations={regulations.all}
        onSelectSearchResult={hendleSetSearchResult}
        onDelete={handleDeleteSelected}
      />
      <SortableTable
        data={searchResult ? [searchResult] : regulations.all}
        selected={selected}
        columns={tableColumns}
        translationNamespace="dictionaries"
        onSelect={setSelected}
        onShow={(data) => openDialog(data, 'view')}
        onEdit={(data) => openDialog(data, 'edit')}
        onDelete={handleDelete}
      />
      {isDialog && (
        <RegulationDialog
          isOpen={isDialog}
          handleClose={closeDialog}
          data={dialogData}
          mode={dialogMode}
        />
      )}
    </main>
  );
}
