import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogActions, Button, Typography } from '@material-ui/core';
import useStyles from './styles';

const FromDialogActions = React.memo((props) => {
  const {
    onCancel, mode, isSuccess, error, isLoading,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <DialogActions className={classes.actions}>
      {mode !== 'view' && (
        <>
          {isSuccess && <Typography className={classes.success} component="span" variant="body1">{mode === 'edit' ? t('successEdited') : t('successCreated')}</Typography>}
          {error && typeof error === 'string' && <Typography color="error" component="span" variant="body1">{error}</Typography>}
          <Button className={classes.submit} autoFocus variant="contained" color="primary" type="submit" disabled={isLoading}>
            {mode === 'edit' ? t('save') : t('create')}
          </Button>
          {mode === 'edit' && (
            <Button className={classes.submit} onClick={onCancel} variant="outlined" color="primary">
              {t('cancel')}
            </Button>
          )}
        </>
      )}
    </DialogActions>
  );
});

FromDialogActions.propTypes = {
  mode: PropTypes.oneOf(['create', 'edit', 'view']).isRequired,
  isSuccess: PropTypes.bool,
  error: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

FromDialogActions.defaultProps = {
  isSuccess: false,
  error: '',
  isLoading: false,
};

export default FromDialogActions;
