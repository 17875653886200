import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(3),
    },
  },
  box: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  tabs: {
    marginBottom: -1,
  },
  tab: {
    maxWidth: '100%',
    opacity: 1,
    border: 'solid 1px transparent',
    fontWeight: 600,

    '&$selected': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.grey[200],
      borderBottomColor: 'transparent',
    },
  },
  tabPanel: {
    flex: 1,
    border: `solid 1px ${theme.palette.grey[200]}`,
    // borderTop: 0,
    backgroundColor: theme.palette.common.white,
  },
  selected: {},
  indicator: {
    display: 'none',
  },
}),
{
  name: 'Club',
});
