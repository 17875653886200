import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const IterabbleList = ({ data }) => {
  const classes = useStyles();

  return (
    <ul className={classes.list}>
      {data.map((el, i, array) => {
        if (i === array.length - 1) {
          return (
            <li className={classes.listItem} key={el}>
              <Typography className={classes.value} gutterBottom>
                {el}
              </Typography>
            </li>
          );
        }

        return (
          <li className={classes.listItem} key={el}>
            <Typography className={classes.value} gutterBottom>
              {`${el},`}
            </Typography>
          </li>
        );
      })}
    </ul>
  );
};

IterabbleList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

export default IterabbleList;
