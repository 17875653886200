/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { usersApi } from '../api/api';
import { parseRawUser, parseRawUsers } from '../services/users.services';
import { setNotification } from './notifications.slice';
import { MESSAGES_TYPES } from '../constants/constants';

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (params, { signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await usersApi.get(params, {
        cancelToken: source.token,
      });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const searchByName = createAsyncThunk('users/searchByName', async (name) => {
  try {
    const response = await usersApi.searchByName(name);

    return parseRawUsers(response.data);
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return Promise.reject(err.response.data);
  }
});

export const searchByPhone = createAsyncThunk('users/searchByPhone', async (phone) => {
  try {
    const response = await usersApi.searchByPhone(phone);

    return parseRawUsers(response.data);
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return Promise.reject(err.response.data);
  }
});

export const searchByEmail = createAsyncThunk('users/searchByEmail', async (email) => {
  try {
    const response = await usersApi.searchByEmail(email);

    return parseRawUsers(response.data);
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return Promise.reject(err.response.data);
  }
});

export const create = createAsyncThunk(
  'users/create',
  async (rawData, { rejectWithValue, dispatch }) => {
    try {
      const data = {
        phone: rawData.phone.replace('+', ''),
        name: rawData.name,
        nickname: rawData.nickname,
        email: rawData.email,
        profile_type: rawData.profileType,
        accepted_regulations: rawData.acceptedRegulations,
        accepted_speedfly_regulations: rawData.acceptedSpeedflyRegulations,
        document_number: rawData.documentNumber,
        pesel: rawData.pesel,
        exported: rawData.exported,
        current_balance: rawData.currentBalance,
        birth_date: rawData.birthDate,
        birth_place: rawData.birthPlace,
        weight: rawData.weight,
        height: rawData.height,
        blood: rawData.blood,
        gender: rawData.gender,
      };

      const response = await usersApi.create(data);

      return parseRawUser(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const edit = createAsyncThunk('users/edit', async (rawData, { rejectWithValue }) => {
  try {
    const data = {
      phone: rawData.phone.replace('+', ''),
      name: rawData.name,
      nickname: rawData.nickname,
      email: rawData.email,
      profile_type: rawData.profileType,
      accepted_regulations: rawData.acceptedRegulations,
      accepted_speedfly_regulations: rawData.acceptedSpeedflyRegulations,
      document_number: rawData.documentNumber,
      pesel: rawData.pesel,
      exported: rawData.exported,
      current_balance: rawData.currentBalance,
      birth_date: rawData.birthDate,
      birth_place: rawData.birthPlace,
      weight: rawData.weight,
      height: rawData.height,
      blood: rawData.blood,
      gender: rawData.gender,
    };

    await usersApi.edit(rawData.id, data);

    return rawData;
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const remove = createAsyncThunk('users/remove', async (id) => {
  try {
    await usersApi.remove(id);

    return { id };
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return Promise.reject(err.response.data);
  }
});

export const searchByCompetence = createAsyncThunk(
  'users/searchByCompetence',
  async (data, { rejectWithValue, signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await usersApi.getByCompetence(
        {
          location_id: data.locationId,
          competence_id: data.competenceId,
        },
        {
          cancelToken: source.token,
        }
      );

      return parseRawUsers(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserRoles = createAsyncThunk(
  'users/getUserRoles',
  async (params, { rejectWithValue }) => {
    try {
      const response = await usersApi.getUserRoles(params);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
)

const users = createSlice({
  name: 'users',
  initialState: {
    all: [],
    loading: false,
    count: 0,
    itemsPerPage: 25,
    isSuccess: false,
    error: '',
    searchResult: null,
    profileTypes: [],
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
    setSearchResult: (state, action) => {
      state.searchResult = action.payload;
    },
  },
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getUsers.fulfilled]: (state, action) => {
      state.all = parseRawUsers(action.payload.users);
      state.count = action.payload.count;
      state.itemsPerPage = action.payload.items_per_page;
      state.loading = false;
      state.profileTypes = action.payload.profile_types;
    },
    [getUsers.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.loading = false;
    },
    [create.fulfilled]: (state, action) => {
      state.all.push(action.payload);
      state.isSuccess = true;
    },
    [edit.pending]: (state) => {
      state.error = '';
    },
    [edit.fulfilled]: (state, action) => {
      state.all = state.all.map((el) => {
        if (el.id === action.payload.id) {
          return action.payload;
        }

        return el;
      });
      state.searchResult = state.searchResult.map((el) => {
        if (el.id === action.payload.id) {
          return action.payload;
        }

        return el;
      });
      state.isSuccess = true;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
    [remove.pending]: (state) => {
      state.error = '';
    },
    [remove.fulfilled]: (state, action) => {
      state.all = state.all.filter((el) => el.id !== action.payload.id);
      state.searchResult = state.searchResult.filter((el) => el.id !== action.payload.id);
    },
    [remove.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
  },
});

export const { resetSuccess, resetError, setSearchResult } = users.actions;

export default users.reducer;
