import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    listStyle: 'none',
  },
  listItem: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  removeButton: {
    position: 'absolute',
    right: -15,
    top: -11,
  },
  removeIcon: {
    width: 13,
    height: 13,
  },
  input: {
    margin: 0,
  },
  inputListItem: {
    display: 'flex',
    alignItems: 'center',
  },
}), {
  name: 'ManagerDialog',
});
