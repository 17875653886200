/* eslint-disable import/prefer-default-export */
import { useState, useCallback, useMemo, useEffect } from 'react';

export const usePagination = (length, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
  }, [length, itemsPerPage]);

  const numberOfPages = useMemo(() => Math.ceil(length / itemsPerPage), [
    length,
    itemsPerPage,
  ]);

  const isFirstPage = useMemo(() => currentPage === 0, [currentPage]);

  const isLastPage = useMemo(() => currentPage === numberOfPages, [
    currentPage,
    numberOfPages,
  ]);

  const goToNextPage = useCallback(() => {
    if (!isLastPage) {
      setCurrentPage((prevState) => prevState + 1);
    }
  }, [isLastPage]);

  const goToPrevPage = useCallback(() => {
    if (!isFirstPage) {
      setCurrentPage((prevState) => prevState - 1);
    }
  }, [isFirstPage]);

  const reset = useCallback(() => {
    setCurrentPage(0);
  }, []);

  const setPage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  return {
    currentPage,
    goToNextPage,
    goToPrevPage,
    reset,
    setPage,
    isFirstPage,
    isLastPage,
  };
};
