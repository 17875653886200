/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { locationsApi } from '../api/api';
import { parseLocations, parseLocation } from '../services/locations.services';
import { fromArray } from '../utils/array.utils';

export const getAll = createAsyncThunk(
  'locations/getAll',
  async (data, { signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await locationsApi.getAll({ cancelToken: source.token });

      return parseLocations(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (data, { getState }) => {
      const { locations } = getState();

      if (Object.values(locations.byId).length > 0) {
        return false;
      }

      return true;
    },
  }
);

export const create = createAsyncThunk('locations/create', async (rawData, { rejectWithValue }) => {
  try {
    const data = {
      name: rawData.name,
      dates: rawData.dates.join(','),
      lat: rawData.lat,
      long: rawData.long,
      active: 'false',
      for_cp: rawData.forCP,
      for_checkin: rawData.forCheckin,
      runway_size: rawData.runwaySize,
      working_altitudes: rawData.workingAltitudes ? rawData.workingAltitudes.join(',') : '',
      services: rawData.services ? rawData.services.join(',') : '',
      location_planes_list: rawData.planes ? rawData.planes.join(',') : '',
      radio_frequency: rawData.radioFrequency,
      runway_directions: rawData.runwayDirections,
      atz: rawData.atz,
      icao: rawData.icao,
      info: rawData.info,
      phones: rawData.phones ? rawData.phones.join(',') : '',
      email: rawData.email,
      working_hours: rawData.workingHours,
      address: rawData.address,
      message_info: rawData.messageInfo,
      place_cost: rawData.placeCost,
      commercial_place_cost: rawData.commercialPlaceCost,
      tandem_equipment: rawData.tandemEquipment,
      flights_per_day: rawData.flightsPerDay,
      time_between_flights: rawData.timeBetweenFlights,
      local_ip: rawData.localIp.slice(-1) === '/' ? rawData.localIp.slice(0, -1) : rawData.localIp,
      use_sensors: rawData.useSensors,
      for_shop: rawData.forShop,
      uspa: rawData.uspa,
      currency: rawData.currency,
      locust_profit: rawData.locustProfit,
      moderation_url: rawData.moderationUrl
    };

    if (rawData.mode) {
      data.mode = rawData.mode;
    }

    const response = await locationsApi.create(data);

    return parseLocation(response.data);
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const edit = createAsyncThunk('locations/edit', async (rawData, { rejectWithValue }) => {
  try {
    const data = {
      name: rawData.name,
      dates: rawData.dates.join(','),
      lat: rawData.lat,
      long: rawData.long,
      for_cp: rawData.forCP,
      for_checkin: rawData.forCheckin,
      runway_size: rawData.runwaySize,
      working_altitudes: rawData.workingAltitudes ? rawData.workingAltitudes.join(',') : '',
      services: rawData.services ? rawData.services.join(',') : '',
      location_planes_list: rawData.planes ? rawData.planes.join(',') : '',
      radio_frequency: rawData.radioFrequency,
      runway_directions: rawData.runwayDirections,
      atz: rawData.atz,
      icao: rawData.icao,
      info: rawData.info,
      phones: rawData.phones ? rawData.phones.join(',') : '',
      email: rawData.email,
      working_hours: rawData.workingHours,
      address: rawData.address,
      message_info: rawData.messageInfo,
      place_cost: rawData.placeCost,
      commercial_place_cost: rawData.commercialPlaceCost,
      tandem_equipment: rawData.tandemEquipment,
      flights_per_day: rawData.flightsPerDay,
      time_between_flights: rawData.timeBetweenFlights,
      local_ip: rawData.localIp.slice(-1) === '/' ? rawData.localIp.slice(0, -1) : rawData.localIp,
      use_sensors: rawData.useSensors,
      for_shop: rawData.forShop,
      uspa: rawData.uspa,
      currency: rawData.currency,
      locust_profit: rawData.locustProfit,
      moderation_url: rawData.moderationUrl
    };

    if (rawData.mode) {
      data.mode = rawData.mode;
    }

    await locationsApi.edit(rawData.id, data);

    return rawData;
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const remove = createAsyncThunk('locations/remove', async (data, { rejectWithValue }) => {
  try {
    await locationsApi.delete(data.id, data.codename);

    return { id: data.id };
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

const locations = createSlice({
  name: 'locations',
  initialState: {
    loading: false,
    error: '',
    byId: {},
    isSuccess: false,
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
    setLocations: (state, action) => {
      state.byId = {
        ...state.byId,
        ...fromArray(action.payload, 'id'),
      };
    },
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
    },
    [getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.byId = fromArray(action.payload, 'id');
    },
    [getAll.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
      state.loading = false;
    },
    [create.pending]: (state) => {
      state.error = '';
    },
    [create.fulfilled]: (state, action) => {
      state.byId[action.payload.id] = action.payload;
      state.isSuccess = true;
    },
    [create.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [edit.pending]: (state) => {
      state.error = '';
    },
    [edit.fulfilled]: (state, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [remove.pending]: (state) => {
      state.error = '';
    },
    [remove.fulfilled]: (state, action) => {
      delete state.byId[action.payload.id];
    },
    [remove.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      } else {
        state.error = action.payload;
      }
    },
  },
});

export const { resetSuccess, resetError, setLocations } = locations.actions;

export default locations.reducer;
