import React from 'react';
import { Paper, Typography, Box } from '@material-ui/core';

function StatCard(props) {
  const { title, value } = props;

  return (
    <Paper>
      <Box padding={2}>
        <Typography variant='subtitle2'>{title}</Typography>
        <Typography variant='h5'>{value}</Typography>
      </Box>
    </Paper>
  );
}

export default StatCard;
