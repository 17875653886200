import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import ExpandTableRow from '../../../components/expand-table-row/ExpandTableRow';
import OrderStatus from '../../../components/order-status/OrderStatus';
import EditOrderDialog from './EditOrderDialog';
import SubOrders from './SubOrders';

import { ORDER_PROP_TYPES } from '../../../prop-types/orders.types';
import { getOrderStatusType } from './utils';
import { getOrderTime } from './utils';
import { useModal } from '../../../hooks/useModal.hook';
import useStyles from './Order.styles';

const ExpandedContent = (props) => {
  const { order } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useModal(false);

  return (
    <Box>
      <Box marginBottom={1}>
        <Box display="flex" marginBottom={2}>
          <Typography variant="h6">{`${t('order')} ${order.id}`}</Typography>
          <IconButton
            className={classes.buttonEdit}
            variant="contained"
            color="primary"
            size="small"
            onClick={openEditDialog}
          >
            <EditIcon />
          </IconButton>
        </Box>
        <Box display="flex" marginBottom={1}>
          <Typography className={classes.infoItem} variant="subtitle2">{`${t(
            'shop.customerInfo'
          )}:`}</Typography>
          <Typography
            className={classes.infoItem}
            variant="body1"
          >{`${order.billing.name} ${order.billing.surname}`}</Typography>
          {','}
          <a className={cn(classes.infoItem, classes.link)} href={`tel:${order.billing.phone}`}>
            <Typography variant="body1">{order.billing.phone}</Typography>
          </a>
          {','}
          <a className={cn(classes.infoItem, classes.link)} href={`mailto:${order.billing.email}`}>
            <Typography variant="body1">{order.billing.email}</Typography>
          </a>
        </Box>
        {order.billing.address && (
          <Box display="flex" marginBottom={1}>
            <Typography className={classes.infoItem} variant="subtitle2">{`${t(
              'shop.shipping'
            )}:`}</Typography>
            {order.billing.country && (
              <Typography
                className={classes.infoItem}
                variant="body1"
              >{`${order.billing.country}, `}</Typography>
            )}
            {order.billing.city && (
              <Typography
                className={classes.infoItem}
                variant="body1"
              >{`${order.billing.city}, `}</Typography>
            )}
            {order.billing.zipCode && (
              <Typography
                className={classes.infoItem}
                variant="body1"
              >{`${order.billing.zipCode}, `}</Typography>
            )}
            {order.billing.address && (
              <Typography className={classes.infoItem} variant="body1">
                {order.billing.address}
              </Typography>
            )}
          </Box>
        )}
        {order.isWordpressOrder && order.extra && (
          <Box display="flex" marginBottom={1}>
            <Typography className={classes.infoItem} variant="subtitle2">{`${t(
              'shop.extra'
            )}:`}</Typography>
            <Typography className={classes.infoItem} variant="body1">
              {order.extra}
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" marginBottom={1} flex={1}>
        {order.subOrders?.length > 0 && <SubOrders subOrders={order.subOrders} />}
      </Box>
      {isEditDialogOpen && (
        <EditOrderDialog
          isOpen={isEditDialogOpen}
          onClose={closeEditDialog}
          data={order}
          mode="edit"
        />
      )}
    </Box>
  );
};

ExpandedContent.propTypes = {
  order: ORDER_PROP_TYPES,
};

ExpandedContent.defaultProps = {
  order: {},
};

const Order = (props) => {
  const { order, locationId } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const orderStatus = getOrderStatusType(order);

  return (
    <ExpandTableRow
      colNumber={6}
      mainRowContent={
        <>
          {order.billing && (
            <>
              <TableCell>
                {`#${order.id} ${order.billing.name} ${order.billing.surname}`}
                {order.isWordpressOrder && (
                  <Chip label="WordPress" className={classes.wordPressChip} />
                )}
              </TableCell>
              <TableCell>{getOrderTime(new Date(order.createdAt), t, i18n.language)}</TableCell>
            </>
          )}
          <TableCell>
            <OrderStatus status={orderStatus} translationNameSpace="shop" />
          </TableCell>
          <TableCell>{order.totalPrice}</TableCell>
          <TableCell>{order.gift ? t('shop.gift') : t('shop.standard')}</TableCell>
        </>
      }
      classes={{
        expandRow: classes.expandedTableRow,
      }}
      expandContent={() => <ExpandedContent order={order} locationId={locationId} />}
    />
  );
};

Order.propTypes = {
  order: ORDER_PROP_TYPES,
};

Order.defaultProps = {
  order: {},
};

export default Order;
