import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2),
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  paper: {
    height: '100%',
    maxHeight: 'calc(100% - 64px)',
    position: 'relative',
  },
  page: {
    flex: 1,
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 56px)',

    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 64px)',
      padding: theme.spacing(3),
    },
  },
  header: {
    // flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}),
{
  name: 'ChatPage',
});
