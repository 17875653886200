import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableContainer,
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import TableHead from './components/table-head/TableHead';
import TableBody from './components/table-body/TableBody';
import CreateDialog from './components/create-dialog/CreateDialog';
import AssignRole from './components/assign-role/AssignRole';
import PageLoader from '../../components/page-loader/PageLoader';
import { getAll, remove } from '../../slices/roles.slice';
import useStyles from './styles';

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { numSelected, roles, onSelectSearchResult } = props;
  const { t } = useTranslation();

  const [isCreatDialog, setIsCreateDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsCreateDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsCreateDialog(false);
  }, []);

  const handleSearchChange = useCallback(
    (e, value) => {
      onSelectSearchResult(value);
    },
    [onSelectSearchResult]
  );

  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.row}>
        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          component="h2"
        >
          {t('users.rolesTitle')}
        </Typography>
      </div>

      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography
              className={classes.selectedTitle}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {`${numSelected} ${t('users.selected')}`}
            </Typography>

            {/* <Tooltip title={t('delete')}>
            <IconButton className={classes.sendButton} aria-label={t('delete')} onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip> */}
          </div>
        </div>
      )}
      <div className={classes.controls}>
        <div className={classes.controlWrapper}>
          <Autocomplete
            className={classes.control}
            type="search"
            name="search"
            variant="outlined"
            size="medium"
            options={roles}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={handleSearchChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('search')}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        <Button variant="contained" color="primary" onClick={openCreateDialog}>
          {t('users.createRole')}
        </Button>
      </div>
      {isCreatDialog && (
        <CreateDialog isOpen={isCreatDialog} handleClose={closeCreateDialog} />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // onDelete: PropTypes.func.isRequired,
  onSelectSearchResult: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state);

  useEffect(() => {
    const promise = dispatch(getAll());

    return () => {
      promise.abort();
    };
  }, [dispatch]);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [searchResult, setSearchResult] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roles.all.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    if (selectedIndex > -1) {
      setSelected((prevState) => prevState.filter((current) => current !== id));
    } else {
      setSelected((prevState) => [...prevState, id]);
    }
  };

  const handleDeleteSelected = useCallback(() => {
    selected.forEach((id) => {
      dispatch(remove(id));
    });
    setSelected([]);
  }, [selected, dispatch]);

  const handleSetSearchResult = useCallback((role) => {
    setSearchResult(role);
  }, []);

  if (roles.loading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        onDelete={handleDeleteSelected}
        roles={roles.all}
        onSelectSearchResult={handleSetSearchResult}
      />
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={searchResult ? 1 : roles.all.length}
          />
          <TableBody
            data={searchResult ? [searchResult] : roles.all}
            order={order}
            orderBy={orderBy}
            onClick={handleClick}
            selected={selected}
          />
        </Table>
      </TableContainer>
      <AssignRole className={classes.assignRole} />
    </main>
  );
}
