// eslint-disable-next-line import/prefer-default-export
export const parseOrder = (order) => ({
  id: order.id,
  flightType: order.flight_type,
  gift: order.gift === 'false' ? false : !!order.gift,
  time: order.time,
  ticketType: order.ticket_type,
  totalPrice: +order.total_price,
  extra: order.extra,
  place: order.place,
  passengers: order.passengers,
  payedUbezPod: order.payed_ubez_pod,
  canChooseTime: order.can_choose_time,
  payedNNW: order.payed_nnw,
  firstPrice: order.first_price,
  expired: order.expired,
  sendNotification: order.send_notification,
  createdAt: order.created_at,
  userId: order.user_id,
  externalId: order.external_id,
  flyers: order.flyers || [],
  tbxSend: order.tbx_send,
  billing: order.billing,
  used: order.used,
  cancelled: order.canceled,
  payments: order.payments || [],
  parentId: order.parent_id,
  isWordpressOrder: order.orders_data === '{}' && !order.parent_id,
});

export const parseOrders = (orders) => orders.map((order) => parseOrder(order));
