import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ServerError from './ServerError';

const Errors = () => (
  <Switch>
    <Route path="/errors/server" component={ServerError} />
  </Switch>
);

export default Errors;
