import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';

import SalaryDialog from './components/salary-dialog/SalaryDialog';
import PageLoader from '../../components/page-loader/PageLoader';
import SortableTable from '../../components/sortable-table/SortableTable';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';

import LocationsSelectors from '../../selectors/locations.selectors';
import SalaryRatesSelectors from '../../selectors/salaryRates.selectors';
import { getByLocationId, remove } from '../../slices/salaryRates.slice';
import { getAll as getAllLocations } from '../../slices/locations.slice';
import { getAll as getAllCompetences } from '../../slices/competences.slice';
import useStyles from './styles';

const TABLE_COLUMNS = [
  { id: 'name', disablePadding: true, isSortable: true },
  { id: 'rate', isSortable: true },
  {
    id: 'competences',
    render: (value) => value && value.map((el) => el.name).join(', '),
  },
];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const {
    numSelected,
    locations,
    currentLocation,
    onChangeLocations,
    onSelectSearchResult,
    rates,
    onDelete,
  } = props;
  const { t } = useTranslation();
  const [isCreatDialog, setIsCreateDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsCreateDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsCreateDialog(false);
  }, []);

  const handleLocationChange = useCallback(
    (e, value) => {
      onChangeLocations(value);
    },
    [onChangeLocations]
  );

  const handleSearchChange = useCallback(
    (e, value) => {
      onSelectSearchResult(value);
    },
    [onSelectSearchResult]
  );

  const [isConfirmDialog, setIsConfirmDialog] = useState(null);

  const openConfirmDialog = useCallback(() => {
    setIsConfirmDialog(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setIsConfirmDialog(false);
  }, []);

  const handleDelete = useCallback(() => {
    onDelete();
    closeConfirmDialog();
  }, [onDelete, closeConfirmDialog]);

  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.row}>
        <Typography className={classes.title} variant="h5" id="tableTitle" component="h2">
          {t('salary.title')}
        </Typography>
      </div>

      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography
              className={classes.selectedTitle}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {`${numSelected} ${t('users.selected')}`}
            </Typography>

            <Tooltip title={t('delete')}>
              <IconButton
                className={classes.selectedButton}
                aria-label={t('delete')}
                onClick={openConfirmDialog}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className={classes.controls}>
        <Autocomplete
          className={classes.control}
          size="medium"
          options={locations}
          value={currentLocation}
          onChange={handleLocationChange}
          renderOption={(option) => option.name}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t('salary.dropZone')}
              placeholder={t('salary.dropZone')}
            />
          )}
        />
        <Autocomplete
          className={classes.control}
          type="search"
          name="search"
          variant="outlined"
          size="medium"
          options={rates}
          renderOption={(option) => option.name}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={handleSearchChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('search')}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={openCreateDialog}
        >
          {t('salary.create')}
        </Button>
      </div>
      {isCreatDialog && (
        <SalaryDialog isOpen={isCreatDialog} handleClose={closeCreateDialog} mode="create" />
      )}
      {isConfirmDialog && (
        <ConfirmDialog
          title={t('confirmDelete')}
          description={t('salary.ratesDeleteWarning')}
          agreeButtonText={t('delete')}
          disagreeButtonText={t('cancel')}
          onAgree={handleDelete}
          onDisagree={closeConfirmDialog}
          isOpen={isConfirmDialog}
          onClose={closeConfirmDialog}
        />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  currentLocation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onChangeLocations: PropTypes.func.isRequired,
  onSelectSearchResult: PropTypes.func.isRequired,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
};

EnhancedTableToolbar.defaultProps = {
  currentLocation: null,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { t } = useTranslation();

  const locations = useSelector(LocationsSelectors.all);
  const locationsLoading = useSelector(LocationsSelectors.loading);

  const loadingSalaryRates = useSelector(SalaryRatesSelectors.loading);

  useEffect(() => {
    const locationsPromise = dispatch(getAllLocations());
    const competencesPromise = dispatch(getAllCompetences());

    return () => {
      locationsPromise.abort();
      competencesPromise.abort();
    };
  }, [dispatch]);

  const [currentLocation, setCurrentLocation] = useState(locations[0] || null);

  const handleChangeLocation = useCallback((value) => {
    if (value) {
      setCurrentLocation(value);
    }
  }, []);

  const all = useSelector((state) => {
    if (currentLocation) {
      return SalaryRatesSelectors.getByLocationId(state, currentLocation.id);
    }

    return [];
  });

  useEffect(() => {
    if (locations[0]) {
      setCurrentLocation(locations[0]);
    }
  }, [locations]);

  useEffect(() => {
    if (currentLocation && currentLocation.id) {
      dispatch(getByLocationId(currentLocation.id));
    }
  }, [dispatch, currentLocation]);

  const [isDialog, setIsDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dialogMode, setDialogMode] = useState('');

  const openDialog = useCallback((data, mode) => {
    setIsDialog(true);
    setDialogData(data);
    setDialogMode(mode);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialog(false);
    setDialogData(null);
    setDialogMode('');
  }, []);

  const [selected, setSelected] = useState([]);

  const handleDeleteSelected = useCallback(() => {
    if (currentLocation) {
      selected.forEach((id) => {
        const deletedEl = all.filter((el) => el.id === id)[0];
        if (deletedEl && !deletedEl.disableDelete) {
          dispatch(remove({ id, locationId: currentLocation.id }));
        }
      });
      setSelected([]);
    }
  }, [dispatch, selected, currentLocation, all]);

  const [searchResult, setSearchResult] = useState(null);

  const handleSetSearchResult = useCallback((value) => {
    setSearchResult(value);
  }, []);

  // const [deletingId, setDeletingId] = useState(null);

  // const openConfirmDialog = useCallback((data) => {
  //   setDeletingId(data.id);
  // }, []);

  // const closeConfirmDialog = useCallback(() => {
  //   setDeletingId(null);
  // }, []);

  // const handleDelete = useCallback(() => {
  //   dispatch(remove({ id: deletingId, locationId: currentLocation.id }));
  //   closeConfirmDialog();
  // }, [dispatch, deletingId, closeConfirmDialog, currentLocation]);

  if (locationsLoading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        locations={locations}
        currentLocation={currentLocation}
        onChangeLocations={handleChangeLocation}
        rates={all}
        onSelectSearchResult={handleSetSearchResult}
        onDelete={handleDeleteSelected}
      />
      <SortableTable
        columns={TABLE_COLUMNS}
        data={searchResult ? [searchResult] : all}
        translationNamespace="salary"
        onSelect={setSelected}
        selected={selected}
        onShow={(data) => openDialog(data, 'view')}
        onEdit={(data) => openDialog(data, 'edit')}
        // onDelete={openConfirmDialog}
      />
      {loadingSalaryRates && <PageLoader />}
      {isDialog && (
        <SalaryDialog
          isOpen={isDialog}
          data={dialogData}
          handleClose={closeDialog}
          mode={dialogMode}
        />
      )}
      {/* {!!deletingId && (
        <ConfirmDialog
          title={t('confirmDelete')}
          description={t('salary.rateDeleteWarning')}
          agreeButtonText={t('delete')}
          disagreeButtonText={t('cancel')}
          onAgree={handleDelete}
          onDisagree={closeConfirmDialog}
          isOpen={!!deletingId}
          onClose={closeConfirmDialog}
        />
      )} */}
    </main>
  );
}
