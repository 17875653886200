import React, { useState } from 'react';
import { Switch, Route, Redirect, Link, useRouteMatch, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import ProductSetsForShop from './product-sets/ProductSets';
import ProductsForShop from './products/Products';
import Stats from './stats/Stats';
import Orders from './orders/Orders';
import Invoices from './invoices/Invoices';
import Agents from '../agents/Agents-panel';
import CommunicationPanel from '../communication-panel/CommunicationPanel';
import Errors from '../errors/Errors';
import Header from '../../components/header/Header';
import Sidebar from '../../components/sidebar/Sidebar';
import NavList from '../../components/nav-list/NavList';
import { getShopAdminMenu } from './constants';
import useStyles from './styles';

const ShopContent = () => {
  const { url, path } = useRouteMatch();

  const params = useParams();

  console.log(path, 'path');
  console.log(url, 'url');

  return (
    <Switch>
      <Route path={`${url}/errors`}>
        <Errors />
      </Route>
      <Route path={`${url}/agents`}>
        <Agents />
      </Route>
      <Route path={`${url}/communication-panel`}>
        <CommunicationPanel />
      </Route>
      <Route exact path={`${url}`}>
        <Redirect to={`${url}/orders`} />
      </Route>
      <Route path={`${url}/orders`}>
        <Orders shop={params.domain} />
      </Route>
      <Route path={`${url}/products`}>
        <ProductsForShop />
      </Route>
      <Route path={`${url}/product-sets`}>
        <ProductSetsForShop shop={params.domain} />
      </Route>
      <Route path={`${url}/stats`}>
        <Stats shop={params.domain} />
      </Route>
      <Route path={`${url}/invoices`}>
        <Invoices />
      </Route>
    </Switch>
  );
};

const Shop = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const params = useParams();
  const { url } = useRouteMatch();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navMenu = getShopAdminMenu(url);

  return (
    <>
      <Header onDrawerToggle={handleDrawerToggle} title={params.domain} />
      <Sidebar toggle={handleDrawerToggle} isOpenMobile={mobileOpen}>
        <NavList navMenu={navMenu} />
      </Sidebar>
      <div className={classes.layout}>
        <div className={classes.toolbarOffset} />
        <Link className={classes.link} to="/">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<ArrowBackIosIcon />}
          >
            {t('back')}
          </Button>
        </Link>
        <ShopContent />
      </div>
    </>
  );
};

export default Shop;
