import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, IconButton, Typography, TextField, InputLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

const CourseDialog = (props) => {
  const {
    isOpen, isEditMode, handleClose, data,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, register } = useForm({ defaultValues: data });

  return (
    <Dialog classes={{ paper: classes.paper }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle className={classes.header} disableTypography id="simple-dialog-title">
        <Typography className={classes.title} variant="h5" component="h1">{isEditMode ? t('dictionaries.edit') : t('dictionaries.new')}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography className={classes.labelText} color="textPrimary" variant="subtitle1" component="div">
              {`${t('dictionaries.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="name"
            name="name"
            placeholder={t('dictionaries.name')}
            variant="outlined"
            required
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="type">
            <Typography className={classes.labelText} color="textPrimary" variant="subtitle1" component="div">
              {`${t('dictionaries.type')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="type"
            name="type"
            placeholder={t('dictionaries.type')}
            variant="outlined"
            required
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="type">
            <Typography className={classes.labelText} color="textPrimary" variant="subtitle1" component="div">
              {`${t('dictionaries.description')}: `}
            </Typography>
          </InputLabel>
          <textarea
            className={classes.textarea}
            placeholder={`${t('dictionaries.description')} *`}
            required
            name="description"
            defaultValue=""
            ref={register}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button className={classes.submit} autoFocus onClick={handleClose} variant="contained" color="primary">
          {isEditMode ? t('save') : t('create')}
        </Button>
        {isEditMode && (
          <Button className={classes.submit} autoFocus onClick={handleClose} variant="outlined" color="primary">
            {t('cancel')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

CourseDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
  }),
};

CourseDialog.defaultProps = {
  isOpen: false,
  isEditMode: false,
  data: {},
};

export default CourseDialog;
