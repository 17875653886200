import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Table, TableContainer, Button,
  Toolbar, Typography, IconButton, Tooltip,
  TextField, InputAdornment,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import TableHead from './components/table-head/TableHead';
import TableBody from './components/table-body/TableBody';
import CourseDialog from './components/course-dialog/CourseDialog';
import useStyles from './styles';

function createData(name, type, description) {
  return {
    name, type, description,
  };
}

const ROWS = [
  createData('jump task', 'type', 'description'),
  createData('jump task', 'type', 'description'),
  createData('jump task', 'type', 'description'),
  createData('jump task', 'type', 'description'),
];

const TYPES = ['type 1', 'type 2'];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { numSelected } = props;
  const { t } = useTranslation();
  const [isCreatDialog, setIsCreateDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsCreateDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsCreateDialog(false);
  }, []);


  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.row}>
        <Typography className={classes.title} variant="h5" id="tableTitle" component="h2">
          {t('dictionaries.courses')}
        </Typography>
      </div>

      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography className={classes.selectedTitle} color="inherit" variant="subtitle1" component="div">
              {`${numSelected} ${t('selected')}`}
            </Typography>

            <Tooltip title={t('delete')}>
              <IconButton className={classes.selectedButton} aria-label={t('delete')}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className={classes.controls}>
        <TextField
          className={classes.control}
          type="search"
          placeholder={t('search')}
          name="search"
          variant="outlined"
          InputProps={{
            className: classes.input,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
        <Autocomplete
          multiple
          className={classes.control}
          id="type"
          name="type"
          size="medium"
          options={TYPES}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={t('dictionaries.type')} placeholder={t('dictionaries.type')} />
          )}
        />
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={openCreateDialog}
        >
          {t('dictionaries.create')}
        </Button>
      </div>
      <CourseDialog isOpen={isCreatDialog} handleClose={closeCreateDialog} />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = ROWS.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    if (selectedIndex > -1) {
      setSelected((prevState) => prevState.filter((current) => current !== name));
    } else {
      setSelected((prevState) => [...prevState, name]);
    }
  };

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar numSelected={selected.length} />
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={ROWS.length}
          />
          <TableBody
            data={ROWS}
            order={order}
            orderBy={orderBy}
            onClick={handleClick}
            selected={selected}
          />
        </Table>
      </TableContainer>
    </main>
  );
}
