import React, { useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { findByToken, logout } from '../slices/auth.slice';
import Dashboard from '../modules/dashboard/Dashboard';
import ShopPanel from '../modules/shop-panel/ShopPanel';
import useStyles from './Protected.styles';

const Protected = () => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const checkPermisions = async () => {
      if (
        query.get('client_app') === 'manifest_panel' &&
        query.get('location_id') === '1' &&
        query.get('admin_token')
      ) {
        try {
          const resAction = await dispatch(findByToken({ token: query.get('admin_token') }));
          const response = unwrapResult(resAction);

          if (response.roles && response.roles.filter((role) => role.name === 'super-admin')) {
            history.push(location.pathname);
          } else {
            await dispatch(logout());
            history.push('/login');
          }
        } catch (err) {
          history.push('/login');
        }
      } else if (!auth.isAuth) {
        history.push('/login');
      }
    };
    checkPermisions();
  }, [dispatch, history, auth.isAuth, location, query]);

  return (
    <div className={classes.container}>
      <CssBaseline />
      <Switch>
        <Route path="/shop/:domain" component={ShopPanel} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </div>
  );
};

export default Protected;
