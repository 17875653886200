import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileUpload from '../../../../components/file-upload/FileUpload';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import PageLoader from '../../../../components/page-loader/PageLoader';
import {
  create,
  edit,
  resetSuccess,
  resetError,
} from '../../../../slices/regulations.slice';
import useStyles from './styles';

const RegulationDialog = (props) => {
  const { isOpen, mode, handleClose, data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { regulations } = useSelector((state) => state);

  useEffect(() => {
    let timeout;

    if (regulations.isSuccess) {
      timeout = setTimeout(() => {
        dispatch(resetSuccess());
        handleClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [handleClose, regulations.isSuccess, dispatch]);

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState,
  } = useForm({ defaultValues: data });

  const file = watch('file', data.file || null);

  const handleFileDelete = useCallback(() => {
    setValue('file', null);
  }, [setValue]);

  const onSubmit = useCallback(
    (values) => {
      if (mode === 'edit') {
        dispatch(
          edit({
            id: data.id,
            ...values,
          })
        );
      } else {
        dispatch(create(values));
      }
    },
    [dispatch, data.id, mode]
  );

  const onClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    handleClose();
  }, [dispatch, handleClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      title={t('regulations.regulation')}
      onClose={onClose}
      isOpen={isOpen}
      onSubmit={memoSubmit}
    >
      <DialogContent className={classes.content}>
        <Controller
          as={TextField}
          control={control}
          className={classes.input}
          defaultValue=""
          id="name"
          name="name"
          label={t('regulations.name')}
          variant="standard"
          required
          placeholder={t('regulations.name')}
          margin="dense"
          disabled={mode === 'view'}
        />
        <textarea
          className={classes.textarea}
          placeholder={t('regulations.description')}
          ref={register}
          name="description"
          disabled={mode === 'view'}
        />
        {file && (
          <div className={classes.file} key={file.size}>
            {file.name && (
              <Typography className={classes.fileName}>{file.name}</Typography>
            )}
            {!file.name && (
              <a
                className={classes.link}
                href={file}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('regulations.fileLink')}
              </a>
            )}
            {mode !== 'view' && (
              <IconButton className={classes.delete} onClick={handleFileDelete}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        )}
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {mode !== 'view' && (
          <>
            <Button
              className={classes.submit}
              autoFocus
              variant="contained"
              color="primary"
              type="submit"
            >
              {mode === 'edit' ? t('save') : t('create')}
            </Button>
            {mode === 'edit' && (
              <Button
                className={classes.submit}
                autoFocus
                onClick={onClose}
                variant="outlined"
                color="primary"
              >
                {t('cancel')}
              </Button>
            )}
            <Controller
              as={FileUpload}
              control={control}
              className={classes.input}
              defaultValue=""
              id="file"
              name="file"
              label={t('messages.attach')}
              multiple={false}
              onChange={([event]) => event[0]}
            />
            {regulations.error && typeof regulations.error === 'string' && (
              <Typography color="error" variant="body1" component="div">
                {regulations.error}
              </Typography>
            )}
            {regulations.isSuccess && (
              <Typography
                className={classes.success}
                component="span"
                variant="body1"
              >
                {mode === 'edit' ? t('successEdited') : t('successCreated')}
              </Typography>
            )}
          </>
        )}
      </DialogActions>
    </FormDialog>
  );
};

RegulationDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  mode: PropTypes.string,
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    file: PropTypes.string,
    id: PropTypes.number,
  }),
};

RegulationDialog.defaultProps = {
  isOpen: false,
  mode: 'create',
  data: {},
};

export default RegulationDialog;
