import { createSelector } from 'reselect';

const paymentRequests = (state) => state.paymentRequests;

const byLocationId = createSelector(paymentRequests, (state) => state.byLocationId);

const getByLocationId = createSelector(
  byLocationId,
  (_, id) => id,
  (state, id) => {
    if (!id) {
      return [];
    }

    return state[id] || [];
  }
);

const getRequestsByLocationId = createSelector(
  byLocationId,
  (_, id) => id,
  (state, id) => {
    if (!id) {
      return [];
    }

    return state[id]?.requests || [];
  }
);

const revolutAccounts = createSelector(paymentRequests, (state) => state.revolutAccounts);

const loading = createSelector(paymentRequests, (state) => state.loading);

const history = createSelector(paymentRequests, (state) => state.history);

const PaymentRequestsSelectors = {
  getByLocationId,
  getRequestsByLocationId,
  revolutAccounts,
  loading,
  history,
};

export default PaymentRequestsSelectors;
