export const parseLocation = (location) => ({
  id: location.id,
  active: location.active,
  createdAt: location.created_at,
  currency: location.currency,
  dates: location.dates ? location.dates.split(',') : [],
  lat: +location.lat,
  long: +location.long,
  planes: location.location_planes_list ? location.location_planes_list.split(',') : [],
  mode: location.mode,
  name: location.name,
  runwaySize: location.runway_size,
  services: location.services ? location.services.split(',') : [],
  workingAltitudes: location.working_altitudes ? location.working_altitudes.split(',') : [],
  updatedAt: location.updated_at,
  primed: location.primed,
  primedAmmount: location.primed_ammount,
  forCP: location.for_cp,
  forCheckin: location.for_checkin,
  radioFrequency: location.radio_frequency,
  runwayDirections: location.runway_directions,
  atz: location.atz,
  icao: location.icao,
  email: location.email,
  info: location.info,
  phones: location.phones ? location.phones.split(',') : [],
  workingHours: location.working_hours,
  address: location.address,
  messageInfo: location.message_info,
  placeCost: location.place_cost,
  commercialPlaceCost: location.commercial_place_cost,
  jumpOrganiser: location.jump_organiser,
  flightsPerDay: location.flights_per_day,
  timeBetweenFlights: location.time_between_flights,
  localIp: location.local_ip,
  useSensors: location.use_sensors,
  forShop: location.for_shop,
  uspa: location.uspa,
  locustProfit: location.locust_profit,
  moderationUrl: location.moderation_url
});

export const parseLocations = (locations) => (
  locations.map((location) => parseLocation(location))
);
