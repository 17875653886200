import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    flex: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  toolbar: {
    padding: 0,
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    flexGrow: 1,
  },
  subtitle: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  block: {
    marginBottom: theme.spacing(3),

    '&:last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  codenameInput: {
    width: '100%',
  },
}),
{
  name: 'DropZones',
});
