import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import PaymentRequestsSelectors from '../../../../selectors/paymentRequests.selectors';
import { payWithRevolut } from '../../../../slices/paymentRequests.slice';
import { formatMoney } from '../../../../utils/number.utils';
import useStyles from './styles';

const PaymentRequestDialog = (props) => {
  const { open, onClose, amount, recipientName, id, locationId } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const revolutAccounts = useSelector(PaymentRequestsSelectors.revolutAccounts);

  const { control, handleSubmit, formState } = useForm();

  const onSubmit = useCallback(
    async (values) => {
      const account = revolutAccounts.filter(
        (el) => el.id === values.accountId
      )[0];

      const response = await dispatch(
        payWithRevolut({
          locationId,
          paymentRequestId: id,
          accountId: values.accountId,
          currency: account.currency,
        })
      );

      if (!response.error) {
        onClose();
      }
    },
    [dispatch, id, revolutAccounts, locationId, onClose]
  );

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      onSubmit={memoSubmit}
      title={t('paymentRequests.paymentRequest')}
      maxWidth="sm"
    >
      <DialogContent>
        <Box className={classes.box} display="flex" flexWrap="wrap">
          <Box display="flex" marginRight="15px">
            <Typography
              className={classes.label}
              variant="subtitle1"
              color="textSecondary"
            >{`${t('recipient')}:`}</Typography>
            <Typography variant="subtitle1">{recipientName}</Typography>
          </Box>
          <Box display="flex">
            <Typography
              className={classes.label}
              variant="subtitle1"
              color="textSecondary"
            >{`${t('amount')}:`}</Typography>
            <Typography variant="subtitle1">{amount}</Typography>
          </Box>
        </Box>
        <Box className={classes.box}>
          <InputLabel htmlFor="account">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('paymentRequests.account')}: `}
            </Typography>
          </InputLabel>
          <Controller
            control={control}
            as={(controllerProps) => (
              <FormControl className={classes.formControl} {...controllerProps}>
                <InputLabel id="accountId">
                  {t('paymentRequests.account')}
                </InputLabel>
                <Select
                  id="accountId"
                  label={t('paymentRequests.account')}
                  {...controllerProps}
                >
                  {revolutAccounts?.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      <Box flex="1">
                        <Typography color="textPrimary">
                          {formatMoney(el.balance, i18n, el.currency)}
                        </Typography>
                        {el.name && (
                          <Typography color="textSecondary">
                            {el.name}
                          </Typography>
                        )}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name="accountId"
            id="accountId"
            variant="outlined"
            defaultValue=""
            required
            fullWidth
            margin="dense"
            label={t('paymentRequests.account')}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={formState.isSubmitting}
        >
          {t('pay')}
        </Button>
        <Button variant="outlined" color="primary">
          {t('cancel')}
        </Button>
      </DialogActions>
    </FormDialog>
  );
};

PaymentRequestDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  amount: PropTypes.number,
  recipientName: PropTypes.string,
  id: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

PaymentRequestDialog.defaultProps = {
  open: false,
  onClose: null,
  amount: 0,
  recipientName: '',
};

export default PaymentRequestDialog;
