import { createSelector } from 'reselect';

const locations = (state) => state.locations;
const loading = createSelector(locations, (state) => state.loading);
const byId = createSelector(locations, (state) => state.byId);

const all = createSelector(byId, (state) => Object.values(state));

const relatedLocations = createSelector(all, (state) => state.filter((el) => !el.active));

const locationsForCP = createSelector(all, (state) =>
  state.filter((el) => el.forCP && !el.forCheckin)
);

const locationsForCheckin = createSelector(all, (state) =>
  state.filter((el) => el.forCheckin && !el.forCP)
);

const locationsForShop = createSelector(all, (state) => state.filter((el) => el.forShop));

const uncategorizedLocations = createSelector(all, (state) =>
  state.filter((el) => !el.forCheckin && !el.forCP)
);

const commonLocalizations = createSelector(all, (state) =>
  state.filter((el) => el.forCheckin && el.forCP)
);

const LocationsSelectors = {
  relatedLocations,
  all,
  locationsForCP,
  locationsForCheckin,
  uncategorizedLocations,
  commonLocalizations,
  loading,
  locationsForShop,
  byId,
};

export default LocationsSelectors;
