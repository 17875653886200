export const parseRequest = (request) => ({
  id: request.id,
  accountId: request.account_id,
  createdAt: request.created_at,
  amount: request.amount,
  counterpartyId: request.counterparty_id,
  reference: request.reference,
  requestId: request.request_id,
  updatedAt: request.updated_at,
  revolutAccountId: request.revolut_account_id,
  state: request.state,
  transactionId: request.transaction_id,
});

export const parseRequests = (requests) => (
  requests.map((request) => parseRequest(request))
);
