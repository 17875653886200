import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  tableRowHidden: {
    display: 'flex',
    maxHeight: 0,
    overflow: 'hidden',
    fontSize: 0,
  },
  tableRowExpanded: {
    maxHeight: 1000,
    fontSize: 'initial',
  },
  mainRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}));
