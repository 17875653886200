import { makeStyles } from '@material-ui/core/styles';
import BackgroundImg from '../../assets/images/background.png';
import { drawerWidth } from '../../constants/styles';

export default makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flex: 1,
    },
    toolbar: {
      ...theme.mixins.toolbar,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logo: {
      width: '120px',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      overflowY: 'initial',
      width: drawerWidth,
      backgroundImage: `url(${BackgroundImg})`,
      border: 0,
    },
  }),
  {
    name: 'Sidebar',
  }
);
