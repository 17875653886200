/* eslint-disable no-param-reassign */
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../slices/auth.slice';
import usersReducer from '../slices/users.slice';
import documentsReducer from '../slices/documents.slice';
import locationsReducer from '../slices/locations.slice';
import rolesReducer from '../slices/roles.slice';
import planesReducer from '../slices/planes.slice';
import equipmentReducer from '../slices/equipment.slice';
import productsReducer from '../slices/products.slice';
import salaryRatesReducer from '../slices/salaryRates.slice';
import productSetsReducer from '../slices/productSets.slice';
import discountsReducer from '../slices/discounts.slice';
import regulationsReducer from '../slices/regulations.slice';
import competencesReducer from '../slices/competences.slice';
import notificationsReducer from '../slices/notifications.slice';
import organizationsReducer from '../slices/organizations.slice';
import paymentRequestsReducer from '../slices/paymentRequests.slice';
import agentsReducer from '../slices/agents.slice';
import subcontractorsReducer from '../slices/subcontractors.slice';
import chatsReducer from '../slices/chats.slice';
import ordersReducer from '../slices/orders.slice';
import shopsReducer from '../slices/shops.slice';

const combinedReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  documents: documentsReducer,
  locations: locationsReducer,
  roles: rolesReducer,
  planes: planesReducer,
  equipment: equipmentReducer,
  products: productsReducer,
  salaryRates: salaryRatesReducer,
  productSets: productSetsReducer,
  discounts: discountsReducer,
  regulations: regulationsReducer,
  competences: competencesReducer,
  notifications: notificationsReducer,
  organizations: organizationsReducer,
  paymentRequests: paymentRequestsReducer,
  agents: agentsReducer,
  subcontractors: subcontractorsReducer,
  chats: chatsReducer,
  orders: ordersReducer,
  shops: shopsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout/fulfilled' || action.type === 'auth/resetAuth') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
