import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  DialogContent,
  InputLabel,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import FormDialogActions from '../../../../components/form-dialog/FormDialogActions';
import PageLoader from '../../../../components/page-loader/PageLoader';
import Textarea from '../../../../components/textarea/Textarea';
import {
  create,
  resetSuccess,
  resetError,
} from '../../../../slices/competences.slice';
import useStyles from './styles';

const CompetenceDialog = (props) => {
  const { isOpen, onClose, data, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { competences } = useSelector((state) => state);

  useEffect(() => {
    let timeout;
    if (competences.isSuccess) {
      timeout = setTimeout(() => {
        dispatch(resetSuccess());
        onClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, onClose, competences.isSuccess]);

  const { control, handleSubmit, formState } = useForm({ defaultValues: data });

  const onSubmit = useCallback(
    (values) => {
      dispatch(create(values));
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    onClose();
  }, [dispatch, onClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      onClose={handleClose}
      aria-labelledby="competence-dialog"
      isOpen={isOpen}
      onSubmit={memoSubmit}
      title={t('competence.competence')}
    >
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('competence.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="name"
            name="name"
            variant="outlined"
            required
            placeholder={t('competence.name')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="description">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('competence.description')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Textarea}
            control={control}
            defaultValue=""
            id="description"
            name="description"
            required
            placeholder={t('competence.description')}
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            defaultValue={false}
            id="canHaveStudent"
            name="canHaveStudent"
            required
            label={t('competence.canHaveStudents')}
            disabled={mode === 'view'}
          />
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <FormDialogActions
        mode={mode}
        isSuccess={competences.isSuccess}
        error={competences.error}
        onCancel={handleClose}
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

CompetenceDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    vat: PropTypes.string,
    rate: PropTypes.number,
    id: PropTypes.number,
    locationId: PropTypes.number,
  }),
  mode: PropTypes.string,
};

CompetenceDialog.defaultProps = {
  isOpen: false,
  data: {},
  mode: 'create',
};

export default CompetenceDialog;
