import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OrdersHistory from './OrdersHistory';
import OrdersToolbar from './OrdersToolbar';
import PageLoader from '../../../components/page-loader/PageLoader';

import { filterOrders } from './utils';
import { getOrdersByDomain as getOrders } from '../../../slices/shops.slice';
import ShopsSelectors from '../../../selectors/shops.selectors';
import useStyles from './styles';

const ShopOrders = (props) => {
  const { shop } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders(shop));
  }, [dispatch, shop]);

  const orders = useSelector((state) => ShopsSelectors.getOrdersByDomain(state, shop));
  const formattedOrders = useSelector((state) => ShopsSelectors.formattedOrders(state, shop));
  const loading = useSelector(ShopsSelectors.loading);

  const [activeFilterType, setActiveFilterType] = useState(null);
  const [filterValue, setFilerValue] = useState(null);

  const handleChangeFilter = (filterType, value) => {
    setActiveFilterType(filterType);
    setFilerValue(value);
  };

  const filteredOrders = useMemo(
    () => filterOrders(formattedOrders, activeFilterType, filterValue),
    [filterValue, formattedOrders, activeFilterType]
  );

  if (loading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <OrdersToolbar orders={orders} onFilterChange={handleChangeFilter} shop={shop} />
      <OrdersHistory orders={filteredOrders} />
    </main>
  );
};

export default ShopOrders;
