import React from 'react';
import PropTypes from 'prop-types';
import { Map as GoogleMap, Marker, GoogleApiWrapper } from 'google-maps-react';

const Map = (props) => {
  const {
    google, center, location, name,
  } = props;

  return (
    <GoogleMap
      zoom={14}
      google={google}
      initialCenter={center}
      center={center}
      containerStyle={{
        position: 'static',
        minHeight: 235,
      }}
    >
      <Marker name={name} position={location} />
    </GoogleMap>
  );
};

Map.propTypes = {
  location: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  name: PropTypes.string,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  google: PropTypes.object, // eslint-disable-line
};

Map.defaultProps = {
  name: '',
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAj5Ig78aHdQYx7fCUSlia7wynsfq490HU',
})(Map);
