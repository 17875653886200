import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  summary: {
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(3)}px 0`,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  label: {
    marginRight: theme.spacing(1),
    marginBottom: 0,
    fontSize: '1.14rem',
    whiteSpace: 'nowrap',
  },
  link: {
    textDecoration: 'none',
  },
  value: {
    fontSize: '1.14rem',
    lineHeight: 1.75,
    textAlign: 'left',
  },
  controls: {
    display: 'flex',
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  details: {
    flexDirection: 'column',
  },
  list: {
    listStyle: 'none',
    maxHeight: 150,
    overflow: 'scroll',
    border: '1px solid black',
    padding: theme.spacing(1),
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  button: {
    border: 0,
    background: 'none',
    padding: 5,
  },
  tableContainer: {
    maxHeight: 300,
  },
  body: {
    overflow: 'scroll',
  },
  control: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginRight: 5,
  },
  sold: {
    '& > td': {
      color: theme.palette.success.main,
    },
  },
  used: {
    '& > td': {
      color: theme.palette.error.main,
    },
  },
  legend: {
    listStyle: 'none',
    display: 'flex',
    marginTop: theme.spacing(2),
    marginLeft: 20,
    fontSize: '1rem',
  },
  legendSold: {
    marginRight: theme.spacing(4),
    position: 'relative',

    '&:before': {
      content: '""',
      position: 'absolute',
      left: -16,
      top: 6,
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: theme.palette.success.main,
    },
  },
  legendUsed: {
    position: 'relative',

    '&:before': {
      content: '""',
      position: 'absolute',
      left: -16,
      top: 6,
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: theme.palette.error.main,
    },
  },
  expandRow: {
    backgroundColor: theme.palette.grey[100],
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  orders: {
    order: 2,
    overflow: 'hidden',
  },
  chart: {
    width: 250,
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      width: 250,
      height: 300,
    },
  },
}),
{
  name: 'Agent',
});
