/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { salaryRatesApi } from '../api/api';
import { parseSalaryRates } from '../services/salaryRates.services';

export const getByLocationId = createAsyncThunk(
  'salaryRates/getByLocationId',
  async (locationId, { signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await salaryRatesApi.getAll(locationId, source.token);

      return { locationId, data: parseSalaryRates(response.data) };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const create = createAsyncThunk(
  'salaryRates/create',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        name: rawData.name,
        rate: rawData.rate,
      };

      if (rawData.vat) {
        data.vat = rawData.vat;
      }

      const response = await salaryRatesApi.create(
        rawData.locations,
        rawData.competenceIds,
        data,
      );

      return parseSalaryRates(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const edit = createAsyncThunk(
  'salaryRates/edit',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        name: rawData.name,
        rate: rawData.rate,
      };

      if (rawData.vat) {
        data.vat = rawData.vat;
      }

      await salaryRatesApi.edit(
        rawData.id,
        rawData.locationId.toString(),
        rawData.competenceIds,
        data,
      );

      return rawData;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const remove = createAsyncThunk(
  'salaryRates/remove',
  async (data, { rejectWithValue }) => {
    try {
      await salaryRatesApi.delete(data.id, data.locationId.toString());

      return { id: data.id, locationId: data.locationId };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

const salaryRates = createSlice({
  name: 'salaryRates',
  initialState: {
    byId: {},
    error: '',
    loading: false,
    isSuccess: false,
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: {
    [getByLocationId.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getByLocationId.fulfilled]: (state, action) => {
      state.loading = false;
      state.byId = {
        [action.payload.locationId]: action.payload.data,
      };
    },
    [getByLocationId.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
      state.loading = false;
    },
    [create.pending]: (state) => {
      state.error = '';
    },
    [create.fulfilled]: (state, action) => {
      action.payload.forEach((el) => {
        if (state.byId[el.locationId]) {
          state.byId[el.locationId].push(el);
        } else {
          state.byId = {
            ...state.byId,
            [el.locationId]: [el],
          };
        }
      });
      state.isSuccess = true;
    },
    [create.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [edit.pending]: (state) => {
      state.error = '';
    },
    [edit.fulfilled]: (state, action) => {
      state.byId = {
        ...state.byId,
        [action.payload.locationId]: state.byId[action.payload.locationId].map((el) => {
          if (el.id === action.payload.id) {
            return {
              ...el,
              ...action.payload,
            };
          }

          return el;
        }),
      };
      state.isSuccess = true;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [remove.pending]: (state) => {
      state.error = '';
    },
    [remove.fulfilled]: (state, action) => {
      state.byId = {
        ...state.byId,
        [action.payload.locationId]: state.byId[action.payload.locationId].filter((el) => (
          el.id !== action.payload.id
        )),
      };
    },
    // [remove.rejected]: (state, action) => {
    //   if (action.payload && action.payload.message) {
    //     state.error = action.payload.message;
    //   }
    //   state.error = action.payload;
    // },
  },
});

export const { resetError, resetSuccess } = salaryRates.actions;

export default salaryRates.reducer;
