import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import {
  DialogContent,
  Typography,
  TextField,
  InputLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PageLoader from '../../../../components/page-loader/PageLoader';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import FormDialogActions from '../../../../components/form-dialog/FormDialogActions';
import {
  createType,
  editType,
  resetSuccess,
  resetError,
} from '../../../../slices/documents.slice';
import useStyles from './styles';

const LicenseDialog = (props) => {
  const { isOpen, mode, onClose, data } = props;
  const classes = useStyles();
  const { documents, competences } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    formState,
    setError,
    errors,
  } = useForm({
    defaultValues: {
      ...data,
      competenceIds: data.competences,
    },
  });

  useEffect(() => {
    let timeoutId = null;

    if (documents.isSuccess) {
      timeoutId = setTimeout(() => {
        dispatch(resetSuccess());
        onClose();
      }, 5000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch, documents, onClose]);

  useEffect(() => {
    if (typeof documents.error === 'object') {
      Object.keys(documents.error).forEach((key) => {
        setError(key, '', documents.error[key][0]);
      });
    }
  }, [documents.error, setError]);

  const onSubmit = useCallback(
    async (values) => {
      if (mode === 'edit') {
        await dispatch(
          editType({
            ...values,
            id: data.id,
            competenceIds: values.competenceIds.map((el) => el.id),
          })
        );
      } else {
        await dispatch(
          createType({
            ...values,
            competenceIds: values.competenceIds.map((el) => el.id),
          })
        );
      }
    },
    [dispatch, data.id, mode]
  );

  const handleClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    onClose();
  }, [dispatch, onClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      onClose={handleClose}
      isOpen={isOpen}
      onSubmit={memoSubmit}
      title={t('dictionaries.documentType')}
      aria-labelledby="rating-dialog"
    >
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                disabled: classes.disabled,
              },
            }}
            defaultValue=""
            id="name"
            name="name"
            placeholder={t('dictionaries.name')}
            variant="outlined"
            required
            margin="dense"
            disabled={mode === 'view' || mode === 'edit'}
            helperText={errors.name && errors.name.message}
            error={!!errors.name}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="origin">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.origin')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                disabled: classes.disabled,
              },
            }}
            defaultValue=""
            id="origin"
            name="origin"
            placeholder={t('dictionaries.origin')}
            variant="outlined"
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="competenceIds">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.competences')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={[]}
            id="competenceIds"
            name="competenceIds"
            onChange={([, value]) => value}
            options={competences.all}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('dictionaries.competences')}
              />
            )}
            size="small"
            disabled={mode === 'view'}
            multiple
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="description">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.description')}: `}
            </Typography>
          </InputLabel>
          <textarea
            ref={register}
            className={classes.textarea}
            defaultValue=""
            id="description"
            name="description"
            placeholder={t('dictionaries.description')}
            variant="outlined"
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <FormDialogActions
        mode={mode}
        isSuccess={documents.isSuccess}
        error={documents.error}
        onCancel={handleClose}
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

LicenseDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  mode: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    origin: PropTypes.string,
    type: PropTypes.string,
    competences: PropTypes.arrayOf(PropTypes.number),
  }),
};

LicenseDialog.defaultProps = {
  isOpen: false,
  mode: 'create',
  data: {},
};

export default LicenseDialog;
