import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  file: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
  fileInput: {
    visibility: 'hidden',
    width: 0,
    height: 0,
  },
  fileName: {
    flex: 1,
  },
  fileText: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
  label: {
    color: theme.palette.primary.main,
    alignItems: 'center',
    display: 'flex',
  },
  delete: {
    padding: 0,
  },
}));
