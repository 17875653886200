import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import PaymentRequestDialog from '../payment-request-dialog/PaymentRequestDialog';
import PageLoader from '../../../../components/page-loader/PageLoader';
import { getAll as getAllPaymentRequests } from '../../../../slices/paymentRequests.slice';
import PaymentRequestsSelectors from '../../../../selectors/paymentRequests.selectors';
import useStyles from './styles';

const PaymentRequests = (props) => {
  const { locationId, searchByUserName } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector(PaymentRequestsSelectors.loading);

  const { t } = useTranslation();

  const paymentRequests = useSelector((state) =>
    PaymentRequestsSelectors.getRequestsByLocationId(state, locationId)
  );

  const paymentRequestsFiltered = useMemo(() => {
    if (searchByUserName) {
      return paymentRequests.filter((el) => el.name === searchByUserName);
    }

    return paymentRequests;
  }, [searchByUserName, paymentRequests]);

  useEffect(() => {
    let response;
    if (locationId) {
      response = dispatch(getAllPaymentRequests(locationId));
    }

    return () => {
      if (response) {
        response.abort();
      }
    };
  }, [dispatch, locationId]);

  const [currentRequest, setCurrentRequest] = useState(null);
  const [isPaymentRequestDialog, setIsPaymentRequestDialog] = useState(false);

  const handleOpenPaymentRequestDialog = useCallback((data) => {
    setIsPaymentRequestDialog(true);
    setCurrentRequest(data);
  }, []);

  const handleClosePaymentRequestDialog = useCallback(() => {
    setIsPaymentRequestDialog(false);
    setCurrentRequest(null);
  }, []);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>{t('userName')}</TableCell>
              <TableCell className={classes.cell}>
                {t('currentBalance')}
              </TableCell>
              <TableCell className={classes.cell}>
                {t('paymentRequests.amount')}
              </TableCell>
              <TableCell className={classes.cell}>
                {t('paymentRequests.invoice')}
              </TableCell>
              <TableCell className={classes.cell}>{t('comment')}</TableCell>
              <TableCell className={classes.cell}>{t('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentRequestsFiltered.map((row) => (
              <TableRow key={row.id}>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>
                  {row.currentBalance}
                </TableCell>
                <TableCell className={classes.cell}>{row.amount}</TableCell>
                <TableCell className={classes.cell}>
                  {row.invoice && (
                    <a
                      className={classes.link}
                      href={row.invoice}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {t('paymentRequests.invoice')}
                    </a>
                  )}
                </TableCell>
                <TableCell className={classes.cell}>{row.comment}</TableCell>
                <TableCell className={classes.cell}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenPaymentRequestDialog(row)}
                  >
                    {t('pay')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {paymentRequestsFiltered.length === 0 && (
        <Typography className={classes.info} component="div">
          {t('paymentRequests.noRequests')}
        </Typography>
      )}
      {isPaymentRequestDialog && (
        <PaymentRequestDialog
          open={isPaymentRequestDialog}
          onClose={handleClosePaymentRequestDialog}
          amount={currentRequest.amount}
          recipientName={currentRequest.name}
          id={currentRequest.id}
          locationId={locationId}
        />
      )}
      {loading && <PageLoader />}
    </>
  );
};

PaymentRequests.propTypes = {
  locationId: PropTypes.number,
  searchByUserName: PropTypes.string,
};

PaymentRequests.defaultProps = {
  locationId: null,
  searchByUserName: '',
};

export default PaymentRequests;
