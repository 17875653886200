import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  box: {
    marginBottom: theme.spacing(1),

    '&:last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  actions: {
    justifyContent: 'flex-start',
    padding: '8px 24px',
  },
  label: {
    marginRight: theme.spacing(1),
  },
}),
{
  name: 'PaymentRequestDialog',
});
