import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(1),
  },
  actions: {
    justifyContent: 'flex-start',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
}),
{
  name: 'GroupMessageDialog',
});
