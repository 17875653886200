/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { documentsApi } from '../api/api';
import {
  parseDocumentTypes,
  parseDocumentType,
} from '../services/documents.services';
import { setNotification } from './notifications.slice';
import { MESSAGES_TYPES, MESSAGES } from '../constants/constants';

export const getTypes = createAsyncThunk(
  'documents/getTypes',
  async (data, { signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await documentsApi.getTypes({
        cancelToken: source.token,
      });

      return parseDocumentTypes(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const createType = createAsyncThunk(
  'documents/createType',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        name: rawData.name,
        active: true,
      };

      if (rawData.description) {
        data.description = rawData.description;
      }

      if (rawData.origin) {
        data.origin = rawData.origin;
      }

      const response = await documentsApi.createType(
        data,
        rawData.competenceIds
      );

      return parseDocumentType(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const editType = createAsyncThunk(
  'documents/editType',
  async (rawData, { rejectWithValue }) => {
    try {
      const data = {
        name: rawData.name,
        origin: rawData.origin,
        description: rawData.description,
      };

      await documentsApi.editType(rawData.id, rawData.competenceIds, data);

      return rawData;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteType = createAsyncThunk(
  'documents/deleteType',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await documentsApi.deleteType(id);

      dispatch(
        setNotification({
          message: MESSAGES.successDeleted,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return { id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const documents = createSlice({
  name: 'documents',
  initialState: {
    loading: false,
    error: '',
    types: [],
    isSuccess: false,
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = false;
    },
  },
  extraReducers: {
    [getTypes.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [getTypes.fulfilled]: (state, action) => {
      state.loading = false;
      state.types = action.payload.filter((type) => type.active);
    },
    [getTypes.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.loading = false;
    },
    [createType.pending]: (state) => {
      state.error = '';
    },
    [createType.fulfilled]: (state, action) => {
      state.types = [...state.types, action.payload];
      state.isSuccess = true;
    },
    [createType.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
      state.error = action.payload;
    },
    [editType.pending]: (state) => {
      state.error = '';
    },
    [editType.fulfilled]: (state, action) => {
      state.types = state.types.map((type) => {
        if (type.id === action.payload.id) {
          return {
            ...type,
            ...action.payload,
          };
        }
        return type;
      });
      state.isSuccess = true;
    },
    [editType.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
    [deleteType.pending]: (state) => {
      state.error = '';
    },
    [deleteType.fulfilled]: (state, action) => {
      state.types = state.types.filter((type) => type.id !== action.payload.id);
    },
    [deleteType.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
  },
});

export const { resetSuccess, resetError } = documents.actions;

export default documents.reducer;
