import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';

const SearchAutocomplete = (props) => {
  const {
    className,
    name,
    label,
    onSearch,
    getOptionLabel,
    getOptionSelected,
    onChange,
    variant,
    inputProps,
    multiple,
    ...other
  } = props;

  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = useCallback(
    async (e, value) => {
      try {
        setLoading(true);
        await onSearch(value, setOptions, setError);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err.message);
      }
    },
    [onSearch]
  );

  return (
    <Autocomplete
      className={className}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      onInputChange={handleSearch}
      onChange={onChange}
      name={name}
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
          placeholder={label}
          variant={variant}
          helperText={error}
          error={!!error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      multiple={multiple}
      {...other}
    />
  );
};

SearchAutocomplete.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  inputProps: PropTypes.shape({
    margin: PropTypes.string,
  }),
  name: PropTypes.string,
  multiple: PropTypes.bool,
};

SearchAutocomplete.defaultProps = {
  className: '',
  label: '',
  getOptionLabel: null,
  getOptionSelected: null,
  onChange: null,
  variant: 'outlined',
  inputProps: {},
  name: null,
  multiple: false,
};

export default SearchAutocomplete;
