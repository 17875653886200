import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  AccordionDetails,
  Divider,
  AccordionActions,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import TimeTable from '../time-table/TimeTable';
import Map from '../../../../components/map/Map';
import Loader from '../../../../components/page-loader/PageLoader';
import IterableList from '../iterable-list/IterableList';
import { getTandemEquipment } from '../../../../slices/equipment.slice';
import { DROP_ZONES_THEME, DROP_ZONES_DEFAULT } from '../../constants';
import useStyles from './styles';

const DropZoneStatic = (props) => {
  const {
    id,
    address,
    runwaySize,
    planes,
    workingAltitudes,
    email,
    phones,
    expanded,
    dates,
    createdAt,
    currency,
    services,
    lat,
    long,
    startEditMode,
    forCP,
    forCheckin,
    runwayDirections,
    icao,
    atz,
    radioFrequency,
    mode,
    workingHours,
    messageInfo,
    placeCost,
    commercialPlaceCost,
    jumpOrganiser,
    flightsPerDay,
    timeBetweenFlights,
    localIp,
    forShop,
    uspa,
    locustProfit,
    moderationUrl
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isEquipmentLoading, setIsEquipmentLoading] = useState(false);
  const [tandemEquipmentNumber, setTandemEquipmentNumber] = useState(0);

  useEffect(() => {
    const getTandems = async () => {
      try {
        setIsEquipmentLoading(true);
        const responseAction = await dispatch(getTandemEquipment(id));
        const response = unwrapResult(responseAction);

        setTandemEquipmentNumber(response.length);
        setIsEquipmentLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    if (expanded && !tandemEquipmentNumber) {
      getTandems();
    }
  }, [dispatch, id, expanded, tandemEquipmentNumber]);

  return (
    <>
      <AccordionDetails>
        <div className={classes.details}>
          <div className={classes.column}>
            <div className={classes.row}>
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('dropZones.registrationDate')}:`}
              </Typography>
              <Typography className={classes.value}>
                {new Date(createdAt).toLocaleDateString()}
              </Typography>
            </div>
            <div className={classes.row}>
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('dropZones.currency')}:`}
              </Typography>
              <Typography className={classes.value}>
                { currency }
              </Typography>
            </div>
            <div className={classes.row}>
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('dropZones.locustProfit')}:`}
              </Typography>
              <Typography className={classes.value}>
                { locustProfit }
              </Typography>
            </div>
            {jumpOrganiser && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.jumpOrganiser')}: `}
                </Typography>
                <Typography className={classes.value}>
                  {jumpOrganiser.name}
                </Typography>
              </div>
            )}
            {!!runwaySize && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.runway')}: `}
                </Typography>
                <Typography className={classes.value}>{runwaySize}</Typography>
              </div>
            )}
            {runwayDirections && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.runwayDirections')}: `}
                </Typography>
                <Typography className={classes.value}>
                  {runwayDirections}
                </Typography>
              </div>
            )}
            {planes && planes.length > 0 && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.planes')}:`}
                </Typography>
                <IterableList data={planes} />
              </div>
            )}
            {workingAltitudes && workingAltitudes.length > 0 && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.altitudes')}:`}
                </Typography>
                <IterableList data={workingAltitudes} />
              </div>
            )}
            {services && services.length > 0 && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.services')}:`}
                </Typography>
                <IterableList data={services} />
              </div>
            )}
            {placeCost > 0 && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.placeCost')}:`}
                </Typography>
                <Typography className={classes.value}>{placeCost}</Typography>
              </div>
            )}
            {commercialPlaceCost > 0 && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.commercialPlaceCost')}:`}
                </Typography>
                <Typography className={classes.value}>
                  {commercialPlaceCost}
                </Typography>
              </div>
            )}
            <div className={classes.row}>
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('dropZones.tandemEquipment')}:`}
              </Typography>
              {isEquipmentLoading && <Loader className={classes.loader} />}
              {!isEquipmentLoading && (
                <Typography className={classes.value}>
                  {tandemEquipmentNumber}
                </Typography>
              )}
            </div>
            <div className={classes.row}>
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('dropZones.flightsPerDay')}: `}
              </Typography>
              <Typography className={classes.value}>
                {flightsPerDay}
              </Typography>
            </div>
            <div className={classes.row}>
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('dropZones.timeBetweenFlights')}: `}
              </Typography>
              <Typography className={classes.value}>
                {`${timeBetweenFlights} ${t('min')}`}
              </Typography>
            </div>
            {radioFrequency && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.radioFrequency')}: `}
                </Typography>
                <Typography className={classes.value}>
                  {radioFrequency}
                </Typography>
              </div>
            )}
            {atz && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.atz')}: `}
                </Typography>
                <Typography className={classes.value}>{atz}</Typography>
              </div>
            )}
            {icao && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.icao')}: `}
                </Typography>
                <Typography className={classes.value}>{icao}</Typography>
              </div>
            )}
            {mode && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.mode')}: `}
                </Typography>
                <Typography className={classes.value}>{mode}</Typography>
              </div>
            )}
            {address && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.address')}: `}
                </Typography>
                <Typography className={classes.value}>{address}</Typography>
              </div>
            )}
            {email && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.email')}: `}
                </Typography>
                <a href={`mailto:${email}`} className={classes.link}>
                  <Typography className={classes.value} color="textPrimary">
                    {email}
                  </Typography>
                </a>
              </div>
            )}
            {phones && phones.length > 0 && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.phones')}: `}
                </Typography>
                <ul className={classes.rowList}>
                  {phones.map((phone, i, array) => {
                    if (i === array.length - 1) {
                      return (
                        <li
                          className={cn(classes.rowListItem, classes.value)}
                          key={phone}
                        >
                          <a className={classes.link} href={`tel:${phone}`}>
                            <Typography
                              className={classes.value}
                              color="textPrimary"
                            >
                              {phone}
                            </Typography>
                          </a>
                        </li>
                      );
                    }

                    return (
                      <li
                        className={cn(classes.rowListItem, classes.value)}
                        key={phone}
                      >
                        <a className={classes.link} href={`tel:${phone}`}>
                          <Typography
                            className={classes.value}
                            color="textPrimary"
                          >
                            {`${phone},`}
                          </Typography>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {localIp && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.serverIP')}: `}
                </Typography>
                <Typography className={classes.value} color="textPrimary">
                  {localIp}
                </Typography>
              </div>
            )}
            {moderationUrl && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.moderationUrl')}: `}
                </Typography>
                <Typography className={classes.value} color="textPrimary">
                  {moderationUrl}
                </Typography>
              </div>
            )}
            {workingHours && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.workingHours')}: `}
                </Typography>
                <Typography className={classes.value} color="textPrimary">
                  {workingHours}
                </Typography>
              </div>
            )}
            {messageInfo && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('dropZones.messageInfo')}: `}
                </Typography>
                <Typography className={classes.value} color="textPrimary">
                  {messageInfo}
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.column}>
            <TimeTable className={classes.timeTable} value={dates} />
          </div>
          {expanded && (
            <div className={classes.column}>
              <Map
                zoom={14}
                location={{ lat, lng: long }}
                name={address}
                center={{ lat, lng: long }}
              />
            </div>
          )}
        </div>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <div className={classes.checkboxes}>
          <FormControlLabel
            control={<Checkbox checked={forCP} color="primary" />}
            disabled
            label={t('dropZones.forCP')}
          />
          <FormControlLabel
            control={<Checkbox checked={forCheckin} color="primary" />}
            label={t('dropZones.forCheckin')}
            disabled
          />
          <FormControlLabel
            control={<Checkbox checked={forShop} color="primary" />}
            label={t('dropZones.forShop')}
            disabled
          />
          <FormControlLabel
            control={<Checkbox checked={uspa} color="primary" />}
            label={t('dropZones.uspa')}
            disabled
          />
        </div>
        {/*
          <Button variant="contained" size="small" color="primary" onClick={() => onDelete(id)}>
            {t('delete')}
          </Button>
        */}
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={startEditMode}
        >
          {t('edit')}
        </Button>
      </AccordionActions>
    </>
  );
};

DropZoneStatic.propTypes = {
  ...DROP_ZONES_THEME,
  expanded: PropTypes.bool,
};

DropZoneStatic.defaultProps = {
  ...DROP_ZONES_DEFAULT,
  expanded: false,
};

export default DropZoneStatic;
