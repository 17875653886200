import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import PageLoader from '../../../components/page-loader/PageLoader';
import Order from './Order';
import OrdersSelectors from '../../../selectors/orders.selectors';
import { usePagination } from '../../../hooks/usePagination.hook';
import useStyles from './OrdersHistory.styles';

const ORDERS_PER_PAGE = 50;
export default function OrdersHistory(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { orders } = props;

  const loading = useSelector(OrdersSelectors.loading);

  const { currentPage, setPage } = usePagination(orders.length, ORDERS_PER_PAGE);

  // useEffect(() => {
  //   if (locationId) {
  //     resetPagination();
  //   }
  // }, [dispatch, locationId, resetPagination]);

  const ordersForDisplay = useMemo(
    () => orders.slice(currentPage * ORDERS_PER_PAGE, (currentPage + 1) * ORDERS_PER_PAGE),
    [orders, currentPage]
  );

  if (loading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      {orders.length > 0 && (
        <>
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('shop.order')}</TableCell>
                  <TableCell>{t('date')}</TableCell>
                  <TableCell>{t('shop.status')}</TableCell>
                  <TableCell>{t('shop.totalPrice')}</TableCell>
                  <TableCell>{t('shop.gift')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody className={classes.body}>
                {ordersForDisplay.map((el) => (
                  <Order key={el.id} order={el} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {Math.ceil(orders.length / ORDERS_PER_PAGE) > 1 && (
            <Pagination
              className={classes.pagination}
              count={Math.ceil(orders.length / ORDERS_PER_PAGE)}
              page={currentPage + 1}
              onChange={(_, page) => setPage(page - 1)}
              color="secondary"
            />
          )}
        </>
      )}
      {!orders.loading && !orders.length && (
        <Typography className={classes.noResultsText} color="textSecondary" component="span">
          {t('shop.noOrders')}
        </Typography>
      )}
      {orders.loading && <PageLoader />}
    </main>
  );
}

OrdersHistory.propTypes = {
  locationId: PropTypes.number,
};

OrdersHistory.defaultProps = {
  locationId: null,
};
