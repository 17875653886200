import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(3),
      },
    },
    row: {
      display: 'flex',
      width: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(3),
      },
    },
    rowSelected: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
    },
    selected: {
      display: 'flex',
      alignItems: 'center',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      },
    },
    tableContainer: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(3),
      },
    },
    toolbar: {
      flexDirection: 'column',
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
      },
    },
    title: {
      flex: '1 1 100%',
    },
    selectedTitle: {
      flex: '1 1 100%',
      color: theme.palette.secondary.contrastText,
    },
    selectedButton: {
      color: theme.palette.secondary.contrastText,
    },
    controls: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: theme.spacing(1),

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },

      '&:last-child': {
        marginBottom: 0,
      },
    },
    control: {
      width: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        marginRight: theme.spacing(2),

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    createButton: {
      flexShrink: 0,
    },
    tabPanel: {
      backgroundColor: 'transparent',
    },
  }),
  {
    name: 'ShopOrders',
  }
);
