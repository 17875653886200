import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion, AccordionSummary, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SubcontractorStaticContent from './SubcontractorStaticContent';
import SubcontractorEditableContent from './SubcontractorEditableContent';
import useStyles from './styles';

const Agent = React.memo((props) => {
  const {
    id, name, email, phone, rates, orders, ordersInfo,
  } = props;
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const togglePanel = () => {
    setExpanded((prevState) => !prevState);
  };

  const [editMode, setEditMode] = useState(false);

  const startEditMode = useCallback(() => {
    setEditMode(true);
  }, []);

  const endEditMode = useCallback(() => {
    setEditMode(false);
  }, []);

  return (
    <Accordion expanded={expanded} onChange={togglePanel}>
      <AccordionSummary
        classes={{ content: classes.summary }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={id}
      >
        <Typography variant="h5" component="h3">{name}</Typography>
      </AccordionSummary>
      {!editMode && (
        <SubcontractorStaticContent
          id={id}
          email={email}
          phone={phone}
          rates={rates}
          onStartEditMode={startEditMode}
          orders={orders}
          ordersInfo={ordersInfo}
        />
      )}
      {editMode && (
        <SubcontractorEditableContent
          id={id}
          name={name}
          email={email}
          phone={phone}
          rates={rates}
          onEndEditMode={endEditMode}
        />
      )}
    </Accordion>
  );
});

Agent.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  rates: PropTypes.shape({
    base: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
    handy: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
    camera: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
    camera_handy: PropTypes.shape({
      id: PropTypes.number,
      rate: PropTypes.number,
      rateType: PropTypes.oneOf(['base', 'handy', 'camera', 'camera_handy']),
    }),
  }),
  orders: PropTypes.arrayOf(PropTypes.string),
  ordersInfo: PropTypes.objectOf(PropTypes.shape({
    externalId: PropTypes.string,
    totalPrice: PropTypes.string,
    place: PropTypes.string,
    time: PropTypes.string,
    extra: PropTypes.string,
  })),
};

Agent.defaultProps = {
  name: '',
  email: '',
  phone: '',
  rates: {},
  orders: [],
  ordersInfo: {},
};

export default Agent;
