import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    width: '100%',
    margin: 0,
    position: 'relative',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  actions: {
    marginTop: 'auto',
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  submit: {
    flexShrink: 0,
  },
  success: {
    color: theme.palette.success.main,
  },
}), {
  name: 'FormDialog',
});
