import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useStyles from './styles';

const Message = React.memo((props) => {
  const classes = useStyles();

  const {
    id, text, time, isOut, isGrouped, read, sendStatus, markAsRead, onSelect,
    isSelected,
  } = props;

  const observe = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (markAsRead && !isOut) {
          markAsRead(id);
        }

        const node = entry.target;
        observer.unobserve(node);
      }
    });
  }, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });

  const ref = useCallback((node) => {
    if (node !== null && !read) {
      observe.observe(node);
    }

    // if (node !== null) {
    //   node.addEventListener('long-press', (e) => {
    //     console.log('long-press');
    //   });
    // }
  }, [observe, read]);

  const handleSelect = () => {
    if (onSelect) {
      onSelect(id);
    }
  };

  return (
    <li
      className={cn(
        classes.container,
        {
          [classes.containerIsOut]: isOut,
          [classes.containerIsSelected]: isSelected,
        },
      )}
      onClick={handleSelect}
      role="presentation"
    >
      {isOut && <CheckCircleIcon className={classes.selectIcon} />}
      {/* {!isOut && !isGrouped && <Avatar src={recipientAvatar} size="small" />} */}
      <div
        className={cn(
          classes.message,
          {
            [classes.messageOut]: isOut,
            [classes.messageIn]: !isOut,
            [classes.grouped]: isGrouped,
          },
        )}
        ref={ref}
      >
        <Typography className={classes.messageText} variant="body1" color="primary">{text}</Typography>
        <div className={classes.info}>
          <Typography className={classes.messageTime}>{new Date(time).toLocaleTimeString('en', { hour: 'numeric', minute: 'numeric' })}</Typography>
          {read && <DoneAllIcon className={cn(classes.icon, classes.readIcon)} />}
          {!read && sendStatus === 'success' && <DoneIcon className={classes.icon} />}
        </div>
      </div>
    </li>
  );
});

Message.propTypes = {
  text: PropTypes.string,
  time: PropTypes.string,
  isOut: PropTypes.bool,
  isGrouped: PropTypes.bool,
  read: PropTypes.bool,
  sendStatus: PropTypes.oneOf(['failure', 'success']),
  markAsRead: PropTypes.func,
  id: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

Message.defaultProps = {
  text: '',
  time: '',
  isOut: false,
  isGrouped: false,
  read: false,
  sendStatus: 'success',
  markAsRead: null,
  isSelected: false,
  onSelect: null,
};

export default Message;
