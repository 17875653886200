/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { paymentRequestsApi, revolutApi, requestsApi } from '../api/api';
import { parsePaymentRequests } from '../services/paymentRequests.services';
import { parseRequests } from '../services/requests.services';
import { setNotification } from './notifications.slice';
import { MESSAGES_TYPES, MESSAGES } from '../constants/constants';

export const getAll = createAsyncThunk(
  'paymentRequests/getAll',
  async (locationId, { signal, dispatch, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await paymentRequestsApi.getAll(
        locationId,
        source.token
      );

      return { locationId, data: parsePaymentRequests(response.data) };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const getRevolutAccount = createAsyncThunk(
  'paymentRequests/getRevolut',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await revolutApi.getAll();

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const payWithRevolut = createAsyncThunk(
  'paymentRequests/payWithRevolut',
  async (rawData, { dispatch, rejectWithValue }) => {
    try {
      const data = {
        payment_request_id: rawData.paymentRequestId.toString(),
        account_id: rawData.accountId,
        currency: rawData.currency,
      };

      const response = await revolutApi.pay(data);

      dispatch(
        setNotification({
          message: MESSAGES.transactionAccepted,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      await dispatch(getAll(rawData.locationId));

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const getHistory = createAsyncThunk(
  'paymentRequests/getHistory',
  async (data, { signal, dispatch, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await requestsApi.getAll({ cancelToken: source.token });

      return parseRequests(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

const paymentRequests = createSlice({
  name: 'paymentRequests',
  initialState: {
    byLocationId: {},
    loading: false,
    revolutAccounts: [],
    history: [],
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
    },
    [getAll.fulfilled]: (state, action) => {
      state.byLocationId = {
        [action.payload.locationId]: {
          totalSum: action.payload.data.totalSum,
          requests: action.payload.data.requests,
        },
      };
      state.loading = false;
    },
    [getAll.rejected]: (state) => {
      state.loading = false;
    },
    [getRevolutAccount.fulfilled]: (state, action) => {
      state.revolutAccounts = action.payload;
    },
    [getHistory.pending]: (state) => {
      state.loading = true;
    },
    [getHistory.fulfilled]: (state, action) => {
      state.history = action.payload;
      state.loading = false;
    },
    [getHistory.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default paymentRequests.reducer;
