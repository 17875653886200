import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Chart from 'react-google-charts';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AgentOrders from '../agent-orders/AgentOrders';
import useStyles from './styles';

const AgentStaticContent = (props) => {
  const { id, email, phone, onStartEditMode, totalOrders, soldOrders, discounts, orders } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const matches = useMediaQuery('(min-width:920px)');

  const cancelledOrders = useMemo(() => orders.filter((order) => order.cancelled).length, [orders]);

  const usedOrders = useMemo(
    () =>
      orders.reduce((sum, currentValue) => {
        if (currentValue.used) {
          return sum + 1;
        }

        return sum;
      }, 0),
    [orders]
  );

  return (
    <>
      <AccordionDetails className={classes.details}>
        <Grid container spacing={4}>
          <Grid item md={6} xl={3}>
            <div className={classes.row}>
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('dropZones.email')}: `}
              </Typography>
              <a href={`mailto:${email}`} className={classes.link}>
                <Typography className={classes.value} color="textPrimary">
                  {email}
                </Typography>
              </a>
            </div>
            <div className={classes.row}>
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('dropZones.phones')}: `}
              </Typography>
              <a className={classes.link} href={`tel:${phone}`}>
                <Typography className={classes.value} color="textPrimary">
                  {phone}
                </Typography>
              </a>
            </div>
            <div className={classes.row}>
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('agents.totalOrders')}: `}
              </Typography>
              <Typography className={classes.value} color="textPrimary">
                {totalOrders}
              </Typography>
            </div>
            <div className={classes.row}>
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('agents.soldOrders')}: `}
              </Typography>
              <Typography className={classes.value} color="textPrimary">
                {soldOrders}
              </Typography>
            </div>
            {discounts && discounts.length > 0 && (
              <div className={classes.row}>
                <Typography
                  className={classes.label}
                  color="textSecondary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('discounts.discount')}: `}
                </Typography>
                <Typography className={classes.value} color="textPrimary">
                  {`${discounts[0].description} (${discounts[0].inPercents}%)`}
                </Typography>
              </div>
            )}
          </Grid>
          {orders?.length > 0 && (
            <Grid className={classes.orders} item sm={12} xl={5}>
              <AgentOrders orders={orders} agentId={id} />
            </Grid>
          )}
          <Grid item md={6} xl={4}>
            <Chart
              width="100%"
              height="250px"
              chartType="PieChart"
              key={matches}
              loader={<div>Loading Chart</div>}
              data={[
                ['Status', 'Orders'],
                [t('sold'), soldOrders - usedOrders],
                [t('used'), usedOrders],
                [t('noSold'), totalOrders - soldOrders],
                [t('cancelled'), cancelledOrders],
              ]}
              options={{
                slices: [
                  {
                    color: '#4caf50',
                  },
                  {
                    color: '#f44336',
                  },
                  {
                    color: '#007fad',
                  },
                  { color: '#f44336' },
                ],
              }}
              legendToggle
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button variant="contained" size="small" color="primary" onClick={onStartEditMode}>
          {t('edit')}
        </Button>
      </AccordionActions>
    </>
  );
};

AgentStaticContent.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  onStartEditMode: PropTypes.func,
  totalOrders: PropTypes.number,
  soldOrders: PropTypes.number,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  id: PropTypes.number.isRequired,
};

AgentStaticContent.defaultProps = {
  email: '',
  phone: '',
  onStartEditMode: null,
  totalOrders: 0,
  soldOrders: 0,
  orders: [],
};

export default AgentStaticContent;
