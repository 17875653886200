import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import plLocale from 'date-fns/locale/pl';
import ruLocale from 'date-fns/locale/ru';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import useStyles from './styles';

const localeMap = {
  en: enLocale,
  pl: plLocale,
  ru: ruLocale,
};

function LocalizationDatePicker(props) {
  const { onChange, value, inputRef, ...otherProps } = props;
  const { i18n } = useTranslation();
  const classes = useStyles();
  const local = useMemo(() => i18n.language.split(0, 2), [i18n]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[local]}>
      <KeyboardDatePicker
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          disableRipple: true,
          className: classes.adornment,
        }}
        ref={inputRef}
        {...otherProps}
      />
    </MuiPickersUtilsProvider>
  );
}

LocalizationDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
};

LocalizationDatePicker.defaultProps = {
  value: null,
};

export default LocalizationDatePicker;
