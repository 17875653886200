/* eslint-disable import/prefer-default-export */
export const VATS = ['0%', '4%', '5%', '7%', '8%', '23%'];

export const PROFILE_TYPES = ['FUNFLYER', 'skydiver', 'stuff', 'PROFI', 'SKYDIVE_SCHOOL', 'BUSINESS'];

export const MESSAGES = {
  successDeleted: 'successDeleted',
  successAssigned: 'successAssigned',
  successUnassigned: 'successUnassigned',
  transactionAccepted: 'transactionAccepted',
  successEdited: 'successEdited',
  successCreated: 'successCreated',
  successSend: 'successSend',
};

export const MESSAGES_TYPES = {
  success: 'success',
  error: 'error',
  warning: 'warning',
};

export const ROLES = {
  flightManager: 'flight-manager',
};

export const COMPETENCES = {
  tandem: 'TANDEM',
  tandemExaminer: 'TANDEM EXAMINER',
  agent: 'AGENT',
  subcontractor: 'SUBCONTRACTOR',
  cameramen: 'CAMERAMEN',
  handycamTandem: 'HANDYCAM TANDEM',
};

export const FLIGHT_TYPES = {
  firstFly: 'first-fly',
  nextFly: 'next-fly',
  academy: 'academy',
  acedemyPro: 'acedemy-pro',
  proflyer: 'proflyer',
  skokWTandemie: 'skok-w-tandemie',
};

export const USER_ROLES = {
  flightManager: 'flight-manager',
};
