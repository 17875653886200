import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  search: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      maxWidth: '300px',
    },
  },
  formGroup: {
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  control: {
    marginRight: theme.spacing(1),
  },
  success: {
    color: theme.palette.success.main,
  },
}),
{
  name: 'AssignRole',
});
