import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import SearchChat from '../search-chat/SearchChat';
import ChatsList from '../chats-list/ChatsList';
import GroupMessageDialog from '../group-message-dialog/GroupMessageDialog';
import ChatsSelectors from '../../../selectors/chats.selectors';
import useStyles from './styles';

const Chats = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const chats = useSelector(ChatsSelectors.all);

  const [searchedUser, setSearchedUser] = useState(null);

  const searchUser = (result) => {
    setSearchedUser(result);
  };

  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const toggleDeleteMode = () => {
    setIsDeleteMode((prevState) => !prevState);
  };

  const [isGroupMessage, setIsGroupMessage] = useState(false);

  const openGroupMessageDialog = () => {
    setIsGroupMessage(true);
  };

  const closeGroupMessageDialog = () => {
    setIsGroupMessage(false);
  };

  return (
    <main className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.row}>
          <Typography
            className={classes.title}
            variant="h5"
            id="tableTitle"
            component="h2"
          >
            {t('communication.title')}
          </Typography>
          <Button
            onClick={openGroupMessageDialog}
            variant="contained"
            color="primary"
          >
            {t('communication.groupMessage')}
          </Button>
        </div>

        <div className={classes.controls}>
          <div className={classes.controlWrapper}>
            <div className={classes.control}>
              <SearchChat
                onSelectResult={searchUser}
                className={classes.search}
                value={searchedUser}
              />
            </div>
          </div>

          {chats?.length > 0 && (
            <Button
              onClick={toggleDeleteMode}
              variant="contained"
              color="primary"
            >
              {isDeleteMode ? t('cancel') : t('delete')}
            </Button>
          )}
        </div>
      </Toolbar>
      <Paper className={classes.paper}>
        <ChatsList searchedUser={searchedUser} isDeleteMode={isDeleteMode} />
      </Paper>
      <GroupMessageDialog
        open={isGroupMessage}
        onClose={closeGroupMessageDialog}
      />
    </main>
  );
};

export default Chats;
