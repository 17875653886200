import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    backgroundColor: 'transparent',
  },
  noResultsText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  table: {
    marginBottom: theme.spacing(1),
  },
  pagination: {
    marginLeft: 'auto',
  },
}));
