/* eslint-disable no-param-reassign */
import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { equipmentApi } from '../api/api';
import {
  parseEquipment,
  parseEquipmentEl,
} from '../services/equipment.services';
import { setNotification } from './notifications.slice';
import { MESSAGES_TYPES, MESSAGES } from '../constants/constants';

export const getAll = createAsyncThunk(
  'equipment/getAll',
  async (data, { signal, rejectWithValue, dispatch }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await equipmentApi.get({ cancelToken: source.token });

      return parseEquipment(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const create = createAsyncThunk(
  'equipment/create',
  async (rawData, { getState, rejectWithValue, dispatch }) => {
    try {
      const { auth } = getState();

      const data = {
        reserve: rawData.reserve,
        main: rawData.main,
        aad: rawData.aad,
        aad_counter: rawData.aadCounter,
        rigger_full_name: rawData.riggerName,
        packed: rawData.packed,
        can_rent: rawData.canRent,
        bag_type: rawData.bagType,
        size: rawData.size,
        reserve_size: rawData.reserveSize,
        main_size: rawData.mainSize,
        is_tn: rawData.isTN,
        reserve_next_pack: rawData.reserveNextPack,
        self_pack: rawData.selfPack,
        weight: rawData.weight
      };

      if (rawData.rentPrice) {
        data.rent_price = rawData.rentPrice;
      }

      if (rawData.reserveSerialNumber) {
        data.reserve_serial_number = rawData.reserveSerialNumber;
      }

      if (rawData.mainProductDate) {
        data.main_product_date = rawData.mainProductDate;
      }

      if (rawData.reserveProductDate) {
        data.reserve_product_date = rawData.reserveProductDate;
      }

      if (rawData.mainSerialNumber) {
        data.main_serial_number = rawData.mainSerialNumber;
      }

      if (rawData.location) {
        data.location = rawData.location;
      }

      const response = await equipmentApi.create(auth.user.id.toString(), data);

      dispatch(
        setNotification({
          message: MESSAGES.successEdited,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return parseEquipmentEl(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const createHarness = createAsyncThunk(
  'equipment/createHarness',
  async (rawData, { getState, rejectWithValue, dispatch }) => {
    try {
      const { auth } = getState();

      const data = {
        harness: true,
        harness_name: rawData.harnessName,
      };

      const response = await equipmentApi.create(auth.user.id.toString(), data);

      dispatch(
        setNotification({
          message: MESSAGES.successCreated,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return parseEquipmentEl(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const editHarness = createAsyncThunk(
  'equipment/editHarness',
  async (rawData, { rejectWithValue, dispatch }) => {
    try {
      const data = {
        harness: true,
        harness_name: rawData.harnessName,
      };

      await equipmentApi.edit(rawData.id, rawData.userId.toString(), data);

      dispatch(
        setNotification({
          message: MESSAGES.successEdited,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return rawData;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const edit = createAsyncThunk(
  'equipment/edit',
  async (rawData, { rejectWithValue, dispatch }) => {
    try {
      const data = {
        reserve: rawData.reserve,
        reserve_next_pack: rawData.reserveNextPack,
        main: rawData.main,
        aad: rawData.aad,
        aad_counter: rawData.aadCounter,
        rigger_full_name: rawData.riggerFullName,
        packed: rawData.packed,
        can_rent: rawData.canRent,
        bag_type: rawData.bagType,
        size: rawData.size,
        main_serial_number: rawData.mainSerialNumber,
        reserve_serial_number: rawData.reserveSerialNumber,
        main_product_date: rawData.mainProductDate,
        reserve_product_date: rawData.reserveProductDate,
        main_size: rawData.mainSize,
        reserve_size: rawData.reserveSize,
        rent_price: rawData.rentPrice,
        is_tn: rawData.isTN,
        location: rawData.location,
        self_pack: rawData.selfPack,
        weight: rawData.weight
      };

      await equipmentApi.edit(rawData.id, rawData.userId.toString(), data);

      dispatch(
        setNotification({
          message: MESSAGES.successEdited,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return rawData;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const remove = createAsyncThunk(
  'equipment/remove',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      await equipmentApi.delete(data.id, data.userId.toString());

      dispatch(
        setNotification({
          message: MESSAGES.successDeleted,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      return { id: data.id };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

export const searchByMain = createAsyncThunk(
  'equipment/searchByMain',
  async (key, { rejectWithValue }) => {
    try {
      const response = await equipmentApi.searchByMain(key);

      return parseEquipment(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const searchByReserve = createAsyncThunk(
  'equipment/searchByReserve',
  async (key, { rejectWithValue }) => {
    try {
      const response = await equipmentApi.searchByReserve(key);

      return parseEquipment(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const searchByBagType = createAsyncThunk(
  'equipment/searchByBagType',
  async (key, { rejectWithValue }) => {
    try {
      const response = await equipmentApi.searchByBagType(key);

      return parseEquipment(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const getTandemEquipment = createAsyncThunk(
  'equipment/getAll',
  async (locationId, { rejectWithValue }) => {
    try {
      const response = await equipmentApi.getTandems(locationId);

      return parseEquipment(response.data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const equipment = createSlice({
  name: 'equipment',
  initialState: {
    loading: false,
    error: '',
    all: [],
    isSuccess: false,
  },
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: {
    [getAll.pending]: (state) => {
      state.loading = true;
    },
    [getAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.all = action.payload;
      state.error = '';
    },
    [getAll.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
    [create.fulfilled]: (state, action) => {
      state.all = [...state.all, action.payload];
      state.error = '';
      state.isSuccess = true;
    },
    [create.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
    [edit.fulfilled]: (state, action) => {
      state.all = state.all.map((el) => {
        if (el.id === action.payload.id) {
          return {
            ...el,
            ...action.payload,
          };
        }

        return el;
      });
      state.error = '';
      state.isSuccess = true;
    },
    [edit.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
    [remove.fulfilled]: (state, action) => {
      state.all = state.all.filter((el) => el.id !== action.payload.id);
      state.error = '';
    },
    [remove.rejected]: (state, action) => {
      if (action.payload && action.payload.message) {
        state.error = action.payload.message;
      }
    },
    [createHarness.fulfilled]: (state, action) => {
      state.all = [...state.all, action.payload];
    },
    [editHarness.fulfilled]: (state, action) => {
      state.all = state.all.map((el) => {
        if (el.id === action.payload.id) {
          return {
            ...el,
            ...action.payload,
          };
        }

        return el;
      });
    },
  },
});

export const { resetSuccess, resetError } = equipment.actions;

export default equipment.reducer;
