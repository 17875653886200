import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import ProductDialog from './components/product-dialog/ProductDialog';
import PageLoader from '../../components/page-loader/PageLoader';
import SortableTable from '../../components/sortable-table/SortableTable';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import { getAll, remove } from '../../slices/products.slice';
import { getAll as getLocations } from '../../slices/locations.slice';
import ProductsSelectors from '../../selectors/products.selectors';
import useStyles from './styles';

const TABLE_COLUMNS = [
  { id: 'name', disablePadding: true, isSortable: true },
  { id: 'price', name: 'grossPrice', isSortable: true },
  { id: 'sfPrice', name: 'sfDiscount', isSortable: true },
  { id: 'vat', isSortable: true },
  { id: 'description' },
  { id: 'forCheckin', isSortable: true },
  { id: 'isProduct', isSortable: true },
  { id: 'isService', isSortable: true },
];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { numSelected, onDelete, onSelectSearchResult, products } = props;
  const { t } = useTranslation();

  const [isCreatDialog, setIsCreateDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsCreateDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsCreateDialog(false);
  }, []);

  const handleSearchChange = useCallback(
    (e, value) => {
      onSelectSearchResult(value);
    },
    [onSelectSearchResult]
  );

  const [isConfirmDialog, setIsConfirmDialog] = useState(null);

  const openConfirmDialog = useCallback(() => {
    setIsConfirmDialog(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setIsConfirmDialog(false);
  }, []);

  const handleDelete = useCallback(() => {
    onDelete();
    closeConfirmDialog();
  }, [onDelete, closeConfirmDialog]);

  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.row}>
        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          component="h2"
        >
          {t('products.title')}
        </Typography>
      </div>

      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography
              className={classes.selectedTitle}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {`${numSelected} ${t('selected')}`}
            </Typography>

            <Tooltip title={t('delete')}>
              <IconButton
                className={classes.selectedButton}
                aria-label={t('delete')}
                onClick={openConfirmDialog}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <div className={classes.controls}>
        <div className={classes.controlWrapper}>
          <Autocomplete
            className={classes.control}
            type="search"
            name="search"
            variant="outlined"
            size="medium"
            options={products}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={handleSearchChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('search')}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={openCreateDialog}
        >
          {t('dictionaries.create')}
        </Button>
      </div>
      {isCreatDialog && (
        <ProductDialog
          isOpen={isCreatDialog}
          onClose={closeCreateDialog}
          mode="create"
        />
      )}
      {isConfirmDialog && (
        <ConfirmDialog
          title={t('confirmDelete')}
          description={t('products.productsDeleteWarning')}
          agreeButtonText={t('delete')}
          disagreeButtonText={t('cancel')}
          onAgree={handleDelete}
          onDisagree={closeConfirmDialog}
          isOpen={isConfirmDialog}
          onClose={closeConfirmDialog}
        />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelectSearchResult: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { products } = useSelector((state) => state);

  const all = useSelector(ProductsSelectors.all);

  useEffect(() => {
    const promise = dispatch(getAll());
    const promiseLocations = dispatch(getLocations());

    return () => {
      promise.abort();
      promiseLocations.abort();
    };
  }, [dispatch]);

  const [isDialog, setIsDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dialogMode, setDialogMode] = useState('');

  const openDialog = useCallback((data, mode) => {
    setIsDialog(true);
    setDialogData(data);
    setDialogMode(mode);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialog(false);
    setDialogData(null);
    setDialogMode('');
  }, []);

  const [selected, setSelected] = useState([]);

  const handleDeleteSelected = useCallback(() => {
    selected.forEach((id) => {
      const deletedEl = all.filter((el) => el.id === id)[0];
      if (deletedEl && !deletedEl.disableDelete) {
        dispatch(remove(id));
      }
    });
    setSelected([]);
  }, [dispatch, selected, all]);

  const [searchResult, setSearchResult] = useState(null);

  const handleSetSearchResult = useCallback((value) => {
    setSearchResult(value);
  }, []);

  const [deletingId, setDeletingId] = useState(null);

  const openConfirmDialog = useCallback((data) => {
    setDeletingId(data.id);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setDeletingId(null);
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(remove(deletingId));
    closeConfirmDialog();
  }, [dispatch, deletingId, closeConfirmDialog]);

  if (products.loading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        onDelete={handleDeleteSelected}
        products={all}
        onSelectSearchResult={handleSetSearchResult}
      />
      <SortableTable
        columns={TABLE_COLUMNS}
        data={searchResult ? [searchResult] : all}
        translationNamespace="products"
        onSelect={setSelected}
        selected={selected}
        onShow={(data) => openDialog(data, 'view')}
        onEdit={(data) => openDialog(data, 'edit')}
        onDelete={openConfirmDialog}
      />
      {isDialog && (
        <ProductDialog
          isOpen={isDialog}
          onClose={closeDialog}
          mode={dialogMode}
          data={dialogData}
        />
      )}
      {!!deletingId && (
        <ConfirmDialog
          title={t('confirmDelete')}
          description={t('products.productDeleteWarning')}
          agreeButtonText={t('delete')}
          disagreeButtonText={t('cancel')}
          onAgree={handleDelete}
          onDisagree={closeConfirmDialog}
          isOpen={!!deletingId}
          onClose={closeConfirmDialog}
        />
      )}
    </main>
  );
}
