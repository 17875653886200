import { createSelector } from 'reselect';

const discounts = (state) => state.discounts;
const all = createSelector(discounts, (state) => state.all);
const discountsInPersents = createSelector(all, (state) =>
  state.filter((el) => el.inPercents).map((el) => el.inPercents)
);

const dzDiscounts = createSelector(all, (state) => state.filter((el) => el.origin === 'dropzone'));
const tunnelDiscounts = createSelector(all, (state) =>
  state.filter((el) => el.origin === 'tunnel')
);
const otherDiscounts = createSelector(all, (state) => state.filter((el) => el.origin === 'other'));

const DiscountsSelectors = {
  discountsInPersents,
  all,
  dzDiscounts,
  tunnelDiscounts,
  otherDiscounts,
};

export default DiscountsSelectors;
