import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    container: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
    },
    dot: {
      width: 10,
      height: 10,
      marginRight: 7,
      borderRadius: '50%',
      backgroundColor: theme.palette.secondary.main,
      animation: `$loadingAnimation 1.4s infinite ${theme.transitions.easing.easeInOut} both`,

      '&:first-child': {
        animationDelay: '-0.32s',
      },
      '&:nth-child(2)': {
        animationDelay: '-0.16s',
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    '@keyframes loadingAnimation': {
      '0%': {
        transform: 'scale(0)',
      },
      '80%': {
        transform: 'scale(0)',
      },
      '100%': {
        transform: 'scale(0)',
      },
      '40%': {
        transform: 'scale(1)',
      },
    },
  }),
  {
    name: 'PageLoader',
  }
);
