import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  TablePagination,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  Button,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SendIcon from '@material-ui/icons/Send';
import PageLoader from '../../components/page-loader/PageLoader';
import SearchAutocomplete from '../../components/search-autocomplete/SearchAutocomplete';
import UserDialog from './components/user-dialog/UserDialog';
import SortableTable from '../../components/sortable-table/SortableTable';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import {
  getUsers,
  searchByName,
  searchByPhone,
  searchByEmail,
  setSearchResult,
  remove,
} from '../../slices/users.slice';
import { ROLES } from '../../constants/constants';
import { matchSorter } from '../../utils/array.utils';
import useStyles from './styles';

const TABLE_COLUMNS = [
  { id: 'name', disablePadding: true, isSortable: true },
  { id: 'phone', isSortable: true },
  { id: 'email', isSortable: true },
  { id: 'type', isSortable: true },
];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { numSelected, profileType, types } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [timeoutId, setTimeoutId] = useState(null);

  const [isUserDialog, setIsUserDialog] = useState(false);

  const openUserDialog = useCallback(() => {
    setIsUserDialog(true);
  }, []);

  const closeUserDialog = useCallback(() => {
    setIsUserDialog(false);
  }, []);

  const handleSearchByName = useCallback(
    (value, setOptions, setError) => {
      if (value.length > 3) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const timeout = setTimeout(async () => {
          try {
            const responseAction = await dispatch(searchByName(value));
            const response = unwrapResult(responseAction);
            setOptions(response);
          } catch (err) {
            setError(err.message);
          }
        }, 500);
        setTimeoutId(timeout);
      }
    },
    [dispatch, timeoutId]
  );

  const handleSearchByPhone = useCallback(
    (value, setOptions, setError) => {
      if (value.length > 3) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const timeout = setTimeout(async () => {
          try {
            const responseAction = await dispatch(searchByPhone(value));
            const response = unwrapResult(responseAction);
            setOptions(response);
          } catch (err) {
            setError(err.message);
          }
        }, 500);
        setTimeoutId(timeout);
      }
    },
    [dispatch, timeoutId]
  );

  const handleSearchByEmail = useCallback(
    (value, setOptions, setError) => {
      if (value.length > 3) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const timeout = setTimeout(async () => {
          try {
            const responseAction = await dispatch(searchByEmail(value));
            const response = unwrapResult(responseAction);
            setOptions(response);
          } catch (err) {
            setError(err.message);
          }
        }, 500);
        setTimeoutId(timeout);
      }
    },
    [dispatch, timeoutId]
  );

  const handleSearchChange = useCallback(
    (e, value) => {
      if (value) {
        dispatch(setSearchResult([value]));
      } else {
        dispatch(setSearchResult([]));
      }
    },
    [dispatch]
  );

  const handleChangeType = useCallback(
    (e, value) => {
      if (value) {
        history.push(`?type=${value}`);
      } else {
        history.push('?');
      }
    },
    [history]
  );

  return (
    <Toolbar
      className={cn(classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.row}>
        <Typography className={classes.title} variant='h5' id='tableTitle' component='div'>
          {t('users.databaseTitle')}
        </Typography>
        <Button onClick={openUserDialog} variant='contained' color='primary'>
          {t('create')}
        </Button>
      </div>

      {numSelected > 0 && (
        <div className={classes.rowSelected}>
          <div className={classes.selected}>
            <Typography
              className={classes.selectedTitle}
              color='inherit'
              variant='subtitle1'
              component='div'
            >
              {`${numSelected} ${t('users.selected')}`}
            </Typography>

            <Tooltip title={t('send')}>
              <IconButton className={classes.sendButton} aria-label={t('send')}>
                <SendIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}

      <div className={classes.filters}>
        <SearchAutocomplete
          className={classes.filter}
          onSearch={handleSearchByName}
          onChange={handleSearchChange}
          size='medium'
          label={t('users.searchByName')}
          getOptionLabel={(option) =>
            `${option.name} ${option.nickname ? `(${option.nickname})` : ''}`
          }
          getOptionSelected={(option, value) => option.name === value.name}
          filterOptions={(opts, state) => matchSorter(opts, state.inputValue, ['name', 'nickname'])}
        />
        <SearchAutocomplete
          className={classes.filter}
          onSearch={handleSearchByEmail}
          onChange={handleSearchChange}
          size='medium'
          label={t('users.searchByEmail')}
          renderOption={(option) => option.email}
          getOptionLabel={(option) => option.email}
          getOptionSelected={(option, value) => option.email === value.email}
          filterOptions={createFilterOptions({
            stringify: (option) => option.email,
          })}
        />
        <SearchAutocomplete
          className={classes.filter}
          onSearch={handleSearchByPhone}
          size='medium'
          label={t('users.searchByPhone')}
          onChange={handleSearchChange}
          renderOption={(option) => option.phone}
          getOptionLabel={(option) => option.phone}
          getOptionSelected={(option, value) => option.phone === value.phone}
          filterOptions={createFilterOptions({
            stringify: (option) => option.phone,
          })}
        />
        <Autocomplete
          className={classes.filter}
          type='search'
          name='type'
          variant='outlined'
          size='medium'
          options={types}
          value={profileType}
          onChange={handleChangeType}
          renderInput={(params) => (
            <TextField {...params} variant='outlined' placeholder={t('users.profileType')} />
          )}
        />
        {/* <Button className={classes.createButton}
        variant="contained" color="primary" onClick={openUserDialog}>{t('create')}</Button> */}
      </div>
      {isUserDialog && (
        <UserDialog isOpen={isUserDialog} mode='create' handleClose={closeUserDialog} />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  profileType: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
};

EnhancedTableToolbar.defaultProps = {
  profileType: '',
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { users } = useSelector((state) => state);

  const query = new URLSearchParams(location.search);
  const currPage = +query.get('page') || 1;
  const profileType = query.get('type');

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const promise = dispatch(getUsers({ page: currPage, profile_type: profileType }));

    return () => promise.abort();
  }, [dispatch, currPage, profileType]);

  const handleChangePage = useCallback(
    async (event, newPage) => {
      if (profileType) {
        history.push(`?page=${newPage + 1}&type=${profileType}`);
      } else {
        history.push(`?page=${newPage + 1}`);
      }
    },
    [profileType, history]
  );

  const [isDialog, setIsDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dialogMode, setDialogMode] = useState('');

  const openDialog = useCallback((data, mode) => {
    setIsDialog(true);
    setDialogData(data);
    setDialogMode(mode);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialog(false);
    setDialogData(null);
    setDialogMode('');
  }, []);

  const [isConfirmDialog, setIsConfirmDialog] = useState(false);

  const openConfirmDialog = useCallback(() => {
    setIsConfirmDialog(true);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setIsConfirmDialog(false);
  }, []);

  const handleDelete = useCallback(
    async (data) => {
      if (data.roles?.some((role) => role.name === ROLES.flightManager)) {
        openConfirmDialog();
      } else {
        await dispatch(remove(data.id));
      }
    },
    [dispatch, openConfirmDialog]
  );

  return (
    <main className={classes.container}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        profileType={profileType}
        types={users.profileTypes}
      />
      <SortableTable
        data={users.searchResult?.length > 0 ? users.searchResult : users.all}
        selected={selected}
        columns={TABLE_COLUMNS}
        translationNamespace='users'
        onSelect={setSelected}
        onShow={(data) => openDialog(data, 'view')}
        onEdit={(data) => openDialog(data, 'edit')}
        onDelete={handleDelete}
      />
      {!users.searchResult && (
        <TablePagination
          classes={{
            toolbar: classes.pagination,
            caption: classes.caption,
            input: classes.paginationInput,
          }}
          component='div'
          count={users.count}
          rowsPerPage={users.itemsPerPage}
          page={currPage - 1}
          onChangePage={handleChangePage}
          labelDisplayedRows={() => (
            <span>{`${currPage} ${t('of')} ${Math.ceil(users.count / users.itemsPerPage)}`}</span>
          )}
        />
      )}
      {users.loading && <PageLoader />}
      {isDialog && (
        <UserDialog
          isOpen={isDialog}
          handleClose={closeDialog}
          data={dialogData}
          mode={dialogMode}
        />
      )}
      {isConfirmDialog && (
        <ConfirmDialog
          isOpen={isConfirmDialog}
          onAgree={closeConfirmDialog}
          title={t('forbidden')}
          description={t('users.flightManegerDelete')}
          agreeButtonText={t('ok')}
        />
      )}
    </main>
  );
}
