import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    listItem: {
      marginBottom: theme.spacing(1),
    },
    subtitle: {
      marginRight: 5,
    },
    sold: {
      '& > td': {
        color: theme.palette.success.main,
      },
    },
    used: {
      '& > td': {
        color: '#c8d7e1',
      },
    },
    cancelled: {
      '& > td': {
        color: theme.palette.error.main,
      },
    },
    expandRow: {
      backgroundColor: theme.palette.grey[100],
    },
    buttonIcon: {
      marginLeft: theme.spacing(1),
    },
    columns: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    column: {
      [theme.breakpoints.up('sm')]: {
        width: `calc((100% - ${theme.spacing(2)}px) / 2)`,
        marginRight: theme.spacing(2),

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    link: {
      color: theme.palette.text.primary,
    },
  }),
  {
    name: 'AgentOrders',
  }
);
