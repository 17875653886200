import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import OrderStatus from '../../../../components/order-status/OrderStatus';
import ExpandTableRow from '../../../../components/expand-table-row/ExpandTableRow';
import SoldOrderDialog from '../sold-order-dialog/SoldOrderDialog';
import AgentOrderDetails from './AgentOrderDetails';
import { useModal } from '../../../../hooks/useModal.hook';
import { cancelOrder } from '../../../../slices/agents.slice';
import { ORDER_STATUSES } from '../../../../constants/orders.constants';
import useStyles from './styles';

const AgentOrder = React.memo(
  (props) => {
    const {
      id,
      totalPrice,
      used,
      extra,
      externalId,
      createdAt,
      flyers,
      agentId,
      time,
      place,
      flightType,
      cancelled,
      userId,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isEditDialog, openEditDialog, closeEditDialog] = useModal(false);

    const openDialog = (e) => {
      e.stopPropagation();
      openEditDialog();
    };

    const handleCancelOrder = (e) => {
      e.stopPropagation();
      dispatch(cancelOrder({ id, userId, totalPrice }));
    };

    const orderStatus = useMemo(() => {
      if (used) {
        return ORDER_STATUSES.used;
      }

      if (cancelled) {
        return ORDER_STATUSES.cancelled;
      }

      if (flyers.length > 0) {
        return ORDER_STATUSES.sold;
      }

      return ORDER_STATUSES.notUsed;
    }, [used, cancelled, flyers]);

    return (
      <>
        <ExpandTableRow
          classes={{
            row: classes.listItem,
            expandRow: classes.expandRow,
          }}
          colNumber={6}
          mainRowContent={
            <>
              <TableCell>{externalId}</TableCell>
              <TableCell>
                <OrderStatus status={orderStatus} />
              </TableCell>
              <TableCell>{extra}</TableCell>
              <TableCell>{totalPrice}</TableCell>
              <TableCell>
                {flyers.length === 0 && (
                  <Tooltip title={t('update')}>
                    <IconButton color="primary" onClick={openDialog}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!used && !cancelled && (
                  <Tooltip title={t('cancel')}>
                    <IconButton color="primary" onClick={handleCancelOrder}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </>
          }
          expandContent={() => (
            <AgentOrderDetails
              externalId={externalId}
              extra={extra}
              totalPrice={totalPrice}
              place={place}
              time={time}
              flyers={flyers}
              flightType={flightType}
              createdAt={createdAt}
            />
          )}
        />
        <SoldOrderDialog
          isOpen={isEditDialog}
          onClose={closeEditDialog}
          id={id}
          agentId={agentId}
          createdAt={createdAt}
        />
      </>
    );
  },
  (prevState, nextState) => {
    if (
      prevState.id === nextState.id &&
      prevState.totalPrice === nextState.totalPrice &&
      prevState.used === nextState.used &&
      prevState.extra === nextState.extra &&
      prevState.externalId === nextState.externalId &&
      prevState.createdAt === nextState.createdAt &&
      prevState.flyers.length === nextState.flyers.length &&
      prevState.agentId === nextState.agentId &&
      prevState.time === nextState.time &&
      prevState.place === nextState.place &&
      prevState.flightType === nextState.flightType &&
      prevState.cancelled === nextState.cancelled &&
      prevState.userId === nextState.userId
    ) {
      return true;
    }

    return false;
  }
);

AgentOrder.propTypes = {
  id: PropTypes.number.isRequired,
  flyers: PropTypes.arrayOf(
    PropTypes.shape({
      extra: PropTypes.string,
    })
  ),
  externalId: PropTypes.string.isRequired,
  extra: PropTypes.string,
  totalPrice: PropTypes.string,
  place: PropTypes.string,
  time: PropTypes.string,
  used: PropTypes.bool,
  createdAt: PropTypes.string.isRequired,
  agentId: PropTypes.number.isRequired,
  flightType: PropTypes.string.isRequired,
};

AgentOrder.defaultProps = {
  extra: '',
  totalPrice: 0,
  place: '',
  time: null,
  flyers: [],
  used: false,
};

export default AgentOrder;
