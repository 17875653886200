/* eslint-disable import/prefer-default-export */
import { useRef, useMemo, useEffect } from 'react';

export const useObserver = (
  onObserve,
  options = { root: null, rootMargin: '0px', threshold: 0.5 }
) => {
  const observer = useMemo(
    () =>
      new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && onObserve) {
            onObserve();
          }
        });
      }, options),
    [onObserve, options]
  );

  const ref = useRef(null);

  useEffect(() => {
    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [observer, ref]);

  return { ref, observer };
};
