import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogContent,
  InputLabel,
  Typography,
  TextField,
  Box,
} from '@material-ui/core';
import FormDialog from '../../../components/form-dialog/FormDialog';
import PageLoader from '../../../components/page-loader/PageLoader';
import FormDialogActions from '../../../components/form-dialog/FormDialogActions';
import { createHarness, editHarness } from '../../../slices/equipment.slice';
import useStyles from './styles';

const HarnessDialog = (props) => {
  const { open, onClose, data, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { equipment } = useSelector((state) => state);

  const { control, handleSubmit, formState } = useForm({ defaultValues: data });

  const onSubmit = useCallback(
    async (values) => {
      let response;
      if (mode === 'edit') {
        response = await dispatch(
          editHarness({
            ...data,
            ...values,
          })
        );
      } else {
        response = await dispatch(createHarness(values));
      }

      if (!response.error) {
        onClose();
      }
    },
    [dispatch, data, mode, onClose]
  );

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      onClose={onClose}
      aria-labelledby="equipment-dialog"
      isOpen={open}
      onSubmit={memoSubmit}
      title={t('equipment.equipment')}
    >
      <DialogContent className={classes.content}>
        <Box display="flex" flexDirection="column" width="100%">
          <InputLabel className={classes.label} htmlFor="harnessName">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('equipment.harnessName')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                disabled: classes.disabled,
              },
            }}
            defaultValue=""
            id="harnessName"
            name="harnessName"
            variant="outlined"
            placeholder={t('equipment.harnessName')}
            size="small"
            disabled={mode === 'view'}
            required
            fullWidth
          />
        </Box>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <FormDialogActions
        mode={mode}
        isSuccess={equipment.isSuccess}
        error={equipment.error}
        onCancel={onClose}
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

HarnessDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    userId: PropTypes.number,
    number: PropTypes.string,
    origin: PropTypes.string,
    disciplin: PropTypes.string,
    expirationDate: PropTypes.string,
    reserveNextPack: PropTypes.string,
    image: PropTypes.string,
    mainProductDate: PropTypes.string,
    reserveProductDate: PropTypes.string,
    location: PropTypes.number,
  }),
  mode: PropTypes.string,
};

HarnessDialog.defaultProps = {
  open: false,
  data: {},
  mode: 'create',
};

export default HarnessDialog;
