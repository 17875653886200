import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import TelInput from '../../../../components/tel-input/TelInput';
import DiscountsSelectors from '../../../../selectors/discounts.selectors';
import { edit } from '../../../../slices/agents.slice';
import useStyles from './styles';

const AgentEditableContent = (props) => {
  const { id, email, phone, name, discounts, onEndEditMode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dropZoneDiscounts = useSelector(DiscountsSelectors.dzDiscounts);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email,
      phone,
      name,
      discount: discounts && discounts[0]?.id,
    },
  });

  const onSubmit = useCallback(
    async (values) => {
      const response = await dispatch(
        edit({
          ...values,
          id,
        })
      );

      if (!response.error) {
        onEndEditMode();
      }
    },
    [dispatch, id, onEndEditMode]
  );

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <>
      <AccordionDetails>
        <form title={t('agents.title')} maxWidth="sm">
          <div className={classes.row}>
            <InputLabel className={classes.label} htmlFor="name">
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('name')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              defaultValue=""
              id="name"
              name="name"
              variant="outlined"
              placeholder={t('users.name')}
              margin="dense"
              fullWidth
            />
          </div>
          <div className={classes.row}>
            <InputLabel className={classes.label} htmlFor="phone">
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('phone')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TelInput}
              control={control}
              className={classes.input}
              defaultValue=""
              id="phone"
              name="phone"
              variant="outlined"
              required
              placeholder={t('users.phone')}
              margin="dense"
              fullWidth
            />
          </div>
          <div className={classes.row}>
            <InputLabel className={classes.label} htmlFor="email">
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('email')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              defaultValue=""
              id="email"
              name="email"
              variant="outlined"
              placeholder={t('email')}
              margin="dense"
              fullWidth
            />
          </div>
          <div className={classes.row}>
            {' '}
            <InputLabel className={classes.label} htmlFor="email">
              <Typography
                className={classes.label}
                color="textSecondary"
                variant="subtitle1"
                component="div"
              >
                {`${t('discounts.discount')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={(controllerProps) => (
                <FormControl className={classes.formControl} {...controllerProps}>
                  <InputLabel htmlFor="discountId">{t('discounts.discount')}</InputLabel>
                  <Select id="discountId" label={t('discounts.discount')} {...controllerProps}>
                    <MenuItem value="">{t('none')}</MenuItem>
                    {dropZoneDiscounts.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {`${item.description} ${item.inPercents}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              control={control}
              className={classes.input}
              name="discount"
              id="discount"
              fullWidth
              margin="dense"
              variant="outlined"
            />
          </div>
        </form>
      </AccordionDetails>
      <AccordionActions>
        <Button variant="contained" size="small" color="primary" onClick={onEndEditMode}>
          {t('cancel')}
        </Button>
        <Button variant="contained" size="small" color="primary" onClick={memoSubmit}>
          {t('save')}
        </Button>
      </AccordionActions>
    </>
  );
};

AgentEditableContent.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  name: PropTypes.string,
  onEndEditMode: PropTypes.func,
  id: PropTypes.number,
};

AgentEditableContent.defaultProps = {
  email: '',
  phone: '',
  name: '',
  onEndEditMode: null,
  id: null,
};

export default AgentEditableContent;
