import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    input: {
      marginRight: theme.spacing(2),
    },
  }),
  {
    name: 'DateRange',
  }
);
