import React, { Suspense, useEffect } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { theme } from '../constants/theme';
import PageLoader from '../components/page-loader/PageLoader';
import Protected from './Protected';
import Notifier from '../modules/notifier/Notifier';
import Login from '../modules/auth/Login';

const useStyles = makeStyles(() => ({
  top: {
    top: 74,
  },
}));

const Main = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    if (i18n.language.includes('ru')) {
      html.setAttribute('class', 'ru');
    } else {
      html.removeAttribute('class');
    }
  }, [i18n.language]);


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route render={() => <Protected />} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

const App = () => {
  const classes = useStyles();
  return (
    <Suspense fallback={<PageLoader />}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} autoHideDuration={5000} classes={{ containerRoot: classes.top }}>
          <Main />
          <Notifier />
        </SnackbarProvider>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
