import { createSelector } from 'reselect';

const orders = (state) => state.orders;

const byId = createSelector(orders, (state) => state.byId);
const loading = createSelector(orders, (state) => state.loading);

const all = createSelector(byId, (state) =>
  Object.values(state).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
);

const formatted = createSelector(all, (state) => {
  const parentOrders = state.filter((order) => !order.parentId);

  const parentOrdersWithSubOrders = parentOrders.map((parentOrder) => ({
    ...parentOrder,
    subOrders: orders.filter((order) => {
      return order.parentId === parentOrder.id;
    }),
  }));

  return parentOrdersWithSubOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
});

const parentOrders = createSelector(all, (state) => state.filter((order) => !order.parentId));

const OrdersSelectors = {
  loading,
  formatted,
  parentOrders,
  all,
  byId,
};
export default OrdersSelectors;
