import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import PageLoader from '../../../../components/page-loader/PageLoader';
import SortableTable from '../../../../components/sortable-table/SortableTable';
import CompetenceDialog from '../competence-dialog/CompetenceDialog';
import { getAll } from '../../../../slices/competences.slice';
import useStyles from './styles';

const TABLE_COLUMNS = [
  { id: 'name', isSortable: true },
  { id: 'canHaveStudent', name: 'canHaveStudents', isSortable: true },
  { id: 'description' },
];

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { data, onSelectSearchResult } = props;
  const { t } = useTranslation();

  const handleSearchChange = useCallback(
    (e, value) => {
      onSelectSearchResult(value);
    },
    [onSelectSearchResult]
  );

  return (
    <Toolbar className={cn(classes.toolbar)}>
      <Typography variant="h5" id="tableTitle" component="h2">
        {t('competences.all')}
      </Typography>
      <Autocomplete
        className={classes.control}
        type="search"
        name="search"
        variant="outlined"
        size="medium"
        options={data}
        renderOption={(option) => option.name}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.name === value.name}
        onChange={handleSearchChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={t('search')}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onSelectSearchResult: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { competences } = useSelector((state) => state);

  const [searchResult, setSearchResult] = useState(null);

  useEffect(() => {
    const promise = dispatch(getAll());

    return () => promise.abort();
  }, [dispatch]);

  const [isDialog, setIsDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dialogMode, setDialogMode] = useState('');

  const openDialog = useCallback((data, mode) => {
    setIsDialog(true);
    setDialogData(data);
    setDialogMode(mode);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialog(false);
    setDialogData(null);
    setDialogMode('');
  }, []);

  const handleSetSearchResult = useCallback((type) => {
    setSearchResult(type);
  }, []);

  return (
    <Box>
      <EnhancedTableToolbar
        data={competences.all}
        onSelectSearchResult={handleSetSearchResult}
      />
      <SortableTable
        className={classes.table}
        columns={TABLE_COLUMNS}
        data={searchResult ? [searchResult] : competences.all}
        translationNamespace="competence"
        onShow={(data) => openDialog(data, 'view')}
        isSelectAvailable={false}
      />
      {isDialog && (
        <CompetenceDialog
          isOpen={isDialog}
          onClose={closeDialog}
          mode={dialogMode}
          data={dialogData}
        />
      )}
      {competences.loading && <PageLoader />}
    </Box>
  );
}
