import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import Chart from 'react-google-charts';
import { ORDER_STATUSES } from '../../../constants/orders.constants';
import { getOrderStatusType } from '../orders/utils';

function OrdersNumberChart(props) {
  const { orders } = props;
  const { t } = useTranslation();

  const ordersChartData = useMemo(() => {
    const chartData = {
      [ORDER_STATUSES.sold]: 0,
      [ORDER_STATUSES.cancelled]: 0,
      [ORDER_STATUSES.paymentDelayed]: 0,
      [ORDER_STATUSES.waitingDelivery]: 0,
      [ORDER_STATUSES.used]: 0,
      [ORDER_STATUSES.inProgress]: 0,
    };

    orders.forEach((order) => {
      chartData[getOrderStatusType(order)] += 1;
    });

    return Object.keys(chartData).map((key) => [key, chartData[key]]);
  }, [orders]);

  return (
    <Paper>
      <Chart
        width={'100%'}
        height={'400px'}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[[t('shop.orderStatus'), t('percent')], ...ordersChartData]}
        options={{
          title: t('shop.ordersStatusesForPeriod'),
        }}
      />
    </Paper>
  );
}

export default OrdersNumberChart;
