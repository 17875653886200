import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TableBody, TableCell, TableRow, Checkbox, Tooltip, IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CourseDialog from '../course-dialog/CourseDialog';
import useStyles from './styles';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const EnhancedTableRow = (props) => {
  const {
    data, id, isSelected, onClick,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isEditDialog, setIsEditDialog] = useState(false);

  const openEditDialog = useCallback((e) => {
    e.stopPropagation();
    setIsEditDialog(true);
  }, []);

  const closeEditDialog = useCallback(() => {
    setIsEditDialog(false);
  }, []);


  return (
    <>
      <TableRow
        hover
        onClick={() => onClick(data.name)}
        role="checkbox"
        aria-checked={isSelected}
        tabIndex={-1}
        selected={isSelected}
      >
        <TableCell padding="checkbox" className={classes.cellCheckbox}>
          <Checkbox
            checked={isSelected}
            inputProps={{ 'aria-labelledby': id }}
          />
        </TableCell>
        <TableCell component="th" id={id} scope="row" padding="none">
          {data.name}
        </TableCell>
        <TableCell align="left">{data.type}</TableCell>
        <TableCell align="left">{data.description}</TableCell>
        <TableCell align="right" className={classes.cellActions}>
          <Tooltip title={t('edit')}>
            <IconButton className={classes.control} aria-label={t('edit')} onClick={openEditDialog}>
              <EditIcon className={classes.controlIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('delete')}>
            <IconButton
              className={classes.control}
              aria-label={t('delete')}
              onClick={(e) => { e.stopPropagation(); }}
            >
              <DeleteIcon className={classes.controlIcon} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {isEditDialog && (
        <CourseDialog
          isOpen={isEditDialog}
          handleClose={closeEditDialog}
          data={data}
          isEditMode
        />
      )}
    </>
  );
};

EnhancedTableRow.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

EnhancedTableRow.defaultProps = {
  isSelected: false,
};

const EnhancedTableBody = (props) => {
  const {
    data, selected, order, orderBy, onClick,
  } = props;

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (name) => {
    onClick(name);
  };

  return (
    <TableBody>
      {stableSort(data, getComparator(order, orderBy))
        .map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;
          const isItemSelected = isSelected(row.name);

          return (
            <EnhancedTableRow
              data={row}
              id={labelId}
              isSelected={isItemSelected}
              onClick={handleClick}
              key={labelId}
            />
          );
        })}
    </TableBody>
  );
};

EnhancedTableBody.propTypes = {
  onClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
  })),
};

EnhancedTableBody.defaultProps = {
  data: [],
  selected: [],
};

export default EnhancedTableBody;
