import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import {
  DialogContent,
  InputLabel,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PageLoader from '../../../../components/page-loader/PageLoader';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import FormDialogActions from '../../../../components/form-dialog/FormDialogActions';
import {
  create,
  edit,
  resetSuccess,
  resetError,
} from '../../../../slices/organizations.slice';
import useStyles from './styles';

const OrganizationDialog = (props) => {
  const { isOpen, handleClose, data, locations, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { organizations } = useSelector((state) => state);

  useEffect(() => {
    let timeoutId;
    if (organizations.isSuccess) {
      timeoutId = setTimeout(() => {
        dispatch(resetSuccess());
        handleClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  });

  const checkable = useSelector(() => {
    let owner = organizations.all.find((el) => el.systemOwner === true);

    return owner && (owner.id !== data.id);
  });

  const { control, errors, handleSubmit, formState } = useForm({
    defaultValues: data,
  });

  const onSubmit = useCallback(
    async (values) => {
      if (mode === 'edit') {
        await dispatch(
          edit({
            id: data.id,
            ...values,
            system_owner: values.systemOwner,
            locationIds: values.locations.map((el) => el.id),
          })
        );
      } else {
        await dispatch(
          create({
            ...values,
            locationIds: values.locations.map((el) => el.id),
          })
        );
      }
    },
    [dispatch, mode, data.id]
  );

  const onClose = useCallback(() => {
    dispatch(resetError());
    handleClose();
  }, [dispatch, handleClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      isOpen={isOpen}
      onSubmit={memoSubmit}
      title={t('organizations.title')}
    >
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('organizations.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="name"
            name="name"
            placeholder={t('organizations.name')}
            variant="outlined"
            required
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="address">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('organizations.address')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="address"
            name="address"
            placeholder={t('organizations.address')}
            variant="outlined"
            required
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="nip">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('organizations.nip')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="nip"
            name="nip"
            placeholder={t('organizations.nip')}
            variant="outlined"
            required
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="regon">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('organizations.regon')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="regon"
            name="regon"
            variant="outlined"
            placeholder={t('organizations.regon')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="director">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('organizations.director')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="director"
            name="director"
            placeholder={t('organizations.director')}
            variant="outlined"
            required
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="locations">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('organizations.dropzones')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={[]}
            id="locations"
            name="locations"
            required
            size="small"
            multiple
            onChange={([, value]) => value}
            options={locations}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('organizations.dropzones')}
                helperText={errors.locations && errors.locations.message}
                error={!!errors.locations}
              />
            )}
            rules={{
              validate: (value) => value.length > 0 || t('requiredField'),
            }}
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="phone">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('organizations.phone')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            name="phone"
            id="phone"
            defaultValue=""
            placeholder={`${t('organizations.phone')}`}
            error={!!errors.locationId}
            disabled={mode === 'view'}
            margin="dense"
            variant="outlined"
            required
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="email">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('organizations.email')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="email"
            placeholder={`${t('organizations.email')}`}
            name="email"
            disabled={mode === 'view'}
            margin="dense"
            variant="outlined"
            required
            type="email"
          />
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            disabled={mode === 'view' || (mode === 'edit' && checkable)}
            id="systemOwner"
            name="systemOwner"
            required
            label={t('organizations.systemOwner')}
          />
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <FormDialogActions
        mode={mode}
        isSuccess={organizations.isSuccess}
        error={organizations.error}
        onCancel={onClose}
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

OrganizationDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['create', 'edit', 'view']),
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    nip: PropTypes.string,
    regon: PropTypes.string,
    director: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    locations: PropTypes.array,
    systemOwner: PropTypes.bool
  }),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

OrganizationDialog.defaultProps = {
  isOpen: false,
  mode: 'create',
  data: {},
};

export default OrganizationDialog;
