import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import OrderStatus from '../../../components/order-status/OrderStatus';
import EditOrderDialog from './EditOrderDialog';
import { ORDER_PROP_TYPES } from '../../../prop-types/orders.types';
import { getOrderStatusType } from './utils';
import { useModal } from '../../../hooks/useModal.hook';
import useStyles from './Order.styles';

const SubOrder = ({ subOrder }) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const subOrderStatus = getOrderStatusType(subOrder);

  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useModal(false);

  return (
    <>
      <TableRow>
        <TableCell className={classes.cell}>
          {`${subOrder.billing.name} ${subOrder.billing.surname} `}
          <a className={cn(classes.infoItem, classes.link)} href={`tel:${subOrder.billing.phone}`}>
            <Typography variant="body1">{subOrder.billing.phone}</Typography>
          </a>
        </TableCell>
        <TableCell className={classes.cell}>{subOrder.externalId}</TableCell>
        <TableCell className={classes.cell}>{subOrder.extra}</TableCell>
        <TableCell className={classes.cell}>{subOrder.totalPrice}</TableCell>
        <TableCell className={classes.cell}>
          {new Date(subOrder.time).toLocaleDateString(i18n.language)}
        </TableCell>
        <TableCell className={classes.cell}>
          <OrderStatus status={subOrderStatus} translationNameSpace="shop" />
        </TableCell>
        <TableCell className={classes.cell}>
          <IconButton
            className={classes.buttonEdit}
            variant="contained"
            color="primary"
            size="small"
            onClick={openEditDialog}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {isEditDialogOpen && (
        <EditOrderDialog
          isOpen={isEditDialogOpen}
          onClose={closeEditDialog}
          data={subOrder}
          mode="edit"
        />
      )}
    </>
  );
};

SubOrder.propTypes = {
  subOrder: ORDER_PROP_TYPES,
};

SubOrder.defaultProps = {
  subOrder: {},
};

const SubOrders = (props) => {
  const { subOrders } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell}>{t('shop.flyer')}</TableCell>
          <TableCell className={classes.cell}>{t('shop.code')}</TableCell>
          <TableCell className={classes.cell}>{t('shop.extra')}</TableCell>
          <TableCell className={classes.cell}>{t('shop.price')}</TableCell>
          <TableCell className={classes.cell}>{t('date')}</TableCell>
          <TableCell className={classes.cell}>{t('shop.status')}</TableCell>
          <TableCell className={classes.cell} />
        </TableRow>
      </TableHead>
      <TableBody>
        {subOrders.map((subOrder) => (
          <SubOrder subOrder={subOrder} />
        ))}
      </TableBody>
    </Table>
  );
};

SubOrders.propTypes = {
  order: ORDER_PROP_TYPES,
};

SubOrders.defaultProps = {
  order: {},
};

export default SubOrders;
