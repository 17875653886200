import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Box from '@material-ui/core/Box';
import useStyles from './styles';

const TabPanel = (props) => {
  const { children, value, index, className, ...other } = props;
  const classes = useStyles();

  return (
    <div
      className={cn(classes.tabPanel, { [classes.active]: value === index }, className)}
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box className={classes.box}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any,
  index: PropTypes.any.isRequired,
  className: PropTypes.string,
};

TabPanel.defaultProps = {
  children: null,
  value: 0,
  className: '',
};

export default TabPanel;
