import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    chatList: {
      listStyle: 'none',
      flex: 1,
    },
    link: {
      textDecoration: 'none',
    },
    search: {
      marginBottom: theme.spacing(1),
      flex: 1,
      marginRight: theme.spacing(1),
    },
    emptyMessage: {
      margin: 'auto',
    },
  }),
  {
    name: 'Chats',
  }
);
