import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import {
  DialogContent,
  InputLabel,
  Typography,
  TextField,
  MenuItem,
  Select,
} from '@material-ui/core';
import PageLoader from '../../../../components/page-loader/PageLoader';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import FormDialogActions from '../../../../components/form-dialog/FormDialogActions';
import Textarea from '../../../../components/textarea/Textarea';
import {
  create,
  edit,
  resetSuccess,
  resetError,
} from '../../../../slices/planes.slice';
import useStyles from './styles';

const PlaneDialog = (props) => {
  const { isOpen, handleClose, data, locations, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { planes } = useSelector((state) => state);

  useEffect(() => {
    let timeoutId;
    if (planes.isSuccess) {
      timeoutId = setTimeout(() => {
        dispatch(resetSuccess());
        handleClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  });

  const { control, errors, handleSubmit, formState } = useForm({
    defaultValues: data,
  });

  const onSubmit = useCallback(
    async (values) => {
      if (mode === 'edit') {
        await dispatch(
          edit({
            id: data.id,
            locationId: data.locationId,
            ...values,
            maxCrew: +values.maxCrew,
            maxPassenger: +values.maxPassenger,
          })
        );
      } else {
        await dispatch(
          create({
            ...values,
            maxCrew: +values.maxCrew,
            maxPassenger: +values.maxPassenger,
          })
        );
      }
    },
    [dispatch, mode, data.id, data.locationId]
  );

  const onClose = useCallback(() => {
    dispatch(resetError());
    handleClose();
  }, [dispatch, handleClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      isOpen={isOpen}
      onSubmit={memoSubmit}
      title={t('dictionaries.plane')}
    >
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                disabled: classes.disabled,
              },
            }}
            defaultValue=""
            id="name"
            name="name"
            placeholder={t('dictionaries.name')}
            variant="outlined"
            required
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="planeType">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.type')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                disabled: classes.disabled,
              },
            }}
            defaultValue=""
            id="planeType"
            name="planeType"
            placeholder={t('dictionaries.type')}
            variant="outlined"
            required
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="number">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.number')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                disabled: classes.disabled,
              },
            }}
            defaultValue=""
            id="number"
            name="number"
            placeholder={t('dictionaries.number')}
            variant="outlined"
            required
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="icao24">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.icao24')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                disabled: classes.disabled,
              },
            }}
            defaultValue=""
            id="icao24"
            name="icao24"
            placeholder={t('dictionaries.icao24')}
            variant="outlined"
            required
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="maxCrew">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.maxCrewMembers')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                disabled: classes.disabled,
              },
            }}
            defaultValue=""
            id="maxCrew"
            name="maxCrew"
            variant="outlined"
            required
            placeholder={t('dictionaries.maxCrewMembers')}
            margin="dense"
            type="number"
            disabled={mode === 'view'}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              min: 0,
            }}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="maxPassenger">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.maxPassengers')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                disabled: classes.disabled,
              },
            }}
            defaultValue=""
            id="maxPassenger"
            name="maxPassenger"
            placeholder={t('dictionaries.maxPassengers')}
            variant="outlined"
            required
            margin="dense"
            type="number"
            disabled={mode === 'view'}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              min: 0,
            }}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="locationId">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.location')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={
              <Select
                variant="outlined"
                margin="dense"
                classes={{
                  root: classes.select,
                  disabled: classes.disabled,
                }}
              >
                <MenuItem value="none">{t('none')}</MenuItem>
                {locations.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            className={classes.input}
            rules={{
              validate: (value) =>
                (!!value && value !== 'none') || t('requiredField'),
            }}
            onChange={([selected]) => selected.target.value}
            name="locationId"
            id="locationId"
            defaultValue="none"
            error={!!errors.locationId}
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="description">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.description')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Textarea}
            control={control}
            defaultValue=""
            id="description"
            placeholder={`${t('dictionaries.description')}`}
            name="description"
            disabled={mode === 'view'}
          />
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <FormDialogActions
        mode={mode}
        isSuccess={planes.isSuccess}
        error={planes.error}
        onCancel={onClose}
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

PlaneDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['create', 'edit', 'view']),
  data: PropTypes.shape({
    name: PropTypes.string,
    grossRate: PropTypes.number,
    taxes: PropTypes.number,
    clubRate: PropTypes.number,
    locationId: PropTypes.number,
    id: PropTypes.number,
  }),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

PlaneDialog.defaultProps = {
  isOpen: false,
  mode: 'create',
  data: {},
};

export default PlaneDialog;
