import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PageLoader from '../../../../components/page-loader/PageLoader';
import { getHistory } from '../../../../slices/paymentRequests.slice';
import PaymentRequestsSelectors from '../../../../selectors/paymentRequests.selectors';
import useStyles from './styles';

const PaymentRequestsHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector(PaymentRequestsSelectors.loading);

  const { t, i18n } = useTranslation();

  const history = useSelector(PaymentRequestsSelectors.history);

  // const paymentRequestsFiltered = useMemo(() => {
  //   if (searchByUserName) {
  //     return paymentRequests.filter((el) => el.name === searchByUserName);
  //   }

  //   return paymentRequests;
  // }, [searchByUserName, paymentRequests]);

  useEffect(() => {
    const response = dispatch(getHistory());

    return () => response.abort();
  }, [dispatch]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>{t('date')}</TableCell>
            <TableCell className={classes.cell}>
              {t('paymentRequests.id')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('paymentRequests.reference')}
            </TableCell>
            <TableCell className={classes.cell}>{t('amount')}</TableCell>
            <TableCell className={classes.cell}>{t('status')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((row) => (
            <TableRow key={row.id}>
              <TableCell className={classes.cell}>
                {new Date(row.createdAt).toLocaleDateString(i18n.language)}
              </TableCell>
              <TableCell className={classes.cell}>{row.requestId}</TableCell>
              <TableCell className={classes.cell}>{row.reference}</TableCell>
              <TableCell className={classes.cell}>{row.amount}</TableCell>
              <TableCell className={classes.cell}>{row.state}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// PaymentRequestsHistory.propTypes = {
//   searchByUserName: PropTypes.string,
// };

// PaymentRequestsHistory.defaultProps = {
//   searchByUserName: '',
// };

export default PaymentRequestsHistory;
