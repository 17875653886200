import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    marginLeft: 'auto',
    paddingRight: 0,
    paddingLeft: 0,
  },
  autocomplete: {
    marginBottom: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    marginTop: 'auto',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    paddingLeft: 0,
    paddingRight: 0,
  },
  send: {
    flexShrink: 0,
  },
  theme: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  textarea: {
    height: '100%',
    outline: 0,
    fontSize: '1rem',
    marginBottom: theme.spacing(1),
    border: 0,
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
  fileName: {
    flex: 1,
  },
  delete: {
    padding: 0,
  },
}), {
  name: 'MessageDialog',
});
