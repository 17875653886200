/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const notifications = createSlice({
  name: 'notifications',
  initialState: {
    all: [],
  },
  reducers: {
    setNotification: (state, action) => {
      state.all = [
        {
          key: uuid(),
          ...action.payload,
        },
        ...state.all,
      ];
    },
    resetNotification: (state, action) => {
      state.all = state.all.filter((el) => el.key !== action.payload);
    },
  },
});

export const { setNotification, resetNotification } = notifications.actions;

export default notifications.reducer;
