export const parseOrganization = (organization) => ({
  id: organization.id,
  createdAt: organization.created_at,
  updatedAt: organization.updated_at,
  address: organization.address,
  director: organization.director,
  email: organization.email,
  name: organization.name,
  nip: organization.nip,
  regon: organization.regon,
  phone: organization.phone,
  locations: organization.locations,
  systemOwner: organization.system_owner
});

export const parseOrganizations = (orientations) => (
  orientations.map((orientation) => parseOrganization(orientation))
);
