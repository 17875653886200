/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { conversationsApi, messageApi } from '../api/api';
import {
  parseConversations,
  parseConversation,
} from '../services/conversations.services';
import { parseMessage } from '../services/messages.services';
import { setNotification } from './notifications.slice';
import { MESSAGES, MESSAGES_TYPES } from '../constants/constants';
import { fromArray } from '../utils/array.utils';

export const getAll = createAsyncThunk(
  'chats/getAll',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();

      const response = await conversationsApi.getAll();

      return parseConversations(response.data, auth.user.id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const getConversation = createAsyncThunk(
  'chats/getConversation',
  async (recipientId, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const response = await conversationsApi.create({
        recipient_id: recipientId.toString(),
      });

      return parseConversation(response.data, auth.user.id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const hideConversation = createAsyncThunk(
  'chats/hideConversation',
  async (recipientId, { getState, rejectWithValue }) => {
    try {
      const { chats } = getState();

      const chat = chats.byId[recipientId];

      await conversationsApi.hide(chat.id.toString());

      return recipientId;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const sendMessage = createAsyncThunk(
  'chats/sendMessage',
  async (data, { rejectWithValue }) => {
    try {
      const response = await messageApi.create({
        conversation_id: data.conversationId.toString(),
        body: data.body,
      });

      return {
        recipientId: data.recipientId,
        message: parseMessage(response.data),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const editMessage = createAsyncThunk(
  'chats/editMessage',
  async (data, { rejectWithValue }) => {
    try {
      const response = await messageApi.edit({
        id: data.id,
        body: data.body,
      });

      return {
        recipientId: data.recipientId,
        message: parseMessage(response.data),
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteMessages = createAsyncThunk(
  'chats/deleteMessages',
  async (data, { rejectWithValue }) => {
    try {
      const promises = data.ids.map((id) => messageApi.delete(id));
      await Promise.all(promises);

      return {
        recipientId: data.recipientId,
        ids: data.ids,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const markMessageAsRead = createAsyncThunk(
  'chats/markMessageAsRead',
  async (data, { rejectWithValue }) => {
    try {
      await messageApi.markAsRead(data.messageId);

      return {
        recipientId: data.recipientId,
        messageId: data.messageId,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const sendGroupMessage = createAsyncThunk(
  'chats/sendGroupMessage',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await messageApi.sendToUsers({
        recipient_ids: data.recipientIds,
        body: data.body,
      });

      dispatch(
        setNotification({
          message: MESSAGES.successSend,
          isLocalMessage: true,
          options: {
            variant: MESSAGES_TYPES.success,
          },
        })
      );

      dispatch(getAll());

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setNotification({
          message: err.response.data.message,
          options: {
            variant: MESSAGES_TYPES.error,
          },
        })
      );

      return rejectWithValue(err.response.data);
    }
  }
);

const chats = createSlice({
  name: 'chat',
  initialState: {
    byId: {},
    order: [],
    loading: false,
    requested: false,
  },
  extraReducers: {
    [getAll.fulfilled]: (state, action) => {
      state.byId = fromArray(action.payload, 'recipientId');
      state.order = action.payload.map((el) => el.recipientId);
      state.requested = false;
    },
    [getConversation.pending]: (state) => {
      state.loading = true;
      state.requested = true;
    },
    [getConversation.fulfilled]: (state, action) => {
      state.byId[action.payload.recipientId] = action.payload;
      state.loading = false;
    },
    [getConversation.rejected]: (state) => {
      state.loading = false;
    },
    [hideConversation.fulfilled]: (state, action) => {
      state.byId[action.payload].active = false;
    },
    [sendMessage.fulfilled]: (state, action) => {
      state.byId[action.payload.recipientId].messages.push(
        action.payload.message
      );
    },
    [markMessageAsRead.fulfilled]: (state, action) => {
      const { recipientId, messageId } = action.payload;

      state.byId[recipientId].messages = state.byId[recipientId].messages.map(
        (message) => {
          if (message.id === messageId) {
            return {
              ...message,
              read: true,
            };
          }

          return message;
        }
      );
    },
    [deleteMessages.fulfilled]: (state, action) => {
      const { recipientId, ids } = action.payload;
      state.byId[recipientId].messages = state.byId[
        recipientId
      ].messages.filter((el) => ids.indexOf(el.id) === -1);
    },
    [editMessage.fulfilled]: (state, action) => {
      const { recipientId, message } = action.payload;
      state.byId[recipientId].messages = state.byId[recipientId].messages.map(
        (el) => {
          if (el.id === message.id) {
            return message;
          }

          return el;
        }
      );
    },
  },
});

export default chats.reducer;
