import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  info: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    width: '100%',
  },
}),
{
  name: 'PaymentRequestsList',
});
