import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Typography,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Button, MenuItem,
} from '@material-ui/core';
import { getAll as getLocations } from '../../../../slices/locations.slice';
import SearchAutocomplete from '../../../../components/search-autocomplete/SearchAutocomplete';
import { getUserRoles, searchByName } from '../../../../slices/users.slice';
import { manageUserRoles } from '../../../../slices/roles.slice';
import PageLoader from '../../../../components/page-loader/PageLoader';
import { matchSorter } from '../../../../utils/array.utils';
import useStyles from './styles';
import LocationsSelectors from '../../../../selectors/locations.selectors.js';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const AssignRole = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locations = useSelector(LocationsSelectors.all);
  const roles = useSelector((state) => state.roles);

  const [inputValue, setInputValue] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('');
  const [userRolesSuccess, setUserRolesSuccess] = useState(false);
  const [assignedRoles, setAssignedRoles] = useState([]);
  const [defaultRoles, setDefaultRoles] = useState([]);

  const handleSetCurrentUser = useCallback((e, newValue) => {
    setCurrentUser(newValue);
  }, []);

  const handleSearchByName = useCallback(
    async (value, setOptions, setErrors) => {
      setInputValue(value);
      if (value.length > 3) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const timeout = setTimeout(async () => {
          try {
            const responseAction = await dispatch(searchByName(value));
            const response = unwrapResult(responseAction);
            setOptions(response);
            setCurrentLocation('');

            if (
              response.length === 1 &&
              response[0].name.toLowerCase() === value.toLowerCase()
            ) {
              handleSetCurrentUser({}, response[0]);
            }
          } catch (err) {
            setErrors(err.message);
          }
        }, 500);
        setTimeoutId(timeout);
      }
    },
    [dispatch, timeoutId, handleSetCurrentUser]
  );

  const handleGetRoles = useCallback(async (e) => {
    const locationId = e.target.value;
    setCurrentLocation(locationId);

    try {
      const responseAction = await dispatch(getUserRoles({
        user_id: +currentUser.id,
        location_id: locationId,
      }));

      const response = await unwrapResult(responseAction);

      setAssignedRoles(response.filter(item => item.id !== 1));
      setDefaultRoles(response.filter(item => item.id !== 1).map(item => item.id));
      setUserRolesSuccess(true);
    } catch (e) {
      console.log(e);
      setAssignedRoles([]);
      setUserRolesSuccess(false);
    }
  }, [currentUser, dispatch]);

  const toggleRole = (e) => {
    const roleId = +e.target.value;
    const role = assignedRoles.find(role => role.id === roleId);

    if (role) {
      setAssignedRoles(prevState => prevState.filter(role => role.id !== roleId));
    } else {
      setAssignedRoles(prevState => prevState.concat({
        id: roleId,
      }));
    }
  };

  const handleSubmit = useCallback(async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      const currentIds = assignedRoles.map(item => item.id);
      const assignIds = currentIds.filter(role => !defaultRoles.includes(role));
      const unassignIds = defaultRoles.filter(role => !currentIds.includes(role));

      const actionResponse = await dispatch(
        manageUserRoles({
          userId: currentUser.id,
          assignIds,
          unassignIds,
          locationId: currentLocation,
        })
      );

      await unwrapResult(actionResponse);

      setIsLoading(false);
      setIsSuccess(true);

      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
    } catch (err) {

      setIsLoading(false);
      if (err.response && err.response.message) {
        setError(err.response.message);
      } else {
        setError(err);
      }
    }
  }, [assignedRoles, currentLocation, currentUser?.id, defaultRoles, dispatch]);

  useEffect(() => {
    dispatch(getLocations())
  }, [dispatch]);

  return (
    <Paper className={cn(classes.container, className)}>
      <Typography
        className={classes.title}
        variant="h5"
        id="tableTitle"
        component="h3"
      >
        {t('users.assignRoleToUser')}
      </Typography>
      <SearchAutocomplete
        className={classes.search}
        onSearch={handleSearchByName}
        onChange={handleSetCurrentUser}
        size="medium"
        label={t('users.searchByName')}
        variant="standard"
        getOptionLabel={(option) =>
          `${option.name} ${option.nickname ? `(${option.nickname})` : ''}`
        }
        getOptionSelected={(option, value) => option.name === value.name}
        filterOptions={(opts, state) =>
          matchSorter(opts, state.inputValue, ['name', 'nickname'])
        }
        inputValue={inputValue}
      />
      {currentUser ? (
        <>
          {locations.length > 0 ? (
            <FormControl fullWidth>
              <InputLabel htmlFor='locationId'>Lokalizacja</InputLabel>
              <Select
                id='locationId'
                label='Lokalizacja'
                onChange={handleGetRoles}
                value={currentLocation}
                defaultValue=''
              >
                {locations.map(item => (
                  <MenuItem value={item.id} key={`location-${item.id}`}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </>
      ) : null}
      <div className={classes.localization}>
        {userRolesSuccess && currentUser && currentLocation ? (
          <>
            <Typography
              className={classes.title}
              variant="h5"
              id="tableTitle"
              component="div"
            >
              {currentUser.name}
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">{t('users.roles')}</FormLabel>
                <FormGroup className={classes.formGroup}>
                  {roles.all.filter(item => item.id !== 1).map((role) => (
                    <FormControlLabel
                      key={role.id}
                      control={
                        <Checkbox
                          checked={assignedRoles.some(item => item.id === role.id)}
                          onChange={toggleRole}
                          value={role.id}
                        />
                      }
                      label={role.name}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <div className={classes.controls}>
                <Button
                  className={classes.control}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {t('users.assign')}
                </Button>
                {error && typeof error === 'string' && (
                  <Typography variant="body1" color="error" component="div">
                    {error}
                  </Typography>
                )}
                {isSuccess && (
                  <Typography
                    variant="body1"
                    className={classes.success}
                    component="div"
                  >
                    {t('users.successAssigned')}
                  </Typography>
                )}
              </div>
              {isLoading && <PageLoader />}
            </form>
          </>
        ) : null}
      </div>
    </Paper>
  );
};

AssignRole.propTypes = {
  className: PropTypes.string,
};

AssignRole.defaultProps = {
  className: '',
};

export default AssignRole;
