import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  summary: {
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(3)}px 0`,
    },
  },
  tooltip: {
    marginLeft: theme.spacing(1),
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: 5,
  },
  column: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  logo: {
    height: 26,
    marginRight: theme.spacing(2),
  },
  rowList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
  },
  rowListItem: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: theme.spacing(1),
    marginBottom: 0,
    fontSize: '1.14rem',
  },
  value: {
    fontSize: '1.14rem',
    marginBottom: 0,
    lineHeight: 1.75,
    textAlign: 'left',
  },
  timeTable: {
    alignSelf: 'center',
    marginBottom: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
  edit: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  managerButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  controls: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
}),
{
  name: 'DropZone',
});
