import { makeStyles } from '@material-ui/core';

export default makeStyles(
  (theme) => ({
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    actions: {
      marginTop: 'auto',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
    },
    submit: {
      flexShrink: 0,
    },
    item: {
      marginBottom: theme.spacing(1),
    },
    input: {
      width: '100%',
    },
    select: {
      marginTop: theme.spacing(1),
      marginBottom: 4,
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    total: {
      display: 'flex',
      paddingLeft: theme.spacing(1),
    },
    price: {
      display: 'flex',
      marginRight: theme.spacing(2),

      '&:last-child': {
        marginRight: 0,
      },
    },
    totalValue: {
      fontWeight: 500,
    },
    totalLabel: {
      marginRight: theme.spacing(1),
    },
    success: {
      color: theme.palette.success.main,
    },
    shrink: {
      display: 'none',
    },
    formLabel: {
      color: 'rgba(0, 0, 0, 0.4)',
    },
  }),
  {
    name: 'ProductDialog',
  }
);
