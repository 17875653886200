import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(1),
  },
}),
{
  name: 'AgentDialog',
});
