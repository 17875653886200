/* eslint-disable import/prefer-default-export */
export const formatDouble = (number, symbolsAfterDot) => (
  Math.round(10 ** symbolsAfterDot * number) / (10 ** symbolsAfterDot)
);

export const formatMoney = (value, locale, currency) => (
  (value).toLocaleString(
    locale,
    {
      style: 'currency',
      currency,
      // minimumFractionDigits: 0,
    },
  )
);
