import { makeStyles } from '@material-ui/core/styles';
import BackgroundImg from '../../assets/images/background.png';
import { drawerWidth } from '../../constants/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
  },
  appBar: {
    backgroundImage: `url(${BackgroundImg})`,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  toolbar: {
    display: 'flex',
  },
  toolbarOffset: {
    ...theme.mixins.toolbar,
  },
  layout: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
  },
  title: {
    margin: '0 auto',
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.42rem',
    },
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  exitButton: {
    padding: 0,
    marginLeft: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  exitIcon: {
    width: '1.5em',
    height: '1.5em',
    color: theme.palette.common.white,
  },
  notificationsIcon: {
    width: '1.2em',
    height: '1.2em',
    color: theme.palette.common.white,
  },
  badge: {
    marginRight: theme.spacing(2),
  },
}),
{
  name: 'Dashboard',
});
