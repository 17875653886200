import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  DialogContent,
  InputLabel,
  DialogActions,
  Hidden,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import FormDialog from '../../../../components/form-dialog/FormDialog';
import PageLoader from '../../../../components/page-loader/PageLoader';
import Textarea from '../../../../components/textarea/Textarea';

import DiscountsSelectors from '../../../../selectors/discounts.selectors';
import LocationsSelectors from '../../../../selectors/locations.selectors';
import { create, resetSuccess, resetError, edit } from '../../../../slices/productSets.slice';
import { getByLocationId as getProductsByLocationId } from '../../../../slices/products.slice';
import { getByLocationId as getSalaryRatesByLocationId } from '../../../../slices/salaryRates.slice';
import { formatDouble } from '../../../../utils/number.utils';
import { COMPETENCES } from '../../../../constants/constants';
import useStyles from './styles';

const ProductSetDialog = (props) => {
  const { isOpen, onClose, data, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { productSets, competences } = useSelector((state) => state);
  const discounts = useSelector(DiscountsSelectors.discountsInPersents);

  const locations = useSelector(LocationsSelectors.all);

  useEffect(() => {
    let timeout;
    if (productSets.isSuccess) {
      timeout = setTimeout(() => {
        dispatch(resetSuccess());
        onClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, onClose, productSets.isSuccess]);

  const { control, handleSubmit, errors, watch, formState } = useForm({
    defaultValues: data,
  });

  const productsSet = watch('products', data.products || []);
  const discount = watch('discount', data.discount || 0);
  const location = watch('locations', data.locations || null);
  const salary = watch('salaryRates', data.salaryRates || []);
  const profit = watch('profit', data.profit || 0);
  const setCompetences = watch('competences', data.competences || []);

  const [salaryRates, setSalaryRates] = useState([]);

  useEffect(() => {
    const getSalaryRates = async () => {
      if (location && location[0]) {
        const response = await dispatch(getSalaryRatesByLocationId(location[0].id));
        if (!response.error) {
          setSalaryRates(response.payload.data);
        } else {
          setSalaryRates([]);
        }
      }
    };
    getSalaryRates();
  }, [dispatch, location]);

  const totalPrice = useMemo(() => {
    const productsPrice = productsSet.reduce((summ, currentValue) => summ + +currentValue.price, 0);
    const salaryPrice = salary.reduce((summ, currentValue) => summ + +currentValue.rate, 0);

    return productsPrice + salaryPrice + +profit;
  }, [productsSet, salary, profit]);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      if (location && location[0]) {
        const response = await dispatch(getProductsByLocationId(location[0].id || location[0]));
        if (!response.error) {
          setProducts(response.payload);
        } else {
          setProducts([]);
        }
      }
    };
    getProducts();
  }, [dispatch, location]);

  const onSubmit = useCallback(
    async (values) => {
      if (mode === 'edit') {
        const price =
          values.products.reduce((summ, currentValue) => summ + +currentValue.price, 0) +
          values.salaryRates.reduce((summ, currentValue) => summ + +currentValue.rate, 0);

        await dispatch(
          edit({
            id: data.id,
            ...values,
            productIds: values.products,
            locations: values.locations,
            price: values.discount ? price * (1 - values.discount / 100) : price,
            competenceIds: values.competences === 'none' ? null : values.competences,
            salaryRateIds: values.salaryRates === 'none' ? null : values.salaryRates,
          })
        );
      } else {
        await dispatch(
          create({
            ...values,
            locations: values.locations.map((el) => el.id),
            productIds: values.products.map((el) => el.id),
            competenceIds:
              values.competences === 'none' ? null : values.competences.map((el) => el.id),
            salaryRateIds:
              values.salaryRates === 'none' ? null : values.salaryRates.map((el) => el.id),
            profit: values.profit && +values.profit,
          })
        );
      }
    },
    [dispatch, data.id, mode]
  );

  const handleClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    onClose();
  }, [dispatch, onClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <FormDialog
      onClose={handleClose}
      isOpen={isOpen}
      title={t('productSets.title')}
      onSubmit={memoSubmit}
    >
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="name"
            name="name"
            variant="outlined"
            required
            placeholder={t('productSets.name')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="locations">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.dropZone')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={[]}
            id="locations"
            name="locations"
            required
            size="small"
            onChange={([, value]) => {
              if (mode === 'create') {
                if (value.length > 0) {
                  return [value[value.length - 1]];
                }
                return value;
              }

              if (!value[0] || value[0].id !== location[0].id) {
                return location;
              }

              return value;
            }}
            options={locations}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('productSets.dropZones')}
                helperText={errors.locations && errors.locations.message}
                error={!!errors.locations}
              />
            )}
            rules={{
              validate: (value) => (value && Object.values(value).length > 0) || t('requiredField'),
            }}
            disabled={mode === 'view'}
            multiple
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="products">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.products')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={[]}
            id="products"
            name="products"
            size="small"
            multiple
            onChange={([, value]) => value}
            options={products}
            renderOption={(option) =>
              `${option.name} ${option.location ? '' : t('common')}`
            }
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('productSets.products')}
                helperText={errors.products && errors.products.message}
                error={!!errors.products}
              />
            )}
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="competences">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.competences')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={[]}
            id="competences"
            name="competences"
            onChange={([, value]) => value}
            options={competences.all}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('productSets.competences')}
              />
            )}
            size="small"
            disabled={mode === 'view'}
            multiple
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="salaryRates">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.salaryRates')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={[]}
            id="salaryRates"
            name="salaryRates"
            required
            size="small"
            onChange={([, value]) => value}
            options={salaryRates}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('productSets.salaryRates')}
              />
            )}
            disabled={mode === 'view'}
            multiple
          />
        </div>
        <div className={classes.row} lang="en-GB">
          <InputLabel className={classes.label} htmlFor="profit">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.profit')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            inputProps={{
              min: 0,
              step: 0.1,
            }}
            defaultValue=""
            id="profit"
            name="profit"
            variant="outlined"
            placeholder={t('productSets.profit')}
            margin="dense"
            type="number"
            disabled={
              mode === 'view' ||
              setCompetences.some(
                (el) => el.name === COMPETENCES.tandem || el.name === COMPETENCES.tandemExaminer
              )
            }
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="discount">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.discount')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={null}
            id="discount"
            name="discount"
            size="small"
            onChange={([, value]) => value}
            options={discounts}
            renderOption={(option) => `${option}%`}
            getOptionLabel={(option) => `${option}%`}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('productSets.products')}
                helperText={errors.productIds && errors.productIds.message}
                error={!!errors.productIds}
              />
            )}
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="slotNumber">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.slotNumber')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            inputProps={{
              min: 0,
            }}
            defaultValue=""
            id="slotNumber"
            name="slotNumber"
            variant="outlined"
            placeholder={t('productSets.slotNumber')}
            margin="dense"
            type="number"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <Controller
            as={<FormControlLabel control={<Checkbox />} />}
            control={control}
            className={classes.chckbox}
            defaultValue
            id="active"
            name="active"
            required
            label={t('productSets.active')}
            type="checkbox"
            disabled={mode === 'view'}
          />
          <Controller
            as={<FormControlLabel control={<Checkbox />} />}
            control={control}
            className={classes.chckbox}
            defaultValue={false}
            id="forCp"
            name="forCp"
            required
            label={t('productSets.forCp')}
            type="checkbox"
            disabled={mode === 'view'}
          />
          <Controller
            as={<FormControlLabel control={<Checkbox />} />}
            control={control}
            className={classes.chckbox}
            defaultValue={false}
            id="forTn"
            name="forTn"
            required
            label={t('productSets.forTn')}
            type="checkbox"
            disabled={mode === 'view'}
          />
          <Controller
            as={<FormControlLabel control={<Checkbox />} />}
            control={control}
            className={classes.chckbox}
            defaultValue={false}
            id="equipmentIsTn"
            name="equipmentIsTn"
            required
            label={t('productSets.equipmentIsTn')}
            type="checkbox"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="description">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('productSets.description')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Textarea}
            control={control}
            placeholder={t('productSets.description')}
            name="description"
            id="description"
            defaultValue=""
            disabled={mode === 'view'}
          />
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Hidden smUp>
          <div className={classes.total}>
            <div className={classes.price}>
              <Typography
                className={classes.totalLabel}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('productSets.totalPrice')}: `}
              </Typography>
              <Typography
                className={classes.totalValue}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {formatDouble(totalPrice, 2)}
              </Typography>
            </div>
            {discount > 0 && (
              <div className={classes.price}>
                <Typography
                  className={classes.totalLabel}
                  color="textPrimary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('productSets.discountPrice')}: `}
                </Typography>
                <Typography
                  className={classes.totalValue}
                  color="textPrimary"
                  variant="subtitle1"
                  component="div"
                >
                  {formatDouble(totalPrice * (1 - discount / 100), 2)}
                </Typography>
              </div>
            )}
          </div>
        </Hidden>
        {mode !== 'view' && (
          <div className={classes.actionsMain}>
            <Button
              className={classes.submit}
              autoFocus
              type="submit"
              variant="contained"
              color="primary"
            >
              {mode === 'edit' ? t('save') : t('create')}
            </Button>
            {mode === 'edit' && (
              <Button onClick={handleClose} variant="outlined" color="primary">
                {t('cancel')}
              </Button>
            )}
            {productSets.error && typeof productSets.error === 'string' && (
              <Typography color="error" variant="body1" component="div">
                {productSets.error}
              </Typography>
            )}
            {productSets.isSuccess && (
              <Typography className={classes.success} component="span" variant="body1">
                {mode === 'edit' ? t('successEdited') : t('successCreated')}
              </Typography>
            )}
          </div>
        )}
        <Hidden xsDown>
          {!productSets.isSuccess && (
            <div className={classes.total}>
              <div className={classes.price}>
                <Typography
                  className={classes.totalLabel}
                  color="textPrimary"
                  variant="subtitle1"
                  component="div"
                >
                  {`${t('productSets.totalPrice')}: `}
                </Typography>
                <Typography
                  className={classes.totalValue}
                  color="textPrimary"
                  variant="subtitle1"
                  component="div"
                >
                  {formatDouble(totalPrice, 2)}
                </Typography>
              </div>
              {discount > 0 && (
                <div className={classes.price}>
                  <Typography
                    className={classes.totalLabel}
                    color="textPrimary"
                    variant="subtitle1"
                    component="div"
                  >
                    {`${t('productSets.discountPrice')}: `}
                  </Typography>
                  <Typography
                    className={classes.totalValue}
                    color="textPrimary"
                    variant="subtitle1"
                    component="div"
                  >
                    {formatDouble(totalPrice * (1 - discount / 100), 2)}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </Hidden>
      </DialogActions>
    </FormDialog>
  );
};

ProductSetDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
    discount: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
    salaryRates: PropTypes.arrayOf(PropTypes.object),
    profit: PropTypes.number,
    competences: PropTypes.arrayOf(
      PropTypes.shape({
        canHaveStudent: PropTypes.bool,
        competenceType: PropTypes.string,
        createdAt: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        updatedAt: PropTypes.string,
      })
    ),
  }),
  mode: PropTypes.string,
};

ProductSetDialog.defaultProps = {
  isOpen: false,
  data: {},
  mode: 'create',
};

export default ProductSetDialog;
