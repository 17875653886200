import React, { useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import LangSwitch from '../../components/lang-switcher/LangSwitcher';
import Logo from '../../assets/images/logo.png';
import { login } from '../../slices/auth.slice';
import useStyles from './styles';

const Login = () => {
  const history = useHistory();
  const { auth } = useSelector((state) => state);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmit = async (values) => {
    await dispatch(login({ email: values.email, password: values.password }));
  };

  const { handleSubmit, control, errors, setError } = useForm();

  useEffect(() => {
    if (auth.isAuth) {
      history.push('/');
    }
  }, [history, auth.isAuth]);

  useEffect(() => {
    if (auth.error) {
      setError([
        {
          name: 'email',
          message: auth.error,
        },
        {
          name: 'password',
          message: auth.error,
        },
      ]);
    }
  }, [setError, auth.error]);

  return (
    <div className={classes.container}>
      <div className={classes.logoWrapper}>
        <img className={classes.logo} src={Logo} alt='logo' />
        <LangSwitch className={classes.switch} />
      </div>
      <div className={classes.formWrapper}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <h1 className={classes.title}>{t('login.formTitle')}</h1>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=''
            name='email'
            label={t('login.email')}
            variant='outlined'
            autoComplete='email'
            type='email'
            required
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
          />
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=''
            name='password'
            label={t('login.password')}
            variant='outlined'
            autoComplete='current-password'
            type='password'
            required
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
          />
          <Button className={classes.button} type='submit' variant='contained' color='primary'>
            {t('login.submit')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
