import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import useStyles from './styles';


const ServerError = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <WarningIcon color="error" className={classes.icon} />
      <Typography className={classes.text} component="h1" variant="h4" color="error">{t('technicalProblem')}</Typography>
      <Typography component="h1" variant="h4" color="error">{t('tryLater')}</Typography>
    </div>
  );
};

export default ServerError;
