import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DatePicker from '../../../../components/date-picker/DatePicker';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import FormDialogActions from '../../../../components/form-dialog/FormDialogActions';
import TelInput from '../../../../components/tel-input/TelInput';
import { updateOrder } from '../../../../slices/agents.slice';
import useStyles from './styles';

const SoldOrderDialog = (props) => {
  const { isOpen, onClose, id, agentId, createdAt } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      expiredAt: new Date(createdAt).setFullYear(new Date(createdAt).getFullYear() + 1),
    },
  });

  const handleChangeCode = useCallback(([e]) => e.target.value.replace(/\s/g, ''), []);

  const onSubmit = useCallback(
    async (values) => {
      const response = await dispatch(
        updateOrder({
          agentId,
          id,
          ...values,
        })
      );

      if (!response.error) {
        onClose();
      }
    },
    [dispatch, id, agentId, onClose]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <FormDialog
      onClose={handleClose}
      isOpen={isOpen}
      onSubmit={memoSubmit}
      title={t('users.user')}
      maxWidth="sm"
    >
      <DialogContent className={classes.content}>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="code">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.code')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="code"
            name="code"
            variant="outlined"
            placeholder={t('users.code')}
            margin="dense"
            fullWidth
            required
            onChange={handleChangeCode}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="code">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('validUntil')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={DatePicker}
            control={control}
            className={classes.input}
            defaultValue={null}
            id="expiredAt"
            name="expiredAt"
            placeholder={t('validUntil')}
            margin="dense"
            fullWidth
            required
            disableToolbar
            inputVariant="outlined"
            variant="inline"
            format="dd/MM/yyyy"
            minDate={Date.now()}
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="name"
            name="name"
            variant="outlined"
            placeholder={t('users.name')}
            margin="dense"
            fullWidth
            required
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="email">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.email')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="email"
            name="email"
            type="email"
            variant="outlined"
            placeholder={t('users.email')}
            margin="dense"
            fullWidth
            required
          />
        </div>
        <div className={classes.item}>
          <InputLabel className={classes.label} htmlFor="phone">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('users.phone')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TelInput}
            control={control}
            className={classes.input}
            defaultValue=""
            id="phone"
            name="phone"
            variant="outlined"
            required
            placeholder={t('users.phone')}
            margin="dense"
            fullWidth
          />
        </div>
      </DialogContent>
      <FormDialogActions onCancel={handleClose} isLoading={formState.isSubmitting} mode="create" />
    </FormDialog>
  );
};

SoldOrderDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  agentId: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
};

SoldOrderDialog.defaultProps = {
  isOpen: false,
};

export default SoldOrderDialog;
