import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContent, InputLabel, Typography, TextField, Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import FormDialog from '../../../components/form-dialog/FormDialog';
import PageLoader from '../../../components/page-loader/PageLoader';
import DatePicker from '../../../components/date-picker/DatePicker';
import FormDialogActions from '../../../components/form-dialog/FormDialogActions';

import LocationsSelectors from '../../../selectors/locations.selectors';
import { create, edit } from '../../../slices/equipment.slice';
import { dateToString } from '../../../utils/date.utils';
import useStyles from './styles';

const ParachuteDialog = (props) => {
  const { open, onClose, data, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { equipment } = useSelector((state) => state);

  const locations = useSelector(LocationsSelectors.all);

  const { control, handleSubmit, formState, errors } = useForm({
    defaultValues: {
      ...data,
      reserveNextPack: data && data.reserveNextPack && new Date(data.reserveNextPack),
      mainProductDate: data && data.mainProductDate && new Date(data.mainProductDate),
      reserveProductDate: data && data.reserveProductDate && new Date(data.reserveProductDate),
      location: data && locations.filter((el) => el.id === data.location)[0],
    },
  });

  const onSubmit = useCallback(
    async (values) => {
      if (mode === 'edit') {
        const response = await dispatch(
          edit({
            id: data.id,
            userId: data.userId,
            ...values,
            reserveNextPack: values.reserveNextPack ? dateToString(values.reserveNextPack) : null,
            mainProductDate: values.mainProductDate ? dateToString(values.mainProductDate) : null,
            reserveProductDate: values.reserveProductDate
              ? dateToString(values.reserveProductDate)
              : null,
            location: values.location && values.location.id,
          })
        );

        if (!response.error) {
          onClose();
        }
      } else {
        const response = await dispatch(
          create({
            ...values,
            reserveNextPack: values.reserveNextPack ? dateToString(values.reserveNextPack) : null,
            mainProductDate: values.mainProductDate ? dateToString(values.mainProductDate) : null,
            reserveProductDate: values.reserveProductDate
              ? dateToString(values.reserveProductDate)
              : null,
            location: values.location && values.location.id,
          })
        );

        if (!response.error) {
          onClose();
        }
      }
    },
    [dispatch, data.id, data.userId, mode, onClose]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <FormDialog
      onClose={onClose}
      aria-labelledby="equipment-dialog"
      isOpen={open}
      onSubmit={memoSubmit}
      title={t('equipment.equipment')}
      paperClassName={classes.dialog}
    >
      <DialogContent className={classes.content}>
        <div className={classes.column}>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="aad">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.aad')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="aad"
              name="aad"
              variant="outlined"
              placeholder={t('equipment.aad')}
              margin="dense"
              disabled={mode === 'view'}
              required
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="aadCounter">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.aadCounter')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="aadCounter"
              name="aadCounter"
              type="number"
              variant="outlined"
              placeholder={t('equipment.aadCounter')}
              margin="dense"
              disabled={mode === 'view'}
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="riggerFullName">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.riggerName')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="riggerFullName"
              name="riggerFullName"
              variant="outlined"
              placeholder={t('equipment.riggerName')}
              margin="dense"
              disabled={mode === 'view'}
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="bagType">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.bagType')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="bagType"
              name="bagType"
              variant="outlined"
              placeholder={t('equipment.bagType')}
              margin="dense"
              disabled={mode === 'view'}
              required
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="size">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.size')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="size"
              name="size"
              variant="outlined"
              placeholder={t('equipment.size')}
              margin="dense"
              disabled={mode === 'view'}
              required
            />
          </div>
          <div className={cn(classes.item, classes.row)}>
            <InputLabel htmlFor="packed">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.packed')}`}
              </Typography>
            </InputLabel>
            <Controller
              as={Checkbox}
              control={control}
              defaultValue={false}
              id="packed"
              name="packed"
              variant="outlined"
              placeholder={t('equipment.packed')}
              margin="dense"
              disabled={mode === 'view'}
            />
          </div>
          <div className={cn(classes.item, classes.row)}>
            <InputLabel htmlFor="selfPack">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.selfPack')}`}
              </Typography>
            </InputLabel>
            <Controller
              as={Checkbox}
              control={control}
              defaultValue={false}
              id="selfPack"
              name="selfPack"
              variant="outlined"
              placeholder={t('equipment.selfPack')}
              margin="dense"
              disabled={mode === 'view'}
            />
          </div>
          <div className={cn(classes.item, classes.row)}>
            <InputLabel htmlFor="canRent">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {t('equipment.canRent')}
              </Typography>
            </InputLabel>
            <Controller
              as={Checkbox}
              control={control}
              defaultValue={false}
              id="canRent"
              name="canRent"
              variant="outlined"
              placeholder={t('equipment.rent')}
              margin="dense"
              disabled={mode === 'view'}
            />
          </div>
          <div className={cn(classes.item, classes.row)}>
            <InputLabel htmlFor="isTN">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.isTN')}`}
              </Typography>
            </InputLabel>
            <Controller
              as={Checkbox}
              control={control}
              defaultValue={false}
              id="isTN"
              name="isTN"
              variant="outlined"
              placeholder={t('equipment.isTN')}
              margin="dense"
              disabled={mode === 'view'}
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="rentPrice">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.rentPrice')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                min: 0,
              }}
              defaultValue=""
              id="rentPrice"
              name="rentPrice"
              type="number"
              variant="outlined"
              placeholder={t('equipment.rentPrice')}
              margin="dense"
              disabled={mode === 'view'}
            />
          </div>
        </div>
        <div className={classes.column}>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="main">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.mainParachute')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="main"
              name="main"
              variant="outlined"
              placeholder={t('equipment.mainParachute')}
              margin="dense"
              disabled={mode === 'view'}
              required
              rules={{
                validate: (value) => !/[_]/.test(value) || t('equipment.mainValidate'),
              }}
              helperText={errors?.main?.message}
              error={!!errors?.main}
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="mainSize">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.mainSize')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="mainSize"
              name="mainSize"
              variant="outlined"
              type="number"
              placeholder={t('equipment.mainSize')}
              margin="dense"
              disabled={mode === 'view'}
              required
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="mainSerialNumber">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.mainSerialNumber')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="mainSerialNumber"
              name="mainSerialNumber"
              variant="outlined"
              placeholder={t('equipment.mainSerialNumber')}
              margin="dense"
              disabled={mode === 'view'}
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="mainProductDate">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.mainProductDate')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={DatePicker}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              id="mainProductDate"
              name="mainProductDate"
              margin="dense"
              variant="inline"
              disableToolbar
              inputVariant="outlined"
              format="dd/MM/yyyy"
              placeholder={t('equipment.mainProductDate')}
              disabled={mode === 'view'}
            />
          </div>
        </div>
        <div className={classes.column}>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="reserve">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.reserve')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="reserve"
              name="reserve"
              variant="outlined"
              placeholder={t('equipment.reserve')}
              margin="dense"
              disabled={mode === 'view'}
              required
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="reserveSize">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.reserveSize')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="reserveSize"
              name="reserveSize"
              variant="outlined"
              type="number"
              placeholder={t('equipment.reserveSize')}
              margin="dense"
              disabled={mode === 'view'}
              required
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="reserveSerialNumber">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.reserveSerialNumber')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="reserveSerialNumber"
              name="reserveSerialNumber"
              variant="outlined"
              placeholder={t('equipment.reserveSerialNumber')}
              margin="dense"
              disabled={mode === 'view'}
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="reserveProductDate">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.reserveProductDate')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={DatePicker}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              id="reserveProductDate"
              name="reserveProductDate"
              variant="inline"
              placeholder={t('equipment.reserveProductDate')}
              margin="dense"
              disableToolbar
              inputVariant="outlined"
              format="dd/MM/yyyy"
              disabled={mode === 'view'}
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="reserveNextPack">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.reserveNextPack')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={DatePicker}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              id="reserveNextPack"
              name="reserveNextPack"
              placeholder={t('equipment.reserveNextPack')}
              margin="dense"
              variant="inline"
              disableToolbar
              inputVariant="outlined"
              format="dd/MM/yyyy"
              disabled={mode === 'view'}
              required
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="location">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('location')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={Autocomplete}
              control={control}
              className={cn(classes.input, classes.autocomplete)}
              defaultValue={null}
              id="location"
              name="location"
              required
              size="small"
              options={locations}
              renderOption={(option) => option.name}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={([, value]) => value}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder={t('location')} />
              )}
              disabled={mode === 'view'}
            />
          </div>
          <div className={classes.item}>
            <InputLabel className={classes.label} htmlFor="weight">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('equipment.weight')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={TextField}
              control={control}
              className={classes.input}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled,
                },
              }}
              defaultValue=""
              id="weight"
              name="weight"
              type="number"
              variant="outlined"
              placeholder={t('equipment.weight')}
              margin="dense"
              disabled={mode === 'view'}
            />
          </div>
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <FormDialogActions
        mode={mode}
        error={equipment.error}
        onCancel={handleClose}
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

ParachuteDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    userId: PropTypes.number,
    number: PropTypes.string,
    origin: PropTypes.string,
    disciplin: PropTypes.string,
    expirationDate: PropTypes.string,
    reserveNextPack: PropTypes.string,
    image: PropTypes.string,
    mainProductDate: PropTypes.string,
    reserveProductDate: PropTypes.string,
    location: PropTypes.number,
  }),
  mode: PropTypes.string,
};

ParachuteDialog.defaultProps = {
  open: false,
  data: {},
  mode: 'create',
};

export default ParachuteDialog;
