import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar, IconButton, Typography, Badge, Tooltip } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import LangSwitcher from '../lang-switcher/LangSwitcher';
import { logout } from '../../slices/auth.slice';
import { getAll as getConversations } from '../../slices/chats.slice';
import ChatSelectors from '../../selectors/chats.selectors';
import LogoPng from '../../assets/images/logo.png';
import useStyles from './styles';

function Header(props) {
  const { onDrawerToggle, title } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogout = useCallback(async () => {
    await dispatch(logout());
  }, [dispatch]);

  const isNewMessages = useSelector(ChatSelectors.isNewMessages);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getConversations());
    }, process.env.REACT_APP_CHATS_REQUEST_TIME);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch]);

  return (
    <AppBar
      position='fixed'
      className={cn(classes.appBar, { [classes.appBarWithSideMenu]: onDrawerToggle })}
    >
      <Toolbar className={classes.toolbar}>
        {onDrawerToggle && (
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={onDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        )}
        {!onDrawerToggle && (
          <div className={classes.toolbar}>
            <img className={classes.logo} src={LogoPng} alt='logo' />
          </div>
        )}
        <Typography className={classes.title} variant='h1'>
          {title}
        </Typography>
        <Link to='/communication-panel'>
          <Badge
            className={classes.badge}
            color='secondary'
            variant='dot'
            invisible={!isNewMessages}
          >
            <QuestionAnswerIcon className={classes.notificationsIcon} />
          </Badge>
        </Link>
        <LangSwitcher />
        <Tooltip title={t('logout')}>
          <IconButton className={classes.exitButton} onClick={handleLogout}>
            <ExitToAppIcon className={classes.exitIcon} />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
