import { ORDER_STATUSES } from '../../../constants/orders.constants';
import { FILTER_TYPES } from './constants';
import {
  isToday,
  getHoursPassedFromDate,
  getMinutesPassedFromDate,
  isYesterday,
} from '../../../utils/date.utils';

const TANDEMBOX_PRODUCT_ID = 85;

export const getOrderStatusType = (order) => {
  console.log(order, 'order');
  if (order.used === true) {
    return ORDER_STATUSES.used;
  }
  if (order.cancelled === true) {
    return ORDER_STATUSES.cancelled;
  }
  if (order.tbxSend) {
    return ORDER_STATUSES.sentToCustomer;
  }
  if (order.extra && order.extra.toLowerCase().includes('tandembox')) {
    return ORDER_STATUSES.waitingDelivery;
  }
  if (
    order.ordersData &&
    order.ordersData.some((el) => el.productsIds.includes(TANDEMBOX_PRODUCT_ID))
  ) {
    return ORDER_STATUSES.waitingDelivery;
  }
  if (order.payments?.length < 1) {
    return ORDER_STATUSES.paymentDelayed;
  }
  if (order.payments?.length > 0) {
    return ORDER_STATUSES.inProgress;
  }

  return ORDER_STATUSES.notUsed;
};

export const getOrderTime = (date, t, locale) => {
  if (isToday(date)) {
    if (getHoursPassedFromDate(date)) {
      return `${getHoursPassedFromDate(date)} ${t('hoursAgo')}`;
    }

    return `${getMinutesPassedFromDate(date)} ${t('minutesAgo')}`;
  } else if (isYesterday(date)) {
    return t('yesterday');
  }

  return date.toLocaleDateString(locale);
};

export const filterOrders = (formattedOrders, filterType, filterValue) => {
  if (filterValue) {
    switch (filterType) {
      case FILTER_TYPES.location: {
        return formattedOrders.filter((order) => {
          if (order.place === filterValue) {
            return true;
          }
          return false;
        });
      }
      case FILTER_TYPES.name: {
        return formattedOrders.filter((order) => {
          if (order.billing && `${order.billing.name} ${order.billing.surname}` === filterValue) {
            return true;
          }

          if (
            order.subOrders.some(
              (subOrder) =>
                subOrder.billing &&
                `${subOrder.billing.name} ${subOrder.billing.surname}` === filterValue
            )
          ) {
            return true;
          }

          return false;
        });
      }
      case FILTER_TYPES.phone: {
        return formattedOrders.filter((order) => {
          if (order.billing?.phone === filterValue) {
            return true;
          }

          if (order.subOrders.some((subOrder) => subOrder.billing?.phone === filterValue)) {
            return true;
          }

          return false;
        });
      }
      case FILTER_TYPES.email: {
        return formattedOrders.filter((order) => {
          if (order.billing?.email === filterValue) {
            return true;
          }

          if (order.subOrders.some((subOrder) => subOrder.billing?.email === filterValue)) {
            return true;
          }

          return false;
        });
      }
      case FILTER_TYPES.orderNumber: {
        return formattedOrders.filter((order) => {
          if (order.id === filterValue) {
            return true;
          }

          if (order.subOrders.some((subOrder) => subOrder.id === filterValue)) {
            return true;
          }

          return false;
        });
      }
      case FILTER_TYPES.orderCode: {
        return formattedOrders.filter((order) => {
          if (order.externalId === filterValue) {
            return true;
          }

          if (order.subOrders.some((subOrder) => subOrder.externalId === filterValue)) {
            return true;
          }

          return false;
        });
      }
      default:
        return formattedOrders;
    }
  }

  return formattedOrders;
};
