import { createSelector } from 'reselect';

const products = (state) => state.products;
const all = createSelector(products, (state) => state.all);
const forCheckin = createSelector(all, (state) => state.filter((el) => el.forCheckin));

const ProductsSelectors = {
  all,
  forCheckin,
};

export default ProductsSelectors;
