import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { DialogContent, InputLabel, Typography, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import FormDialog from '../../../../components/form-dialog/FormDialog';
import FormDialogActions from '../../../../components/form-dialog/FormDialogActions';
import PageLoader from '../../../../components/page-loader/PageLoader';

import LocationsSelectors from '../../../../selectors/locations.selectors';
import { create, resetSuccess, resetError, edit } from '../../../../slices/salaryRates.slice';
import useStyles from './styles';

const SalaryDialog = (props) => {
  const { isOpen, handleClose, data, mode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { salaryRates, competences } = useSelector((state) => state);
  const locations = useSelector(LocationsSelectors.all);

  useEffect(() => {
    let timeout;
    if (salaryRates.isSuccess) {
      timeout = setTimeout(() => {
        dispatch(resetSuccess());
        handleClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, handleClose, salaryRates.isSuccess]);

  const { control, handleSubmit, errors, formState } = useForm({
    defaultValues: {
      ...data,
      competenceIds: data.competences,
    },
  });

  const onSubmit = useCallback(
    async (values) => {
      if (mode === 'edit') {
        await dispatch(
          edit({
            id: data.id,
            locationId: data.locationId,
            ...values,
            rate: +values.rate,
            competenceIds: values.competenceIds.map((el) => el.id),
            competences: values.competenceIds,
          })
        );
      } else {
        await dispatch(
          create({
            ...values,
            locations: values.locations.map((el) => el.id),
            rate: +values.rate,
            competenceIds: values.competenceIds.map((el) => el.id),
          })
        );
      }
    },
    [dispatch, data.id, data.locationId, mode]
  );

  const onClose = useCallback(() => {
    dispatch(resetError());
    dispatch(resetSuccess());
    handleClose();
  }, [dispatch, handleClose]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <FormDialog
      onClose={onClose}
      aria-labelledby="salary-rate-dialog"
      isOpen={isOpen}
      onSubmit={memoSubmit}
      title={t('salary.salaryRate')}
    >
      <DialogContent className={classes.content}>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="name">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('salary.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="name"
            name="name"
            variant="outlined"
            required
            placeholder={t('salary.name')}
            margin="dense"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="rate">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('salary.rate')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            defaultValue=""
            id="rate"
            name="rate"
            variant="outlined"
            required
            placeholder={t('salary.rate')}
            margin="dense"
            type="number"
            disabled={mode === 'view'}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="competenceIds">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dictionaries.competences')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={Autocomplete}
            control={control}
            className={cn(classes.input, classes.autocomplete)}
            defaultValue={[]}
            id="competenceIds"
            name="competenceIds"
            onChange={([, value]) => value}
            options={competences.all}
            renderOption={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('dictionaries.competences')}
                helperText={errors.competenceIds && errors.competenceIds.message}
                error={!!errors.competenceIds}
              />
            )}
            size="small"
            disabled={mode === 'view'}
            multiple
            rules={{
              validate: (value) => value.length > 0 || t('requiredField'),
            }}
            required
          />
        </div>
        {mode === 'create' && (
          <div className={classes.row}>
            <InputLabel className={classes.label} htmlFor="locations">
              <Typography
                className={classes.labelText}
                color="textPrimary"
                variant="subtitle1"
                component="div"
              >
                {`${t('salary.dropZones')}: `}
              </Typography>
            </InputLabel>
            <Controller
              as={Autocomplete}
              control={control}
              className={cn(classes.input, classes.autocomplete)}
              defaultValue={[]}
              id="locations"
              name="locations"
              required
              size="small"
              multiple
              onChange={([, value]) => value}
              options={locations}
              renderOption={(option) => option.name}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t('salary.dropZones')}
                  helperText={errors.locations && errors.locations.message}
                  error={!!errors.locations}
                />
              )}
              rules={{
                validate: (value) => value.length > 0 || t('requiredField'),
              }}
              disabled={mode === 'view'}
            />
          </div>
        )}
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <FormDialogActions
        mode={mode}
        isSuccess={salaryRates.isSuccess}
        error={salaryRates.error}
        onCancel={onClose}
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

SalaryDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string,
    vat: PropTypes.string,
    rate: PropTypes.number,
    id: PropTypes.number,
    locationId: PropTypes.number,
    competences: PropTypes.arrayOf(PropTypes.object),
  }),
  mode: PropTypes.string,
};

SalaryDialog.defaultProps = {
  isOpen: false,
  isEditMode: false,
  data: {},
  mode: 'create',
};

export default SalaryDialog;
