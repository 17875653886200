import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { TableContainer, Table } from '@material-ui/core';
import TableHead from './TableHead';
import TableBody from './TableBody';
import useStyles from './styles';

const SortableTable = (props) => {
  const {
    data,
    onSelect,
    onShow,
    onEdit,
    onDelete,
    translationNamespace,
    columns,
    selected,
    bodyCellClassName,
    headCellClassName,
    className,
    isSelectAvailable,
  } = props;
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(columns[0].id);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);

      if (onSelect) {
        onSelect(newSelected);
      }
      return;
    }

    if (onSelect) {
      onSelect([]);
    }
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);

    if (onSelect && selectedIndex > -1) {
      onSelect((prevState) => prevState.filter((current) => current !== id));
    } else if (onSelect) {
      onSelect((prevState) => [...prevState, id]);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer className={cn(className, classes.container)}>
      <Table aria-labelledby='table' size='medium' aria-label='enhanced table'>
        <TableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
          translationNamespace={translationNamespace}
          columns={columns}
          cellClassName={headCellClassName}
          isSelectAvailable={isSelectAvailable}
          isActions={onShow || onEdit || onDelete}
        />
        <TableBody
          data={data}
          order={order}
          orderBy={orderBy}
          onClick={handleClick}
          selected={selected}
          onShow={onShow}
          onEdit={onEdit}
          onDelete={onDelete}
          columns={columns}
          cellClassName={bodyCellClassName}
          isSelectAvailable={isSelectAvailable}
        />
      </Table>
    </TableContainer>
  );
};

SortableTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  onShow: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  translationNamespace: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isSortable: PropTypes.bool,
      disablePadding: PropTypes.bool,
      render: PropTypes.func,
    })
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.number),
  bodyCellClassName: PropTypes.string,
  headCellClassName: PropTypes.string,
  className: PropTypes.string,
  isSelectAvailable: PropTypes.bool,
};

SortableTable.defaultProps = {
  onShow: null,
  onEdit: null,
  onDelete: null,
  selected: [],
  bodyCellClassName: '',
  headCellClassName: '',
  className: '',
  isSelectAvailable: true,
};

export default SortableTable;
