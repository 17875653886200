import React, {} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles';

const ConfirmDialog = (props) => {
  const {
    isOpen, title, description, onAgree, onDisagree, onClose, agreeButtonText, disagreeButtonText,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.container }}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.title} id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {agreeButtonText && (
          <Button onClick={onAgree} color="primary" variant="contained">
            {agreeButtonText}
          </Button>
        )}
        {disagreeButtonText && (
          <Button theme={{ container: classes.disagreeButton }} onClick={onDisagree} color="primary" autoFocus variant="outlined">
            {disagreeButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onAgree: PropTypes.func.isRequired,
  onDisagree: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  disagreeButtonText: PropTypes.string,
  agreeButtonText: PropTypes.string.isRequired,
};

ConfirmDialog.defaultProps = {
  isOpen: false,
  description: '',
  disagreeButtonText: '',
  onDisagree: null,
};

export default ConfirmDialog;
