import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  loaderWhite: {
    color: theme.palette.common.white,
  },
  childrenInvisible: {
    visibility: 'hidden',
  },
}));
