import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, IconButton, Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import FileUpload from '../../../../components/file-upload/FileUpload';
import useStyles from './styles';

const EMAILS = [
  {
    name: 'Victor Lapenko',
    email: 'lapenko@gmail.com',
  },
  {
    name: 'Olge Prokopenko',
    email: 'prokopenko@gmail.com',
  },
  {
    name: 'Alesia Vasilko',
    email: 'vasilko@gmail.com',
  },
];

const MessageDialog = (props) => {
  const { isOpen, handleClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);

  const handleFileUpload = useCallback((newFiles) => {
    setFiles((prevState) => ([...prevState, ...newFiles]));
  }, []);

  const handleFileDelete = useCallback((index) => {
    setFiles((prevState) => prevState.filter((el, i) => i !== index));
  }, []);

  return (
    <Dialog classes={{ paper: classes.paper }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle className={classes.header} disableTypography id="simple-dialog-title">
        <Typography className={classes.title} variant="h5" component="h1">{t('messages.newMessage')}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Autocomplete
          className={classes.autocomplete}
          multiple
          id="size-small-standard-multi"
          size="small"
          options={EMAILS}
          getOptionLabel={(option) => option.email}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label={t('messages.to')} placeholder={t('messages.to')} />
          )}
        />
        <TextField className={classes.theme} variant="standard" label={t('messages.theme')} placeholder={t('messages.theme')} />
        <textarea className={classes.textarea} />
        {files.map((file, i) => (
          <div className={classes.file} key={file.size}>
            <Typography className={classes.fileName}>{file.name}</Typography>
            <IconButton className={classes.delete} onClick={() => handleFileDelete(i)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <DialogActions className={classes.actions}>
          <Button className={classes.send} autoFocus onClick={handleClose} variant="contained" color="primary">
            {t('messages.send')}
          </Button>
          <FileUpload id="file" label={t('messages.attach')} onChange={handleFileUpload} />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

MessageDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

MessageDialog.defaultProps = {
  isOpen: false,
};

export default MessageDialog;
