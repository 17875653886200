import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import {
  DialogContent,
  Typography,
  InputLabel,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import MultipleInput from '../multiple-input/MultipleInput';
import TimeTable from '../time-table/TimeTable';
import PageLoader from '../../../../components/page-loader/PageLoader';
import FormDialog from '../../../../components/form-dialog/FormDialog';
import FormDialogActions from '../../../../components/form-dialog/FormDialogActions';
import {
  create,
  resetSuccess,
  resetError,
} from '../../../../slices/locations.slice';
import useStyles from './styles';

const FileUpload = (props) => {
  const { label, id, name, accept, required, value, onChange } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = (e) => {
    onChange(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <div className={classes.fileUpload}>
      <InputLabel className={classes.fileUploadLabel} htmlFor={id}>
        {value && (
          <>
            <Typography>{label}</Typography>
            <img className={classes.fileUploadValue} src={value} alt={label} />
          </>
        )}
        {!value && (
          <div className={classes.emptyFile}>{`${t(
            'dropZones.upload'
          )} ${label}`}</div>
        )}
      </InputLabel>
      <input
        className={classes.inputFile}
        id={id}
        name={name}
        type="file"
        accept={accept}
        required={required}
        onChange={handleChange}
      />
    </div>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  accept: '',
  required: false,
  value: '',
};

const DropZoneDialog = (props) => {
  const { isOpen, handleClose } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { locations } = useSelector((state) => state);

  useEffect(() => {
    let timeoutId;
    if (locations.isSuccess) {
      timeoutId = setTimeout(() => {
        dispatch(resetSuccess());
        handleClose();
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  });

  const { control, handleSubmit, errors, setError, formState } = useForm();

  useEffect(() => {
    if (locations.error && typeof locations.error === 'object') {
      Object.keys(locations.error).forEach((key) => {
        setError(key, '', locations.error[key][0]);
      });
    }
  }, [locations, setError]);

  const onSubmit = useCallback(
    async (values) => {
      await dispatch(
        create({
          ...values,
          lat: +values.lat,
          long: +values.long,
          runwaySize: +values.runwaySize,
          placeCost: +values.placeCost,
          commercialPlaceCost: +values.commercialPlaceCost,
        })
      );
    },
    [dispatch]
  );

  const onClose = useCallback(() => {
    dispatch(resetError());
    handleClose();
  }, [handleClose, dispatch]);

  const memoSubmit = useMemo(() => handleSubmit(onSubmit), [
    handleSubmit,
    onSubmit,
  ]);

  return (
    <FormDialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      isOpen={isOpen}
      title={t('dropZones.create')}
      onSubmit={memoSubmit}
    >
      <DialogContent className={classes.content}>
        {/* <div className={classes.row}>
          <Controller
            as={FileUpload}
            control={control}
            className={classes.input}
            id="logo"
            name="logo"
            label={t('dropZones.logo')}
            accept="image/png, image/jpeg, image/png, image/jpg"
          />
        </div> */}
        <div className={classes.row}>
          <InputLabel
            className={cn(classes.label, {
              [classes.labelError]: !!errors.name,
            })}
            htmlFor="name"
          >
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.name')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            InputProps={{
              inputProps: {
                maxLength: '20',
              },
            }}
            defaultValue=""
            id="name"
            name="name"
            variant="outlined"
            required
            placeholder={t('dropZones.name')}
            margin="dense"
            helperText={errors.name && errors.name.message}
            error={!!errors.name}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="currency">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.currency')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="currency"
            name="currency"
            variant="outlined"
            placeholder={t('dropZones.currency')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="locustProfit">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.locustProfit')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="locustProfit"
            name="locustProfit"
            variant="outlined"
            placeholder={t('dropZones.locustProfit')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="runwaySize">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.runway')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="runwaySize"
            name="runwaySize"
            variant="outlined"
            type="number"
            placeholder={t('dropZones.runwaySize')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="runwayDirections">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.runwayDirections')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="runwayDirections"
            name="runwayDirections"
            variant="outlined"
            placeholder={t('dropZones.runwayDirections')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="planes">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.planes')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={MultipleInput}
            control={control}
            className={classes.input}
            defaultValue={[]}
            id="planes"
            name="planes"
            placeholder={t('dropZones.planes')}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="workingAltitudes">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.altitudes')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={MultipleInput}
            control={control}
            className={classes.input}
            defaultValue={[]}
            id="workingAltitudes"
            name="workingAltitudes"
            type="number"
            placeholder={t('dropZones.altitudes')}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="services">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.services')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={MultipleInput}
            control={control}
            className={classes.input}
            defaultValue={[]}
            id="services"
            name="services"
            placeholder={t('dropZones.services')}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="flightsPerDay">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.flightsPerDay')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputProps={{
              classes: {
                input: classes.inputOutlinened,
              },
            }}
            defaultValue=""
            id="flightsPerDay"
            name="flightsPerDay"
            variant="outlined"
            type="number"
            placeholder={t('dropZones.flightsPerDay')}
            margin="dense"
            required
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="timeBetweenFlights">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.timeBetweenFlightsInMin')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputProps={{
              classes: {
                input: classes.inputOutlinened,
              },
            }}
            defaultValue=""
            id="timeBetweenFlights"
            name="timeBetweenFlights"
            variant="outlined"
            type="number"
            placeholder={t('dropZones.timeBetweenFlightsInMin')}
            margin="dense"
            required
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="placeCost">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.placeCost')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="placeCost"
            name="placeCost"
            type="number"
            placeholder={t('dropZones.placeCost')}
            margin="dense"
            variant="outlined"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="commercialPlaceCost">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.commercialPlaceCost')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="commercialPlaceCost"
            name="commercialPlaceCost"
            type="number"
            placeholder={t('dropZones.commercialPlaceCost')}
            margin="dense"
            variant="outlined"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="email">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.email')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="email"
            name="email"
            type="email"
            placeholder={t('dropZones.email')}
            margin="dense"
            variant="outlined"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="phones">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.phones')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={MultipleInput}
            control={control}
            className={classes.input}
            defaultValue={[]}
            id="phones"
            name="phones"
            type="phone"
            placeholder={t('dropZones.phones')}
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="address">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.address')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="address"
            name="address"
            variant="outlined"
            placeholder={t('dropZones.address')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="lat">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.lat')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="lat"
            name="lat"
            variant="outlined"
            type="number"
            required
            placeholder={t('dropZones.lat')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="long">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.lng')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="long"
            name="long"
            variant="outlined"
            type="number"
            required
            placeholder={t('dropZones.lng')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="localIp">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.serverIP')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="localIp"
            name="localIp"
            variant="outlined"
            placeholder={t('dropZones.serverIP')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="moderationUrl">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.moderationUrl')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="moderationUrl"
            name="moderationUrl"
            variant="outlined"
            placeholder={t('dropZones.moderationUrl')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="radioFrequency">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.radioFrequency')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="radioFrequency"
            name="radioFrequency"
            variant="outlined"
            type="number"
            placeholder={t('dropZones.radioFrequency')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="atz">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.atz')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="atz"
            name="atz"
            variant="outlined"
            placeholder={t('dropZones.atz')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="icao">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.icao')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="icao"
            name="icao"
            variant="outlined"
            placeholder={t('dropZones.icao')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="long">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.mode')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={
              <RadioGroup aria-label={t('dropZones.mode')}>
                <FormControlLabel
                  value="high"
                  control={<Radio />}
                  label={t('dropZones.high')}
                />
                <FormControlLabel
                  value="regular"
                  control={<Radio />}
                  label={t('dropZones.regular')}
                />
                <FormControlLabel
                  value="low"
                  control={<Radio />}
                  label={t('dropZones.low')}
                />
                <FormControlLabel
                  value="owner"
                  control={<Radio />}
                  label={t('dropZones.owner')}
                />
              </RadioGroup>
            }
            control={control}
            className={classes.radios}
            defaultValue=""
            id="mode"
            name="mode"
            variant="outlined"
          />
        </div>
        <div className={classes.row}>
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="forCP"
            name="forCP"
            required
            label={t('dropZones.forCP')}
          />
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="forCheckin"
            name="forCheckin"
            required
            label={t('dropZones.forCheckin')}
          />
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="forShop"
            name="forShop"
            required
            label={t('dropZones.forShop')}
          />
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="uspa"
            name="uspa"
            required
            label={t('dropZones.uspa')}
          />
        </div>
        <div className={classes.row}>
          <Controller
            as={<FormControlLabel control={<Checkbox color="secondary" />} />}
            control={control}
            className={classes.checkbox}
            defaultValue={false}
            id="useSensors"
            name="useSensors"
            required
            label={t('dropZones.useSensors')}
          />
        </div>
        <div className={classes.column}>
          <Typography
            className={classes.labelText}
            color="textPrimary"
            variant="subtitle1"
            component="div"
          >
            {`${t('dropZones.timeTable')}: `}
          </Typography>
          <Controller
            as={TimeTable}
            control={control}
            className={classes.timeTable}
            isEditable
            name="dates"
            defaultValue={[]}
            rules={{
              validate: (value) => (value && value.length > 0) || 'required',
            }}
          />
          <ErrorMessage errors={errors} name="dates">
            {({ message }) => (
              <Typography color="error" component="div" variant="caption">
                {message}
              </Typography>
            )}
          </ErrorMessage>
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="workingHours">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.workingHours')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="workingHours"
            name="workingHours"
            variant="outlined"
            placeholder={t('dropZones.workingHours')}
            margin="dense"
          />
        </div>
        <div className={classes.row}>
          <InputLabel className={classes.label} htmlFor="messageInfo">
            <Typography
              className={classes.labelText}
              color="textPrimary"
              variant="subtitle1"
              component="div"
            >
              {`${t('dropZones.messageInfo')}: `}
            </Typography>
          </InputLabel>
          <Controller
            as={TextField}
            control={control}
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
              },
            }}
            defaultValue=""
            id="messageInfo"
            name="messageInfo"
            variant="outlined"
            placeholder={t('dropZones.messageInfo')}
            margin="dense"
          />
        </div>
        {formState.isSubmitting && <PageLoader />}
      </DialogContent>
      <FormDialogActions
        mode="create"
        onCancel={onClose}
        isSuccess={locations.isSuccess}
        error={
          locations.error && typeof locations.error === 'object'
            ? t('dropZones.errorMessage')
            : locations.error
        }
        isLoading={formState.isSubmitting}
      />
    </FormDialog>
  );
};

DropZoneDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

DropZoneDialog.defaultProps = {
  isOpen: false,
};

export default DropZoneDialog;
