export const parseMessage = (el) => ({
  id: el.id,
  conversationId: el.conversation_id,
  createdAt: el.created_at,
  updatedAt: el.updated_at,
  userId: el.user_id,
  body: el.body,
  read: el.read,
});

export const parseMessages = (messages) => (
  messages.map((el) => parseMessage(el))
);
