import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Agent from '../agent/Agent';
import AgentDialog from '../agent-dialog/AgentDialog';
import PageLoader from '../../../../components/page-loader/PageLoader';
import { getAll } from '../../../../slices/agents.slice';
import { getAll as getProducts } from '../../../../slices/products.slice';
import AgentsSelectors from '../../../../selectors/agents.selectors';
import useStyles from './styles';

const Agents = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const agents = useSelector(AgentsSelectors.all);
  const loading = useSelector(AgentsSelectors.loading);

  console.log(agents, 'agents');

  useEffect(() => {
    const promiseAgents = dispatch(getAll());
    const promisesProducts = dispatch(getProducts());

    return () => {
      promiseAgents.abort();
      promisesProducts.abort();
    };
  }, [dispatch]);

  const [isDialog, setIsDialog] = useState(false);

  const openCreateDialog = useCallback(() => {
    setIsDialog(true);
  }, []);

  const closeCreateDialog = useCallback(() => {
    setIsDialog(false);
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <main className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h5" component="h2">
          {t('agents.title')}
        </Typography>
        <Button variant="contained" color="primary" onClick={openCreateDialog}>
          {t('agents.create')}
        </Button>
      </Toolbar>
      <div>
        {agents.map((item) => (
          <Agent key={item.id} id={item.id} />
        ))}
      </div>
      {isDialog && <AgentDialog isOpen={isDialog} onClose={closeCreateDialog} />}
    </main>
  );
};

export default Agents;
