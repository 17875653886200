import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LoadingButton from '../../../components/loading-button/LoadingButton';
import Loader from '../../../components/page-loader/PageLoader';
import ChatInput from './ChatInput';
import Message from '../message/Message';
import {
  getConversation,
  sendMessage as send,
  editMessage as edit,
  markMessageAsRead,
  deleteMessages as deleteAction,
} from '../../../slices/chats.slice';
import ChatSelectors from '../../../selectors/chats.selectors';
import { dateToString } from '../../../utils/date.utils';
import useStyles from './styles';

const Chat = (props) => {
  const { recipientId, className } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const containedRef = useRef();

  const auth = useSelector((state) => state.auth);
  const ownerId = auth?.user?.id;

  const [isLoading, setIsLoading] = useState(false);
  const chat = useSelector((state) =>
    ChatSelectors.getById(state, recipientId)
  );

  useEffect(() => {
    if (!chat) {
      setIsLoading(true);
      dispatch(getConversation(recipientId));
      setIsLoading(false);
    }
  }, [dispatch, recipientId, chat]);

  useEffect(() => {
    const setIntervalId = setInterval(() => {
      dispatch(getConversation(recipientId));
    }, 5000);

    return () => {
      clearInterval(setIntervalId);
    };
  }, [dispatch, recipientId]);

  const [inputHeight, setInputHeight] = useState(0);
  const inputRef = useRef();

  useLayoutEffect(() => {
    if (inputRef.current) {
      setInputHeight(inputRef.current?.offsetHeight);
    }
  }, []);

  const markAsRead = useCallback(
    (id) => {
      dispatch(
        markMessageAsRead({
          recipientId,
          messageId: id,
        })
      );
    },
    [dispatch, recipientId]
  );

  const [selectedMessages, setSelectedMessages] = useState([]);

  const selectMessage = useCallback(
    (messageId) => {
      setSelectedMessages((prevState) => {
        const isSelected = prevState.some((el) => el.id === messageId);

        if (isSelected) {
          return prevState.filter((el) => el.id !== messageId);
        }

        let messageObj;
        chat.messages.forEach((el) => {
          if (el.id === messageId) {
            messageObj = el;
          }
        });

        return [...prevState, messageObj];
      });
    },
    [chat]
  );

  const sendMessage = async (message) => {
    if (containedRef.current) {
      containedRef.current.scrollIntoView(false);
    }

    dispatch(
      send({
        conversationId: chat.id,
        body: message,
        recipientId: chat.recipientId,
      })
    );
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const deleteMessages = async () => {
    setIsDeleting(true);
    await dispatch(
      deleteAction({
        recipientId: chat.recipientId,
        ids: selectedMessages.map((el) => el.id),
      })
    );
    setSelectedMessages([]);
    setIsDeleting(false);
  };

  const [editedMessage, setEditedMessage] = useState(null);

  const startEditMessage = () => {
    setEditedMessage(selectedMessages[0]);
    setSelectedMessages([]);
  };

  const editMessage = async (message) => {
    await dispatch(
      edit({
        recipientId: chat.recipientId,
        id: editedMessage.id,
        body: message,
      })
    );
    setEditedMessage(null);
  };

  return (
    <Box
      className={className}
      display="flex"
      flexDirection="column"
      height="100%"
      flex={1}
    >
      <Box
        className={classes.chat}
        style={{ height: `calc(100% - ${inputHeight}px)` }}
      >
        {chat && (
          <ul className={classes.list} ref={containedRef}>
            {chat.messages?.map((message, i, messagesArray) => {
              const messageBefore = messagesArray[i - 1];
              const messageAfter = messagesArray[i + 1];

              const currentMessageDate = dateToString(
                new Date(message.createdAt)
              );

              const afterMessageDate =
                messageAfter && dateToString(new Date(messageAfter.createdAt));

              const beforeMessageDate =
                messageBefore &&
                dateToString(new Date(messageBefore.createdAt));

              const isGrouped =
                messageAfter?.userId === message.userId &&
                currentMessageDate === afterMessageDate;

              let date;

              if (!messageBefore || currentMessageDate !== beforeMessageDate) {
                date = new Date(message.createdAt);
              }

              const isToday =
                dateToString(new Date()) ===
                dateToString(new Date(message.createdAt));

              return (
                <>
                  {date && (
                    <Typography
                      className={classes.date}
                      component="span"
                      color="textSecondary"
                    >
                      {isToday
                        ? t('today')
                        : date.toLocaleDateString(i18n.language, {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })}
                    </Typography>
                  )}
                  <Message
                    id={message.id}
                    text={message.body}
                    time={message.createdAt}
                    isOut={message.userId === ownerId}
                    isGrouped={isGrouped}
                    markAsRead={markAsRead}
                    read={message.read}
                    isSelected={selectedMessages.some(
                      (el) => el.id === message.id
                    )}
                    onSelect={selectMessage}
                  />
                </>
              );
            })}
          </ul>
        )}
        {isLoading && <Loader />}
      </Box>
      <ChatInput
        ref={inputRef}
        onSend={sendMessage}
        onEdit={editMessage}
        value={editedMessage?.body}
      />
      {selectedMessages.length > 0 && (
        <Box className={classes.controls}>
          <LoadingButton
            className={classes.button}
            onClick={deleteMessages}
            variant="contained"
            color="secondary"
            loading={isDeleting}
          >
            {t('delete')}
          </LoadingButton>
          {selectedMessages.length === 1 && (
            <LoadingButton
              onClick={startEditMessage}
              variant="contained"
              color="secondary"
            >
              {t('edit')}
            </LoadingButton>
          )}
        </Box>
      )}
    </Box>
  );
};

Chat.propTypes = {
  recipientId: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Chat.defaultProps = {
  className: '',
};

export default Chat;
