import { useTranslation } from 'react-i18next';

export const dateToString = (date, format) => {
  if (!date) {
    return '';
  }

  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  if (format && format === 'dd-MM-YYYY') {
    return `${day}-${month}-${year}`;
  }

  return `${year}-${month}-${day}`;
};

export const getTimerStamp = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;

  const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();
  const secondsString = seconds < 10 ? `0${seconds}` : seconds.toString();

  return `${minutesString}:${secondsString}`;
};

export const getHoursPassedFromDate = (date) => {
  const currentTime = Date.now();
  const time = date.getTime();

  const MS_IN_HOUR = 3600000;

  const timeDiff = currentTime - time;

  return Math.floor(timeDiff / MS_IN_HOUR);
};

export const getMinutesPassedFromDate = (date) => {
  const currentTime = Date.now();
  const time = date.getTime();

  const MS_IN_MINUTE = 60000;

  const timeDiff = currentTime - time;

  return Math.floor(timeDiff / MS_IN_MINUTE);
};

export const isToday = (date) => {
  const currentTime = Date.now();
  const time = date.getTime();

  const timeDiff = currentTime - time;

  const currentDate = new Date(currentTime).getDate();

  const MS_IN_DAY = 86400000;

  return timeDiff < MS_IN_DAY && currentDate === date.getDate();
};

export const isYesterday = (date) => {
  const currentTime = Date.now();
  const time = date.getTime();

  const timeDiff = currentTime - time;

  const currentDate = new Date(currentTime).getDate();

  const MS_IN_DAY = 86400000;

  return timeDiff < MS_IN_DAY * 2 && date.getDate() === currentDate - 1;
};
